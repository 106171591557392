import { Model, RunModelStatus } from '@modules/model/ModelTypes';
import { useStudyPermissions } from '@modules/user/duck/userHooks';
import { Button, notification, Space } from '@ui';
import routes from '@routes';
import { QueryErrorType } from '@shared/utils/Error';
import { useRunModelMutation } from '@modules/model/duck/modelApi';
import { useNavigate } from 'react-router-dom';
import { TFunction } from 'i18next';
import { PauseCircleOutlined, PlayCircleOutlined, SettingOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';

export const ModelActionButtons = ({
  record,
  status,
  loading,
  isCurrentModelEnv,
  studyId,
  modelEnvLabel,
  t,
}: ModelActionButtonsProps) => {
  const navigate = useNavigate();
  const {
    userPermissions: { canDataModelRun, canDataModelConfigure },
  } = useStudyPermissions();
  const [runModel, { isLoading: isRunModelLoading }] = useRunModelMutation();
  const [showRunBtn, setShowRunBtn] = useState(true);

  useEffect(() => {
    if (status?.finished_at) {
      setShowRunBtn(true);
    }
  }, [status?.finished_at]);

  const isConfigExist = record.spark_schema?.config && !record.spark_schema?.config?.includes('data_stage.');

  const onRunModelHandler = async (id: number) => {
    try {
      setShowRunBtn(false);
      await runModel(id).unwrap();
      notification.info({ message: t('builder.runNotification') });
    } catch (e) {
      console.error(e);
      setShowRunBtn(true);
      notification.error({
        message: (e as QueryErrorType).data.userMsg,
        description: (e as QueryErrorType).data.devMsg,
        duration: 0,
      });
    }
  };

  const onConfigureModelHandler = (id: number) => {
    navigate(
      routes.study.models.view.resolver({
        modelId: id,
        studyId,
        sourceLabel: modelEnvLabel,
      }),
    );
  };

  const renderRunModelButton = () => {
    if (isRunModelLoading || loading) {
      return <Button loading />;
    }

    if (showRunBtn) {
      return (
        <Button
          title={isConfigExist ? t('rootTable.actionMenu.run') : t('errors.notConfiguredYet')}
          icon={<PlayCircleOutlined />}
          loading={isRunModelLoading}
          disabled={!isConfigExist}
          onClick={() => onRunModelHandler(record.id)}
        />
      );
    }

    return (
      <Button
        title={!status?.started_at ? t('rootTable.status.pending') : t('rootTable.status.started')}
        icon={<PauseCircleOutlined />}
        onClick={() => setShowRunBtn(true)}
      />
    );
  };

  return (
    <Space>
      {isCurrentModelEnv && canDataModelRun && renderRunModelButton()}
      {canDataModelConfigure && (
        <Button
          title={!isCurrentModelEnv ? t('rootTable.actionMenu.viewConfig') : t('rootTable.actionMenu.configure')}
          icon={<SettingOutlined />}
          loading={false}
          onClick={() => onConfigureModelHandler(record.id)}
        />
      )}
    </Space>
  );
};

interface ModelActionButtonsProps {
  record: Model;
  status?: RunModelStatus;
  t: TFunction;
  loading: boolean;
  isCurrentModelEnv?: boolean;
  studyId: number;
  modelEnvLabel?: string;
}
