import { ViewerGroupType } from '@modules/viewer/ViewerTypes';
import { t } from 'i18next';

export const SnapshotStoreAggregateSeparator = ' / ';

export class SnapshotCategoryOptionHelper {
  static getOptionDescription(groupType: ViewerGroupType) {
    return (
      ([ViewerGroupType.internal, ViewerGroupType.rccGroups, ViewerGroupType.crossStudyRT].includes(groupType) &&
        t(`datastore.storeTypes.${groupType}`).toString()) ||
      ''
    );
  }

  static getOptionValue(title: string, groupType: ViewerGroupType) {
    return `${title} ${groupType}`;
  }

  static getOption(title: string, groupType: ViewerGroupType) {
    return {
      text: title,
      description: SnapshotCategoryOptionHelper.getOptionDescription(groupType),
      value: SnapshotCategoryOptionHelper.getOptionValue(title, groupType),
    };
  }
}

export function snapshotCategoryAggregation<
  T extends { type: ViewerGroupType; tableName: string; store: string; description?: string },
>(data: T[]) {
  const aggregationCounter: Record<string, number> = {};

  const result = (data || []).reduce<
    Array<T & { store: string | string[]; type: ViewerGroupType | ViewerGroupType[] }>
  >((acc, current) => {
    const aggregatedName = current.tableName;
    aggregationCounter[aggregatedName] = (aggregationCounter[aggregatedName] || 0) + 1;

    if (aggregationCounter[aggregatedName] > 1) {
      return acc.map((c) => ({
        ...c,
        store:
          c.tableName === aggregatedName
            ? (Array.isArray(c.store) ? c.store : [c.store]).concat(current.store)
            : c.store,
        type:
          c.tableName === aggregatedName ? (Array.isArray(c.type) ? c.type : [c.type]).concat(current.type) : c.type,
      }));
    }
    return acc.concat(current);
  }, []);

  return result;
}
