import { appApi } from '@config/appApi';
import { Connection, Source } from '@modules/source/SourceTypes';
import { AuditInvalidations } from '@modules/audit/duck/auditApi';
import { TagDescription } from '@reduxjs/toolkit/query/react';
import { IFormValue } from 'react-auto-form';
import { SOURCE_TAG_DESCRIPTION } from './sourceConstants';

export const SourceApiRoutes = {
  list: `api/sources`,
  source: (sourceId: number) => `api/sources/${sourceId}`,
  connectionList: 'api/connections/list',
  testConnection: 'api/connection/test',
};

export const SourceInvalidations: {
  ID: (sourceId: number) => TagDescription<SOURCE_TAG_DESCRIPTION.ID>;
  LIST: TagDescription<SOURCE_TAG_DESCRIPTION.LIST>;
  CONNECTION_LIST: TagDescription<SOURCE_TAG_DESCRIPTION.CONNECTION_LIST>;
} = {
  ID: (sourceId: number) => ({ type: SOURCE_TAG_DESCRIPTION.ID, sourceId }),
  LIST: { type: SOURCE_TAG_DESCRIPTION.LIST, id: 'LIST' },
  CONNECTION_LIST: { type: SOURCE_TAG_DESCRIPTION.CONNECTION_LIST, id: 'LIST' },
};

export const SourceApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    sourceListPaginated: builder.query<SourceListResponse, SourceListQueryParams | void>({
      providesTags: [SourceInvalidations.LIST],
      query: (params) => ({
        params,
        url: SourceApiRoutes.list,
      }),
    }),
    sourceList: builder.query<SourceListResponse['items'], SourceListQueryParams | void>({
      providesTags: [SourceInvalidations.LIST],
      query: (params) => ({
        params,
        url: SourceApiRoutes.list,
      }),
    }),
    connectionList: builder.query<ConnectionListResponse, void>({
      providesTags: [SourceInvalidations.CONNECTION_LIST],
      query: () => ({
        url: SourceApiRoutes.connectionList,
      }),
    }),
    deleteSource: builder.mutation<void, number>({
      invalidatesTags: (request, error, sourceId) => [
        SourceInvalidations.LIST,
        SourceInvalidations.ID(sourceId),
        AuditInvalidations.LIST,
      ],
      query: (sourceId) => ({
        method: 'DELETE',
        url: SourceApiRoutes.source(sourceId),
      }),
    }),
    saveSource: builder.mutation<void, SourceSaveQuery>({
      invalidatesTags: (request, error, data) =>
        data.id
          ? [SourceInvalidations.LIST, SourceInvalidations.ID(data.id), AuditInvalidations.LIST]
          : [SourceInvalidations.LIST, AuditInvalidations.LIST],
      query: (data) => ({
        data,
        method: data.id ? 'PUT' : 'POST',
        url: data.id ? SourceApiRoutes.source(data.id) : SourceApiRoutes.list,
      }),
    }),
    testConnection: builder.mutation<TestConnectionResponse, IFormValue>({
      invalidatesTags: [SourceInvalidations.CONNECTION_LIST],
      query: (data) => ({
        data,
        method: 'POST',
        url: SourceApiRoutes.testConnection,
      }),
    }),
  }),
});

export const {
  useSourceListQuery,
  useSourceListPaginatedQuery,
  useConnectionListQuery,
  useDeleteSourceMutation,
  useSaveSourceMutation,
  useTestConnectionMutation,
} = SourceApi;

export interface SourceListResponse {
  currentPage: number;
  totalItems: number;
  totalPages: number;
  items: Array<Source>;
}

export type ConnectionListResponse = Array<Connection>;

interface SourceSaveQuery {
  id?: number;
  name: string;
  connection_id: number;
  connection_name: string;
  connection_properties: IFormValue;
  active: boolean;
}

interface SourceListQueryParams {
  page?: number;
  order?: string;
  sort_by?: string;
}

interface TestConnectionResponse {
  message?: string;
}
