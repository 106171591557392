import { selectStoreModals } from '@modules/stores/duck/storeSelectors';
import { storeActions } from '@modules/stores/duck/storeSlice';
import { StoreModalsType } from '@modules/stores/modals/StoreModalsConstants';
import { useDispatch, useSelector } from 'react-redux';
import { StoreModalsSaveStore, StoreModalsSaveStoreProps } from './components';

export const StoreModalsController = () => {
  const dispatch = useDispatch();
  const { data, selectedModal } = useSelector(selectStoreModals);

  const closeModal = () => {
    dispatch(storeActions.dropModal());
  };

  return (
    <>
      <StoreModalsSaveStore
        open={selectedModal === StoreModalsType.saveStore}
        data={data as StoreModalsSaveStoreProps['data']}
        onClose={closeModal}
      />
    </>
  );
};
