import { selectAuditModals } from '@modules/audit/duck/auditSelectors';
import { auditActions } from '@modules/audit/duck/auditSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AuditModalsDetails, AuditModalsDetailsProps } from './components';
import { AuditModalsType } from './AuditModalsConstants';

export const AuditModalsController = () => {
  const dispatch = useDispatch();
  const { data, selectedModal } = useSelector(selectAuditModals);

  const closeModal = () => {
    dispatch(auditActions.dropModal());
  };

  return (
    <>
      <AuditModalsDetails
        open={selectedModal === AuditModalsType.showDetails}
        data={data as AuditModalsDetailsProps['data']}
        onClose={closeModal}
      />
    </>
  );
};
