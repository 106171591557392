import { Loader, QueryError } from '@components';
import { useLibraryAppListener } from '@modules/library/root/duck/libraryHooks';
import { selectLibraryAppPreloaded } from '@modules/library/root/duck/librarySelectors';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const LibraryAppRouterProvider = () => {
  const { queryError } = useLibraryAppListener();

  const libraryAppPreloaded = useSelector(selectLibraryAppPreloaded);

  if (queryError) {
    return <QueryError error={{ status: 404, data: { userMsg: 'Library Not Found' } }} title="Library Not Found" />;
  }

  if (!libraryAppPreloaded) {
    return <Loader mode="fixed" />;
  }

  return <Outlet />;
};
