import { FormItem, Radio } from '@ui';
import { initPeriodicallyFields, PeriodicallyFields, RUN_TYPE } from '@components/form/schedulerRun';
import { useTranslation } from 'react-i18next';

export const GpdipForm = () => {
  const { t } = useTranslation('datastore');

  return (
    <>
      <FormItem asInfo>
        <FormItem name={['export', 'run']}>
          <Radio.Group>
            <Radio value={RUN_TYPE.automatic}>{t('scheduler.automatic')}</Radio>
            <Radio value={RUN_TYPE.periodically}>{t('scheduler.periodically')}</Radio>
            <Radio value={RUN_TYPE.manual}>{t('scheduler.manual')}</Radio>
          </Radio.Group>
        </FormItem>
      </FormItem>
      <FormItem dependencies={[['export', 'run']]} asInfo>
        {(form) => {
          if (form.getFieldValue(['export', 'run']) === 'periodically') {
            return (
              <FormItem name={['export', 'runPeriodically']} initialValue={initPeriodicallyFields} asInfo>
                <PeriodicallyFields t={t} />
              </FormItem>
            );
          }
          return null;
        }}
      </FormItem>
    </>
  );
};
