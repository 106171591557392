import { analysisObjectModelActions } from '@modules/library/analysisObjects/model/duck/analysisObjectModelSlice';
import { selectAnalysisObjectModelModals } from '@modules/library/analysisObjects/model/duck/analysisObjectModelSelectors';
import { EAnalysisObjectModelModalsType } from '@modules/library/analysisObjects/model/modals/AnalysisObjectModelModalsConstants';
import { useDispatch, useSelector } from 'react-redux';
import { AnalysisObjectModelModalsCopyModel } from './components';
import { IAnalysisObjectModelModalsCopyModelProps } from './components/copy/AnalysisObjectModelModalsCopyModel';

export const AnalysisObjectModelModalsController = () => {
  const dispatch = useDispatch();
  const { data, selectedModal } = useSelector(selectAnalysisObjectModelModals);

  const closeModal = () => {
    dispatch(analysisObjectModelActions.dropModal());
  };

  return (
    <AnalysisObjectModelModalsCopyModel
      open={selectedModal === EAnalysisObjectModelModalsType.copyModel}
      data={data as IAnalysisObjectModelModalsCopyModelProps['data']}
      onClose={closeModal}
    />
  );
};
