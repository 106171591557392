import { Space, Typography } from '@ui';
import { ReactNode } from 'react';

export const PageTitle = ({ children, extra, hidden }: PageTitleProps) => {
  if (hidden) {
    return null;
  }

  return (
    <Space full align="start" justify="space-between">
      <Typography.Title level={2}>{children}</Typography.Title>
      {extra && <div css={cssExtra}>{extra}</div>}
    </Space>
  );
};

const cssExtra = () => ({
  marginTop: '0.2em',
});

export interface PageTitleProps {
  children: ReactNode;
  pageTitle?: string;
  extra?: ReactNode;
  hidden?: boolean;
}
