import { selectStudyModals } from '@modules/study/duck/studySelectors';
import { StudyModalsType } from '@modules/study/modals';
import { StudyModalsDbSchema } from '@modules/study/modals/components';
import { studyActions } from '@modules/study/duck/studySlice';
import { useDispatch, useSelector } from 'react-redux';

export const StudyModalsController = () => {
  const dispatch = useDispatch();
  const { selectedModal } = useSelector(selectStudyModals);

  const closeModal = () => {
    dispatch(studyActions.dropModal());
  };

  return <StudyModalsDbSchema open={selectedModal === StudyModalsType.dbschema} onClose={closeModal} />;
};
