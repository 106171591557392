import { CSSObject } from '@emotion/react';

export const StudyRccIframePage = ({ pageId, studyId, title }: StudyRccIframePageProps) => {
  return (
    <div css={cssBody}>
      <div css={cssContentWrapper}>
        <iframe
          title={title}
          src={`https://rcdev.redcapcloud.com/#cid=nph${pageId}&studyId=${studyId}`}
          css={cssContent}
        />
      </div>
    </div>
  );
};

const cssBody = (): CSSObject => ({
  width: '100vw',
  overflow: 'hidden',
  position: 'relative',
});

const cssContentWrapper = (): CSSObject => ({
  width: '100vw',
  height: '100vh',
  margin: '-50px 0 0 -270px',
});

const cssContent = (): CSSObject => ({
  width: '100%',
  height: '100%',
});

interface StudyRccIframePageProps {
  title: string;
  pageId: number;
  studyId: number;
}
