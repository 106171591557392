export const OPERATORS = [
  { value: '[]' },
  { value: '()' },
  { value: '-' },
  { value: '+' },
  { value: '*' },
  { value: '/' },
  { value: '^' },
  { value: '%' },
  { value: '=' },
  { value: '==' },
  { value: '<>' },
  { value: '>' },
  { value: '<' },
  { value: '>=' },
  { value: '<=' },
  { value: '<=>' },
  { value: '!' },
  { value: 'and' },
  { value: 'or' },
  { value: 'not' },
];

export const FUNCTIONS = [
  {
    name: 'abs',
    description: 'abs(expr) - Returns the absolute value of the numeric or interval value.',
    rawData: {
      value: 'abs()',
    },
  },
  {
    name: 'acos',
    description: 'acos(expr) - Returns the inverse cosine (a.k.a. arc cosine) of expr.',
    rawData: {
      value: 'acos()',
    },
  },
  {
    name: 'acosh',
    description: 'acosh(expr) - Returns inverse hyperbolic cosine of expr.',
    rawData: {
      value: 'acosh()',
    },
  },
  {
    name: 'add_months',
    description: 'add_months(start_date, num_months) - Returns the date that is num_months after start_date.',
    rawData: {
      value: 'add_months()',
    },
  },
  {
    name: 'aggregate',
    description:
      'aggregate(expr, start, merge, finish) - Applies a binary operator to an initial state and all elements in the array, and reduces this to a single state. The final state is converted into the final result by applying a finish function.',
    rawData: {
      value: 'aggregate()',
    },
  },
  {
    name: 'any',
    description: 'any(expr) - Returns true if at least one value of expr is true.',
    rawData: {
      value: 'any()',
    },
  },
  {
    name: 'any_value',
    description:
      'any_value(expr[, isIgnoreNull]) - Returns some value of expr for a group of rows. If isIgnoreNull is true, returns only non-null values.',
    rawData: {
      value: 'any_value()',
    },
  },
  {
    name: 'approx_count_distinct',
    description:
      'approx_count_distinct(expr[, relativeSD]) - Returns the estimated cardinality by HyperLogLog++. relativeSD defines the maximum relative standard deviation allowed.',
    rawData: {
      value: 'approx_count_distinct()',
    },
  },
  {
    name: 'approx_percentile',
    description:
      'approx_percentile(col, percentage [, accuracy]) - Returns the approximate percentile of the numeric or ansi interval column col which is the smallest value in the ordered col values (sorted from least to greatest) such that no more than percentage of col values is less than the value or equal to that value. The value of percentage must be between 0.0 and 1.0. The accuracy parameter (default: 10000) is a positive numeric literal which controls approximation accuracy at the cost of memory.',
    rawData: {
      value: 'approx_percentile()',
    },
  },
  {
    name: 'array',
    description: 'array(expr, ...) - Returns an array with the given elements.',
    rawData: {
      value: 'array()',
    },
  },
  {
    name: 'array_agg',
    description: 'array_agg(expr) - Collects and returns a list of non-unique elements.',
    rawData: {
      value: 'array_agg()',
    },
  },
  {
    name: 'array_append',
    description:
      'array_append(array, element) - Add the element at the end of the array passed as first argument. Type of element should be similar to type of the elements of the array. Null element is also appended into the array. But if the array passed, is NULL output is NULL',
    rawData: {
      value: 'array_append()',
    },
  },
  {
    name: 'array_compact',
    description: 'array_compact(array) - Removes null values from the array.',
    rawData: {
      value: 'array_compact()',
    },
  },
  {
    name: 'array_contains',
    description: 'array_contains(array, value) - Returns true if the array contains the value.',
    rawData: {
      value: 'array_contains()',
    },
  },
  {
    name: 'array_distinct',
    description: 'array_distinct(array) - Removes duplicate values from the array.',
    rawData: {
      value: 'array_distinct()',
    },
  },
  {
    name: 'array_except',
    description:
      'array_except(array1, array2) - Returns an array of the elements in array1 but not in array2, without duplicates.',
    rawData: {
      value: 'array_except()',
    },
  },
  {
    name: 'array_insert',
    description:
      "array_insert(x, pos, val) - Places val into index pos of array x. Array indices start at 1. The maximum negative index is -1 for which the function inserts new element after the current last element. Index above array size appends the array, or prepends the array if index is negative, with 'null' elements.",
    rawData: {
      value: 'array_insert()',
    },
  },
  {
    name: 'array_intersect',
    description:
      'array_intersect(array1, array2) - Returns an array of the elements in the intersection of array1 and array2, without duplicates.',
    rawData: {
      value: 'array_intersect()',
    },
  },
  {
    name: 'array_join',
    description:
      'array_join(array, delimiter[, nullReplacement]) - Concatenates the elements of the given array using the delimiter and an optional string to replace nulls. If no value is set for nullReplacement, any null value is filtered.',
    rawData: {
      value: 'array_join()',
    },
  },
  {
    name: 'array_max',
    description:
      'array_max(array) - Returns the maximum value in the array. NaN is greater than any non-NaN elements for double/float type. NULL elements are skipped.',
    rawData: {
      value: 'array_max()',
    },
  },
  {
    name: 'array_min',
    description:
      'array_min(array) - Returns the minimum value in the array. NaN is greater than any non-NaN elements for double/float type. NULL elements are skipped.',
    rawData: {
      value: 'array_min()',
    },
  },
  {
    name: 'array_position',
    description:
      'array_position(array, element) - Returns the (1-based) index of the first matching element of the array as long, or 0 if no match is found.',
    rawData: {
      value: 'array_position()',
    },
  },
  {
    name: 'array_prepend',
    description:
      'array_prepend(array, element) - Add the element at the beginning of the array passed as first argument. Type of element should be the same as the type of the elements of the array. Null element is also prepended to the array. But if the array passed is NULL output is NULL',
    rawData: {
      value: 'array_prepend()',
    },
  },
  {
    name: 'array_remove',
    description: 'array_remove(array, element) - Remove all elements that equal to element from array.',
    rawData: {
      value: 'array_remove()',
    },
  },
  {
    name: 'array_repeat',
    description: 'array_repeat(element, count) - Returns the array containing element count times.',
    rawData: {
      value: 'array_repeat()',
    },
  },
  {
    name: 'array_size',
    description: 'array_size(expr) - Returns the size of an array. The function returns null for null input.',
    rawData: {
      value: 'array_size()',
    },
  },
  {
    name: 'array_sort',
    description:
      'array_sort(expr, func) - Sorts the input array. If func is omitted, sort in ascending order. The elements of the input array must be orderable. NaN is greater than any non-NaN elements for double/float type. Null elements will be placed at the end of the returned array.',
    rawData: {
      value: 'array_sort()',
    },
  },
  {
    name: 'array_union',
    description:
      'array_union(array1, array2) - Returns an array of the elements in the union of array1 and array2, without duplicates.',
    rawData: {
      value: 'array_union()',
    },
  },
  {
    name: 'arrays_overlap',
    description:
      'arrays_overlap(a1, a2) - Returns true if a1 contains at least a non-null element present also in a2. If the arrays have no common element and they are both non-empty and either of them contains a null element null is returned, false otherwise.',
    rawData: {
      value: 'arrays_overlap()',
    },
  },
  {
    name: 'arrays_zip',
    description:
      'arrays_zip(a1, a2, ...) - Returns a merged array of structs in which the N-th struct contains all N-th values of input arrays.',
    rawData: {
      value: 'arrays_zip()',
    },
  },
  {
    name: 'ascii',
    description: 'ascii(str) - Returns the numeric value of the first character of str.',
    rawData: {
      value: 'ascii()',
    },
  },
  {
    name: 'asin',
    description: 'asin(expr) - Returns the inverse sine (a.k.a. arc sine) the arc sin of expr.',
    rawData: {
      value: 'asin()',
    },
  },
  {
    name: 'asinh',
    description: 'asinh(expr) - Returns inverse hyperbolic sine of expr.',
    rawData: {
      value: 'asinh()',
    },
  },
  {
    name: 'assert_true',
    description: 'assert_true(expr) - Throws an exception if expr is not true.',
    rawData: {
      value: 'assert_true()',
    },
  },
  {
    name: 'atan',
    description: 'atan(expr) - Returns the inverse tangent (a.k.a. arc tangent) of expr.',
    rawData: {
      value: 'atan()',
    },
  },
  {
    name: 'atan2',
    description:
      'atan2(exprY, exprX) - Returns the angle in radians between the positive x-axis of a plane and the point given by the coordinates (exprX, exprY).',
    rawData: {
      value: 'atan2()',
    },
  },
  {
    name: 'atanh',
    description: 'atanh(expr) - Returns inverse hyperbolic tangent of expr.',
    rawData: {
      value: 'atanh()',
    },
  },
  {
    name: 'avg',
    description: 'avg(expr) - Returns the mean calculated from values of a group.',
    rawData: {
      value: 'avg()',
    },
  },
  {
    name: 'base64',
    description: 'base64(bin) - Converts the argument from a binary bin to a base 64 string.',
    rawData: {
      value: 'base64()',
    },
  },
  {
    name: 'bigint',
    description: 'bigint(expr) - Casts the value expr to the target data type bigint.',
    rawData: {
      value: 'bigint()',
    },
  },
  {
    name: 'bin',
    description: 'bin(expr) - Returns the string representation of the long value expr represented in binary.',
    rawData: {
      value: 'bin()',
    },
  },
  {
    name: 'binary',
    description: 'binary(expr) - Casts the value expr to the target data type binary.',
    rawData: {
      value: 'binary()',
    },
  },
  {
    name: 'bit_and',
    description: 'bit_and(expr) - Returns the bitwise AND of all non-null input values, or null if none.',
    rawData: {
      value: 'bit_and()',
    },
  },
  {
    name: 'bit_count',
    description:
      'bit_count(expr) - Returns the number of bits that are set in the argument expr as an unsigned 64-bit integer, or NULL if the argument is NULL.',
    rawData: {
      value: 'bit_count()',
    },
  },
  {
    name: 'bit_get',
    description:
      'bit_get(expr, pos) - Returns the value of the bit (0 or 1) at the specified position. The positions are numbered from right to left, starting at zero. The position argument cannot be negative.',
    rawData: {
      value: 'bit_get()',
    },
  },
  {
    name: 'bit_length',
    description: 'bit_length(expr) - Returns the bit length of string data or number of bits of binary data.',
    rawData: {
      value: 'bit_length()',
    },
  },
  {
    name: 'bit_or',
    description: 'bit_or(expr) - Returns the bitwise OR of all non-null input values, or null if none.',
    rawData: {
      value: 'bit_or()',
    },
  },
  {
    name: 'bit_xor',
    description: 'bit_xor(expr) - Returns the bitwise XOR of all non-null input values, or null if none.',
    rawData: {
      value: 'bit_xor()',
    },
  },
  {
    name: 'bitmap_bit_position',
    description: 'bitmap_bit_position(child) - Returns the bit position for the given input child expression.',
    rawData: {
      value: 'bitmap_bit_position()',
    },
  },
  {
    name: 'bitmap_bucket_number',
    description: 'bitmap_bucket_number(child) - Returns the bucket number for the given input child expression.',
    rawData: {
      value: 'bitmap_bucket_number()',
    },
  },
  {
    name: 'bitmap_construct_agg',
    description:
      'bitmap_construct_agg(child) - Returns a bitmap with the positions of the bits set from all the values from the child expression. The child expression will most likely be bitmap_bit_position().',
    rawData: {
      value: 'bitmap_construct_agg()',
    },
  },
  {
    name: 'bitmap_count',
    description: 'bitmap_count(child) - Returns the number of set bits in the child bitmap.',
    rawData: {
      value: 'bitmap_count()',
    },
  },
  {
    name: 'bitmap_or_agg',
    description:
      'bitmap_or_agg(child) - Returns a bitmap that is the bitwise OR of all of the bitmaps from the child expression. The input should be bitmaps created from bitmap_construct_agg().',
    rawData: {
      value: 'bitmap_or_agg()',
    },
  },
  {
    name: 'bool_and',
    description: 'bool_and(expr) - Returns true if all values of expr are true.',
    rawData: {
      value: 'bool_and()',
    },
  },
  {
    name: 'bool_or',
    description: 'bool_or(expr) - Returns true if at least one value of expr is true.',
    rawData: {
      value: 'bool_or()',
    },
  },
  {
    name: 'boolean',
    description: 'boolean(expr) - Casts the value expr to the target data type boolean.',
    rawData: {
      value: 'boolean()',
    },
  },
  {
    name: 'bround',
    description: 'bround(expr, d) - Returns expr rounded to d decimal places using HALF_EVEN rounding mode.',
    rawData: {
      value: 'bround()',
    },
  },
  {
    name: 'btrim',
    description: 'btrim(str) - Removes the leading and trailing space characters from str.',
    rawData: {
      value: 'btrim()',
    },
  },
  {
    name: 'cardinality',
    description: 'cardinality(expr) - Returns the size of an array or a map.',
    rawData: {
      value: 'cardinality()',
    },
  },
  {
    name: 'cast',
    description: 'cast(expr AS type) - Casts the value expr to the target data type type.',
    rawData: {
      value: 'cast()',
    },
  },
  {
    name: 'cbrt',
    description: 'cbrt(expr) - Returns the cube root of expr.',
    rawData: {
      value: 'cbrt()',
    },
  },
  {
    name: 'ceil',
    description:
      'ceil(expr[, scale]) - Returns the smallest number after rounding up that is not smaller than expr. An optional scale parameter can be specified to control the rounding behavior.',
    rawData: {
      value: 'ceil()',
    },
  },
  {
    name: 'ceiling',
    description:
      'ceiling(expr[, scale]) - Returns the smallest number after rounding up that is not smaller than expr. An optional scale parameter can be specified to control the rounding behavior.',
    rawData: {
      value: 'ceiling()',
    },
  },
  {
    name: 'char',
    description:
      'char(expr) - Returns the ASCII character having the binary equivalent to expr. If n is larger than 256 the result is equivalent to chr(n % 256)',
    rawData: {
      value: 'char()',
    },
  },
  {
    name: 'char_length',
    description:
      'char_length(expr) - Returns the character length of string data or number of bytes of binary data. The length of string data includes the trailing spaces. The length of binary data includes binary zeros.',
    rawData: {
      value: 'char_length()',
    },
  },
  {
    name: 'character_length',
    description:
      'character_length(expr) - Returns the character length of string data or number of bytes of binary data. The length of string data includes the trailing spaces. The length of binary data includes binary zeros.',
    rawData: {
      value: 'character_length()',
    },
  },
  {
    name: 'chr',
    description:
      'chr(expr) - Returns the ASCII character having the binary equivalent to expr. If n is larger than 256 the result is equivalent to chr(n % 256)',
    rawData: {
      value: 'chr()',
    },
  },
  {
    name: 'coalesce',
    description: 'coalesce(expr1, expr2, ...) - Returns the first non-null argument if exists. Otherwise, null.',
    rawData: {
      value: 'coalesce()',
    },
  },
  {
    name: 'collect_list',
    description: 'collect_list(expr) - Collects and returns a list of non-unique elements.',
    rawData: {
      value: 'collect_list()',
    },
  },
  {
    name: 'collect_set',
    description: 'collect_set(expr) - Collects and returns a set of unique elements.',
    rawData: {
      value: 'collect_set()',
    },
  },
  {
    name: 'concat',
    description: 'concat(col1, col2, ..., colN) - Returns the concatenation of col1, col2, ..., colN.',
    rawData: {
      value: 'concat()',
    },
  },
  {
    name: 'concat_ws',
    description:
      'concat_ws(sep[, str | array(str)]+) - Returns the concatenation of the strings separated by sep, skipping null values.',
    rawData: {
      value: 'concat_ws()',
    },
  },
  {
    name: 'contains',
    description:
      'contains(left, right) - Returns a boolean. The value is True if right is found inside left. Returns NULL if either input expression is NULL. Otherwise, returns False. Both left or right must be of STRING or BINARY type.',
    rawData: {
      value: 'contains()',
    },
  },
  {
    name: 'conv',
    description: 'conv(num, from_base, to_base) - Convert num from from_base to to_base.',
    rawData: {
      value: 'conv()',
    },
  },
  {
    name: 'convert_timezone',
    description:
      'convert_timezone([sourceTz, ]targetTz, sourceTs) - Converts the timestamp without time zone sourceTs from the sourceTz time zone to targetTz.',
    rawData: {
      value: 'convert_timezone()',
    },
  },
  {
    name: 'corr',
    description: 'corr(expr1, expr2) - Returns Pearson coefficient of correlation between a set of number pairs.',
    rawData: {
      value: 'corr()',
    },
  },
  {
    name: 'cos',
    description: 'cos(expr) - Returns the cosine of expr.',
    rawData: {
      value: 'cos()',
    },
  },
  {
    name: 'cosh',
    description: 'cosh(expr) - Returns the hyperbolic cosine of expr.',
    rawData: {
      value: 'cosh()',
    },
  },
  {
    name: 'cot',
    description: 'cot(expr) - Returns the cotangent of expr.',
    rawData: {
      value: 'cot()',
    },
  },
  {
    name: 'count',
    description: 'count(*) - Returns the total number of retrieved rows, including rows containing null.',
    rawData: {
      value: 'count()',
    },
  },
  {
    name: 'count_if',
    description: 'count_if(expr) - Returns the number of TRUE values for the expression.',
    rawData: {
      value: 'count_if()',
    },
  },
  {
    name: 'count_min_sketch',
    description:
      'count_min_sketch(col, eps, confidence, seed) - Returns a count-min sketch of a column with the given esp, confidence and seed. The result is an array of bytes, which can be deserialized to a CountMinSketch before usage. Count-min sketch is a probabilistic data structure used for cardinality estimation using sub-linear space.',
    rawData: {
      value: 'count_min_sketch()',
    },
  },
  {
    name: 'covar_pop',
    description: 'covar_pop(expr1, expr2) - Returns the population covariance of a set of number pairs.',
    rawData: {
      value: 'covar_pop()',
    },
  },
  {
    name: 'covar_samp',
    description: 'covar_samp(expr1, expr2) - Returns the sample covariance of a set of number pairs.',
    rawData: {
      value: 'covar_samp()',
    },
  },
  {
    name: 'crc32',
    description: 'crc32(expr) - Returns a cyclic redundancy check value of the expr as a bigint.',
    rawData: {
      value: 'crc32()',
    },
  },
  {
    name: 'csc',
    description: 'csc(expr) - Returns the cosecant of expr.',
    rawData: {
      value: 'csc()',
    },
  },
  {
    name: 'cume_dist',
    description: 'cume_dist() - Computes the position of a value relative to all values in the partition.',
    rawData: {
      value: 'cume_dist()',
    },
  },
  {
    name: 'curdate',
    description:
      'curdate() - Returns the current date at the start of query evaluation. All calls of curdate within the same query return the same value.',
    rawData: {
      value: 'curdate()',
    },
  },
  {
    name: 'current_catalog',
    description: 'current_catalog() - Returns the current catalog.',
    rawData: {
      value: 'current_catalog()',
    },
  },
  {
    name: 'current_database',
    description: 'current_database() - Returns the current database.',
    rawData: {
      value: 'current_database()',
    },
  },
  {
    name: 'current_date',
    description:
      'current_date() - Returns the current date at the start of query evaluation. All calls of current_date within the same query return the same value.',
    rawData: {
      value: 'current_date()',
    },
  },
  {
    name: 'current_schema',
    description: 'current_schema() - Returns the current database.',
    rawData: {
      value: 'current_schema()',
    },
  },
  {
    name: 'current_timestamp',
    description:
      'current_timestamp() - Returns the current timestamp at the start of query evaluation. All calls of current_timestamp within the same query return the same value.',
    rawData: {
      value: 'current_timestamp()',
    },
  },
  {
    name: 'current_timezone',
    description: 'current_timezone() - Returns the current session local timezone.',
    rawData: {
      value: 'current_timezone()',
    },
  },
  {
    name: 'current_user',
    description: 'current_user() - user name of current execution context.',
    rawData: {
      value: 'current_user()',
    },
  },
  {
    name: 'date',
    description: 'date(expr) - Casts the value expr to the target data type date.',
    rawData: {
      value: 'date()',
    },
  },
  {
    name: 'date_add',
    description: 'date_add(start_date, num_days) - Returns the date that is num_days after start_date.',
    rawData: {
      value: 'date_add()',
    },
  },
  {
    name: 'date_diff',
    description: 'date_diff(endDate, startDate) - Returns the number of days from startDate to endDate.',
    rawData: {
      value: 'date_diff()',
    },
  },
  {
    name: 'date_format',
    description:
      'date_format(timestamp, fmt) - Converts timestamp to a value of string in the format specified by the date format fmt.',
    rawData: {
      value: 'date_format()',
    },
  },
  {
    name: 'date_from_unix_date',
    description: 'date_from_unix_date(days) - Create date from the number of days since 1970-01-01.',
    rawData: {
      value: 'date_from_unix_date()',
    },
  },
  {
    name: 'date_part',
    description: 'date_part(field, source) - Extracts a part of the date/timestamp or interval source.',
    rawData: {
      value: 'date_part()',
    },
  },
  {
    name: 'date_sub',
    description: 'date_sub(start_date, num_days) - Returns the date that is num_days before start_date.',
    rawData: {
      value: 'date_sub()',
    },
  },
  {
    name: 'date_trunc',
    description: 'date_trunc(fmt, ts) - Returns timestamp ts truncated to the unit specified by the format model fmt.',
    rawData: {
      value: 'date_trunc()',
    },
  },
  {
    name: 'dateadd',
    description: 'dateadd(start_date, num_days) - Returns the date that is num_days after start_date.',
    rawData: {
      value: 'dateadd()',
    },
  },
  {
    name: 'datediff',
    description: 'datediff(endDate, startDate) - Returns the number of days from startDate to endDate.',
    rawData: {
      value: 'datediff()',
    },
  },
  {
    name: 'datepart',
    description: 'datepart(field, source) - Extracts a part of the date/timestamp or interval source.',
    rawData: {
      value: 'datepart()',
    },
  },
  {
    name: 'day',
    description: 'day(date) - Returns the day of month of the date/timestamp.',
    rawData: {
      value: 'day()',
    },
  },
  {
    name: 'dayofmonth',
    description: 'dayofmonth(date) - Returns the day of month of the date/timestamp.',
    rawData: {
      value: 'dayofmonth()',
    },
  },
  {
    name: 'dayofweek',
    description:
      'dayofweek(date) - Returns the day of the week for date/timestamp (1 = Sunday, 2 = Monday, ..., 7 = Saturday).',
    rawData: {
      value: 'dayofweek()',
    },
  },
  {
    name: 'dayofyear',
    description: 'dayofyear(date) - Returns the day of year of the date/timestamp.',
    rawData: {
      value: 'dayofyear()',
    },
  },
  {
    name: 'decimal',
    description: 'decimal(expr) - Casts the value expr to the target data type decimal.',
    rawData: {
      value: 'decimal()',
    },
  },
  {
    name: 'decode',
    description: 'decode(bin, charset) - Decodes the first argument using the second argument character set.',
    rawData: {
      value: 'decode()',
    },
  },
  {
    name: 'degrees',
    description: 'degrees(expr) - Converts radians to degrees.',
    rawData: {
      value: 'degrees()',
    },
  },
  {
    name: 'dense_rank',
    description:
      'dense_rank() - Computes the rank of a value in a group of values. The result is one plus the previously assigned rank value. Unlike the function rank, dense_rank will not produce gaps in the ranking sequence.',
    rawData: {
      value: 'dense_rank()',
    },
  },
  {
    name: 'double',
    description: 'double(expr) - Casts the value expr to the target data type double.',
    rawData: {
      value: 'double()',
    },
  },
  {
    name: 'e',
    description: "e() - Returns Euler's number, e.",
    rawData: {
      value: 'e()',
    },
  },
  {
    name: 'element_at',
    description:
      'element_at(array, index) - Returns element of array at given (1-based) index. If Index is 0, Spark will throw an error. If index  < 0, accesses elements from the last to the first.',
    rawData: {
      value: 'element_at()',
    },
  },
  {
    name: 'elt',
    description: 'elt(n, input1, input2, ...) - Returns the n-th input, e.g., returns input2 when n is 2.',
    rawData: {
      value: 'elt()',
    },
  },
  {
    name: 'encode',
    description: 'encode(str, charset) - Encodes the first argument using the second argument character set.',
    rawData: {
      value: 'encode()',
    },
  },
  {
    name: 'endswith',
    description:
      'endswith(left, right) - Returns a boolean. The value is True if left ends with right. Returns NULL if either input expression is NULL. Otherwise, returns False. Both left or right must be of STRING or BINARY type.',
    rawData: {
      value: 'endswith()',
    },
  },
  {
    name: 'equal_null',
    description:
      'equal_null(expr1, expr2) - Returns same result as the EQUAL(=) operator for non-null operands, but returns true if both are null, false if one of the them is null.',
    rawData: {
      value: 'equal_null()',
    },
  },
  {
    name: 'every',
    description: 'every(expr) - Returns true if all values of expr are true.',
    rawData: {
      value: 'every()',
    },
  },
  {
    name: 'exists',
    description: 'exists(expr, pred) - Tests whether a predicate holds for one or more elements in the array.',
    rawData: {
      value: 'exists()',
    },
  },
  {
    name: 'exp',
    description: 'exp(expr) - Returns e to the power of expr.',
    rawData: {
      value: 'exp()',
    },
  },
  {
    name: 'explode',
    description:
      'explode(expr) - Separates the elements of array expr into multiple rows, or the elements of map expr into multiple rows and columns. Unless specified otherwise, uses the default column name col for elements of the array or key and value for the elements of the map.',
    rawData: {
      value: 'explode()',
    },
  },
  {
    name: 'explode_outer',
    description:
      'explode_outer(expr) - Separates the elements of array expr into multiple rows, or the elements of map expr into multiple rows and columns. Unless specified otherwise, uses the default column name col for elements of the array or key and value for the elements of the map.',
    rawData: {
      value: 'explode_outer()',
    },
  },
  {
    name: 'expm1',
    description: 'expm1(expr) - Returns exp(expr) - 1.',
    rawData: {
      value: 'expm1()',
    },
  },
  {
    name: 'extract',
    description: 'extract(field FROM source) - Extracts a part of the date/timestamp or interval source.',
    rawData: {
      value: 'extract()',
    },
  },
  {
    name: 'factorial',
    description: 'factorial(expr) - Returns the factorial of expr. expr is [0..20]. Otherwise, null.',
    rawData: {
      value: 'factorial()',
    },
  },
  {
    name: 'filter',
    description: 'filter(expr, func) - Filters the input array using the given predicate.',
    rawData: {
      value: 'filter()',
    },
  },
  {
    name: 'find_in_set',
    description:
      'find_in_set(str, str_array) - Returns the index (1-based) of the given string (str) in the comma-delimited list (str_array). Returns 0, if the string was not found or if the given string (str) contains a comma.',
    rawData: {
      value: 'find_in_set()',
    },
  },
  {
    name: 'first',
    description:
      'first(expr[, isIgnoreNull]) - Returns the first value of expr for a group of rows. If isIgnoreNull is true, returns only non-null values.',
    rawData: {
      value: 'first()',
    },
  },
  {
    name: 'first_value',
    description:
      'first_value(expr[, isIgnoreNull]) - Returns the first value of expr for a group of rows. If isIgnoreNull is true, returns only non-null values.',
    rawData: {
      value: 'first_value()',
    },
  },
  {
    name: 'flatten',
    description: 'flatten(arrayOfArrays) - Transforms an array of arrays into a single array.',
    rawData: {
      value: 'flatten()',
    },
  },
  {
    name: 'float',
    description: 'float(expr) - Casts the value expr to the target data type float.',
    rawData: {
      value: 'float()',
    },
  },
  {
    name: 'floor',
    description:
      'floor(expr[, scale]) - Returns the largest number after rounding down that is not greater than expr. An optional scale parameter can be specified to control the rounding behavior.',
    rawData: {
      value: 'floor()',
    },
  },
  {
    name: 'forall',
    description: 'forall(expr, pred) - Tests whether a predicate holds for all elements in the array.',
    rawData: {
      value: 'forall()',
    },
  },
  {
    name: 'format_number',
    description:
      "format_number(expr1, expr2) - Formats the number expr1 like '#,###,###.##', rounded to expr2 decimal places. If expr2 is 0, the result has no decimal point or fractional part. expr2 also accept a user specified format. This is supposed to function like MySQL's FORMAT.",
    rawData: {
      value: 'format_number()',
    },
  },
  {
    name: 'format_string',
    description: 'format_string(strfmt, obj, ...) - Returns a formatted string from printf-style format strings.',
    rawData: {
      value: 'format_string()',
    },
  },
  {
    name: 'from_csv',
    description: 'from_csv(csvStr, schema[, options]) - Returns a struct value with the given csvStr and schema.',
    rawData: {
      value: 'from_csv()',
    },
  },
  {
    name: 'from_json',
    description: 'from_json(jsonStr, schema[, options]) - Returns a struct value with the given jsonStr and schema.',
    rawData: {
      value: 'from_json()',
    },
  },
  {
    name: 'from_unixtime',
    description: 'from_unixtime(unix_time[, fmt]) - Returns unix_time in the specified fmt.',
    rawData: {
      value: 'from_unixtime()',
    },
  },
  {
    name: 'from_utc_timestamp',
    description:
      "from_utc_timestamp(timestamp, timezone) - Given a timestamp like '2017-07-14 02:40:00.0', interprets it as a time in UTC, and renders that time as a timestamp in the given time zone. For example, 'GMT+1' would yield '2017-07-14 03:40:00.0'.",
    rawData: {
      value: 'from_utc_timestamp()',
    },
  },
  {
    name: 'get',
    description:
      'get(array, index) - Returns element of array at given (0-based) index. If the index points outside of the array boundaries, then this function returns NULL.',
    rawData: {
      value: 'get()',
    },
  },
  {
    name: 'get_json_object',
    description: 'get_json_object(json_txt, path) - Extracts a json object from path.',
    rawData: {
      value: 'get_json_object()',
    },
  },
  {
    name: 'getbit',
    description:
      'getbit(expr, pos) - Returns the value of the bit (0 or 1) at the specified position. The positions are numbered from right to left, starting at zero. The position argument cannot be negative.',
    rawData: {
      value: 'getbit()',
    },
  },
  {
    name: 'greatest',
    description: 'greatest(expr, ...) - Returns the greatest value of all parameters, skipping null values.',
    rawData: {
      value: 'greatest()',
    },
  },
  {
    name: 'grouping',
    description:
      'grouping(col) - indicates whether a specified column in a GROUP BY is aggregated or not, returns 1 for aggregated or 0 for not aggregated in the result set.",',
    rawData: {
      value: 'grouping()',
    },
  },
  {
    name: 'grouping_id',
    description:
      'grouping_id([col1[, col2 ..]]) - returns the level of grouping, equals to (grouping(c1)  << (n-1)) + (grouping(c2)  << (n-2)) + ... + grouping(cn)',
    rawData: {
      value: 'grouping_id()',
    },
  },
  {
    name: 'hash',
    description: 'hash(expr1, expr2, ...) - Returns a hash value of the arguments.',
    rawData: {
      value: 'hash()',
    },
  },
  {
    name: 'hex',
    description: 'hex(expr) - Converts expr to hexadecimal.',
    rawData: {
      value: 'hex()',
    },
  },
  {
    name: 'histogram_numeric',
    description:
      "histogram_numeric(expr, nb) - Computes a histogram on numeric 'expr' using nb bins. The return value is an array of (x,y) pairs representing the centers of the histogram's bins. As the value of 'nb' is increased, the histogram approximation gets finer-grained, but may yield artifacts around outliers.",
    rawData: {
      value: 'histogram_numeric()',
    },
  },
  {
    name: 'hll_sketch_agg',
    description:
      "hll_sketch_agg(expr, lgConfigK) - Returns the HllSketch's updatable binary representation. lgConfigK (optional) the log-base-2 of K, with K is the number of buckets or slots for the HllSketch.",
    rawData: {
      value: 'hll_sketch_agg()',
    },
  },
  {
    name: 'hll_sketch_estimate',
    description:
      'hll_sketch_estimate(expr) - Returns the estimated number of unique values given the binary representation of a Datasketches HllSketch.',
    rawData: {
      value: 'hll_sketch_estimate()',
    },
  },
  {
    name: 'hll_union',
    description:
      'hll_union(first, second, allowDifferentLgConfigK) - Merges two binary representations of Datasketches HllSketch objects, using a Datasketches Union object. Set allowDifferentLgConfigK to true to allow unions of sketches with different lgConfigK values (defaults to false).',
    rawData: {
      value: 'hll_union()',
    },
  },
  {
    name: 'hll_union_agg',
    description:
      'hll_union_agg(expr, allowDifferentLgConfigK) - Returns the estimated number of unique values. allowDifferentLgConfigK (optional) Allow sketches with different lgConfigK values to be unioned (defaults to false).',
    rawData: {
      value: 'hll_union_agg()',
    },
  },
  {
    name: 'hour',
    description: 'hour(timestamp) - Returns the hour component of the string/timestamp.',
    rawData: {
      value: 'hour()',
    },
  },
  {
    name: 'hypot',
    description: 'hypot(expr1, expr2) - Returns sqrt(expr12 + expr22).',
    rawData: {
      value: 'hypot()',
    },
  },
  {
    name: 'if',
    description: 'if(expr1, expr2, expr3) - If expr1 evaluates to true, then returns expr2; otherwise returns expr3.',
    rawData: {
      value: 'if()',
    },
  },
  {
    name: 'ifnull',
    description: 'ifnull(expr1, expr2) - Returns expr2 if expr1 is null, or expr1 otherwise.',
    rawData: {
      value: 'ifnull()',
    },
  },
  {
    name: 'in',
    description: 'expr1 in(expr2, expr3, ...) - Returns true if expr equals to any valN.',
    rawData: {
      value: 'expr1 in()',
    },
  },
  {
    name: 'initcap',
    description:
      'initcap(str) - Returns str with the first letter of each word in uppercase. All other letters are in lowercase. Words are delimited by white space.',
    rawData: {
      value: 'initcap()',
    },
  },
  {
    name: 'inline',
    description:
      'inline(expr) - Explodes an array of structs into a table. Uses column names col1, col2, etc. by default unless specified otherwise.',
    rawData: {
      value: 'inline()',
    },
  },
  {
    name: 'inline_outer',
    description:
      'inline_outer(expr) - Explodes an array of structs into a table. Uses column names col1, col2, etc. by default unless specified otherwise.',
    rawData: {
      value: 'inline_outer()',
    },
  },
  {
    name: 'input_file_block_length',
    description: 'input_file_block_length() - Returns the length of the block being read, or -1 if not available.',
    rawData: {
      value: 'input_file_block_length()',
    },
  },
  {
    name: 'input_file_block_start',
    description: 'input_file_block_start() - Returns the start offset of the block being read, or -1 if not available.',
    rawData: {
      value: 'input_file_block_start()',
    },
  },
  {
    name: 'input_file_name',
    description: 'input_file_name() - Returns the name of the file being read, or empty string if not available.',
    rawData: {
      value: 'input_file_name()',
    },
  },
  {
    name: 'instr',
    description: 'instr(str, substr) - Returns the (1-based) index of the first occurrence of substr in str.',
    rawData: {
      value: 'instr()',
    },
  },
  {
    name: 'int',
    description: 'int(expr) - Casts the value expr to the target data type int.',
    rawData: {
      value: 'int()',
    },
  },
  {
    name: 'isnan',
    description: 'isnan(expr) - Returns true if expr is NaN, or false otherwise.',
    rawData: {
      value: 'isnan()',
    },
  },
  {
    name: 'isnotnull',
    description: 'isnotnull(expr) - Returns true if expr is not null, or false otherwise.',
    rawData: {
      value: 'isnotnull()',
    },
  },
  {
    name: 'isnull',
    description: 'isnull(expr) - Returns true if expr is null, or false otherwise.',
    rawData: {
      value: 'isnull()',
    },
  },
  {
    name: 'json_array_length',
    description: 'json_array_length(jsonArray) - Returns the number of elements in the outermost JSON array.',
    rawData: {
      value: 'json_array_length()',
    },
  },
  {
    name: 'json_object_keys',
    description: 'json_object_keys(json_object) - Returns all the keys of the outermost JSON object as an array.',
    rawData: {
      value: 'json_object_keys()',
    },
  },
  {
    name: 'json_tuple',
    description:
      'json_tuple(jsonStr, p1, p2, ..., pn) - Returns a tuple like the function get_json_object, but it takes multiple names. All the input parameters and output column types are string.',
    rawData: {
      value: 'json_tuple()',
    },
  },
  {
    name: 'kurtosis',
    description: 'kurtosis(expr) - Returns the kurtosis value calculated from values of a group.',
    rawData: {
      value: 'kurtosis()',
    },
  },
  {
    name: 'lag',
    description:
      'lag(input[, offset[, default]]) - Returns the value of input at the offsetth row before the current row in the window. The default value of offset is 1 and the default value of default is null. If the value of input at the offsetth row is null, null is returned. If there is no such offset row (e.g., when the offset is 1, the first row of the window does not have any previous row), default is returned.',
    rawData: {
      value: 'lag()',
    },
  },
  {
    name: 'last',
    description:
      'last(expr[, isIgnoreNull]) - Returns the last value of expr for a group of rows. If isIgnoreNull is true, returns only non-null values',
    rawData: {
      value: 'last()',
    },
  },
  {
    name: 'last_day',
    description: 'last_day(date) - Returns the last day of the month which the date belongs to.',
    rawData: {
      value: 'last_day()',
    },
  },
  {
    name: 'last_value',
    description:
      'last_value(expr[, isIgnoreNull]) - Returns the last value of expr for a group of rows. If isIgnoreNull is true, returns only non-null values',
    rawData: {
      value: 'last_value()',
    },
  },
  {
    name: 'lcase',
    description: 'lcase(str) - Returns str with all characters changed to lowercase.',
    rawData: {
      value: 'lcase()',
    },
  },
  {
    name: 'lead',
    description:
      'lead(input[, offset[, default]]) - Returns the value of input at the offsetth row after the current row in the window. The default value of offset is 1 and the default value of default is null. If the value of input at the offsetth row is null, null is returned. If there is no such an offset row (e.g., when the offset is 1, the last row of the window does not have any subsequent row), default is returned.',
    rawData: {
      value: 'lead()',
    },
  },
  {
    name: 'least',
    description: 'least(expr, ...) - Returns the least value of all parameters, skipping null values.',
    rawData: {
      value: 'least()',
    },
  },
  {
    name: 'left',
    description:
      'left(str, len) - Returns the leftmost len(len can be string type) characters from the string str,if len is less or equal than 0 the result is an empty string.',
    rawData: {
      value: 'left()',
    },
  },
  {
    name: 'len',
    description:
      'len(expr) - Returns the character length of string data or number of bytes of binary data. The length of string data includes the trailing spaces. The length of binary data includes binary zeros.',
    rawData: {
      value: 'len()',
    },
  },
  {
    name: 'length',
    description:
      'length(expr) - Returns the character length of string data or number of bytes of binary data. The length of string data includes the trailing spaces. The length of binary data includes binary zeros.',
    rawData: {
      value: 'length()',
    },
  },
  {
    name: 'levenshtein',
    description:
      'levenshtein(str1, str2[, threshold]) - Returns the Levenshtein distance between the two given strings. If threshold is set and distance more than it, return -1.',
    rawData: {
      value: 'levenshtein()',
    },
  },
  {
    name: 'ln',
    description: 'ln(expr) - Returns the natural logarithm (base e) of expr.',
    rawData: {
      value: 'ln()',
    },
  },
  {
    name: 'localtimestamp',
    description:
      'localtimestamp() - Returns the current timestamp without time zone at the start of query evaluation. All calls of localtimestamp within the same query return the same value.',
    rawData: {
      value: 'localtimestamp()',
    },
  },
  {
    name: 'locate',
    description:
      'locate(substr, str[, pos]) - Returns the position of the first occurrence of substr in str after position pos. The given pos and return value are 1-based.',
    rawData: {
      value: 'locate()',
    },
  },
  {
    name: 'log',
    description: 'log(base, expr) - Returns the logarithm of expr with base.',
    rawData: {
      value: 'log()',
    },
  },
  {
    name: 'log10',
    description: 'log10(expr) - Returns the logarithm of expr with base 10.',
    rawData: {
      value: 'log10()',
    },
  },
  {
    name: 'log1p',
    description: 'log1p(expr) - Returns log(1 + expr).',
    rawData: {
      value: 'log1p()',
    },
  },
  {
    name: 'log2',
    description: 'log2(expr) - Returns the logarithm of expr with base 2.',
    rawData: {
      value: 'log2()',
    },
  },
  {
    name: 'lower',
    description: 'lower(str) - Returns str with all characters changed to lowercase.',
    rawData: {
      value: 'lower()',
    },
  },
  {
    name: 'lpad',
    description:
      'lpad(str, len[, pad]) - Returns str, left-padded with pad to a length of len. If str is longer than len, the return value is shortened to len characters or bytes. If pad is not specified, str will be padded to the left with space characters if it is a character string, and with zeros if it is a byte sequence.',
    rawData: {
      value: 'lpad()',
    },
  },
  {
    name: 'ltrim',
    description: 'ltrim(str) - Removes the leading space characters from str.',
    rawData: {
      value: 'ltrim()',
    },
  },
  {
    name: 'luhn_check',
    description:
      'luhn_check(str ) - Checks that a string of digits is valid according to the Luhn algorithm. This checksum function is widely applied on credit card numbers and government identification numbers to distinguish valid numbers from mistyped, incorrect numbers.',
    rawData: {
      value: 'luhn_check()',
    },
  },
  {
    name: 'make_date',
    description: 'make_date(year, month, day) - Create date from year, month and day fields.',
    rawData: {
      value: 'make_date()',
    },
  },
  {
    name: 'make_dt_interval',
    description:
      'make_dt_interval([days[, hours[, mins[, secs]]]]) - Make DayTimeIntervalType duration from days, hours, mins and secs.',
    rawData: {
      value: 'make_dt_interval()',
    },
  },
  {
    name: 'make_interval',
    description:
      'make_interval([years[, months[, weeks[, days[, hours[, mins[, secs]]]]]]]) - Make interval from years, months, weeks, days, hours, mins and secs.',
    rawData: {
      value: 'make_interval()',
    },
  },
  {
    name: 'make_timestamp',
    description:
      'make_timestamp(year, month, day, hour, min, sec[, timezone]) - Create timestamp from year, month, day, hour, min, sec and timezone fields.',
    rawData: {
      value: 'make_timestamp()',
    },
  },
  {
    name: 'make_timestamp_ltz',
    description:
      'make_timestamp_ltz(year, month, day, hour, min, sec[, timezone]) - Create the current timestamp with local time zone from year, month, day, hour, min, sec and timezone fields.',
    rawData: {
      value: 'make_timestamp_ltz()',
    },
  },
  {
    name: 'make_timestamp_ntz',
    description:
      'make_timestamp_ntz(year, month, day, hour, min, sec) - Create local date-time from year, month, day, hour, min, sec fields.',
    rawData: {
      value: 'make_timestamp_ntz()',
    },
  },
  {
    name: 'make_ym_interval',
    description: 'make_ym_interval([years[, months]]) - Make year-month interval from years, months.',
    rawData: {
      value: 'make_ym_interval()',
    },
  },
  {
    name: 'map',
    description: 'map(key0, value0, key1, value1, ...) - Creates a map with the given key/value pairs.',
    rawData: {
      value: 'map()',
    },
  },
  {
    name: 'map_concat',
    description: 'map_concat(map, ...) - Returns the union of all the given maps',
    rawData: {
      value: 'map_concat()',
    },
  },
  {
    name: 'map_contains_key',
    description: 'map_contains_key(map, key) - Returns true if the map contains the key.',
    rawData: {
      value: 'map_contains_key()',
    },
  },
  {
    name: 'map_entries',
    description: 'map_entries(map) - Returns an unordered array of all entries in the given map.',
    rawData: {
      value: 'map_entries()',
    },
  },
  {
    name: 'map_filter',
    description: 'map_filter(expr, func) - Filters entries in a map using the function.',
    rawData: {
      value: 'map_filter()',
    },
  },
  {
    name: 'map_from_arrays',
    description:
      'map_from_arrays(keys, values) - Creates a map with a pair of the given key/value arrays. All elements in keys should not be null',
    rawData: {
      value: 'map_from_arrays()',
    },
  },
  {
    name: 'map_from_entries',
    description: 'map_from_entries(arrayOfEntries) - Returns a map created from the given array of entries.',
    rawData: {
      value: 'map_from_entries()',
    },
  },
  {
    name: 'map_keys',
    description: 'map_keys(map) - Returns an unordered array containing the keys of the map.',
    rawData: {
      value: 'map_keys()',
    },
  },
  {
    name: 'map_values',
    description: 'map_values(map) - Returns an unordered array containing the values of the map.',
    rawData: {
      value: 'map_values()',
    },
  },
  {
    name: 'map_zip_with',
    description:
      'map_zip_with(map1, map2, function) - Merges two given maps into a single map by applying function to the pair of values with the same key. For keys only presented in one map, NULL will be passed as the value for the missing key. If an input map contains duplicated keys, only the first entry of the duplicated key is passed into the lambda function.',
    rawData: {
      value: 'map_zip_with()',
    },
  },
  {
    name: 'mask',
    description:
      "mask(input[, upperChar, lowerChar, digitChar, otherChar]) - masks the given string value. The function replaces characters with 'X' or 'x', and numbers with 'n'. This can be useful for creating copies of tables with sensitive information removed.",
    rawData: {
      value: 'mask()',
    },
  },
  {
    name: 'max',
    description: 'max(expr) - Returns the maximum value of expr.',
    rawData: {
      value: 'max()',
    },
  },
  {
    name: 'max_by',
    description: 'max_by(x, y) - Returns the value of x associated with the maximum value of y.',
    rawData: {
      value: 'max_by()',
    },
  },
  {
    name: 'md5',
    description: 'md5(expr) - Returns an MD5 128-bit checksum as a hex string of expr.',
    rawData: {
      value: 'md5()',
    },
  },
  {
    name: 'mean',
    description: 'mean(expr) - Returns the mean calculated from values of a group.',
    rawData: {
      value: 'mean()',
    },
  },
  {
    name: 'median',
    description: 'median(col) - Returns the median of numeric or ANSI interval column col.',
    rawData: {
      value: 'median()',
    },
  },
  {
    name: 'min',
    description: 'min(expr) - Returns the minimum value of expr.',
    rawData: {
      value: 'min()',
    },
  },
  {
    name: 'min_by',
    description: 'min_by(x, y) - Returns the value of x associated with the minimum value of y.',
    rawData: {
      value: 'min_by()',
    },
  },
  {
    name: 'minute',
    description: 'minute(timestamp) - Returns the minute component of the string/timestamp.',
    rawData: {
      value: 'minute()',
    },
  },
  {
    name: 'mode',
    description:
      'mode(col) - Returns the most frequent value for the values within col. NULL values are ignored. If all the values are NULL, or there are 0 rows, returns NULL.',
    rawData: {
      value: 'mode()',
    },
  },
  {
    name: 'monotonically_increasing_id',
    description:
      'monotonically_increasing_id() - Returns monotonically increasing 64-bit integers. The generated ID is guaranteed to be monotonically increasing and unique, but not consecutive.',
    rawData: {
      value: 'monotonically_increasing_id()',
    },
  },
  {
    name: 'month',
    description: 'month(date) - Returns the month component of the date/timestamp.',
    rawData: {
      value: 'month()',
    },
  },
  {
    name: 'months_between',
    description:
      'months_between(timestamp1, timestamp2[, roundOff]) - If timestamp1 is later than timestamp2, then the result is positive. If timestamp1 and timestamp2 are on the same day of month, or both are the last day of month, time of day will be ignored. Otherwise, the difference is calculated based on 31 days per month, and rounded to 8 digits unless roundOff=false.',
    rawData: {
      value: 'months_between()',
    },
  },
  {
    name: 'named_struct',
    description:
      'named_struct(name1, val1, name2, val2, ...) - Creates a struct with the given field names and values.',
    rawData: {
      value: 'named_struct()',
    },
  },
  {
    name: 'nanvl',
    description: "nanvl(expr1, expr2) - Returns expr1 if it's not NaN, or expr2 otherwise.",
    rawData: {
      value: 'nanvl()',
    },
  },
  {
    name: 'negative',
    description: 'negative(expr) - Returns the negated value of expr.',
    rawData: {
      value: 'negative()',
    },
  },
  {
    name: 'next_day',
    description:
      'next_day(start_date, day_of_week) - Returns the first date which is later than start_date and named as indicated. The function returns NULL if at least one of the input parameters is NULL.',
    rawData: {
      value: 'next_day()',
    },
  },
  {
    name: 'now',
    description: 'now() - Returns the current timestamp at the start of query evaluation.',
    rawData: {
      value: 'now()',
    },
  },
  {
    name: 'nth_value',
    description:
      'nth_value(input[, offset]) - Returns the value of input at the row that is the offsetth row from beginning of the window frame. Offset starts at 1. If ignoreNulls=true, we will skip nulls when finding the offsetth row. Otherwise, every row counts for the offset. If there is no such an offsetth row (e.g., when the offset is 10, size of the window frame is less than 10), null is returned.',
    rawData: {
      value: 'nth_value()',
    },
  },
  {
    name: 'ntile',
    description: 'ntile(n) - Divides the rows for each window partition into n buckets ranging from 1 to at most n.',
    rawData: {
      value: 'ntile()',
    },
  },
  {
    name: 'nullif',
    description: 'nullif(expr1, expr2) - Returns null if expr1 equals to expr2, or expr1 otherwise.',
    rawData: {
      value: 'nullif()',
    },
  },
  {
    name: 'nvl',
    description: 'nvl(expr1, expr2) - Returns expr2 if expr1 is null, or expr1 otherwise.',
    rawData: {
      value: 'nvl()',
    },
  },
  {
    name: 'nvl2',
    description: 'nvl2(expr1, expr2, expr3) - Returns expr2 if expr1 is not null, or expr3 otherwise.',
    rawData: {
      value: 'nvl2()',
    },
  },
  {
    name: 'octet_length',
    description: 'octet_length(expr) - Returns the byte length of string data or number of bytes of binary data.',
    rawData: {
      value: 'octet_length()',
    },
  },
  {
    name: 'overlay',
    description:
      'overlay(input, replace, pos[, len]) - Replace input with replace that starts at pos and is of length len.',
    rawData: {
      value: 'overlay()',
    },
  },
  {
    name: 'parse_url',
    description: 'parse_url(url, partToExtract[, key]) - Extracts a part from a URL.',
    rawData: {
      value: 'parse_url()',
    },
  },
  {
    name: 'percent_rank',
    description: 'percent_rank() - Computes the percentage ranking of a value in a group of values.',
    rawData: {
      value: 'percent_rank()',
    },
  },
  {
    name: 'percentile',
    description:
      'percentile(col, percentage [, frequency]) - Returns the exact percentile value of numeric or ANSI interval column col at the given percentage. The value of percentage must be between 0.0 and 1.0. The value of frequency should be positive integral',
    rawData: {
      value: 'percentile()',
    },
  },
  {
    name: 'percentile_approx',
    description:
      'percentile_approx(col, percentage [, accuracy]) - Returns the approximate percentile of the numeric or ansi interval column col which is the smallest value in the ordered col values (sorted from least to greatest) such that no more than percentage of col values is less than the value or equal to that value. The value of percentage must be between 0.0 and 1.0. The accuracy parameter (default: 10000) is a positive numeric literal which controls approximation accuracy at the cost of memory.',
    rawData: {
      value: 'percentile_approx()',
    },
  },
  {
    name: 'pi',
    description: 'pi() - Returns pi.',
    rawData: {
      value: 'pi()',
    },
  },
  {
    name: 'pmod',
    description: 'pmod(expr1, expr2) - Returns the positive value of expr1 mod expr2.',
    rawData: {
      value: 'pmod()',
    },
  },
  {
    name: 'posexplode',
    description:
      'posexplode(expr) - Separates the elements of array expr into multiple rows with positions, or the elements of map expr into multiple rows and columns with positions. Unless specified otherwise, uses the column name pos for position, col for elements of the array or key and value for elements of the map.',
    rawData: {
      value: 'posexplode()',
    },
  },
  {
    name: 'posexplode_outer',
    description:
      'posexplode_outer(expr) - Separates the elements of array expr into multiple rows with positions, or the elements of map expr into multiple rows and columns with positions. Unless specified otherwise, uses the column name pos for position, col for elements of the array or key and value for elements of the map.',
    rawData: {
      value: 'posexplode_outer()',
    },
  },
  {
    name: 'position',
    description:
      'position(substr, str[, pos]) - Returns the position of the first occurrence of substr in str after position pos. The given pos and return value are 1-based.',
    rawData: {
      value: 'position()',
    },
  },
  {
    name: 'positive',
    description: 'positive(expr) - Returns the value of expr.',
    rawData: {
      value: 'positive()',
    },
  },
  {
    name: 'pow',
    description: 'pow(expr1, expr2) - Raises expr1 to the power of expr2.',
    rawData: {
      value: 'pow()',
    },
  },
  {
    name: 'power',
    description: 'power(expr1, expr2) - Raises expr1 to the power of expr2.',
    rawData: {
      value: 'power()',
    },
  },
  {
    name: 'printf',
    description: 'printf(strfmt, obj, ...) - Returns a formatted string from printf-style format strings.',
    rawData: {
      value: 'printf()',
    },
  },
  {
    name: 'quarter',
    description: 'quarter(date) - Returns the quarter of the year for date, in the range 1 to 4.',
    rawData: {
      value: 'quarter()',
    },
  },
  {
    name: 'radians',
    description: 'radians(expr) - Converts degrees to radians.',
    rawData: {
      value: 'radians()',
    },
  },
  {
    name: 'raise_error',
    description: 'raise_error(expr) - Throws an exception with expr.',
    rawData: {
      value: 'raise_error()',
    },
  },
  {
    name: 'rand',
    description:
      'rand([seed]) - Returns a random value with independent and identically distributed (i.i.d.) uniformly distributed values in [0, 1).',
    rawData: {
      value: 'rand()',
    },
  },
  {
    name: 'randn',
    description:
      'randn([seed]) - Returns a random value with independent and identically distributed (i.i.d.) values drawn from the standard normal distribution.',
    rawData: {
      value: 'randn()',
    },
  },
  {
    name: 'random',
    description:
      'random([seed]) - Returns a random value with independent and identically distributed (i.i.d.) uniformly distributed values in [0, 1).',
    rawData: {
      value: 'random()',
    },
  },
  {
    name: 'rank',
    description:
      'rank() - Computes the rank of a value in a group of values. The result is one plus the number of rows preceding or equal to the current row in the ordering of the partition. The values will produce gaps in the sequence.',
    rawData: {
      value: 'rank()',
    },
  },
  {
    name: 'reduce',
    description:
      'reduce(expr, start, merge, finish) - Applies a binary operator to an initial state and all elements in the array, and reduces this to a single state. The final state is converted into the final result by applying a finish function.',
    rawData: {
      value: 'reduce()',
    },
  },
  {
    name: 'regexp',
    description: 'regexp(str, regexp) - Returns true if str matches regexp, or false otherwise.',
    rawData: {
      value: 'regexp()',
    },
  },
  {
    name: 'regexp_count',
    description:
      'regexp_count(str, regexp) - Returns a count of the number of times that the regular expression pattern regexp is matched in the string str.',
    rawData: {
      value: 'regexp_count()',
    },
  },
  {
    name: 'regexp_extract',
    description:
      'regexp_extract(str, regexp[, idx]) - Extract the first string in the str that match the regexp expression and corresponding to the regex group index.',
    rawData: {
      value: 'regexp_extract()',
    },
  },
  {
    name: 'regexp_extract_all',
    description:
      'regexp_extract_all(str, regexp[, idx]) - Extract all strings in the str that match the regexp expression and corresponding to the regex group index.',
    rawData: {
      value: 'regexp_extract_all()',
    },
  },
  {
    name: 'regexp_instr',
    description:
      'regexp_instr(str, regexp) - Searches a string for a regular expression and returns an integer that indicates the beginning position of the matched substring. Positions are 1-based, not 0-based. If no match is found, returns 0.',
    rawData: {
      value: 'regexp_instr()',
    },
  },
  {
    name: 'regexp_like',
    description: 'regexp_like(str, regexp) - Returns true if str matches regexp, or false otherwise.',
    rawData: {
      value: 'regexp_like()',
    },
  },
  {
    name: 'regexp_replace',
    description:
      'regexp_replace(str, regexp, rep[, position]) - Replaces all substrings of str that match regexp with rep.',
    rawData: {
      value: 'regexp_replace()',
    },
  },
  {
    name: 'regexp_substr',
    description:
      'regexp_substr(str, regexp) - Returns the substring that matches the regular expression regexp within the string str. If the regular expression is not found, the result is null.',
    rawData: {
      value: 'regexp_substr()',
    },
  },
  {
    name: 'regr_avgx',
    description:
      'regr_avgx(y, x) - Returns the average of the independent variable for non-null pairs in a group, where y is the dependent variable and x is the independent variable.',
    rawData: {
      value: 'regr_avgx()',
    },
  },
  {
    name: 'regr_avgy',
    description:
      'regr_avgy(y, x) - Returns the average of the dependent variable for non-null pairs in a group, where y is the dependent variable and x is the independent variable.',
    rawData: {
      value: 'regr_avgy()',
    },
  },
  {
    name: 'regr_count',
    description:
      'regr_count(y, x) - Returns the number of non-null number pairs in a group, where y is the dependent variable and x is the independent variable.',
    rawData: {
      value: 'regr_count()',
    },
  },
  {
    name: 'regr_intercept',
    description:
      'regr_intercept(y, x) - Returns the intercept of the univariate linear regression line for non-null pairs in a group, where y is the dependent variable and x is the independent variable.',
    rawData: {
      value: 'regr_intercept()',
    },
  },
  {
    name: 'regr_r2',
    description:
      'regr_r2(y, x) - Returns the coefficient of determination for non-null pairs in a group, where y is the dependent variable and x is the independent variable.',
    rawData: {
      value: 'regr_r2()',
    },
  },
  {
    name: 'regr_slope',
    description:
      'regr_slope(y, x) - Returns the slope of the linear regression line for non-null pairs in a group, where y is the dependent variable and x is the independent variable.',
    rawData: {
      value: 'regr_slope()',
    },
  },
  {
    name: 'regr_sxx',
    description:
      'regr_sxx(y, x) - Returns REGR_COUNT(y, x) * VAR_POP(x) for non-null pairs in a group, where y is the dependent variable and x is the independent variable.',
    rawData: {
      value: 'regr_sxx()',
    },
  },
  {
    name: 'regr_sxy',
    description:
      'regr_sxy(y, x) - Returns REGR_COUNT(y, x) * COVAR_POP(y, x) for non-null pairs in a group, where y is the dependent variable and x is the independent variable.',
    rawData: {
      value: 'regr_sxy()',
    },
  },
  {
    name: 'regr_syy',
    description:
      'regr_syy(y, x) - Returns REGR_COUNT(y, x) * VAR_POP(y) for non-null pairs in a group, where y is the dependent variable and x is the independent variable.',
    rawData: {
      value: 'regr_syy()',
    },
  },
  {
    name: 'repeat',
    description: 'repeat(str, n) - Returns the string which repeats the given string value n times.',
    rawData: {
      value: 'repeat()',
    },
  },
  {
    name: 'replace',
    description: 'replace(str, search[, replace]) - Replaces all occurrences of search with replace.',
    rawData: {
      value: 'replace()',
    },
  },
  {
    name: 'reverse',
    description: 'reverse(array) - Returns a reversed string or an array with reverse order of elements.',
    rawData: {
      value: 'reverse()',
    },
  },
  {
    name: 'right',
    description:
      'right(str, len) - Returns the rightmost len(len can be string type) characters from the string str,if len is less or equal than 0 the result is an empty string.',
    rawData: {
      value: 'right()',
    },
  },
  {
    name: 'rint',
    description:
      'rint(expr) - Returns the double value that is closest in value to the argument and is equal to a mathematical integer.',
    rawData: {
      value: 'rint()',
    },
  },
  {
    name: 'rlike',
    description: 'rlike(str, regexp) - Returns true if str matches regexp, or false otherwise.',
    rawData: {
      value: 'rlike()',
    },
  },
  {
    name: 'round',
    description: 'round(expr, d) - Returns expr rounded to d decimal places using HALF_UP rounding mode.',
    rawData: {
      value: 'round()',
    },
  },
  {
    name: 'row_number',
    description:
      'row_number() - Assigns a unique, sequential number to each row, starting with one, according to the ordering of rows within the window partition.',
    rawData: {
      value: 'row_number()',
    },
  },
  {
    name: 'rpad',
    description:
      'rpad(str, len[, pad]) - Returns str, right-padded with pad to a length of len. If str is longer than len, the return value is shortened to len characters. If pad is not specified, str will be padded to the right with space characters if it is a character string, and with zeros if it is a binary string.',
    rawData: {
      value: 'rpad()',
    },
  },
  {
    name: 'rtrim',
    description: 'rtrim(str) - Removes the trailing space characters from str.',
    rawData: {
      value: 'rtrim()',
    },
  },
  {
    name: 'schema_of_csv',
    description: 'schema_of_csv(csv[, options]) - Returns schema in the DDL format of CSV string.',
    rawData: {
      value: 'schema_of_csv()',
    },
  },
  {
    name: 'schema_of_json',
    description: 'schema_of_json(json[, options]) - Returns schema in the DDL format of JSON string.',
    rawData: {
      value: 'schema_of_json()',
    },
  },
  {
    name: 'sec',
    description: 'sec(expr) - Returns the secant of expr.',
    rawData: {
      value: 'sec()',
    },
  },
  {
    name: 'second',
    description: 'second(timestamp) - Returns the second component of the string/timestamp.',
    rawData: {
      value: 'second()',
    },
  },
  {
    name: 'sentences',
    description: 'sentences(str[, lang, country]) - Splits str into an array of array of words.',
    rawData: {
      value: 'sentences()',
    },
  },
  {
    name: 'sequence',
    description:
      'sequence(start, stop, step) - Generates an array of elements from start to stop (inclusive), incrementing by step. The type of the returned elements is the same as the type of argument expressions.',
    rawData: {
      value: 'sequence()',
    },
  },
  {
    name: 'session_window',
    description:
      'session_window(time_column, gap_duration) - Generates session window given a timestamp specifying column and gap duration.',
    rawData: {
      value: 'session_window()',
    },
  },
  {
    name: 'sha',
    description: 'sha(expr) - Returns a sha1 hash value as a hex string of the expr.',
    rawData: {
      value: 'sha()',
    },
  },
  {
    name: 'sha1',
    description: 'sha1(expr) - Returns a sha1 hash value as a hex string of the expr.',
    rawData: {
      value: 'sha1()',
    },
  },
  {
    name: 'sha2',
    description:
      'sha2(expr, bitLength) - Returns a checksum of SHA-2 family as a hex string of expr. SHA-224, SHA-256, SHA-384, and SHA-512 are supported. Bit length of 0 is equivalent to 256.',
    rawData: {
      value: 'sha2()',
    },
  },
  {
    name: 'shiftleft',
    description: 'shiftleft(base, expr) - Bitwise left shift.',
    rawData: {
      value: 'shiftleft()',
    },
  },
  {
    name: 'shiftright',
    description: 'shiftright(base, expr) - Bitwise (signed) right shift.',
    rawData: {
      value: 'shiftright()',
    },
  },
  {
    name: 'shiftrightunsigned',
    description: 'shiftrightunsigned(base, expr) - Bitwise unsigned right shift.',
    rawData: {
      value: 'shiftrightunsigned()',
    },
  },
  {
    name: 'shuffle',
    description: 'shuffle(array) - Returns a random permutation of the given array.',
    rawData: {
      value: 'shuffle()',
    },
  },
  {
    name: 'sign',
    description: 'sign(expr) - Returns -1.0, 0.0 or 1.0 as expr is negative, 0 or positive.',
    rawData: {
      value: 'sign()',
    },
  },
  {
    name: 'signum',
    description: 'signum(expr) - Returns -1.0, 0.0 or 1.0 as expr is negative, 0 or positive.',
    rawData: {
      value: 'signum()',
    },
  },
  {
    name: 'sin',
    description: 'sin(expr) - Returns the sine of expr.',
    rawData: {
      value: 'sin()',
    },
  },
  {
    name: 'sinh',
    description: 'sinh(expr) - Returns hyperbolic sine of expr.',
    rawData: {
      value: 'sinh()',
    },
  },
  {
    name: 'size',
    description: 'size(expr) - Returns the size of an array or a map.',
    rawData: {
      value: 'size()',
    },
  },
  {
    name: 'skewness',
    description: 'skewness(expr) - Returns the skewness value calculated from values of a group.',
    rawData: {
      value: 'skewness()',
    },
  },
  {
    name: 'slice',
    description:
      'slice(x, start, length) - Subsets array x starting from index start (array indices start at 1, or starting from the end if start is negative) with the specified length.',
    rawData: {
      value: 'slice()',
    },
  },
  {
    name: 'smallint',
    description: 'smallint(expr) - Casts the value expr to the target data type smallint.',
    rawData: {
      value: 'smallint()',
    },
  },
  {
    name: 'some',
    description: 'some(expr) - Returns true if at least one value of expr is true.',
    rawData: {
      value: 'some()',
    },
  },
  {
    name: 'sort_array',
    description:
      'sort_array(array[, ascendingOrder]) - Sorts the input array in ascending or descending order according to the natural ordering of the array elements. NaN is greater than any non-NaN elements for double/float type. Null elements will be placed at the beginning of the returned array in ascending order or at the end of the returned array in descending order.',
    rawData: {
      value: 'sort_array()',
    },
  },
  {
    name: 'soundex',
    description: 'soundex(str) - Returns Soundex code of the string.',
    rawData: {
      value: 'soundex()',
    },
  },
  {
    name: 'space',
    description: 'space(n) - Returns a string consisting of n spaces.',
    rawData: {
      value: 'space()',
    },
  },
  {
    name: 'spark_partition_id',
    description: 'spark_partition_id() - Returns the current partition id.',
    rawData: {
      value: 'spark_partition_id()',
    },
  },
  {
    name: 'split',
    description:
      'split(str, regex, limit) - Splits str around occurrences that match regex and returns an array with a length of at most limit',
    rawData: {
      value: 'split()',
    },
  },
  {
    name: 'split_part',
    description:
      'split_part(str, delimiter, partNum) - Splits str by delimiter and return requested part of the split (1-based). If any input is null, returns null. if partNum is out of range of split parts, returns empty string. If partNum is 0, throws an error. If partNum is negative, the parts are counted backward from the end of the string. If the delimiter is an empty string, the str is not split.',
    rawData: {
      value: 'split_part()',
    },
  },
  {
    name: 'sqrt',
    description: 'sqrt(expr) - Returns the square root of expr.',
    rawData: {
      value: 'sqrt()',
    },
  },
  {
    name: 'stack',
    description:
      'stack(n, expr1, ..., exprk) - Separates expr1, ..., exprk into n rows. Uses column names col0, col1, etc. by default unless specified otherwise.',
    rawData: {
      value: 'stack()',
    },
  },
  {
    name: 'startswith',
    description:
      'startswith(left, right) - Returns a boolean. The value is True if left starts with right. Returns NULL if either input expression is NULL. Otherwise, returns False. Both left or right must be of STRING or BINARY type.',
    rawData: {
      value: 'startswith()',
    },
  },
  {
    name: 'std',
    description: 'std(expr) - Returns the sample standard deviation calculated from values of a group.',
    rawData: {
      value: 'std()',
    },
  },
  {
    name: 'stddev',
    description: 'stddev(expr) - Returns the sample standard deviation calculated from values of a group.',
    rawData: {
      value: 'stddev()',
    },
  },
  {
    name: 'stddev_pop',
    description: 'stddev_pop(expr) - Returns the population standard deviation calculated from values of a group.',
    rawData: {
      value: 'stddev_pop()',
    },
  },
  {
    name: 'stddev_samp',
    description: 'stddev_samp(expr) - Returns the sample standard deviation calculated from values of a group.',
    rawData: {
      value: 'stddev_samp()',
    },
  },
  {
    name: 'str_to_map',
    description:
      "str_to_map(text[, pairDelim[, keyValueDelim]]) - Creates a map after splitting the text into key/value pairs using delimiters. Default delimiters are ',' for pairDelim and ':' for keyValueDelim. Both pairDelim and keyValueDelim are treated as regular expressions.",
    rawData: {
      value: 'str_to_map()',
    },
  },
  {
    name: 'string',
    description: 'string(expr) - Casts the value expr to the target data type string.',
    rawData: {
      value: 'string()',
    },
  },
  {
    name: 'struct',
    description: 'struct(col1, col2, col3, ...) - Creates a struct with the given field values.',
    rawData: {
      value: 'struct()',
    },
  },
  {
    name: 'substr',
    description:
      'substr(str, pos[, len]) - Returns the substring of str that starts at pos and is of length len, or the slice of byte array that starts at pos and is of length len.',
    rawData: {
      value: 'substr()',
    },
  },
  {
    name: 'substring',
    description:
      'substring(str, pos[, len]) - Returns the substring of str that starts at pos and is of length len, or the slice of byte array that starts at pos and is of length len.',
    rawData: {
      value: 'substring()',
    },
  },
  {
    name: 'substring_index',
    description:
      'substring_index(str, delim, count) - Returns the substring from str before count occurrences of the delimiter delim. If count is positive, everything to the left of the final delimiter (counting from the left) is returned. If count is negative, everything to the right of the final delimiter (counting from the right) is returned. The function substring_index performs a case-sensitive match when searching for delim.',
    rawData: {
      value: 'substring_index()',
    },
  },
  {
    name: 'sum',
    description: 'sum(expr) - Returns the sum calculated from values of a group.',
    rawData: {
      value: 'sum()',
    },
  },
  {
    name: 'tan',
    description: 'tan(expr) - Returns the tangent of expr.',
    rawData: {
      value: 'tan()',
    },
  },
  {
    name: 'tanh',
    description: 'tanh(expr) - Returns the hyperbolic tangent of expr.',
    rawData: {
      value: 'tanh()',
    },
  },
  {
    name: 'timestamp',
    description: 'timestamp(expr) - Casts the value expr to the target data type timestamp.',
    rawData: {
      value: 'timestamp()',
    },
  },
  {
    name: 'timestamp_micros',
    description: 'timestamp_micros(microseconds) - Creates timestamp from the number of microseconds since UTC epoch.',
    rawData: {
      value: 'timestamp_micros()',
    },
  },
  {
    name: 'timestamp_millis',
    description: 'timestamp_millis(milliseconds) - Creates timestamp from the number of milliseconds since UTC epoch.',
    rawData: {
      value: 'timestamp_millis()',
    },
  },
  {
    name: 'timestamp_seconds',
    description:
      'timestamp_seconds(seconds) - Creates timestamp from the number of seconds (can be fractional) since UTC epoch.',
    rawData: {
      value: 'timestamp_seconds()',
    },
  },
  {
    name: 'tinyint',
    description: 'tinyint(expr) - Casts the value expr to the target data type tinyint.',
    rawData: {
      value: 'tinyint()',
    },
  },
  {
    name: 'to_binary',
    description:
      'to_binary(str[, fmt]) - Converts the input str to a binary value based on the supplied fmt. fmt can be a case-insensitive string literal of "hex", "utf-8", "utf8", or "base64". By default, the binary format for conversion is "hex" if fmt is omitted. The function returns NULL if at least one of the input parameters is NULL.',
    rawData: {
      value: 'to_binary()',
    },
  },
  {
    name: 'to_char',
    description:
      'to_char(numberExpr, formatExpr) - Convert numberExpr to a string based on the formatExpr. Throws an exception if the conversion fails.',
    rawData: {
      value: 'to_char()',
    },
  },
  {
    name: 'to_csv',
    description: 'to_csv(expr[, options]) - Returns a CSV string with a given struct value',
    rawData: {
      value: 'to_csv()',
    },
  },
  {
    name: 'to_date',
    description:
      'to_date(date_str[, fmt]) - Parses the date_str expression with the fmt expression to a date. Returns null with invalid input. By default, it follows casting rules to a date if the fmt is omitted.',
    rawData: {
      value: 'to_date()',
    },
  },
  {
    name: 'to_json',
    description: 'to_json(expr[, options]) - Returns a JSON string with a given struct value',
    rawData: {
      value: 'to_json()',
    },
  },
  {
    name: 'to_number',
    description:
      "to_number(expr, fmt) - Convert string 'expr' to a number based on the string format 'fmt'. Throws an exception if the conversion fails.",
    rawData: {
      value: 'to_number()',
    },
  },
  {
    name: 'to_timestamp',
    description:
      'to_timestamp(timestamp_str[, fmt]) - Parses the timestamp_str expression with the fmt expression to a timestamp. Returns null with invalid input. By default, it follows casting rules to a timestamp if the fmt is omitted.',
    rawData: {
      value: 'to_timestamp()',
    },
  },
  {
    name: 'to_timestamp_ltz',
    description:
      'to_timestamp_ltz(timestamp_str[, fmt]) - Parses the timestamp_str expression with the fmt expression to a timestamp with local time zone. Returns null with invalid input. By default, it follows casting rules to a timestamp if the fmt is omitted.',
    rawData: {
      value: 'to_timestamp_ltz()',
    },
  },
  {
    name: 'to_timestamp_ntz',
    description:
      'to_timestamp_ntz(timestamp_str[, fmt]) - Parses the timestamp_str expression with the fmt expression to a timestamp without time zone. Returns null with invalid input. By default, it follows casting rules to a timestamp if the fmt is omitted.',
    rawData: {
      value: 'to_timestamp_ntz()',
    },
  },
  {
    name: 'to_unix_timestamp',
    description: 'to_unix_timestamp(timeExp[, fmt]) - Returns the UNIX timestamp of the given time.',
    rawData: {
      value: 'to_unix_timestamp()',
    },
  },
  {
    name: 'to_utc_timestamp',
    description:
      "to_utc_timestamp(timestamp, timezone) - Given a timestamp like '2017-07-14 02:40:00.0', interprets it as a time in the given time zone, and renders that time as a timestamp in UTC. For example, 'GMT+1' would yield '2017-07-14 01:40:00.0'.",
    rawData: {
      value: 'to_utc_timestamp()',
    },
  },
  {
    name: 'to_varchar',
    description:
      'to_varchar(numberExpr, formatExpr) - Convert numberExpr to a string based on the formatExpr. Throws an exception if the conversion fails.',
    rawData: {
      value: 'to_varchar()',
    },
  },
  {
    name: 'transform',
    description: 'transform(expr, func) - Transforms elements in an array using the function.',
    rawData: {
      value: 'transform()',
    },
  },
  {
    name: 'transform_keys',
    description: 'transform_keys(expr, func) - Transforms elements in a map using the function.',
    rawData: {
      value: 'transform_keys()',
    },
  },
  {
    name: 'transform_values',
    description: 'transform_values(expr, func) - Transforms values in the map using the function.',
    rawData: {
      value: 'transform_values()',
    },
  },
  {
    name: 'translate',
    description:
      'translate(input, from, to) - Translates the input string by replacing the characters present in the from string with the corresponding characters in the to string.',
    rawData: {
      value: 'translate()',
    },
  },
  {
    name: 'trim',
    description: 'trim(str) - Removes the leading and trailing space characters from str.',
    rawData: {
      value: 'trim()',
    },
  },
  {
    name: 'trunc',
    description:
      'trunc(date, fmt) - Returns date with the time portion of the day truncated to the unit specified by the format model fmt.',
    rawData: {
      value: 'trunc()',
    },
  },
  {
    name: 'try_add',
    description:
      'try_add(expr1, expr2) - Returns the sum of expr1and expr2 and the result is null on overflow. The acceptable input types are the same with the + operator.',
    rawData: {
      value: 'try_add()',
    },
  },
  {
    name: 'try_avg',
    description:
      'try_avg(expr) - Returns the mean calculated from values of a group and the result is null on overflow.',
    rawData: {
      value: 'try_avg()',
    },
  },
  {
    name: 'try_divide',
    description:
      'try_divide(dividend, divisor) - Returns dividend/divisor. It always performs floating point division. Its result is always null if expr2 is 0. dividend must be a numeric or an interval. divisor must be a numeric.',
    rawData: {
      value: 'try_divide()',
    },
  },
  {
    name: 'try_element_at',
    description:
      'try_element_at(array, index) - Returns element of array at given (1-based) index. If Index is 0, Spark will throw an error. If index  < 0, accesses elements from the last to the first. The function always returns NULL if the index exceeds the length of the array.',
    rawData: {
      value: 'try_element_at()',
    },
  },
  {
    name: 'try_multiply',
    description:
      'try_multiply(expr1, expr2) - Returns expr1*expr2 and the result is null on overflow. The acceptable input types are the same with the * operator.',
    rawData: {
      value: 'try_multiply()',
    },
  },
  {
    name: 'try_subtract',
    description:
      'try_subtract(expr1, expr2) - Returns expr1-expr2 and the result is null on overflow. The acceptable input types are the same with the - operator.',
    rawData: {
      value: 'try_subtract()',
    },
  },
  {
    name: 'try_sum',
    description:
      'try_sum(expr) - Returns the sum calculated from values of a group and the result is null on overflow.',
    rawData: {
      value: 'try_sum()',
    },
  },
  {
    name: 'try_to_binary',
    description:
      'try_to_binary(str[, fmt]) - This is a special version of to_binary that performs the same operation, but returns a NULL value instead of raising an error if the conversion cannot be performed.',
    rawData: {
      value: 'try_to_binary()',
    },
  },
  {
    name: 'try_to_number',
    description:
      "try_to_number(expr, fmt) - Convert string 'expr' to a number based on the string format fmt. Returns NULL if the string 'expr' does not match the expected format. The format follows the same semantics as the to_number function.",
    rawData: {
      value: 'try_to_number()',
    },
  },
  {
    name: 'try_to_timestamp',
    description:
      'try_to_timestamp(timestamp_str[, fmt]) - Parses the timestamp_str expression with the fmt expression to a timestamp. The function always returns null on an invalid input with/without ANSI SQL mode enabled. By default, it follows casting rules to a timestamp if the fmt is omitted.',
    rawData: {
      value: 'try_to_timestamp()',
    },
  },
  {
    name: 'typeof',
    description: 'typeof(expr) - Return DDL-formatted type string for the data type of the input.',
    rawData: {
      value: 'typeof()',
    },
  },
  {
    name: 'ucase',
    description: 'ucase(str) - Returns str with all characters changed to uppercase.',
    rawData: {
      value: 'ucase()',
    },
  },
  {
    name: 'unbase64',
    description: 'unbase64(str) - Converts the argument from a base 64 string str to a binary.',
    rawData: {
      value: 'unbase64()',
    },
  },
  {
    name: 'unhex',
    description: 'unhex(expr) - Converts hexadecimal expr to binary.',
    rawData: {
      value: 'unhex()',
    },
  },
  {
    name: 'unix_date',
    description: 'unix_date(date) - Returns the number of days since 1970-01-01.',
    rawData: {
      value: 'unix_date()',
    },
  },
  {
    name: 'unix_micros',
    description: 'unix_micros(timestamp) - Returns the number of microseconds since 1970-01-01 00:00:00 UTC.',
    rawData: {
      value: 'unix_micros()',
    },
  },
  {
    name: 'unix_millis',
    description:
      'unix_millis(timestamp) - Returns the number of milliseconds since 1970-01-01 00:00:00 UTC. Truncates higher levels of precision.',
    rawData: {
      value: 'unix_millis()',
    },
  },
  {
    name: 'unix_seconds',
    description:
      'unix_seconds(timestamp) - Returns the number of seconds since 1970-01-01 00:00:00 UTC. Truncates higher levels of precision.',
    rawData: {
      value: 'unix_seconds()',
    },
  },
  {
    name: 'unix_timestamp',
    description: 'unix_timestamp([timeExp[, fmt]]) - Returns the UNIX timestamp of current or specified time.',
    rawData: {
      value: 'unix_timestamp()',
    },
  },
  {
    name: 'upper',
    description: 'upper(str) - Returns str with all characters changed to uppercase.',
    rawData: {
      value: 'upper()',
    },
  },
  {
    name: 'url_decode',
    description:
      "url_decode(str) - Decodes a str in 'application/x-www-form-urlencoded' format using a specific encoding scheme.",
    rawData: {
      value: 'url_decode()',
    },
  },
  {
    name: 'url_encode',
    description:
      "url_encode(str) - Translates a string into 'application/x-www-form-urlencoded' format using a specific encoding scheme.",
    rawData: {
      value: 'url_encode()',
    },
  },
  {
    name: 'user',
    description: 'user() - user name of current execution context.',
    rawData: {
      value: 'user()',
    },
  },
  {
    name: 'uuid',
    description:
      'uuid() - Returns an universally unique identifier (UUID) string. The value is returned as a canonical UUID 36-character string.',
    rawData: {
      value: 'uuid()',
    },
  },
  {
    name: 'var_pop',
    description: 'var_pop(expr) - Returns the population variance calculated from values of a group.',
    rawData: {
      value: 'var_pop()',
    },
  },
  {
    name: 'var_samp',
    description: 'var_samp(expr) - Returns the sample variance calculated from values of a group.',
    rawData: {
      value: 'var_samp()',
    },
  },
  {
    name: 'variance',
    description: 'variance(expr) - Returns the sample variance calculated from values of a group.',
    rawData: {
      value: 'variance()',
    },
  },
  {
    name: 'version',
    description:
      'version() - Returns the Spark version. The string contains 2 fields, the first being a release version and the second being a git revision.',
    rawData: {
      value: 'version()',
    },
  },
  {
    name: 'weekday',
    description:
      'weekday(date) - Returns the day of the week for date/timestamp (0 = Monday, 1 = Tuesday, ..., 6 = Sunday).',
    rawData: {
      value: 'weekday()',
    },
  },
  {
    name: 'weekofyear',
    description:
      'weekofyear(date) - Returns the week of the year of the given date. A week is considered to start on a Monday and week 1 is the first week with >3 days.',
    rawData: {
      value: 'weekofyear()',
    },
  },
  {
    name: 'width_bucket',
    description:
      'width_bucket(value, min_value, max_value, num_bucket) - Returns the bucket number to which value would be assigned in an equiwidth histogram with num_bucket buckets, in the range min_value to max_value."',
    rawData: {
      value: 'width_bucket()',
    },
  },
  {
    name: 'window',
    description:
      'window(time_column, window_duration[, slide_duration[, start_time]]) - Bucketize rows into one or more time windows given a timestamp specifying column. Window starts are inclusive but the window ends are exclusive, e.g. 12:05 will be in the window [12:05,12:10) but not in [12:00,12:05). Windows can support microsecond precision. Windows in the order of months are not supported.',
    rawData: {
      value: 'window()',
    },
  },
  {
    name: 'window_time',
    description:
      'window_time(window_column) - Extract the time value from time/session window column which can be used for event time value of window. The extracted time is (window.end - 1) which reflects the fact that the the aggregating windows have exclusive upper bound - [start, end).',
    rawData: {
      value: 'window_time()',
    },
  },
  {
    name: 'xpath',
    description:
      'xpath(xml, xpath) - Returns a string array of values within the nodes of xml that match the XPath expression.',
    rawData: {
      value: 'xpath()',
    },
  },
  {
    name: 'xpath_boolean',
    description:
      'xpath_boolean(xml, xpath) - Returns true if the XPath expression evaluates to true, or if a matching node is found.',
    rawData: {
      value: 'xpath_boolean()',
    },
  },
  {
    name: 'xpath_double',
    description:
      'xpath_double(xml, xpath) - Returns a double value, the value zero if no match is found, or NaN if a match is found but the value is non-numeric.',
    rawData: {
      value: 'xpath_double()',
    },
  },
  {
    name: 'xpath_float',
    description:
      'xpath_float(xml, xpath) - Returns a float value, the value zero if no match is found, or NaN if a match is found but the value is non-numeric.',
    rawData: {
      value: 'xpath_float()',
    },
  },
  {
    name: 'xpath_int',
    description:
      'xpath_int(xml, xpath) - Returns an integer value, or the value zero if no match is found, or a match is found but the value is non-numeric.',
    rawData: {
      value: 'xpath_int()',
    },
  },
  {
    name: 'xpath_long',
    description:
      'xpath_long(xml, xpath) - Returns a long integer value, or the value zero if no match is found, or a match is found but the value is non-numeric.',
    rawData: {
      value: 'xpath_long()',
    },
  },
  {
    name: 'xpath_number',
    description:
      'xpath_number(xml, xpath) - Returns a double value, the value zero if no match is found, or NaN if a match is found but the value is non-numeric.',
    rawData: {
      value: 'xpath_number()',
    },
  },
  {
    name: 'xpath_short',
    description:
      'xpath_short(xml, xpath) - Returns a short integer value, or the value zero if no match is found, or a match is found but the value is non-numeric.',
    rawData: {
      value: 'xpath_short()',
    },
  },
  {
    name: 'xpath_string',
    description:
      'xpath_string(xml, xpath) - Returns the text contents of the first xml node that matches the XPath expression.',
    rawData: {
      value: 'xpath_string()',
    },
  },
  {
    name: 'xxhash64',
    description: 'xxhash64(expr1, expr2, ...) - Returns a 64-bit hash value of the arguments. Hash seed is 42.',
    rawData: {
      value: 'xxhash64()',
    },
  },
  {
    name: 'year',
    description: 'year(date) - Returns the year component of the date/timestamp.',
    rawData: {
      value: 'year()',
    },
  },
  {
    name: 'zip_with',
    description:
      'zip_with(left, right, func) - Merges the two given arrays, element-wise, into a single array using function. If one array is shorter, nulls are appended at the end to match the length of the longer array, before applying function.',
    rawData: {
      value: 'zip_with()',
    },
  },
];
