import { SourceModalsPushModalPayload, SourceModalsState } from '@modules/source/modals/SourceModalsTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: SourceStore = {
  modals: { selectedModal: null },
};

export const sourceSlice = createSlice({
  name: 'source',
  initialState,
  reducers: {
    pushModal: (state, action: PayloadAction<SourceModalsPushModalPayload>) => {
      const { type, data } = action.payload;
      state.modals = { selectedModal: type, data };
    },
    dropModal: (state, action: PayloadAction<void>) => {
      state.modals = initialState.modals;
    },
    reset: (state) => {
      return initialState;
    },
  },
});

export const sourceActions = sourceSlice.actions;

export const sourceReducer = sourceSlice.reducer;

interface SourceStore {
  modals: SourceModalsState;
}
