import { Typography } from '@ui';
import Scrollbars from 'react-custom-scrollbars-2';
import { useTranslation } from 'react-i18next';
import { CSSObject } from '@emotion/react';
import { NotificationItem } from './NotificationItem';
import { TNotification } from '../duck/notificationsApi';

export const NotificationsArchivedPane = ({ archived }: NotificationsArchivedPaneProps) => {
  const { t } = useTranslation(['shared']);

  return (
    <>
      <Scrollbars autoHide={false} autoHeight autoHeightMin={490}>
        {archived.length === 0 && (
          <div css={cssText}>
            <Typography.Text type="secondary">{t('notification.archivedNotFound')}</Typography.Text>
          </div>
        )}
        {archived.map((notification) => (
          <NotificationItem key={notification.notificationId} notification={notification} selectable={false} />
        ))}
      </Scrollbars>
    </>
  );
};

const cssText = (): CSSObject => ({
  width: '100%',
  textAlign: 'center',
});

interface NotificationsArchivedPaneProps {
  archived: TNotification[];
}
