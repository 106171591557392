import { SupportedEnvs } from '@app/AppTypes';
import { appApi } from '@config/appApi';

export const AppApiRoutes = {
  supportedEnvs: '/api/supported/envs',
};

export const AppApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    supportedEnvs: builder.query<SupportedEnvs, void>({
      keepUnusedDataFor: 3600,
      query: (params) => ({ params, url: AppApiRoutes.supportedEnvs }),
    }),
  }),
});

export const { useSupportedEnvsQuery } = AppApi;
