import { ELibrarySourceType, IBaseLibraryEntitySystemInfo } from '@modules/library/root/LibraryTypes';
import { ILibraryNotebook, ILibraryNotebookQuery } from '@modules/library/notebook/LibraryNotebookTypes';
import { ILibraryImportNotebook } from '../components/LibraryImportNotebook';

export const prepareLibraryNotebook = (
  data: ILibraryImportNotebook[],
  kind: ELibrarySourceType,
  systemInfo?: IBaseLibraryEntitySystemInfo,
): ILibraryNotebookQuery['data'] => {
  if (!Array.isArray(data) || kind !== ELibrarySourceType.Library) return [];

  return data.map((item) => {
    const rawData = item.rawData as ILibraryNotebook;
    return {
      id: rawData.id,
      name: rawData.name,
      description: rawData.description,
      configuration: rawData.configuration,
      source: rawData.source || '',
      source_id: rawData.source_id || 0,
      env: rawData.env || '',
      tenant_info: rawData.tenant_info || '',
      object_type: rawData.object_type || '',
    };
  });
};
