import { appApi } from '@config/appApi';
import { MODEL_TAG_DESCRIPTION } from '@modules/model/duck/modelConstants';
import {
  LineageQueryParams,
  ModelConfigureQuery,
  ModelListQueryParams,
  ModelListResponse,
  ModelLogsQueryParams,
  ModelLogsType,
  ModelQuery,
  ModelResponse,
  ReviewModelLogsQueryParams,
  ReviewModelQuery,
  RunModelStatus,
  RunSqlQueryParams,
  RunSqlQueryResponse,
} from '@modules/model/ModelTypes';
import { StoreInvalidations } from '@modules/stores/duck/storeApi';
import { ViewerInvalidations } from '@modules/viewer/duck/viewerApi';
import { AuditInvalidations } from '@modules/audit/duck/auditApi';
import { GpdipInvalidations } from '@modules/gpdip/duck/gpdipTags';
import { TagDescription } from '@reduxjs/toolkit/query/react';

export const ModelApiRoutes = {
  list: `api/models`,
  run: (modelId: number) => `api/models/${modelId}/run`,
  configure: (modelId: number) => `api/models/${modelId}/configuration`,
  model: ({ modelId, sourceEnv }: { modelId: number; sourceEnv?: string }) =>
    `api/models/${modelId}${sourceEnv ? `?source_env=${sourceEnv}` : ''}`,
  logs: (modelId: number) => `api/models/${modelId}/logs`,
  reviewLogs: (modelName: string) => `api/review/models/${modelName}/logs`,
  review: `/api/model/review/table`,
  runSql: 'api/data/sql/preview',
  lineage: 'api/data/sql/lineage',
  status: 'api/models/run/status',
};

export const ModelInvalidations: {
  ID: (modelId: number) => TagDescription<MODEL_TAG_DESCRIPTION.ID>;
  LIST: TagDescription<MODEL_TAG_DESCRIPTION.LIST>;
  LOGS: (modelId: number) => TagDescription<MODEL_TAG_DESCRIPTION.LOGS>;
  REVIEW_LOGS: (modelName: string, source?: string) => TagDescription<MODEL_TAG_DESCRIPTION.REVIEW_LOGS>;
  STASUSES: TagDescription<MODEL_TAG_DESCRIPTION.STATUSES>;
} = {
  ID: (modelId: number) => ({ type: MODEL_TAG_DESCRIPTION.ID, modelId }),
  LIST: { type: MODEL_TAG_DESCRIPTION.LIST, id: 'LIST' },
  LOGS: (modelId: number) => ({
    type: MODEL_TAG_DESCRIPTION.LOGS,
    modelId,
  }),
  REVIEW_LOGS: (modelName: string, source?: string) => ({
    type: MODEL_TAG_DESCRIPTION.REVIEW_LOGS,
    modelName: `${modelName}-${source}`,
  }),
  STASUSES: { type: MODEL_TAG_DESCRIPTION.STATUSES, id: 'STATUSES' },
};

export const ModelApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    model: builder.query<ModelResponse, { modelId: number; sourceEnv?: string }>({
      providesTags: (request, error, data) => [ModelInvalidations.ID(data.modelId)],
      query: (data) => ({
        url: ModelApiRoutes.model(data),
      }),
    }),
    modelListPaginated: builder.query<ModelListResponse, ModelListQueryParams | void>({
      providesTags: [ModelInvalidations.LIST],
      query: (params) => ({
        params,
        url: ModelApiRoutes.list,
      }),
    }),
    modelList: builder.query<ModelListResponse['items'], ModelListQueryParams | void>({
      providesTags: [ModelInvalidations.LIST],
      query: (params) => ({
        params,
        url: ModelApiRoutes.list,
      }),
    }),
    modelLogs: builder.query<ModelLogsType, ModelLogsQueryParams>({
      providesTags: (request, error, modelId) => [ModelInvalidations.LOGS(modelId)],
      query: (modelId) => ({ url: ModelApiRoutes.logs(modelId) }),
    }),
    reviewModelLogs: builder.query<ModelLogsType, ReviewModelLogsQueryParams>({
      providesTags: (request, error, { modelName, source_env }) => [
        ModelInvalidations.REVIEW_LOGS(modelName, source_env),
      ],
      query: ({ modelName, source_env }) => ({ url: ModelApiRoutes.reviewLogs(modelName), params: { source_env } }),
    }),
    deleteModel: builder.mutation<void, number>({
      invalidatesTags: (request, error, modelId) => [
        ModelInvalidations.LIST,
        ModelInvalidations.ID(modelId),
        StoreInvalidations.LIST,
        AuditInvalidations.LIST,
        ViewerInvalidations.TABLES_EXIST,
        ViewerInvalidations.TABLES_IN_SQL_EXIST,
      ],
      query: (modelId) => ({
        method: 'DELETE',
        url: ModelApiRoutes.model({ modelId }),
      }),
    }),
    runModel: builder.mutation<void, number>({
      invalidatesTags: (request, error, modelId) => [
        StoreInvalidations.LIST,
        ViewerInvalidations.TABLES_EXIST,
        ViewerInvalidations.TABLES_IN_SQL_EXIST,
        ModelInvalidations.LOGS(modelId),
      ],
      query: (modelId) => ({
        method: 'POST',
        url: ModelApiRoutes.run(modelId),
      }),
    }),
    configureModel: builder.mutation<void, ModelConfigureQuery>({
      invalidatesTags: (request, error, data) => [
        ModelInvalidations.LIST,
        ViewerInvalidations.TABLES_LIST,
        AuditInvalidations.LIST,
        ModelInvalidations.ID(data.id),
      ],
      query: (data) => ({
        data,
        method: 'PUT',
        url: ModelApiRoutes.configure(data.id),
      }),
    }),
    saveModel: builder.mutation<void, ModelQuery>({
      invalidatesTags: (request, error, data) =>
        data.id
          ? [
              ModelInvalidations.LIST,
              ModelInvalidations.ID(data.id),
              StoreInvalidations.LIST,
              data.name ? ViewerInvalidations.TABLE_INFO(data.name!) : ViewerInvalidations.ALL_TABLE_INFO,
              AuditInvalidations.LIST,
              GpdipInvalidations.LIST,
            ]
          : [ModelInvalidations.LIST, StoreInvalidations.LIST, AuditInvalidations.LIST, GpdipInvalidations.LIST],
      query: (data) => ({
        data,
        method: data.id ? 'PUT' : 'POST',
        url: data.id ? ModelApiRoutes.model({ modelId: data.id }) : ModelApiRoutes.list,
      }),
    }),
    reviewModel: builder.mutation<void, ReviewModelQuery>({
      query: ({ source_env, ...data }) => ({
        data,
        params: { source_env },
        method: 'POST',
        url: ModelApiRoutes.review,
      }),
    }),
    runSql: builder.query<RunSqlQueryResponse, RunSqlQueryParams>({
      keepUnusedDataFor: 0,
      query: ({ sql_statement, size, sourceEnv }) => ({
        data: { sql_statement },
        params: { size, source_env: sourceEnv },
        method: 'POST',
        url: ModelApiRoutes.runSql,
        timeout: 0,
      }),
    }),
    lineage: builder.query<Record<string, string[]>, LineageQueryParams>({
      keepUnusedDataFor: 0,
      query: ({ sql_statement, virtual_metadata }) => ({
        data: { sql_statement, virtual_metadata },
        method: 'POST',
        url: ModelApiRoutes.lineage,
      }),
    }),
    runModelStatus: builder.query<RunModelStatus[], void>({
      providesTags: [ModelInvalidations.STASUSES],
      query: () => ({
        url: ModelApiRoutes.status,
      }),
    }),
  }),
});

export const {
  useModelListPaginatedQuery,
  useModelLogsQuery,
  useReviewModelLogsQuery,
  useDeleteModelMutation,
  useSaveModelMutation,
  useReviewModelMutation,
  useConfigureModelMutation,
  useModelQuery,
  useRunModelMutation,
  useLazyRunSqlQuery,
  useLazyLineageQuery,
  useLazyModelListQuery,
  useRunSqlQuery,
  useLazyRunModelStatusQuery,
} = ModelApi;
