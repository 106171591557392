import { BaseStage } from '@modules/modelEditor/components/builder/stages';
import { modelEditorActions } from '@modules/modelEditor/duck/modelEditorSlice';
import { getStageData } from '@modules/modelEditor/duck/modelEditorUtils';
import { ModelEditorModalsType } from '@modules/modelEditor/modals';
import { useDispatch } from 'react-redux';
import { NodeProps } from 'reactflow';

export const ResultStage = (props: NodeProps) => {
  const dispatch = useDispatch();

  const onSettingsHandler = () =>
    dispatch(
      modelEditorActions.pushModal({
        type: ModelEditorModalsType.resultSettings,
        data: {
          nodeId: props.id,
          initData: getStageData(props),
        },
      }),
    );

  const actions = {
    onSettings: onSettingsHandler,
  };

  return <BaseStage {...props} actions={actions} sourceHandle={false} disableDataViewer />;
};
