import { I18nLocales } from '@i18n';
import en from './datastore_en.json';

export const dataStoreLocales: Record<I18nLocales, I18nDataStoreTranslations> = {
  en,
};

export interface I18nDataStoreTranslations {
  pageRootName: string;
  loadingError: string;
  rootTable: {
    name: string;
    description: string;
    dataSource: string;
    created: string;
    active: string;
  };
  confirmation: {
    title: string;
    description: string;
  };
  saveForm: {
    title: string;
    titleEdit: string;
    name: string;
    description: string;
    dataSource: string;
    sensitive: string;
    active: string;
  };
  notifications: {
    sendTo: string;
    anyEmail: string;
    studyUsers: string;
    emailAddress: string;
    selectUser: string;
    typeEmail: string;
  };
  scheduler: {
    once: string;
    periodically: string;
    everyMonth: string;
    everyDayOfTheMonth: string;
    from: string;
    to: string;
    atTime: string;
    every: string;
  };
  storeTypes: {
    external: string;
    rccGroups: string;
    internal: string;
    studyRT: string;
    crossStudyRT: string;
  };
}
