import { QueryErrorType } from '@shared/utils/Error';
import { Result } from '@ui';
import { SerializedError } from '@reduxjs/toolkit';
import { ResultProps } from 'antd/lib/result';

interface QueryErrorProps {
  error?: QueryErrorType | SerializedError;
  title?: string | null;
  extra?: ResultProps['extra'];
}

export const QueryError = ({ error, title, extra }: QueryErrorProps) => {
  const currentError = error as QueryErrorType;

  return (
    <Result
      status={
        ['success', 'error', 'info', 'warning', 404, 403, 500, '404', '403', '500'].includes(currentError.status as any)
          ? (currentError.status as any)
          : 'error'
      }
      title={title}
      subTitle={currentError.data.userMsg?.toString()}
      extra={extra}
      children={process.env.NODE_ENV === 'development' && currentError.data.devMsg}
    />
  );
};
