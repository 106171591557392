import { useColumnSearch } from '@components/ui/table/tableHooks';
import { getListOption } from '@shared/utils/Table';
import { ActorAndInfo } from '@components';
import { dateToString } from '@shared/utils/Date';
import { Table, Tag } from '@ui';
import { SupportedEnvs } from '@app/AppTypes';
import { TFunction } from 'i18next';
import { Button, TableColumnsType, TableProps, Typography } from 'antd';
import { useMemo } from 'react';
import { FileSearchOutlined } from '@ant-design/icons';
import { IAnalysisPackageDeployment, IAnalysisPackageDeploymentListResponse } from '../AnalysisPackageTypes';
import { AnalysisPackageDeploymentStatus } from '../duck/analysisPackageConstants';

const getTagColor = (status: keyof typeof AnalysisPackageDeploymentStatus) => {
  if (status === AnalysisPackageDeploymentStatus.Success) {
    return 'success';
  }
  if (status === AnalysisPackageDeploymentStatus.Error) {
    return 'error';
  }
  if (status === AnalysisPackageDeploymentStatus.Running) {
    return 'processing';
  }
  return 'default';
};

export const AnalysisPackageDeploymentList = ({
  data,
  environments,
  pagination,
  loading,
  supportedEnvs,
  onChange,
  onOpenLog,
  onOpenPackage,
  t,
}: IAnalysisPackageDeploymentListProps) => {
  const { getColumnSearchProps, locale } = useColumnSearch<IAnalysisPackageDeploymentListResponse['items'][0]>();

  const environmentOptions = getListOption(environments).map((el) => ({
    ...el,
    text: supportedEnvs[el.value]?.label || t('na'),
  }));

  const statusOptions = Object.entries(AnalysisPackageDeploymentStatus).map(([key, value]) => ({
    text: t(`deployments.status.${value}`),
    value: key,
  }));

  const columns: TableColumnsType<IAnalysisPackageDeploymentListResponse['items'][0]> = useMemo(
    () => [
      {
        title: t('deployments.rootTable.version'),
        dataIndex: 'version',
        ...getColumnSearchProps(['package', 'version']),
        onFilter: undefined,
        render: (version, record) => (
          <Typography.Link onClick={() => onOpenPackage(record.package)} children={record.package.version} />
        ),
      },
      {
        title: t('deployments.rootTable.env'),
        filters: environmentOptions,
        dataIndex: 'environment',
        onFilter: undefined,
        render: (environment: string) => supportedEnvs[environment]?.label || t('na'),
      },
      {
        title: t('deployments.rootTable.started'),
        dataIndex: 'started_at',
        sorter: () => 0,
        sortDirections: ['ascend'],
        render: (startedAt: number, record) => (
          <ActorAndInfo info={dateToString(startedAt)} actor={record.created_by} />
        ),
      },
      {
        width: 110,
        title: t('deployments.rootTable.status'),
        dataIndex: 'status',
        filters: statusOptions,
        onFilter: undefined,
        render: (_, record) => <Tag color={getTagColor(record.status)}>{t(`deployments.status.${record.status}`)}</Tag>,
      },
      {
        width: 100,
        title: t('deployments.rootTable.log'),
        dataIndex: 'deployPackage',
        align: 'center',
        render: (_, record) => <Button icon={<FileSearchOutlined />} onClick={() => onOpenLog(record)} />,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [environmentOptions, statusOptions, supportedEnvs],
  );

  return (
    <Table
      bordered
      locale={locale}
      columns={columns}
      dataSource={data?.items}
      loading={loading}
      rowKey={(item) => item.id}
      onChange={onChange}
      tableLayout="fixed"
      scroll={{ x: 800 }}
      pagination={pagination}
    />
  );
};

export interface IAnalysisPackageDeploymentListProps {
  supportedEnvs: SupportedEnvs;
  studyId: number;
  data?: IAnalysisPackageDeploymentListResponse;
  environments: string[];
  pagination?: TableProps<IAnalysisPackageDeploymentListResponse['items'][0]>['pagination'];
  onChange?: TableProps<IAnalysisPackageDeploymentListResponse['items'][0]>['onChange'];
  onOpenLog: (data: IAnalysisPackageDeployment) => void;
  onOpenPackage: (data: { id: number }) => void;
  loading?: boolean;
  t: TFunction;
}
