import { JobModalsPushModalPayload, JobModalsState } from '@modules/job/modals';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: JobStore = {
  modals: { selectedModal: null, isMinimized: false },
};

export const jobSlice = createSlice({
  name: 'job',
  initialState,
  reducers: {
    pushModal: (state, action: PayloadAction<JobModalsPushModalPayload>) => {
      const { type, data } = action.payload;
      state.modals = { selectedModal: type, data };
    },
    dropModal: (state, action: PayloadAction<void>) => {
      state.modals = initialState.modals;
    },
    minimizeModal: (state, action: PayloadAction<boolean>) => {
      state.modals.isMinimized = action.payload;
    },
    reset: (state) => {
      return initialState;
    },
  },
});

export const jobActions = jobSlice.actions;

export const jobReducer = jobSlice.reducer;

interface JobStore {
  modals: JobModalsState;
}
