import { Breadcrumb, Button, Space, Typography } from '@ui';
import { ReactNode } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined, ReloadOutlined } from '@ant-design/icons';

export const PageNavigation = ({ breadcrumbs, hideBackBtn, extra, refresh }: PageNavigationProps) => {
  const navigate = useNavigate();
  const lastItem = breadcrumbs.at(-1);

  return (
    <Space full justify="space-between" css={cssBreadcrumb}>
      <Breadcrumb
        items={breadcrumbs.map((item) => ({
          title:
            lastItem !== item && item.link ? (
              <Link to={item.link}>{item.label}</Link>
            ) : item.click ? (
              <Typography.Link onClick={item.click} children={item.label} />
            ) : (
              item.label
            ),
          menu: item.menu
            ? {
                items: item.menu.map((itemMenu, key) => ({
                  key,
                  label: itemMenu.link ? (
                    <Link to={itemMenu.link}>{itemMenu.label}</Link>
                  ) : itemMenu.click ? (
                    <Typography.Link onClick={itemMenu.click} children={itemMenu.label} />
                  ) : (
                    itemMenu.label
                  ),
                })),
              }
            : undefined,
        }))}
      />
      <Space>
        {!hideBackBtn && <Button size="small" onClick={() => navigate(-1)} icon={<ArrowLeftOutlined />} />}
        {refresh && (
          <Button
            size="small"
            type="primary"
            onClick={refresh.onRefresh}
            loading={refresh.loading}
            icon={<ReloadOutlined />}
          />
        )}
        {extra}
      </Space>
    </Space>
  );
};

const cssBreadcrumb = () => ({
  marginTop: '-16px',
  marginBottom: '8px',
});

export interface PageNavigationProps {
  breadcrumbs: Array<{
    label: ReactNode;
    click?: () => void;
    link?: string;
    menu?: Array<{
      label: ReactNode;
      click?: () => void;
      link?: string;
    }>;
  }>;
  hideBackBtn?: boolean;
  extra?: Array<ReactNode> | ReactNode | false;
  refresh?:
    | false
    | {
        onRefresh: () => void;
        loading?: boolean;
      };
}
