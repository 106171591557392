import { selectSourceModals } from '@modules/source/duck/sourceSelectors';
import { sourceActions } from '@modules/source/duck/sourceSlice';
import { useDispatch, useSelector } from 'react-redux';
import { SourceModalsSaveSource, SourceModalsSaveSourceProps } from './components';
import { SourceModalsType } from './SourceModalsConstants';

export const SourceModalsController = () => {
  const dispatch = useDispatch();
  const { data, selectedModal } = useSelector(selectSourceModals);

  const closeModal = () => {
    dispatch(sourceActions.dropModal());
  };

  return (
    <>
      <SourceModalsSaveSource
        open={selectedModal === SourceModalsType.saveSource}
        data={data as SourceModalsSaveSourceProps['data']}
        onClose={closeModal}
      />
    </>
  );
};
