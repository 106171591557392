import { GpdipModalsPushModalPayload, GpdipModalsState } from '@modules/gpdip/modals/GpdipModalsTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: GpdipStore = {
  modals: { selectedModal: null },
};

export const gpdipSlice = createSlice({
  name: 'gpdip',
  initialState,
  reducers: {
    pushModal: (state, action: PayloadAction<GpdipModalsPushModalPayload>) => {
      const { type, data } = action.payload;
      state.modals = { selectedModal: type, data };
    },
    dropModal: (state, action: PayloadAction<void>) => {
      state.modals = initialState.modals;
    },
    reset: (state) => {
      return initialState;
    },
  },
});

export const gpdipActions = gpdipSlice.actions;

export const gpdipReducer = gpdipSlice.reducer;

interface GpdipStore {
  modals: GpdipModalsState;
}
