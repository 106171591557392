import {
  LibraryAuditModalsPushModalPayload,
  LibraryAuditModalsState,
} from '@modules/library/audit/modals/LibraryAuditModalsTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: LibraryAuditStore = {
  modals: { selectedModal: null },
};

export const libraryAuditSlice = createSlice({
  name: 'source',
  initialState,
  reducers: {
    pushModal: (state, action: PayloadAction<LibraryAuditModalsPushModalPayload>) => {
      const { type, data } = action.payload;
      state.modals = { selectedModal: type, data };
    },
    dropModal: (state, action: PayloadAction<void>) => {
      state.modals = initialState.modals;
    },
    reset: (state) => {
      return initialState;
    },
  },
});

export const libraryAuditActions = libraryAuditSlice.actions;

export const libraryAuditReducer = libraryAuditSlice.reducer;

interface LibraryAuditStore {
  modals: LibraryAuditModalsState;
}
