import { CSSObject } from '@emotion/react';
import AntdCard, { CardProps as AntdCardProps } from 'antd/lib/card';
import { ForwardedRef, forwardRef } from 'react';

const Card = forwardRef(({ full, ...props }: CardProps, ref: ForwardedRef<any>) => (
  <AntdCard ref={ref} css={cssCard({ full })} {...props} />
));

const cssCard =
  ({ full }: Pick<CardProps, 'full'>) =>
  (): CSSObject => ({
    width: full ? '100%' : 'auto',
  });

export interface CardProps extends AntdCardProps {
  full?: boolean;
}

export { Card };
