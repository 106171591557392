import { appApi } from '@config/appApi';
import {
  ILibraryNotebookListQueryParams,
  ILibraryNotebookQuery,
  ILibraryNotebookResponse,
  ILibraryNotebookValidateQuery,
} from '@modules/library/notebook/LibraryNotebookTypes';
import { LIBRARY_NOTEBOOK_TAG_DESCRIPTION } from '@modules/library/notebook/duck/libraryNotebookConstants';
import { TagDescription } from '@reduxjs/toolkit/query/react';

export const LibraryNotebookApiRoutes = {
  list: 'api/library/libraries/python-notebooks',
  item: (id: number) => `api/library/libraries/python-notebooks/${id}`,
  validate: '/api/library/libraries/python-notebooks/validate',
};

const LibraryNotebookInvalidations: {
  LIST: TagDescription<LIBRARY_NOTEBOOK_TAG_DESCRIPTION.LIST>;
  ID: (id: number) => TagDescription<LIBRARY_NOTEBOOK_TAG_DESCRIPTION.ID>;
} = {
  LIST: { type: LIBRARY_NOTEBOOK_TAG_DESCRIPTION.LIST, id: 'LIST' },
  ID: (id: number) => ({ type: LIBRARY_NOTEBOOK_TAG_DESCRIPTION.ID, id }),
};

export const LibraryNotebookApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    libraryNotebookList: builder.query<ILibraryNotebookResponse, ILibraryNotebookListQueryParams | void>({
      providesTags: [LibraryNotebookInvalidations.LIST],
      query: (params) => ({
        params,
        url: LibraryNotebookApiRoutes.list,
      }),
    }),
    libraryDeleteNotebook: builder.mutation<void, number>({
      invalidatesTags: (request, error, id) => [LibraryNotebookInvalidations.LIST, LibraryNotebookInvalidations.ID(id)],
      query: (id) => ({
        method: 'DELETE',
        url: LibraryNotebookApiRoutes.item(id),
      }),
    }),
    libraryCopyNotebook: builder.mutation<void, ILibraryNotebookQuery>({
      invalidatesTags: [LibraryNotebookInvalidations.LIST],
      query: ({ data, overwrite }) => ({
        data,
        params: { overwrite },
        method: 'POST',
        url: LibraryNotebookApiRoutes.list,
      }),
    }),
    validateNotebook: builder.mutation<void, ILibraryNotebookValidateQuery>({
      query: (data) => ({
        data,
        method: 'POST',
        url: LibraryNotebookApiRoutes.validate,
      }),
    }),
  }),
});

export const {
  useLibraryNotebookListQuery,
  useLazyLibraryNotebookListQuery,
  useLibraryDeleteNotebookMutation,
  useLibraryCopyNotebookMutation,
  useValidateNotebookMutation,
} = LibraryNotebookApi;
