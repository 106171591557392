import { Space, Button, AutoComplete, Row, Col, FormItem } from '@ui';
import { ViewerDataPaginationResponse } from '@modules/viewer/duck/viewerApi';
import { css, CSSObject, Theme } from '@emotion/react';
import { useEffect, useRef, useState } from 'react';
import { get } from 'lodash';
import { AutoCompleteProps, Input, Typography } from 'antd';
import FormItemLabel from 'antd/lib/form/FormItemLabel';

export const ViewerDataTableFilter = ({
  appliedFilters,
  data,
  tableId,
  setAppliedFilters,
}: IViewerDataTableFilterProps) => {
  const [filters, setFilters] = useState<FiltersState>({});
  const [positions, setPositions] = useState<{ where?: number; order?: number }>({});
  const [options, setOptions] = useState<{ value: string; label: string }[]>([]);
  const refSkipFirstReset = useRef(true);

  useEffect(() => {
    const setPositionsHandler = (filter: keyof typeof positions) => (event: KeyboardEvent | MouseEvent) => {
      const key: string = get(event, 'key', '');
      let position = get(event.target, 'selectionStart', 0);
      if (position > 0 && (key === 'Backspace' || key === 'ArrowLeft')) {
        position--;
      }
      if (position <= get(event.target, 'value.length', 0) && (key === 'ArrowRight' || key.length === 1)) {
        position++;
      }
      setPositions((prev) => ({
        ...prev,
        [filter]: position,
      }));
    };

    const setWherePosition = setPositionsHandler('where');
    const setOrderPosition = setPositionsHandler('order');

    const elWhere = document.getElementById('acWhere')!;
    const elOrder = document.getElementById('acOrder')!;

    elWhere?.addEventListener('keydown', setWherePosition);
    elWhere?.addEventListener('click', setWherePosition);
    elOrder?.addEventListener('keydown', setOrderPosition);
    elOrder?.addEventListener('click', setOrderPosition);

    return () => {
      elWhere?.removeEventListener('keydown', setWherePosition);
      elWhere?.removeEventListener('click', setWherePosition);
      elOrder?.removeEventListener('keydown', setOrderPosition);
      elOrder?.removeEventListener('click', setOrderPosition);
    };
  }, []);

  useEffect(() => {
    if (refSkipFirstReset.current) {
      refSkipFirstReset.current = false;
      return;
    }
    clearFilters();
    setOptions([]);
  }, [tableId]);

  const fields = get(data, 'meta', []).map((item) => item.name);

  const clearFilters = () => setFilters({});

  const applyFilters = () => {
    if (
      (filters.where ?? '') === (appliedFilters.where ?? '') &&
      (filters.order ?? '') === (appliedFilters.order ?? '')
    ) {
      return;
    }
    setAppliedFilters({ ...filters, tableId });
  };

  const changeFilters =
    (filter: keyof typeof positions): AutoCompleteProps['onChange'] =>
    (value) => {
      setFilters((prev) => ({
        ...prev,
        [filter]: value,
      }));
    };

  const onSearchHandler =
    (filter: keyof typeof positions): AutoCompleteProps['onSearch'] =>
    (value) => {
      const prompt = get(value?.substring(0, positions[filter] || 0).match(/\b\w+$/), 0, '').toLowerCase();
      setOptions(
        fields.filter((field) => field.toLowerCase().includes(prompt)).map((field) => ({ value: field, label: field })),
      );
    };

  const onSelectHandler =
    (filter: keyof typeof positions): AutoCompleteProps['onSelect'] =>
    (value, option) => {
      const position = positions[filter] || 0;
      const prompt = get(filters[filter]!.substring(0, position).match(/\b\w+$/), 0, '');
      setFilters((prev) => ({
        ...prev,
        [filter]:
          filters[filter]!.substring(0, position - prompt.length) + option.value + filters[filter]!.substring(position),
      }));
    };

  return (
    <Row gutter={24} css={cssBody}>
      <Col span={10}>
        <FormItem label="Where" htmlFor="acWhere">
          <AutoComplete
            id="acWhere"
            css={cssFilterField}
            options={options}
            onChange={changeFilters('where')}
            onSearch={onSearchHandler('where')}
            onSelect={onSelectHandler('where')}
            value={filters.where}
            allowClear
          >
            <Input status={appliedFilters.isError ? 'error' : ''} />
          </AutoComplete>
        </FormItem>
      </Col>
      <Col span={10}>
        <FormItem label="Order by" htmlFor="acOrder">
          <AutoComplete
            id="acOrder"
            css={cssFilterField}
            options={options}
            onChange={changeFilters('order')}
            onSearch={onSearchHandler('order')}
            onSelect={onSelectHandler('order')}
            value={filters.order}
            allowClear
          >
            <Input status={appliedFilters.isError ? 'error' : ''} />
          </AutoComplete>
        </FormItem>
      </Col>
      <Col span={4}>
        <Button type="primary" onClick={applyFilters}>
          Apply
        </Button>
      </Col>
    </Row>
  );
};

const cssBody = (theme: Theme): CSSObject => ({
  padding: `0 ${theme.padding}px`,
});

const cssFilterField = (): CSSObject => ({
  width: '100%',
});

interface IViewerDataTableFilterProps {
  appliedFilters: FiltersState;
  setAppliedFilters: (state: FiltersState) => void;
  tableId?: string;
  data?: ViewerDataPaginationResponse;
}

export interface FiltersState {
  tableId?: string;
  where?: string;
  order?: string;
  isError?: boolean;
}
