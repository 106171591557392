import {
  TAnalysisObjectModelModalsPushModalPayload,
  TAnalysisObjectModelModalsState,
} from '@modules/library/analysisObjects/model/modals/AnalysisObjectModelModalsTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: AnalysisObjectModelStore = {
  modals: { selectedModal: null },
};

export const analysisObjectModelSlice = createSlice({
  name: 'analysisObjectModel',
  initialState,
  reducers: {
    pushModal: (state, action: PayloadAction<TAnalysisObjectModelModalsPushModalPayload>) => {
      const { type, data } = action.payload;
      state.modals = { selectedModal: type, data };
    },
    dropModal: (state, action: PayloadAction<void>) => {
      state.modals = initialState.modals;
    },
    reset: (state) => {
      return initialState;
    },
  },
});

export const analysisObjectModelActions = analysisObjectModelSlice.actions;

export const analysisObjectModelReducer = analysisObjectModelSlice.reducer;

interface AnalysisObjectModelStore {
  modals: TAnalysisObjectModelModalsState;
}
