import {
  DataModelModalsSaveModal,
  ModelModalsLog,
  ModelModalsLogProps,
  ModelModalsSaveModalProps,
} from '@modules/model/modals/components';
import { selectModelModals } from '@modules/model/duck/modelSelectors';
import { ModelModalsType } from '@modules/model/modals';
import { modelActions } from '@modules/model/duck/modelSlice';
import { useDispatch, useSelector } from 'react-redux';

export const ModelModalsController = () => {
  const dispatch = useDispatch();
  const { data, selectedModal } = useSelector(selectModelModals);

  const closeModal = () => {
    dispatch(modelActions.dropModal());
  };

  return (
    <>
      {data && (
        <ModelModalsLog
          open={selectedModal === ModelModalsType.openLogs}
          data={data as ModelModalsLogProps['data']}
          onClose={closeModal}
        />
      )}
      <DataModelModalsSaveModal
        open={selectedModal === ModelModalsType.saveModel}
        data={data as ModelModalsSaveModalProps['data']}
        onClose={closeModal}
      />
    </>
  );
};
