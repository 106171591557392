import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TNotification } from './notificationsApi';

interface INotificationsStore {
  latest: TNotification[];
  archived: TNotification[];
}

const initialState: INotificationsStore = {
  archived: [],
  latest: [],
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setArchived: (state, action: PayloadAction<TNotification[]>) => {
      state.archived = action.payload;
    },
    setLatest: (state, action: PayloadAction<TNotification[]>) => {
      state.latest = action.payload;
    },
  },
});

export const notificationsActions = notificationsSlice.actions;

export const notificationsReducer = notificationsSlice.reducer;
