import { PropertiesView } from '@components';
import { Button, Space, Typography } from '@ui';
import { StudyModalsController, StudyModalsType } from '@modules/study/modals';
import { studyActions } from '@modules/study/duck/studySlice';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

export const StudyDetailsTemplate = ({ detailsMap, conditionsAndEligibilityMap }: StudyDetailsTemplateProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['study']);

  const openDbSchemaModal = () => dispatch(studyActions.pushModal({ type: StudyModalsType.dbschema }));

  return (
    <>
      <Space direction="vertical" size="large">
        <PropertiesView data={detailsMap} />
        {conditionsAndEligibilityMap && (
          <PropertiesView
            data={conditionsAndEligibilityMap}
            renderHeader={<Typography.Title level={4} children={t('detailsPage.conditionsEligibilityTitle')} />}
          />
        )}
        <Button type="default" onClick={openDbSchemaModal}>
          {t('detailsPage.showStructure')}
        </Button>
      </Space>
      <StudyModalsController />
    </>
  );
};

interface StudyDetailsTemplateProps {
  detailsMap: Array<{
    key: string;
    value: any;
  }>;
  conditionsAndEligibilityMap?: Array<{
    key: string;
    value: any;
  }>;
}
