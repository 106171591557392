import { I18nLocales } from '@i18n';
import en from './study_en.json';

export const studyLocales: Record<I18nLocales, I18nStudyTranslations> = {
  en,
};

export interface I18nStudyTranslations {
  rootPage: {
    title: string;
    loadingError: string;
  };
  detailsPage: {
    loadingError: string;
    studyName: string;
    briefTitle: string;
    officialTitle: string;
    briefSummary: string;
    sponsor: string;
    screeningStartDate: string;
    secondaryIDs: string;
    collaborators: string;
    principalInvestigator: string;
    protocolVerification: string;
    approvalDate: string;
    protocolID: string;
    maximumAge: string;
    minimumAge: string;
    conditionsEligibilityTitle: string;
  };
  dbSchema: {
    title: string;
    rowNumber: string;
    name: string;
    type: string;
  };
}
