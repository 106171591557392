import { dateToString } from '@shared/utils/Date';
import { ActorAndInfo, InformationModal } from '@components';
import { useColumnSearch } from '@components/ui/table/tableHooks';
import { tableListToOptions, tableNamesToOptions } from '@shared/utils/Table';
import { useStudyPermissions } from '@modules/user/duck/userHooks';
import { Button, Popconfirm, Space, Table } from '@ui';
import { SupportedEnvs } from '@app/AppTypes';
import { Scope } from '@modules/dataset/DatasetTypes';
import { DatasetScopeList, DatasetScopeListScope, renderInfoModalContent } from '@modules/dataset/components';
import { useMemo, useState } from 'react';
import { TableColumnsType, TableProps } from 'antd';
import { TFunction } from 'i18next';
import { DeleteOutlined } from '@ant-design/icons';
import { IAnalysisObjectSQLDatasetListResponse, IAOSQLDatasetItem } from '../AnalysisObjectSQLDatasetTypes';

export const AnalysisObjectSQLDatasetList = ({
  data,
  loading,
  pagination,
  onChange,
  loadingDelete,
  supportedEnvs,
  onDeleteSQLDataset,
  t,
}: AnalysisObjectSQLDatasetListProps) => {
  const [studiesDetailsModal, setStudiesDetailsModal] = useState<DatasetScopeListScope>();
  const { getColumnSearchProps, locale } = useColumnSearch<IAOSQLDatasetItem>();

  const {
    userPermissions: { canGlDmAoDelete },
  } = useStudyPermissions();

  const storeListOption = tableNamesToOptions(data?.items, 'data_store');
  const sourceListOption = tableListToOptions(data?.items?.map((el) => ({ name: el.source!, id: el.source })));

  const columns: TableColumnsType<IAOSQLDatasetItem> = useMemo(
    () =>
      (
        [
          {
            title: t('rootTable.name'),
            dataIndex: 'name',
            ...getColumnSearchProps('name'),
            onFilter: undefined,
          },
          {
            title: t('sqlDatasetAO.rootTable.scope'),
            dataIndex: 'object_scope',
            render: (object_scope: Scope) => (
              <DatasetScopeList currentStudyName="-" onMoreBtnClick={setStudiesDetailsModal} scope={object_scope} />
            ),
          },
          {
            title: t('rootTable.source'),
            dataIndex: 'source',
            filters: sourceListOption,
            onFilter: undefined,
            render: (_, record) =>
              record.source && (
                <ActorAndInfo
                  info={supportedEnvs[record.env]?.label || t('na')}
                  actor={record.source}
                  actorSize={'sm'}
                />
              ),
          },
          {
            title: t('rootTable.created'),
            dataIndex: 'linked_at',
            sorter: () => 0,
            sortDirections: ['ascend'],
            render: (linkedAt: number, record) => (
              <ActorAndInfo info={dateToString(linkedAt)} actor={record.created_by} />
            ),
          },
          {
            width: 80,
            title: t('delete'),
            dataIndex: 'actionDelete',
            align: 'center',
            render: (_, record) => (
              <Space>
                <Popconfirm
                  title={t('sqlDatasetAO.confirmation.title')}
                  description={t('sqlDatasetAO.confirmation.description')}
                  okText={t('yes')}
                  cancelText={t('no')}
                  onConfirm={() => onDeleteSQLDataset({ id: record.version_id, name: record.name })}
                >
                  <Button icon={<DeleteOutlined />} loading={loadingDelete} />
                </Popconfirm>
              </Space>
            ),
          },
        ] as TableColumnsType<IAOSQLDatasetItem>
      ).filter(Boolean),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [storeListOption, sourceListOption, canGlDmAoDelete],
  );

  return (
    <>
      <Table
        bordered
        locale={locale}
        columns={columns}
        dataSource={data?.items}
        loading={loading}
        rowKey={(item) => item.id}
        onChange={onChange}
        tableLayout="fixed"
        scroll={{ x: 900 }}
        pagination={pagination}
      />
      {studiesDetailsModal && (
        <InformationModal
          message={renderInfoModalContent(studiesDetailsModal)}
          onClose={() => setStudiesDetailsModal(null)}
          width="500px"
          autoHeightMin={50}
        />
      )}
    </>
  );
};

export interface AnalysisObjectSQLDatasetListProps {
  studyId: number;
  data?: IAnalysisObjectSQLDatasetListResponse;
  pagination?: TableProps<IAOSQLDatasetItem>['pagination'];
  onChange?: TableProps<IAOSQLDatasetItem>['onChange'];
  loading?: boolean;
  loadingDelete?: boolean;
  onDeleteSQLDataset: (data: { id: number; name: string }) => void;
  supportedEnvs: SupportedEnvs;
  t: TFunction;
}
