import studyRoutes, { crossStudyRoutes } from '@routes/study/RoutesStudy';

const appRouteId = {
  root: 'app-root',
};

const appRoute = {
  root: '/',
};

const appResolver = {
  root: () => '/',
};

export default {
  app: {
    root: {
      path: appRoute.root,
      id: appRouteId.root,
      resolver: appResolver.root,
    },
  },
  study: studyRoutes,
  crossStudy: crossStudyRoutes,
};
