import { Input, List, Space, Typography } from '@components/ui';
import { useClickAndDoubleClickHandler } from '@modules/modelEditor/modals/utils/useClickAndDoubleClickHandler';
import { SearchOutlined } from '@ant-design/icons';
import { ReactNode, useMemo, useState } from 'react';
import { CSSObject, Theme } from '@emotion/react';
import Scrollbars from 'react-custom-scrollbars-2';
import { TFunction } from 'i18next';
import { SpaceProps } from 'antd';

export function ExpressionVarsList<RawData>({
  dataSource,
  searchPlaceholder,
  loading = false,
  selectedItem = '',
  disabled,
  notDisableSingleClick,
  onDragStart,
  onClick,
  onDoubleClick,
  t,
  spaceSize,
}: ExpressionVarsListProps<RawData>) {
  const [filterValue, setFilterValue] = useState('');

  const filteredData = useMemo(() => {
    return dataSource.filter((data) =>
      filterValue
        ? data.name.toLowerCase().includes(filterValue) ||
          data.title?.toLowerCase().includes(filterValue) ||
          data.description?.toLowerCase().includes(filterValue)
        : true,
    );
  }, [dataSource, filterValue]);

  const clickHandler = useClickAndDoubleClickHandler({
    onSingleClick: (item: ExpressionVarsListProps<RawData>['dataSource'][0], event) =>
      (!disabled || notDisableSingleClick) && onClick && onClick(item.rawData),
    onDoubleClick: (item: ExpressionVarsListProps<RawData>['dataSource'][0], event) =>
      !disabled && onDoubleClick && onDoubleClick(item?.rawData),
  });

  return (
    <Space direction="vertical" size={spaceSize || 'middle'} full>
      <Input
        size="small"
        width="100%"
        value={filterValue}
        onChange={(e) => setFilterValue(e.target.value.toLowerCase())}
        placeholder={searchPlaceholder}
        addonAfter={<SearchOutlined />}
      />
      <Scrollbars autoHeight height={200} autoHeightMin={200} css={cssScroll}>
        <List
          loading={loading}
          css={cssList}
          itemLayout="horizontal"
          dataSource={filteredData}
          locale={{
            emptyText: <Typography.Text type="secondary">{t('transform.noData')}</Typography.Text>,
          }}
          size="small"
          renderItem={(item) => (
            <List.Item
              css={cssListItem({ selectedItem, rawData: item?.rawData as RowDataWithValue })}
              onClick={(event) => clickHandler(item, event)}
            >
              {item.title && (
                <Typography.Text css={cssText} strong>
                  {item.title}
                </Typography.Text>
              )}
              {disabled ? (
                <Typography.Text children={item.name} strong />
              ) : (
                <Typography.Link
                  css={cssText}
                  strong
                  draggable
                  onDragStart={(e) => onDragStart && onDragStart(item.rawData as RowDataWithValue, e)}
                  children={item.name}
                />
              )}
              {item.description && (
                <Typography.Text css={[cssText, cssTextDescription]} type="secondary">
                  {item.description}
                </Typography.Text>
              )}
            </List.Item>
          )}
        />
      </Scrollbars>
    </Space>
  );
}

const cssScroll = (theme: Theme): CSSObject => ({
  border: `1px solid ${theme.colorBorder}`,
  borderRadius: theme.borderRadiusSM,
});

const cssList = (theme: Theme): CSSObject => ({
  width: '100%',
});

const cssListItem =
  ({ selectedItem, rawData }: ListItemCSSProps) =>
  (theme: Theme): CSSObject => ({
    '&&&': { padding: theme.paddingXXS, alignItems: 'flex-start' },
    flexDirection: 'column',
    lineHeight: '1rem',
    cursor: 'pointer',
    backgroundColor:
      (typeof selectedItem === 'string' ? selectedItem : selectedItem?.id || selectedItem?.value) ===
      (rawData?.id || rawData?.value)
        ? theme.colorFillSecondary
        : 'inherit',
  });

const cssText = (): CSSObject => ({
  display: 'inline-block',
  width: '100%',
  fontSize: '0.8rem',
  lineHeight: '1rem',
});

const cssTextDescription = (): CSSObject => ({
  fontSize: '0.7rem',
});

interface ExpressionVarsListProps<RawData> {
  searchPlaceholder?: string;
  dataSource: Array<{ title?: string; name: string; info?: ReactNode; description?: string; rawData: RawData }>;
  loading?: boolean;
  selectedItem?: string | RowDataWithValue | null;
  disabled?: boolean;
  notDisableSingleClick?: boolean;
  onDragStart?: (data: RowDataWithValue, event: React.DragEvent<HTMLElement>) => void;
  onClick?: (rawData: RawData) => void;
  onDoubleClick?: (rawData: RawData) => void;
  t: TFunction;
  spaceSize?: SpaceProps['size'];
}

type RowDataWithValue = { value: string; id?: string };

interface ListItemCSSProps {
  selectedItem: string | RowDataWithValue | null;
  rawData: RowDataWithValue;
}
