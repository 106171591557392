import { capitalizeFirstLetter } from '@shared/utils/Form';
import { Typography } from '@ui';
import { CSSObject, Theme } from '@emotion/react';
import { Scope } from '../DatasetTypes';

export const DatasetScopeList = ({ scope, currentStudyName, onMoreBtnClick }: DatasetScopeListProps) => {
  if (!scope) {
    return <>{currentStudyName || '-'}</>;
  }

  if (Array.isArray(scope)) {
    const visibleStudiesList = scope
      .slice(0, 3)
      .map((el) => el.study_name)
      .join(', ');

    return scope.length > 3 ? (
      <button
        onClick={(e) => {
          e.preventDefault();
          onMoreBtnClick(scope);
        }}
        css={cssActionBtn}
      >
        {`${visibleStudiesList}...`}
      </button>
    ) : (
      <>{visibleStudiesList}</>
    );
  }
  return <>{capitalizeFirstLetter(scope)}</>;
};

export const renderInfoModalContent = (studyScopeItems: DatasetScopeListScope) =>
  studyScopeItems?.map((el) => (
    <Typography.Paragraph style={{ marginBottom: 0 }}>{el.study_name}</Typography.Paragraph>
  )) ?? '';

const cssActionBtn = (theme: Theme): CSSObject => ({
  border: 'none',
  background: 'none',
  color: theme.colorLink,
  cursor: 'pointer',
  textAlign: 'left',
  padding: 0,
});

interface DatasetScopeListProps {
  scope: Scope;
  currentStudyName?: string;
  onMoreBtnClick: (scope: DatasetScopeListScope) => void;
}

export type DatasetScopeListScope = Array<{ study_name: string }> | null;
