import { I18nLocales } from '@i18n';
import en from './analysisPackage_en.json';

export const analysisPackageLocales: Record<I18nLocales, I18nAnalysisPackageTranslations> = {
  en,
};

interface I18nAnalysisPackageTranslations {
  pageRootName: string;
  loadingError: string;
  rootTable: {
    version: string;
    description: string;
    created: string;
    packageObjects: string;
    deployPackage: string;
  };
  confirmation: {
    title: string;
    description: string;
    successMessage: string;
  };
  saveForm: {
    title: string;
    titleView: string;
    version: string;
    description: string;
    latestVersions: string;
    packageObjects: string;
    successMessageCreate: string;
    errorMessageCreate: string;
  };
}
