import { Button, Space, Tooltip } from '@ui';
import { InternalSider } from '@app/components';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { getFolderIconColor, getTableNameWithSchema } from '@shared/utils/Viewer';
import { ViewerGroupType, ViewerGroupTypeNames } from '@modules/viewer/ViewerTypes';
import React, { useEffect, useMemo, useRef } from 'react';
import { FolderFilled, FolderOpenFilled, HolderOutlined, RedoOutlined } from '@ant-design/icons';
import { css, CSSObject, Theme } from '@emotion/react';
import Scrollbars from 'react-custom-scrollbars-2';
import TreeView, { flattenTree, INode, INodeRendererProps, ITreeViewProps } from 'react-accessible-treeview';
import { DragEvent } from 'react';
import { TableListSiderToolbar } from './TableListSiderToolbar';
import { NodeData } from './tableListSiderHooks';

const getFolderIcon = (expanded: boolean, groupType: ViewerGroupType) => {
  const Icon = expanded ? FolderOpenFilled : FolderFilled;
  const color = getFolderIconColor(groupType);
  const title = ViewerGroupTypeNames[groupType];
  return (
    <Tooltip title={title} key={`${expanded}-${groupType}`}>
      <Icon style={{ color }} />
    </Tooltip>
  );
};

export const TableListSiderTreeItem = ({
  node,
  draggable,
  selectedTable,
  onSelectTable,
  refetch,
  isExternalRoute,
}: ITableListSiderTreeItemProps) => {
  const selectedTableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (selectedTableRef.current && isExternalRoute && selectedTable) {
      const tableName = getTableNameWithSchema(selectedTable).name;
      if (tableName && tableName === selectedTableRef.current.innerText) {
        const selectedTableRect = selectedTableRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        if (selectedTableRect.top > windowHeight / 2) {
          selectedTableRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    }
  }, [selectedTable, isExternalRoute]);

  const isSelected = useMemo(
    () =>
      selectedTable
        ? // eslint-disable-next-line eqeqeq
          selectedTable.split(':').at(0) == node.element.id || node.element.id === selectedTable
        : false,
    [selectedTable, node.element.id],
  );

  const renderContent = useMemo(() => {
    const { name, id, metadata = {} } = node.element;

    if (!node.isBranch) {
      return (
        <Space block full justify="space-between" css={isSelected ? cssTreeSelectedItem : undefined}>
          <div ref={selectedTableRef} css={cssTreeLabel} onClick={node.handleSelect}>
            {draggable ? <HolderOutlined css={cssDraggableIcon} /> : <React.Fragment />}
            {name}
          </div>
          {isSelected && (
            <Button
              css={cssTreeRefetchBtn}
              icon={<RedoOutlined />}
              size="small"
              onClick={() => {
                onSelectTable(id as string, name);
                selectedTable && refetch();
              }}
            />
          )}
        </Space>
      );
    } else {
      return (
        <Space full justify="space-between" className={isSelected ? 'DataViewer-active-leaf' : ''}>
          <div css={cssTreeLabel}>
            {getFolderIcon(node.isExpanded, metadata?.groupType as ViewerGroupType)}
            <span style={{ paddingLeft: 4 }}>{name}</span>
          </div>
        </Space>
      );
    }
  }, [
    node.isBranch,
    node.element.name,
    node.element.id,
    node.isExpanded,
    node.element.metadata?.groupType,
    isSelected,
    draggable,
    selectedTable,
  ]);

  return renderContent;
};

export const TableListSiderTemplate = (props: ITableListSiderTemplateProps) => {
  const originData: NodeData = {
    id: 'root',
    name: '',
    children: (props.treeData ?? []) as NodeData[],
  };

  const data = flattenTree(originData);

  const nodeRender = (node: INodeRendererProps) => (
    <div
      key={node.element.id}
      {...node.getNodeProps({ onClick: node.handleExpand })}
      draggable={props.draggable}
      onDragStart={(event) => {
        props.onDragStart(event, node.element);
      }}
    >
      {props.titleRender(node, props.draggable)}
    </div>
  );

  return (
    <InternalSider>
      <TableListSiderToolbar
        reloadTables={props.reloadTables}
        isErrorLoadingTables={props.isErrorLoadingTables}
        isLoadingData={props.isLoadingData}
        setSearch={props.setSearch}
      />
      <Scrollbars
        autoHide={false}
        renderThumbHorizontal={(props) => <div css={cssScroll} {...props} />}
        renderThumbVertical={(props) => <div css={cssScroll} {...props} />}
      >
        <TreeView
          css={cssTree}
          data={data}
          aria-label="data viewer tree"
          nodeRenderer={nodeRender}
          selectedIds={props.selectedKeys}
          expandedIds={props.expandedKeys}
          onExpand={props.onExpand}
          onNodeSelect={props.onSelect}
        />
      </Scrollbars>
    </InternalSider>
  );
};

interface ITableListSiderTreeItemProps {
  node: INodeRendererProps;
  onSelectTable: (key: string, title: string) => void;
  refetch: () => void;
  selectedTable: string | null | undefined;
  isExternalRoute?: boolean;
  draggable?: boolean;
}

export interface ITableListSiderTemplateProps {
  treeData?: NodeData[];
  titleRender: (props: INodeRendererProps, draggable?: boolean) => ReactJSXElement;
  selectedKeys: string[];
  expandedKeys: React.Key[];
  selectedTable?: string | null;
  defaultTableName?: string | null;
  draggable?: boolean;
  refetch: () => void | null;
  isLoadingData: boolean;
  reloadTables: () => void;
  isErrorLoadingTables: boolean;
  setSearch: (searchText: string) => void;
  onSelect: ITreeViewProps['onNodeSelect'];
  onExpand: ITreeViewProps['onExpand'];
  onDragStart: (event: DragEvent, nodeProps: INode) => void;
}

const cssDraggableIcon = css({
  width: '24px',
  opacity: 0.2,
  transition: 'opacity 0.3s',
});

const cssTreeRefetchBtn = css({
  display: 'none',
  background: 'transparent',
  border: 'none',
});

const cssTreeSelectedItem = css({
  [`&:hover .css-${cssTreeRefetchBtn.name}`]: {
    display: 'inline-block',
  },
});

const cssScroll = (theme: Theme): CSSObject => ({
  backgroundColor: theme.colorBorder,
  zIndex: 2,
  borderRadius: '1rem',
  opacity: 0.7,
});

const cssTree = (theme: Theme): CSSObject => ({
  '&&.tree': {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    background: 'transparent',
    borderRadius: 0,
    boxSizing: 'border-box',
    color: 'rgba(0, 0, 0, 0.88)',
    fontSize: '14px',
    lineHeight: 1.5,
    transition: 'background-color 0.3s',

    '&& .ant-space-item:first-of-type': {
      overflow: 'hidden',
      width: '100%',
    },

    '&& .tree-branch-wrapper': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },

    '&& .tree-node__branch': {
      paddingBottom: 4,
    },

    '&& .tree-node': {
      listStyle: 'none',
      lineHeight: '24px',
      display: 'flex',
      alignItems: 'flex-start',
      padding: '0 0 4px 8px',
      outline: 'none',
      cursor: 'pointer',

      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '100%',
    },

    '&& .tree-node--selected': {
      backgroundColor: '#4b85a8',
      padding: '0 4px',
    },

    '&&& .tree-node--selected:hover': {
      backgroundColor: '#4b85a8',
    },

    '&& .tree-node:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },

    '&& .tree-node-group': {
      listStyle: 'none',
      padding: 0,
      margin: 0,
    },

    '&& .tree-leaf-list-item': {
      padding: '0 0 4px 0',
      color: 'inherit',
      lineHeight: '24px',
      background: 'transparent',
      cursor: 'pointer',
      transition: 'all 0.2s, border 0s, line-height 0s, box-shadow 0s',
    },

    '&& .tree-node__leaf': {
      padding: '0 4px 0 28px',
      display: 'flex',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },

    '&&& .tree-node:has(.DataViewer-active-leaf):hover': {
      backgroundColor: theme['primary-4'],
    },
  },

  '&&.tree .tree-node:has(.DataViewer-active-leaf)': {
    backgroundColor: theme['primary-4'],
  },
});

const cssTreeLabel = css({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  width: '100%',
});
