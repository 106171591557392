import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  TAnalysisObjectSQLDatasetModalsPushModalPayload,
  TAnalysisObjectSQLDatasetModalsState,
} from '../modals/AnalysisObjectSQLDatasetModalsTypes';

const initialState: AnalysisObjectSQLDatasetStore = {
  modals: { selectedModal: null },
};

export const analysisObjectSQLDatasetSlice = createSlice({
  name: 'analysisObjectSQLDataset',
  initialState,
  reducers: {
    pushModal: (state, action: PayloadAction<TAnalysisObjectSQLDatasetModalsPushModalPayload>) => {
      const { type, data } = action.payload;
      state.modals = { selectedModal: type, data };
    },
    dropModal: (state, action: PayloadAction<void>) => {
      state.modals = initialState.modals;
    },
    reset: (state) => {
      return initialState;
    },
  },
});

export const analysisObjectSQLDatasetActions = analysisObjectSQLDatasetSlice.actions;

export const analysisObjectSQLDatasetReducer = analysisObjectSQLDatasetSlice.reducer;

interface AnalysisObjectSQLDatasetStore {
  modals: TAnalysisObjectSQLDatasetModalsState;
}
