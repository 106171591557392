import { appApi } from '@config/appApi';
import { ANALYSIS_OBJECT_MODEL_TAG_DESCRIPTION } from '@modules/library/analysisObjects/model/duck/analysisObjectModelConstants';
import {
  IAnalysisObjectModelListQueryParams,
  IAnalysisObjectModelListResponse,
  IAnalysisObjectModelQuery,
  TAnalysisObjectModelFiltersResponse,
} from '@modules/library/analysisObjects/model/AnalysisObjectModelTypes';
import { TagDescription } from '@reduxjs/toolkit/query/react';

export const AnalysisObjectModelApiRoutes = {
  list: '/api/analysis/models',
  model: (modelId: number) => `/api/analysis/models/${modelId}`,
  filters: '/api/analysis/models/filters',
};

const AnalysisObjectModelInvalidations: {
  LIST: TagDescription<ANALYSIS_OBJECT_MODEL_TAG_DESCRIPTION.LIST>;
  ID: (id: number) => TagDescription<ANALYSIS_OBJECT_MODEL_TAG_DESCRIPTION.ID>;
  FILTERS: TagDescription<ANALYSIS_OBJECT_MODEL_TAG_DESCRIPTION.FILTERS>;
} = {
  LIST: { type: ANALYSIS_OBJECT_MODEL_TAG_DESCRIPTION.LIST, id: 'LIST' },
  ID: (modelId: number) => ({ type: ANALYSIS_OBJECT_MODEL_TAG_DESCRIPTION.ID, modelId }),
  FILTERS: { type: ANALYSIS_OBJECT_MODEL_TAG_DESCRIPTION.FILTERS, id: 'FILTERS' },
};

export const AnalysisObjectModelApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    analysisObjectModelList: builder.query<
      IAnalysisObjectModelListResponse,
      IAnalysisObjectModelListQueryParams | void
    >({
      providesTags: [AnalysisObjectModelInvalidations.LIST],
      query: (params) => ({
        params,
        url: AnalysisObjectModelApiRoutes.list,
      }),
    }),
    analysisObjectModelFilters: builder.query<TAnalysisObjectModelFiltersResponse, void>({
      providesTags: [AnalysisObjectModelInvalidations.FILTERS],
      query: (params) => ({
        params,
        url: AnalysisObjectModelApiRoutes.filters,
      }),
    }),
    deleteAnalysisObjectModel: builder.mutation<void, number>({
      invalidatesTags: (request, error, id) => [
        AnalysisObjectModelInvalidations.LIST,
        AnalysisObjectModelInvalidations.ID(id),
      ],
      query: (id) => ({
        method: 'DELETE',
        url: AnalysisObjectModelApiRoutes.model(id),
      }),
    }),
    copyAnalysisObjectModel: builder.mutation<void, IAnalysisObjectModelQuery>({
      invalidatesTags: [AnalysisObjectModelInvalidations.LIST],
      query: ({ data, source }) => ({
        params: { source },
        data,
        method: 'POST',
        url: AnalysisObjectModelApiRoutes.list,
      }),
    }),
  }),
});

export const {
  useAnalysisObjectModelListQuery,
  useAnalysisObjectModelFiltersQuery,
  useDeleteAnalysisObjectModelMutation,
  useCopyAnalysisObjectModelMutation,
} = AnalysisObjectModelApi;
