import { Skeleton, Space } from '@ui';

export const PageSkeleton = ({ hideTitle }: PageSkeletonProps) => (
  <Space full direction="vertical" size="large">
    {!hideTitle && <Skeleton.Button active size="large" css={cssTitle} block />}
    <Skeleton active />
  </Space>
);

interface PageSkeletonProps {
  hideTitle?: boolean;
}

const cssTitle = () => ({
  '&&': {
    width: '30%',
  },
});
