import { I18nLocales } from '@i18n';
import en from './analysisObjects_en.json';

export const analysisObjectsLocales: Record<I18nLocales, I18nAnalysisObjectsTranslations> = {
  en,
};

interface I18nAnalysisObjectsTranslations {
  sqlDatasetAO: {
    rootPageName: string;
    modals: {
      deleteSuccess: string;
    };
    loadingListError: string;
    importForm: {
      title: string;
    };
    rootTable: {
      scope: string;
    };
    confirmation: {
      title: string;
      description: string;
    };
  };
  importForm: {
    sourceKind: string;
    sourceKindPlaceholder: string;
    sourceId: string;
    sourceIdPlaceholder: string;
    createNewName: string;
    duplicateLabel: {
      label: string;
      createCopy: string;
      useExisting: string;
    };
    successMessage: string;
  };
  rootTable: {
    name: string;
    created: string;
    updated: string;
    active: string;
    version: string;
    libraryVersion: string;
    sourceVersion: string;
    source: string;
  };
}
