import { DraggableModal } from '@ui';
import { useCdrColumns } from '@modules/library/cdr/duck/libraryCdrHooks';
import { LibraryStatus } from '@modules/library/root/duck/libraryConstants';
import { ELibraryEntityNames, ELibrarySourceType } from '@modules/library/root/LibraryTypes';
import { useCopyAnalysisObjectCDRMutation } from '@modules/library/analysisObjects/cdr/duck/analysisObjectCDRApi';
import { ILibraryImportCDRProps, LibraryImportCDR } from '@modules/library/cdr/components/LibraryImportCDR';
import { IAnalysisObjectCDRQuery } from '@modules/library/analysisObjects/cdr/AnalysisObjectCDRTypes';
import { prepareLibraryCDR } from '@modules/library/cdr/duck/libraryCdrUtils';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { CSSObject } from '@emotion/react';

const AnalysisObjectCdrModalsCopyCdrContent = ({ onClose, t }: IAnalysisObjectCdrModalsCopyCdrContentProps) => {
  const { cdrColumns, locale } = useCdrColumns();
  const [copyCDR] = useCopyAnalysisObjectCDRMutation();

  const onImport: ILibraryImportCDRProps['onImport'] = async (values, { kind, systemInfo }) => {
    await copyCDR({
      data: prepareLibraryCDR(values, kind, systemInfo) as IAnalysisObjectCDRQuery['data'],
      source: ELibrarySourceType.Study === kind ? 'study' : undefined,
    }).unwrap();
  };

  return (
    <LibraryImportCDR
      onClose={onClose}
      columns={cdrColumns}
      locale={locale}
      kind={ELibraryEntityNames.CDR}
      onImport={onImport}
      libraryStatuses={[LibraryStatus.Active]}
      hideOverwriteHandles
      onlyCurrentEnv
    />
  );
};

export const AnalysisObjectCdrModalsCopyCdr = ({ open, data, onClose }: IAnalysisObjectCdrModalsCopyCdrProps) => {
  const { t } = useTranslation(['libraryCdr']);

  return (
    <DraggableModal
      css={cssModal}
      width={800}
      open={open}
      onCancel={onClose}
      title={t('copyForm.title')}
      footer={null}
      destroyOnClose
    >
      {open && <AnalysisObjectCdrModalsCopyCdrContent data={data} onClose={onClose} t={t} />}
    </DraggableModal>
  );
};

const cssModal = (): CSSObject => ({
  '& .ant-modal-content': {
    minWidth: 800,
  },
});

export interface IAnalysisObjectCdrModalsCopyCdrProps {
  open: boolean;
  data: any;
  onClose: () => void;
}

interface IAnalysisObjectCdrModalsCopyCdrContentProps
  extends Pick<IAnalysisObjectCdrModalsCopyCdrProps, 'data' | 'onClose'> {
  t: TFunction;
}
