import { appApi } from '@config/appApi';
import { User } from '@modules/user/UserTypes';
import { SearchUsersQuery, SearchUsersResponse } from '@components/form';
import { TagDescription } from '@reduxjs/toolkit/query/react';
import { USER_TAG_DESCRIPTION } from './userConstants';

export const UserApiRoutes = {
  currentUser: '/api/user',
  searchUsers: '/api/users/autocomplete',
};

const UserInvalidates: {
  CURRENT_USER: TagDescription<USER_TAG_DESCRIPTION.CURRENT_USER>;
} = {
  CURRENT_USER: { type: USER_TAG_DESCRIPTION.CURRENT_USER, id: 'CURRENT_USER' },
};

export const UserApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    currentUser: builder.query<User, void>({
      providesTags: [UserInvalidates.CURRENT_USER],
      query: () => ({
        params: { study_id: undefined, library_id: undefined },
        url: UserApiRoutes.currentUser,
      }),
    }),
    searchUsers: builder.query<SearchUsersResponse, SearchUsersQuery>({
      query: (params) => ({
        params,
        url: UserApiRoutes.searchUsers,
      }),
    }),
  }),
});

export const { useCurrentUserQuery, useLazySearchUsersQuery } = UserApi;
