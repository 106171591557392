import { selectAppENV, selectGlobalLibrary, selectGlobalStudy } from '@app/duck/appSelectors';
import { useSelector } from 'react-redux';
import { useEffect, useRef } from 'react';

export const usePageTitle = (initialTitle?: string) => {
  const appEnv = useSelector(selectAppENV);
  const globalStudy = useSelector(selectGlobalStudy);
  const globalLibrary = useSelector(selectGlobalLibrary);
  const name = globalStudy?.name ?? globalLibrary?.name ?? '';
  const title = useRef(initialTitle ?? '');

  const envLabel = appEnv?.label ? `(${appEnv?.label})` : '';
  const appPrefix = `DataStage ${envLabel}: ${name}`;

  useEffect(() => {
    title.current = initialTitle ?? '';
  }, [initialTitle]);

  const setTitle = (newTitle?: string) => {
    let documentTitle = appPrefix;
    if (newTitle) {
      documentTitle = `${appPrefix} ${newTitle}`;
    }
    document.title = documentTitle;
    // Save title between re-renders
    title.current = newTitle ?? '';
  };

  setTitle(title.current);

  return { setTitle, title: document.title };
};
