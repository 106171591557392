import { useDispatch, useSelector } from 'react-redux';
import {
  AnalysisObjectSQLDatasetModalsCopy,
  IAnalysisObjectSQLDatasetModalsCopyProps,
} from './components/import/AnalysisObjectSQLDatasetModalsImport';
import { EAnalysisObjectSQLDatasetModalsType } from './AnalysisObjectSQLDatasetModalsConstants';
import { selectAnalysisObjectSQLDatasetModals } from '../duck/analysisObjectSQLDatasetSelectors';
import { analysisObjectSQLDatasetActions } from '../duck/analysisObjectSQLDatasetSlice';

export const AnalysisObjectSQLDatasetModalsController = () => {
  const dispatch = useDispatch();
  const { data, selectedModal } = useSelector(selectAnalysisObjectSQLDatasetModals);

  const closeModal = () => {
    dispatch(analysisObjectSQLDatasetActions.dropModal());
  };

  return (
    <AnalysisObjectSQLDatasetModalsCopy
      open={selectedModal === EAnalysisObjectSQLDatasetModalsType.importSQLDataset}
      data={data as IAnalysisObjectSQLDatasetModalsCopyProps['data']}
      onClose={closeModal}
    />
  );
};
