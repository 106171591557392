import { useTranslation } from 'react-i18next';
import { QueryError } from './QueryError';

export const PageNotFound = () => {
  const { t } = useTranslation();

  return (
    <QueryError
      error={{ status: 404, data: { userMsg: t('errors.notFound.route') } }}
      title={t('errors.notFound.page')}
    />
  );
};
