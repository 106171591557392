import { ProgressLineWithTitle } from '@ui';
import { TFunction } from 'i18next';

export const UploadStepMinimized = ({
  t,
  uploadFileResult,
  discoveryJobResult,
  processJobResult,
  progressUpload,
  progress,
}: UploadStepMinimizedProps) => {
  return (
    <>
      {uploadFileResult && discoveryJobResult?.isUninitialized && (
        <ProgressLineWithTitle
          title={t('Upload file')}
          isError={uploadFileResult?.isError}
          percent={progressUpload?.progress}
          estimated={progressUpload?.estimated}
        />
      )}
      {!discoveryJobResult?.isUninitialized && !processJobResult && (
        <ProgressLineWithTitle
          title={t('uploadModal.discovering')}
          isError={discoveryJobResult?.isError}
          percent={progress?.progress}
          estimated={progress?.estimated}
        />
      )}
      {processJobResult && (
        <ProgressLineWithTitle
          title={t('uploadModal.processing')}
          isError={processJobResult?.isError}
          percent={progress?.progress}
          estimated={progress?.estimated}
        />
      )}
    </>
  );
};

interface UploadStepMinimizedProps {
  t: TFunction;
  progress: Record<string, any> | null;
  uploadFileResult?: Record<string, any>;
  discoveryJobResult?: Record<string, any>;
  processJobResult?: Record<string, any>;
  progressUpload?: Record<string, any> | null;
}
