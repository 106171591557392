import { Popconfirm, Radio } from '@ui';
import { selectConfirmationOpened, selectModelEditorReadOnly } from '@modules/modelEditor/duck/modelEditorSelectors';
import { DEFAULT_STAGE_HEIGHT, DEFAULT_STAGE_WIDTH } from '@modules/modelEditor/components/builder/constants';
import { ModelEditorStageActionType } from '@modules/modelEditor/ModelEditorTypes';
import { CustomHandle } from '@modules/modelEditor/components/builder/components/CustomHandle';
import { modelEditorActions } from '@modules/modelEditor/duck/modelEditorSlice';
import { CSSObject, Theme } from '@emotion/react';
import { ReactNode, FC, useEffect } from 'react';
import { NodeToolbar, Position, NodeProps, useKeyPress } from 'reactflow';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

export const ContainerStage: FC<ContainerStageProps> = ({
  customStyle,
  children,
  id,
  data,
  isConnectable,
  selected,
  actionButtons,
  isDeletable,
  background,
  targetHandle,
  sourceHandle,
  onDeleteStage,
  onDoubleClick,
}) => {
  const { t } = useTranslation(['model']);
  const dispatch = useDispatch();
  const { validation, toolbarPosition } = data;
  const backspacePressed = useKeyPress('Backspace');
  const isConfirmationOpened = useSelector(selectConfirmationOpened);
  const readOnly = useSelector(selectModelEditorReadOnly);

  const setConfirmationOpened = (flag: boolean) => dispatch(modelEditorActions.confirmationOpened(flag));

  useEffect(() => {
    if (backspacePressed && !readOnly) {
      setConfirmationOpened(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backspacePressed]);

  const onKeyClickHandler = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      setConfirmationOpened(false);
    }
  };

  const onMouseClickHandler = () => setConfirmationOpened(false);

  useEffect(() => {
    if (isConfirmationOpened) {
      document.addEventListener('keydown', onKeyClickHandler);
      document.addEventListener('mouseup', onMouseClickHandler);
    }

    return () => {
      document.removeEventListener('keydown', onKeyClickHandler);
      document.removeEventListener('mouseup', onMouseClickHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfirmationOpened]);

  return (
    <div css={[cssLayout({ selected, background }), customStyle]} onDoubleClick={onDoubleClick}>
      <NodeToolbar position={toolbarPosition} css={cssNodeToolbar}>
        <Radio.Group size="small">
          {actionButtons &&
            actionButtons.map((btn) => (
              <Radio.Button key={btn.value} value={btn.value} onClick={() => btn.onClick()}>
                {btn.icon}
              </Radio.Button>
            ))}

          {isDeletable && (
            <Radio.Button
              disabled={readOnly}
              key={ModelEditorStageActionType.delete}
              value={ModelEditorStageActionType.delete}
              onClick={() => setConfirmationOpened(true)}
            >
              <Popconfirm
                disabled={readOnly}
                id={`popconfirmDeleteNode_${id}`}
                title={t('builder.confirmation.delete.title')}
                description={
                  <>
                    <div css={cssWarning}>{t('builder.confirmation.warn')}</div>
                    <div>{t('builder.confirmation.delete.description')}</div>
                  </>
                }
                okText={t('yes')}
                cancelText={t('no')}
                onConfirm={() => {
                  onDeleteStage(id);
                  setConfirmationOpened(false);
                }}
                onCancel={() => setConfirmationOpened(false)}
                open={isConfirmationOpened}
              >
                <DeleteOutlined />
              </Popconfirm>
            </Radio.Button>
          )}
        </Radio.Group>
      </NodeToolbar>
      {children || t('builder.unknownName')}
      {targetHandle && (
        <CustomHandle type="target" position={Position.Left} isConnectable={isConnectable} validation={validation} />
      )}
      {sourceHandle && (
        <CustomHandle type="source" position={Position.Right} isConnectable={isConnectable} validation={validation} />
      )}
    </div>
  );
};

const cssLayout = ({ selected, background }: ContainerStageStyleProps): CSSObject => ({
  width: DEFAULT_STAGE_WIDTH,
  height: DEFAULT_STAGE_HEIGHT,
  background,
  borderRadius: '10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'grab',
  position: 'relative',
  boxShadow: '0px 0px 8px 5px rgba(34, 60, 80, 0.1);',
  border: selected ? '0.5px solid #1a192b' : '',
  textAlign: 'center',
  userSelect: 'none',
});

const cssWarning = (theme: Theme): CSSObject => ({
  color: theme.colorError,
  fontWeight: '600',
});

const cssNodeToolbar = (theme: Theme): CSSObject => ({
  backgroundColor: theme.colorBgContainer,
  borderRadius: theme.borderRadiusSM,
});

export interface ActionButton {
  value: string;
  onClick: () => void;
  icon: ReactNode;
}

interface ContainerStageProps extends Pick<NodeProps, 'id' | 'data' | 'isConnectable' | 'selected'> {
  children?: ReactNode;
  actionButtons?: ActionButton[];
  background?: string;
  targetHandle?: boolean;
  sourceHandle?: boolean;
  customStyle?: any;
  isDeletable?: boolean;
  onDeleteStage: (nodeId: string) => void;
  onDoubleClick: () => void;
}

interface ContainerStageStyleProps {
  background?: string;
  selected: boolean;
}
