import { I18nLocales } from '@i18n';
import en from './gpdip_en.json';

export const gpdipLocales: Record<I18nLocales, I18nGpdipTranslations> = {
  en,
};

interface I18nGpdipTranslations {
  pageRootName: string;
  loadingError: string;
  configuration: string;
  rootTable: {
    name: string;
    automatic: string;
    finishedAt: string;
    error: string;
    runAction: string;
    tooltipError: string;
    successTag: string;
    errorTag: string;
    sync: {
      auto: string;
      manual: string;
    };
  };
  settings: {
    title: string;
    separator: string;
    exportTo: string;
    successMessage: string;
  };
  syncSettings: {
    title: string;
  };
  errors: {
    sync: string;
    syncDetails: string;
    settings: string;
  };
}
