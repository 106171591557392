import { useLocation, useNavigate, useParams } from 'react-router-dom';

export const useLocationResolver = <Params = Record<string, any>, State = object>() => {
  const params = useParams<any>();
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);

  const pushSearchParams = (newSearchParams: URLSearchParams) => {
    location.search = `?${newSearchParams.toString()}`;
    navigate(location);
  };

  return {
    location,
    navigate,
    searchParams,
    params: params as Record<keyof Params, string>,
    state: location.state as State,
    pushSearchParams,
  };
};
