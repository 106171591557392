import { selectAuthUser } from '@modules/auth/duck/AuthSelector';
import { Avatar, Descriptions, Dropdown, Modal, notification, Space, Typography } from '@components/ui';
import { getDomain } from '@app/duck/utils';
import { QueryErrorType } from '@shared/utils/Error';
import { configuration } from '@config/constants';
import { capitalizeFirstLetter } from '@shared/utils/Form';
import { ReleasesModal } from '@app/modals/AppReleases';
import { LogoutOutlined, SnippetsOutlined, SwapOutlined, UserOutlined } from '@ant-design/icons';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CSSObject, Theme } from '@emotion/react';
import { useSelector } from 'react-redux';
import { ItemType } from 'antd/es/menu/hooks/useItems';

export const AppUserButton = () => {
  const { t } = useTranslation(['shared', 'app', 'appHeader']);

  const [showProfile, setShowProfile] = useState(false);
  const [showReleases, setShowReleases] = useState(false);
  const user = useSelector(selectAuthUser);

  const userEnvs = user?.environments || {};
  const userCurrentEnv = user?.env_name || '';
  const userCurrentEnvLabel = userEnvs[userCurrentEnv]?.label || '';

  const envOptions = Object.values(userEnvs).map((v) => ({ key: v!.domain, label: v!.label }));

  const username = `${capitalizeFirstLetter(user?.first_name)} ${capitalizeFirstLetter(user?.last_name)}`;

  const onConfirmChangingEnv = async (data: any) => {
    try {
      window.location.replace(getDomain(data));
    } catch (error) {
      notification.error({ message: (error as QueryErrorType)?.data?.userMsg });
    }
  };

  const handleChangeEnv = (value: Record<string, any>) => {
    const selectedEnv = value.domEvent.target.textContent;
    if (selectedEnv !== userCurrentEnvLabel) {
      Modal.confirm({
        title: t('appHeader.env.confirmationTitle'),
        content: (
          <span>
            {t('appHeader.env.confirmationText', { currentEnv: userCurrentEnvLabel })}
            <strong>{selectedEnv}</strong>?
          </span>
        ),
        onOk: () => {
          onConfirmChangingEnv(value.key);
        },
      });
    }
  };

  const userMenu = useMemo(() => {
    const logoutLink = localStorage.getItem('referrer')
      ? `${configuration.logoutURL}?referrer=${localStorage.getItem('referrer')}`
      : configuration.logoutURL;

    return [
      {
        key: 'userMeta',
        type: 'group',
        label: (
          <Space>
            <Avatar css={cssAvatar} size="large" icon={<UserOutlined />} />
            <Space direction="vertical">
              <div css={cssUserInfo}>
                <Typography.Text strong>{username}</Typography.Text>
                <Typography.Text type="secondary">{user?.email}</Typography.Text>
              </div>
            </Space>
          </Space>
        ),
      },
      {
        type: 'divider',
      },
      // {
      //   key: 'profile',
      //   label: (
      //     <Typography.Text
      //       onClick={() => {
      //         setShowProfile(true);
      //       }}
      //       children={t('profile')}
      //     />
      //   ),
      // },
      envOptions.length > 1 && {
        key: 'switchEnvironment',
        icon: <SwapOutlined />,
        label: t('appHeader.env.switchEnvironment'),
        children: envOptions.map((el: any) => ({
          ...el,
          onClick: handleChangeEnv,
          style: {
            color: el.label === userCurrentEnv ? '#1677ff' : 'initial',
            backgroundColor: el.label === userCurrentEnv ? '#e6f4ff' : 'initial',
          },
        })),
      },
      {
        key: 'releases',
        icon: <SnippetsOutlined />,
        label: 'Releases',
        onClick: () => setShowReleases(true),
      },
      {
        type: 'divider',
      },
      {
        key: 'logout',
        icon: <LogoutOutlined rotate={-180} />,
        label: <a href={logoutLink} children={t('signOut')} />,
      },
    ].filter((el) => el) as ItemType[];
  }, [envOptions, t, userCurrentEnv, username, user?.email]);

  return (
    <>
      <Dropdown
        placement="bottomRight"
        menu={{ items: userMenu }}
        trigger={['click']}
        overlayStyle={{
          minWidth: '80px',
        }}
        autoAdjustOverflow={false}
      >
        <div css={cssContent}>
          <Avatar css={cssAvatar} size="small" icon={<UserOutlined />} />
          {username}
        </div>
      </Dropdown>
      <ReleasesModal open={showReleases} onClose={() => setShowReleases(false)} />
      <Modal open={showProfile} width={600} footer={false} onCancel={() => setShowProfile(false)}>
        <Descriptions title={t('app:userProfile:title')} column={1}>
          {/* <Descriptions.Item label={t('shared:system:username')}>{user.username}</Descriptions.Item>
          <Descriptions.Item label={t('shared:system:email')}>{user.email}</Descriptions.Item>
          <Descriptions.Item label={t('app:userProfile:firstName')}>{user.firstName}</Descriptions.Item>
          <Descriptions.Item label={t('app:userProfile:lastName')}>{user.lastName}</Descriptions.Item>
          <Descriptions.Item label={t('app:userProfile:tenant')}>{user.tenant}</Descriptions.Item> */}
        </Descriptions>
      </Modal>
    </>
  );
};

const cssContent = (): CSSObject => ({
  display: 'flex',
  gap: 8,
  alignItems: 'center',
  cursor: 'pointer',
  height: '1em',
});

const cssUserInfo = (): CSSObject => ({
  display: 'flex',
  flexDirection: 'column',
});

const cssAvatar = (theme: Theme) => ({
  backgroundColor: theme.colorPrimary,
});
