// Should the stage button be shown in the Model Editor Toolbar or not
export const UtilConfig = {
  STAGES: {
    JOIN: true,
    UNION: true,
    PIVOT: true,
    UNPIVOT: true,
    GROUP_BY: true,
    CUSTOM_SQL: true,
    RESULT: true,
    DOMAIN: false,
    TRANSFORM: true,
  },
};
