import { I18nLocales } from '@i18n';
import en from './dataset_en.json';

export const datasetLocales: Record<I18nLocales, I18nDatasetTranslations> = {
  en,
};

interface I18nDatasetTranslations {
  pageRootName: string;
  loadingError: string;
  rootTable: {
    name: string;
    created: string;
    updated: string;
    type: string;
    sync: string;
    syncExport: string;
    syncStates: {
      auto: string;
      once: string;
      disabled: string;
      period: string;
      manual: string;
    };
    actions: {
      title: string;
      delete: {
        title: string;
        confirmationDeleteTitle: string;
        confirmationDelete: string;
        successDeleteMessage: string;
        errorDeleteMessage: string;
      };

      viewData: string;
    };
  };
}
