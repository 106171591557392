import { EnvDataListQueryBase } from '@shared/GlobalTypes';
import { LibraryStatus } from './duck/libraryConstants';

export interface Library {
  id: number;
  name: string;
  description: string;
  created_at: number;
  created_by: string;
  updated_at?: number;
  updated_by?: string;
  status: LibraryStatus;
}

export type LibraryListResponse = {
  currentPage: number;
  items: Library[];
  totalItems: number;
  totalPages: number;
};

export enum ELibraryEntityKind {
  Model = 'data_model',
  CDR = 'cdr_report',
  CDA = 'cda',
  SQL_Lab = 'sql_lab',
  Notebook = 'notebook',
}

export enum ELibraryEntityNames {
  Model = 'Data Model',
  CDR = 'CDR Report',
  CDA = 'CDA',
  SQL_Lab = 'SQL Lab',
}

export enum ELibrarySourceType {
  Library = 'Library',
  Study = 'Study',
}

export enum ESelectedActions {
  'SHOW_ALL' = 'SHOW_ALL',
  'HIDE_SELECTED' = 'HIDE_SELECTED',
}

export interface IBaseLibraryEntity extends IBaseLibraryEntitySystemInfo {
  id: number;
  name: string;
  version: string;
  configuration: Record<string, any>;
  created_at: string;
  updated_at?: string;
  created_by: string;
  updated_by?: string;
}

export interface IBaseLibraryEntityUpdate<TConfiguration extends Record<string, any>>
  extends IBaseLibraryEntitySystemInfo {
  id?: number;
  name: string;
  version?: string;
  version_id?: number;
  configuration: TConfiguration;
}

export interface IBaseLibraryEntitySystemInfo {
  source: string; // Study name
  tenant_info: string; // Tenant name
  env: string; // Environment name
}

export interface IBaseLibraryParams {
  library_id?: number;
  study_id?: number;
}

export interface EnvStudyListQuery {
  env: string;
}
