import { selectJobModals } from '@modules/job/duck/jobSelectors';
import {
  JobModalsViewVariables,
  JobModalsViewVariablesProps,
  JobModalsHistory,
  JobModalsHistoryProps,
  JobModalsDeleteConfirm,
  JobModalsDeleteConfirmProps,
} from '@modules/job/modals/components';
import { jobActions } from '@modules/job/duck/jobSlice';
import { JobModalsType } from '@modules/job/modals/JobModalsConstants';
import { useDispatch, useSelector } from 'react-redux';

export const JobModalsController = () => {
  const dispatch = useDispatch();
  const { data, selectedModal } = useSelector(selectJobModals);

  const closeModal = () => {
    dispatch(jobActions.dropModal());
  };

  return (
    <>
      <JobModalsViewVariables
        open={selectedModal === JobModalsType.viewVariables}
        data={data as JobModalsViewVariablesProps['data']}
        onClose={closeModal}
      />
      <JobModalsHistory
        open={selectedModal === JobModalsType.viewHistory}
        data={data as JobModalsHistoryProps['data']}
        onClose={closeModal}
      />
      <JobModalsDeleteConfirm
        open={selectedModal === JobModalsType.deleteJob}
        data={data as JobModalsDeleteConfirmProps['data']}
        onClose={closeModal}
      />
    </>
  );
};
