import { Table } from '@ui';
import routes from '@routes';
import { useColumnSearch } from '@components/ui/table/tableHooks';
import { ActorAndInfo } from '@components';
import { StudyListResponse } from '@modules/study/StudyTypes';
import { SELECTED_ACTIONS } from '@shared/GlobalConstants';
import { Link } from 'react-router-dom';
import { useMemo, useState } from 'react';

export const StudyList = ({ studies }: StudyListProps) => {
  const { getColumnSearchProps } = useColumnSearch<StudyListResponse[0]>();
  const [selectedStudies, setSelectedStudies] = useState<React.Key[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<SELECTED_ACTIONS>(SELECTED_ACTIONS.SHOW_ALL);

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: StudyListResponse, info: any) => {
      setSelectedStudies(selectedRowKeys);
    },
    getCheckboxProps: (record: StudyListResponse[0]) => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: `selected-${record.id}`,
    }),
  };

  const filteredStudies = useMemo(() => {
    if (selectedFilter === SELECTED_ACTIONS.HIDE_SELECTED) {
      return studies?.filter((study) => !selectedStudies.includes(study.id));
    }
    return studies;
  }, [selectedFilter, studies, selectedStudies]);

  if (!studies) {
    return null;
  }

  return (
    <Table
      size="small"
      bordered
      columns={[
        {
          key: 'name',
          dataIndex: 'name',
          title: 'Study Name',
          ...getColumnSearchProps('name'),
          render: (_, record) => (
            <Link
              to={routes.study.view.resolver({ studyId: record.id })}
              state={{
                protocolId: record.protocol,
                name: record.name,
                studySchema: record.study_schema,
                configured: record.configured,
              }}
              children={record.name}
            />
          ),
        },
        {
          key: 'studyCategory',
          dataIndex: 'studyCategory',
          title: 'Category',
        },
        {
          width: 120,
          key: 'studyVersion',
          dataIndex: 'studyVersion',
          title: 'App Version',
        },
        {
          key: 'summary',
          dataIndex: 'summary',
          title: 'Summary',
        },
        {
          key: 'created',
          title: 'Created',
          render: (_, record) => (
            <ActorAndInfo actor={record.userCreated.toString()} info={new Date(record.creationDate).toLocaleString()} />
          ),
        },
      ]}
      rowKey="id"
      rowSelection={{
        preserveSelectedRowKeys: true,
        type: 'checkbox',
        ...rowSelection,
        selectedRowKeys: selectedStudies,
        selections: [
          Table.SELECTION_INVERT,
          {
            key: SELECTED_ACTIONS.SHOW_ALL,
            text: 'Show all',
            onSelect: () => setSelectedFilter(SELECTED_ACTIONS.SHOW_ALL),
          },
          {
            key: SELECTED_ACTIONS.HIDE_SELECTED,
            text: 'Hide selected',
            onSelect: () => setSelectedFilter(SELECTED_ACTIONS.HIDE_SELECTED),
          },
        ],
      }}
      dataSource={filteredStudies}
      pagination={false}
    />
  );
};

interface StudyListProps {
  studies?: StudyListResponse;
}
