import { ViewerGroupType } from '@modules/viewer/ViewerTypes';
import { EMPTY_ENV } from '@modules/viewer/duck/viewerConstants';
import { SupportedEnvs } from '@app/AppTypes';
import { ViewerStore } from './viewerSlice';

const getGroupByName = (data: ViewerStore['groups'][0][0], groupType: ViewerGroupType) => data[groupType] || [];

export const getFlatGroups = (data: ViewerStore['groups'][0][0]) => [
  ...getGroupByName(data, ViewerGroupType.internal),
  ...getGroupByName(data, ViewerGroupType.external),
  ...getGroupByName(data, ViewerGroupType.rccGroups),
];

export const prepareCurrentEnvironment = (
  userCurrentEnv: string,
  allUserEnvironments: SupportedEnvs,
  selectedEnv?: string,
) => ({
  env: selectedEnv || userCurrentEnv,
  ...(allUserEnvironments[selectedEnv || userCurrentEnv] || EMPTY_ENV),
  current: selectedEnv ? selectedEnv === userCurrentEnv : true,
});

export const getReviewSourceTableName = (tableName: string, sourceEnvLabel: string) =>
  `${tableName}_ENV_${sourceEnvLabel.toUpperCase()}_TEMP`;

export const checkReviewTableName = (tableId: string) => /.+_ENV_.+_TEMP/.test(tableId);
