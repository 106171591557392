import { PageTemplateSimple } from '@components';
import { useStudyDetailsQuery } from '@modules/study/duck/studyApi';
import { StudyDetails } from '@modules/study/components';
import { useTranslation } from 'react-i18next';

export const StudyPage = () => {
  const { t } = useTranslation(['study']);

  const studyDetailsQuery = useStudyDetailsQuery();
  const study = studyDetailsQuery.currentData?.uniqueIdentifier;

  return (
    <PageTemplateSimple
      title={{ children: study, pageTitle: t('detailsPage.pageTitle') }}
      content={{
        isLoading: studyDetailsQuery.isFetching && !studyDetailsQuery.currentData,
        errorText: t('detailsPage.loadingError'),
        error: studyDetailsQuery.error,
      }}
    >
      {studyDetailsQuery.currentData && <StudyDetails study={studyDetailsQuery.currentData} />}
    </PageTemplateSimple>
  );
};
