import { I18nLocales } from '@i18n';
import en from './libraryRoot_en.json';

export const libraryRootLocales: Record<I18nLocales, I18nlibraryRootTranslations> = {
  en,
};

export interface I18nlibraryRootTranslations {
  root: {
    title: string;
    loadingError: string;
    table: {
      name: string;
      description: string;
      created: string;
      status: string;
    };
  };
  status: {
    active: string;
    development: string;
    retired: string;
  };
}
