import { ELibrarySourceType, IBaseLibraryEntitySystemInfo } from '@modules/library/root/LibraryTypes';
import { ILibrarySqlLab, ILibrarySqlLabQuery } from '@modules/library/sqlLab/LibrarySqlLabTypes';
import { ILibraryImportSqlLab } from '../components/LibraryImportSqlLab';

export const prepareLibrarySqlLab = (
  data: ILibraryImportSqlLab[],
  kind: ELibrarySourceType,
  systemInfo?: IBaseLibraryEntitySystemInfo,
): ILibrarySqlLabQuery['data'] => {
  if (!Array.isArray(data) || kind !== ELibrarySourceType.Library) return [];

  return data.map((item) => {
    const rawData = item.rawData as ILibrarySqlLab;
    return {
      id: rawData.id,
      name: rawData.name,
      description: rawData.description,
      configuration: rawData.configuration,
      source: rawData.source || '',
      source_id: rawData.source_id || null,
      env: rawData.env || '',
      tenant_info: rawData.tenant_info || '',
    };
  });
};
