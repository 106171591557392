export const downloadByUrl = (url: string) => {
  let iframe = document.getElementById('iframe2download') as HTMLIFrameElement;
  if (!iframe) {
    iframe = document.createElement('iframe');
    iframe.id = 'iframe2download';
    iframe.style.display = 'none';
    document.body.appendChild(iframe);
  }

  const a = document.createElement('a');
  a.style.display = 'none';

  iframe.contentWindow?.document.body.appendChild(a);

  a.rel = 'noopener';
  a.href = url;

  a.click();

  iframe.contentWindow?.document.body.removeChild(a);
};
