import { ProcessJobData, ProcessJobMapping } from '@modules/job/duck/jobApi';

export interface DomainMetadata {
  name: string;
  type: string;
  length?: number;
  precision?: number;
  scale?: number;
}

export interface Domain {
  id: number;
  name: string;
  table_name: string;
  database: string;
  total_rows: number;
  total_columns: number;
  imported_rows: number;
  errored_rows: number;
  status: string;
  meta: DomainMetadata[];
  message?: string;
  data_store_id?: number;
  deleted_at?: number;
  table_exists: boolean;
}

export enum Status {
  Error = 'Error',
  Success = 'Success',
}

export interface Job {
  id: number;
  name: string;
  last_run: number;
  status: Status;
  data_source: string;
  data_store: string;
  connection: string;
  data_source_id: number;
  data_store_id: number;
  deleted_at: number;
  connection_id: number;
  created_by: string;
  data?: any;
}

export interface JobHistory {
  id: number;
  errored_rows: number;
  imported_rows: number;
  table_name: string;
  job_name: string;
  message?: string;
  run_at: number;
  run_by: string;
  total_rows: number;
  total_columns: number;
  status: string;
}

export interface IStructure {
  [key: string]: any;
}

export interface TabProps extends Omit<ProcessJobData, 'mapping'> {
  mapping: TableDataRowProps[];
  structure: IStructure;
  confirmed: boolean;
  isError: boolean;
  errorMessage: string | React.ReactNode;
  sample: Record<string, string | number>[];
  skip: boolean;
  isMappingExists: boolean;
  isHideConfirmBtn: boolean;
}

export interface TableDataRowProps extends ProcessJobMapping {
  sourceMappingColumn?: string;
  skip?: boolean;
}
