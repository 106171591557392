import { useAppContext } from '@app/AppContext';
import { Loader } from '@components';
import { useCurrentUserQuery } from '@modules/user/duck/userApi';
import { formatUserPermissions } from '@modules/user/duck/userUtils';
import { TAuthAppPermissions } from '@modules/auth/AuthTypes';
import { JSXElementConstructor, ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthReady } from './duck/AuthSelector';
import { authActions } from './duck/AuthSlice';

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const { t } = useTranslation(['shared']);
  const { notificationApi } = useAppContext();
  const dispatch = useDispatch();
  const currentUserQuery = useCurrentUserQuery();

  const authReady = useSelector(selectAuthReady);

  useEffect(() => {
    if (currentUserQuery.isSuccess && !!currentUserQuery.data) {
      localStorage.setItem('user_name', currentUserQuery.data?.preferred_username.split(':').at(0) || '');
      dispatch(authActions.setUser(currentUserQuery.data));
      dispatch(
        authActions.setAppPermissions(
          formatUserPermissions(currentUserQuery.data?.app_scope_permissions ?? {}) as TAuthAppPermissions,
        ),
      );
      if (currentUserQuery.data.permissions_not_available === true) {
        notificationApi.warning({ message: t('notification.permissionsNotAvailable') });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserQuery]);

  return authReady ? children : <Loader mode="fixed" size="large" />;
};

interface AuthProviderProps {
  children: ReactElement<any, string | JSXElementConstructor<any>>;
}
