import { appApi } from '@config/appApi';
import { Domain, Job, JobHistory, TableDataRowProps } from '@modules/job/JobTypes';
import { JOB_TAG_DESCRIPTION } from '@modules/job/duck/jobConstants';
import { appAxios } from '@config/AppConfig';
import { StoreInvalidations } from '@modules/stores/duck/storeApi';
import { handleQueryError } from '@shared/utils/Error';
import { ViewerInvalidations } from '@modules/viewer/duck/viewerApi';
import { sleep } from '@shared/utils/common';
import { partsGenerator } from '@modules/job/duck/jobApiUtils';
import { TagDescription } from '@reduxjs/toolkit/query/react';
import dayjs from 'dayjs';
import { isArray } from 'lodash';

export const JobApiRoutes = {
  list: `api/jobs`,
  jobDetails: (jobId: number) => `api/jobs/details/${jobId}`,
  jobHistory: (jobId: number) => `api/jobs/history/${jobId}`,
  jobDelete: (tableName: string) => `api/data/${tableName}`,
  process: 'api/process',
  processResult: (id: string) => `api/process/${id}`,
  discover: 'api/discover',
  discoveryResult: (id: string) => `api/discover/${id}`,
};

export const FileApiRoutes = {
  upload: (action: 'start' | 'complete' | 'cancel' | 'part') => `api/upload/${action}`,
};

export const JobInvalidations: {
  LIST: TagDescription<JOB_TAG_DESCRIPTION.LIST>;
  HISTORY: (jobId: number) => TagDescription<JOB_TAG_DESCRIPTION.HISTORY>;
  DOMAIN_LIST: (jobId: number) => TagDescription<JOB_TAG_DESCRIPTION.DOMAIN_LIST>;
  UPLOAD: TagDescription<JOB_TAG_DESCRIPTION.UPLOAD>;
  UPLOAD_RESULT: (id: string) => TagDescription<JOB_TAG_DESCRIPTION.UPLOAD_RESULT>;
} = {
  LIST: { type: JOB_TAG_DESCRIPTION.LIST, id: 'LIST' },
  HISTORY: (jobId: number) => ({ type: JOB_TAG_DESCRIPTION.HISTORY, jobId }),
  DOMAIN_LIST: (jobId: number) => ({ type: JOB_TAG_DESCRIPTION.DOMAIN_LIST, jobId }),
  UPLOAD: { type: JOB_TAG_DESCRIPTION.UPLOAD, id: 'UPLOAD' },
  UPLOAD_RESULT: (id: string) => ({ type: JOB_TAG_DESCRIPTION.UPLOAD_RESULT, id }),
};

export const JobApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    jobList: builder.query<JobListResponse['items'], JobListQueryParams | void>({
      providesTags: [JobInvalidations.LIST],
      query: (params) => ({ params, url: JobApiRoutes.list }),
    }),
    jobListPaginated: builder.query<JobListResponse, JobListQueryParams | void>({
      providesTags: [JobInvalidations.LIST],
      query: (params) => ({ params, url: JobApiRoutes.list }),
    }),
    jobDomains: builder.query<JobDomainsResponse, number>({
      providesTags: (result, error, jobId) => [JobInvalidations.DOMAIN_LIST(jobId)],
      query: (jobId) => ({ url: JobApiRoutes.jobDetails(jobId) }),
    }),
    jobHistory: builder.query<JobHistory[], number>({
      providesTags: (result, error, jobId) => [JobInvalidations.DOMAIN_LIST(jobId), JobInvalidations.HISTORY(jobId)],
      query: (jobId) => ({ url: JobApiRoutes.jobHistory(jobId) }),
    }),
    discoveryJob: builder.mutation<DiscoveryJobResult[], DiscoveryJobParams>({
      queryFn: async ({ callback, study_id, ...params }, api) => {
        callback(0, -1);
        return appAxios
          .post(JobApiRoutes.discover, params, { signal: api.signal, params: { study_id } })
          .then(async ({ data }: ProcessJobResponse) => {
            while (true) {
              const response: ProcessResultResponse = await appAxios.get(JobApiRoutes.discoveryResult(data.id), {
                signal: api.signal,
                params: { study_id },
              });
              if (response.data.result) {
                const result = JSON.parse(response.data.result) as DiscoveryJobResponse;
                if (isArray(result)) {
                  callback(100, 0);
                  await sleep(500);
                  return { data: result };
                } else {
                  if (result.error) {
                    console.error(result.error);
                    return Promise.reject(new Error(result.error));
                  }
                  callback(result.progress, result.estimated);
                }
              }
              await sleep(5000);
            }
          })
          .catch((error) => {
            return { error: handleQueryError(error) };
          });
      },
    }),
    processJob: builder.mutation<string, ProcessJobParams>({
      invalidatesTags: [
        JobInvalidations.LIST,
        StoreInvalidations.LIST,
        ViewerInvalidations.TABLES_EXIST,
        ViewerInvalidations.TABLES_IN_SQL_EXIST,
      ],
      queryFn: async ({ callback, study_id, ...params }, api) => {
        callback(0, -1);
        return appAxios
          .post(JobApiRoutes.process, params, { signal: api.signal, params: { study_id } })
          .then(async ({ data }: ProcessJobResponse) => {
            while (true) {
              const response: ProcessResultResponse = await appAxios.get(JobApiRoutes.processResult(data.id), {
                signal: api.signal,
                params: { study_id },
              });
              if (response.data.result) {
                const result = JSON.parse(response.data.result);
                if (result.job_id) {
                  callback(100, 0);
                  return { data: result.job_id };
                }
                if (result.error) {
                  console.error(result.error);
                  return Promise.reject(new Error(result.error));
                }
                callback(result.progress, result.estimated);
              }
              await sleep(5000);
            }
          })
          .catch((error) => {
            return { error: handleQueryError(error) };
          });
      },
    }),
    deleteJob: builder.mutation<void, DeleteJobParams>({
      invalidatesTags: (result, error, { jobId }) => [
        JobInvalidations.LIST,
        StoreInvalidations.LIST,
        JobInvalidations.DOMAIN_LIST(jobId),
        ViewerInvalidations.TABLES_EXIST,
        ViewerInvalidations.TABLES_IN_SQL_EXIST,
      ],
      query: ({ storeId, tableName, database }) => ({
        params: {
          store_id: storeId,
          ch_db: database,
        },
        method: 'DELETE',
        url: JobApiRoutes.jobDelete(tableName),
      }),
    }),
    uploadFile: builder.mutation<UploadFileResponse, UploadFileParams>({
      invalidatesTags: (request, error, data) => [ViewerInvalidations.ALL_TABLE_INFO],
      queryFn: async ({ file, callback, study_id }, api) => {
        let uploadedFilename = file.name;
        let uploadId: string;
        callback(0, -1);
        return appAxios
          .post(
            FileApiRoutes.upload('start'),
            { filename: uploadedFilename },
            { signal: api.signal, params: { study_id } },
          )
          .then(async ({ data }) => {
            uploadedFilename = data.filename;
            uploadId = data.upload_id;

            // Chunks must be minimum 5Mb
            const chunkSize = 1024 * 1024 * 5;
            const chunks = Math.ceil(file.size / chunkSize);

            const parts: string[] = [];
            const getParts = partsGenerator(file, chunkSize);
            let completed = 0;
            let started = dayjs();

            const promises = Array.from(
              { length: 10 },
              () =>
                new Promise(async (resolve, reject) => {
                  while (true) {
                    const nextChunk = getParts.next();
                    if (nextChunk.done) {
                      resolve(true);
                      break;
                    }
                    const { chunk, partNum } = nextChunk.value;
                    const formData: FormData = new FormData();
                    formData.append('file', chunk);
                    formData.append('part', partNum.toString());
                    formData.append('upload_id', uploadId);
                    formData.append('filename', uploadedFilename);

                    const result = await appAxios
                      .post(FileApiRoutes.upload('part'), formData, {
                        headers: { 'Content-Type': 'multipart/form-data', Accept: 'application/json' },
                        signal: api.signal,
                        timeout: 0,
                        retry: 3,
                        retryDelay: 3000,
                        params: { study_id },
                      })
                      .then(({ data }) => {
                        parts[partNum - 1] = data;
                        completed++;
                        const percents = (100 * completed) / chunks;
                        const now = dayjs();
                        const estimated = ((100 - percents) * now.diff(started)) / percents;
                        callback(percents, estimated);
                        return true;
                      })
                      .catch(() => false);

                    if (!result) {
                      reject(false);
                      break;
                    }
                  }
                }),
            );
            return Promise.all(promises).then(() => parts);
          })
          .then((parts) =>
            appAxios.post(
              FileApiRoutes.upload('complete'),
              {
                filename: uploadedFilename,
                upload_id: uploadId,
                parts,
              },
              { signal: api.signal, params: { study_id } },
            ),
          )
          .then(() => ({ data: { success: true, filename: uploadedFilename } }))
          .catch(async (error) => {
            await appAxios
              .post(
                FileApiRoutes.upload('cancel'),
                { filename: uploadedFilename, upload_id: uploadId },
                { params: { study_id } },
              )
              .catch(() => {});
            return { error: handleQueryError(error) };
          });
      },
    }),
  }),
});

export const {
  useJobListQuery,
  useJobListPaginatedQuery,
  useJobDomainsQuery,
  useJobHistoryQuery,
  useProcessJobMutation,
  useDiscoveryJobMutation,
  useUploadFileMutation,
  useDeleteJobMutation,
} = JobApi;

export interface JobListResponse {
  currentPage: number;
  totalItems: number;
  totalPages: number;
  items: Array<Job>;
}

export type JobDomainsResponse = Array<Domain>;

interface JobListQueryParams {
  page?: number;
  order?: string;
  sort_by?: string;
}

export type UploadFileParams = {
  file: File;
  callback: (percents: number, estimated: number) => void;
  study_id?: number;
};

export type UploadFileResponse = {
  success: boolean;
  filename?: string;
};

export type DiscoveryJobResponse = DiscoveryJobProgress | DiscoveryJobResult[];

type DiscoveryJobProgress = {
  progress: number;
  estimated: number;
  error?: string;
};

export type DiscoveryJobResult = {
  name: string;
  total_columns: number;
  total_rows: number;
  structure: Record<string, string>;
  sample: Record<string, string | number>[];
  mapping?: TableDataRowProps[];
};

export type DiscoveryJobParams = {
  store_id: number;
  separator?: string;
  filename: string;
  skip_blank: boolean;
  skip_rows: number;
  ignore_errors: boolean;
  callback: (value?: number, estimated?: number) => void;
  study_id?: number;
};

export interface ProcessJobMapping {
  sourceColumn?: string;
  targetColumn: string;
  description?: string;
  type: string;
  length?: number | null;
  nullable: boolean;
  primaryKey: boolean;
}

export interface ProcessJobData {
  tableName: string;
  referenceTable?: string;
  total_columns: number;
  total_rows: number;
  mapping: ProcessJobMapping[];
}

export interface ProcessParams {
  store_id: number;
  ignore_errors: boolean;
  filename: string;
  offset: number;
  separator: string;
}

export interface ProcessJobParams extends ProcessParams {
  data: ProcessJobData[];
  callback: (value?: number, estimated?: number) => void;
  study_id?: number;
}

export interface DeleteJobParams {
  tableName: string;
  storeId: number;
  jobId: number;
  database: string;
}

export interface ProcessJobResponse {
  data: {
    id: string;
  };
}

interface ProcessResultResponse {
  data: {
    result: string;
    finished: boolean;
  };
}
