import { ValidationConfigProps } from '@modules/modelEditor/components/builder/StageConfig';
import React, { useCallback } from 'react';
import { CSSObject } from '@emotion/react';
import {
  Connection,
  Edge,
  getConnectedEdges,
  Handle,
  ReactFlowStore,
  useNodeId,
  useReactFlow,
  useStore,
  Node,
  HandleProps,
} from 'reactflow';

const countEdesElementsByType = (edges: Edge[], nodeId: string | null, type: string) =>
  edges.filter((edge) => edge[type as keyof typeof edge] === nodeId).length;

const findById = (nodes: Node[], id: string) => nodes?.find((node) => node.id === id);

const selector =
  (nodeId: string, isConnectable = true, maxConnections = Infinity, type: string) =>
  (s: ReactFlowStore) => {
    if (!isConnectable) return false;
    const node = s.nodeInternals.get(nodeId);
    const connectedEdges = getConnectedEdges([node!], s.edges);
    return countEdesElementsByType(connectedEdges, nodeId, type) < maxConnections;
  };

export const CustomHandle = ({ validation, isConnectable, ...props }: CustomHandleProps) => {
  const { getNodes, getEdges } = useReactFlow();
  const nodeId = useNodeId();
  const { maxIncomingConnections, maxOutgoingConnections } = validation;

  const isConnectableEnd = useStore(
    useCallback(selector(nodeId!, props.isConnectableEnd, maxIncomingConnections, props.type), [
      nodeId,
      props.isConnectableEnd,
      maxIncomingConnections,
    ]),
  );

  const isConnectableStart = useStore(
    useCallback(selector(nodeId!, props.isConnectableStart, maxOutgoingConnections, props.type), [
      nodeId,
      props.isConnectableStart,
      maxOutgoingConnections,
    ]),
  );

  const isConnectedItself = (connection: Connection) => {
    const { source, target } = connection;
    return source && target && source === target;
  };

  const isTheSameStagesAreSourceAndTarget = (connection: Connection) => {
    const { source, target } = connection;
    const edges = getEdges();
    return edges.filter((edge) => edge.source === target && edge.target === source).length > 0;
  };

  const isContainsStageFromNotToConnect = (connection: Connection) => {
    if (connection.source === null || connection.target === null) {
      return false;
    }
    const nodes = getNodes();
    const sourceNode = findById(nodes, connection.source);
    const targetNode = findById(nodes, connection.target);
    if (sourceNode && targetNode) {
      const { notConnectTo = [] } = sourceNode.data.validation;
      return notConnectTo.includes(targetNode.type);
    }
    return false;
  };

  const isValidConnection = (connection: Connection) =>
    !isContainsStageFromNotToConnect(connection) &&
    !isTheSameStagesAreSourceAndTarget(connection) &&
    !isConnectedItself(connection);

  return (
    <Handle
      {...props}
      css={cssHandle}
      position={props.position}
      isConnectable={isConnectable}
      isConnectableStart={isConnectableStart}
      isConnectableEnd={isConnectableEnd}
      isValidConnection={isValidConnection}
    />
  );
};

interface CustomHandleProps extends HandleProps {
  validation: ValidationConfigProps;
}

const cssHandle = (): CSSObject => ({
  '&&.react-flow__handle-connecting': {
    background: '#ff6060',
    width: '10px',
    height: '10px',
  },
  '&&.react-flow__handle-valid': {
    background: '#55dd99',
    width: '10px',
    height: '10px',
  },
});
