export type SelectValue = {
  value: string;
  label: string;
};

export interface CustomInputProps<Values = any> {
  value?: Values;
  disabled?: boolean;
  onChange?: (value: any) => void;
}

export type SetPartionallyFieldsFn = (
  values: TRunPeriodically,
  field: { name: string; value: any },
) => TRunPeriodically;

export enum RUN_TYPE {
  automatic = 'automatic',
  once = 'once',
  periodically = 'periodically',
  manual = 'manual',
}

export type TRunPeriodically = {
  atTime?: number;
  fromTime?: number;
  toTime?: number;
  onDays?: number[];
  inMonth?: number[];
  hour?: number;
  weekdays: string[];
  every: number;
};

export interface SchedulerRunFormValues {
  active: boolean;
  run: RUN_TYPE;
  runOnceAt?: number;
  runPeriodically?: TRunPeriodically;
  [key: string]: any;
}

export interface PrepareUpdateSchedulerRunData {
  values: SchedulerRunFormValues;
}
