import { AnalysisPackageList } from '@modules/library/analysisPackage/components';
import { useTablePaginationState } from '@components/ui/table/tableHooks';
import { initialPage } from '@shared/utils/common';
import { IAnalysisPackageResponse } from '@modules/library/analysisPackage/AnalysisPackageTypes';
import { AnalysisPackageModalsController, EAnalysisPackageType } from '@modules/library/analysisPackage/modals';
import { useAnalysisPackageListQuery } from '@modules/library/analysisPackage/duck/analysisPackageApi';
import { analysisPackageActions } from '@modules/library/analysisPackage/duck/analysisPackageSlice';
import { useStudyPermissions } from '@modules/user/duck/userHooks';
import { PageTemplateSimple } from '@components';
import { Button } from '@ui';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const AnalysisPackageRootPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['analysisPackage']);
  const { paginationState, getPagination, onTableChange, preparedFilters, preparedSorter } =
    useTablePaginationState<IAnalysisPackageResponse['items'][0]>(initialPage);

  const {
    userPermissions: { canGlApUpdate },
  } = useStudyPermissions();

  const analysisPackageQuery = useAnalysisPackageListQuery({
    page: paginationState.current - 1,
    ...preparedFilters,
    ...preparedSorter,
  });

  const pagination = getPagination(analysisPackageQuery.data?.totalItems);

  const addAnalysisPackage = () =>
    dispatch(analysisPackageActions.pushModal({ type: EAnalysisPackageType.saveAnalysisPackage }));

  return (
    <>
      <PageTemplateSimple
        title={{
          children: t('pageRootName'),
          extra: canGlApUpdate && <Button children={t('add')} onClick={addAnalysisPackage} />,
        }}
        content={{
          isLoading: analysisPackageQuery.isLoading && !analysisPackageQuery.data,
          errorText: t('loadingError'),
          error: analysisPackageQuery.error,
        }}
      >
        {!analysisPackageQuery.isLoading && (
          <AnalysisPackageList
            data={analysisPackageQuery.data}
            onChange={onTableChange}
            pagination={pagination}
            loading={analysisPackageQuery.isFetching}
            t={t}
          />
        )}
      </PageTemplateSimple>
      <AnalysisPackageModalsController />
    </>
  );
};
