import { Button, DraggableModal, Space } from '@ui';
import { ExpressionBuilder } from '@modules/modelEditor/components/expressionBuilder';
import { selectModelEditorReadOnly } from '@modules/modelEditor/duck/modelEditorSelectors';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const ModelEditorModalsTransformationContent = ({
  data,
  readOnly,
  onChange,
  t,
}: ModelEditorModalsTransformationContentProps) => {
  return (
    <ExpressionBuilder
      variables={data.variables}
      value={data.value ?? ''}
      onChange={onChange}
      readOnly={readOnly}
      t={t}
    />
  );
};

export const ModelEditorModalsTransformation = ({ open, data, onClose }: ModelEditorModalsTransformationProps) => {
  const readOnly = useSelector(selectModelEditorReadOnly);
  const { t } = useTranslation(['model']);
  const [value, onChange] = useState(data?.value ?? '');

  useEffect(() => {
    onChange(data?.value ?? '');
  }, [data?.value]);

  const onSubmit = () => {
    onClose(value);
  };

  const onCancel = () => {
    onChange(data?.value ?? '');
    onClose(data?.value ?? '');
  };

  const onDelete = () => {
    onChange('');
    onClose('');
  };

  return (
    <DraggableModal
      width={920}
      open={open}
      onCancel={onCancel}
      onOk={onSubmit}
      title={t('transform.title')}
      cancelText={t('transform.cancel')}
      okText={t('transform.save')}
      destroyOnClose
      footer={
        readOnly ? (
          <Space>
            <Button children={t('close')} onClick={onCancel} />
          </Space>
        ) : (
          <Space>
            <Button danger children={t('delete')} onClick={onDelete} />
            <Button children={t('cancel')} onClick={onCancel} />
            <Button type="primary" children={t('save')} onClick={onSubmit} />
          </Space>
        )
      }
    >
      {open && <ModelEditorModalsTransformationContent data={data} onChange={onChange} t={t} readOnly={readOnly} />}
    </DraggableModal>
  );
};

interface ModelEditorModalsTransformationContentProps extends Pick<ModelEditorModalsTransformationProps, 'data'> {
  t: TFunction;
  onChange: Dispatch<SetStateAction<string>>;
  readOnly?: boolean;
}
export interface ModelEditorModalsTransformationProps {
  open: boolean;
  data: {
    variables: string[];
    value?: string;
  };
  onClose: (result?: string) => void;
}
