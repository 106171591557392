import { selectLibraryCdrReportModals } from '@modules/library/cdr/duck/libraryCdrSelectors';
import { libraryCdrActions } from '@modules/library/cdr/duck/libraryCdrSlice';
import { ELibraryCdrModalsType } from '@modules/library/cdr/modals/LibraryCdrModalsConstants';
import { useDispatch, useSelector } from 'react-redux';
import { LibraryCdrModalsCopyCdrReport, ILibraryCdrModalsCopyCdrProps } from './components';

export const LibraryCdrModalsController = () => {
  const dispatch = useDispatch();
  const { data, selectedModal } = useSelector(selectLibraryCdrReportModals);

  const closeModal = () => {
    dispatch(libraryCdrActions.dropModal());
  };

  return (
    <LibraryCdrModalsCopyCdrReport
      open={selectedModal === ELibraryCdrModalsType.copyCdrReport}
      data={data as ILibraryCdrModalsCopyCdrProps['data']}
      onClose={closeModal}
    />
  );
};
