import {
  GpdipModalsSettings,
  GpdipModalsSyncSettings,
  GpdipModalsSyncSettingsProps,
  GpdipSettingsProps,
} from '@modules/gpdip/modals/components';
import { selectGpdipModals } from '@modules/gpdip/duck/gpdipSelectors';
import { GpdipModalsType } from '@modules/gpdip/modals/GpdipModalsConstants';
import { gpdipActions } from '@modules/gpdip/duck/gpdipSlice';
import { useDispatch, useSelector } from 'react-redux';

export const GpdipModalsController = () => {
  const dispatch = useDispatch();
  const { selectedModal, data } = useSelector(selectGpdipModals);

  const closeModal = () => {
    dispatch(gpdipActions.dropModal());
  };

  return (
    <>
      <GpdipModalsSettings
        open={selectedModal === GpdipModalsType.settings}
        data={data as GpdipSettingsProps['data']}
        onClose={closeModal}
      />

      <GpdipModalsSyncSettings
        open={selectedModal === GpdipModalsType.syncSettings}
        data={data as GpdipModalsSyncSettingsProps['data']}
        onClose={closeModal}
      />
    </>
  );
};
