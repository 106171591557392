import { I18nLocales } from '@i18n';
import en from './viewer_en.json';

export const viewerLocales: Record<I18nLocales, I18nViewerTranslations> = {
  en,
};

interface I18nViewerTranslations {
  dataError: string;
  exportForm: {
    title: string;
    titleFiltered: string;
    fileName: string;
    fileFormat: string;
    separator: string;
    exportTo: string;
  };
}
