import { Buffer } from 'buffer';

export const decodeBase64 = (input: string) => {
  const binString = Buffer.from(input, 'base64');

  return new TextDecoder().decode(binString);
};

export const encodeBase64 = (input: string) => {
  const bytes = new TextEncoder().encode(input);
  const binString = String.fromCodePoint(...bytes);

  return Buffer.from(binString).toString('base64');
};
