import { I18nLocales } from '@i18n';
import en from './libraryCdr_en.json';

export const libraryCdrLocales: Record<I18nLocales, I18nLibraryCdrTranslations> = {
  en,
};

interface I18nLibraryCdrTranslations {
  pageRootName: string;
  loadingError: string;
  rootTable: {
    name: string;
    cdrType: string;
    created: string;
    updated: string;
    libraryVersion: string;
    sourceVersion: string;
    source: string;
    active: string;
  };
  confirmation: {
    title: string;
    description: string;
    successMessage: string;
  };
  copyForm: {
    title: string;
  };
}
