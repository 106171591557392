import { PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Input, InputRef, Space } from 'antd';
import Select, { SelectProps } from 'antd/es/select';
import { useRef, useState } from 'react';

export const SelectWithAddItem = (props: SelectProps) => {
  const [items, setItems] = useState<string[]>([]);
  const [name, setName] = useState('');
  const inputRef = useRef<InputRef>(null);

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const addItem = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    e.preventDefault();
    setItems([...items, name || 'New item']);
    setName('');
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  return (
    <Select
      {...props}
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider style={{ margin: '8px 0' }} />
          <Space style={{ padding: '0 8px 4px' }}>
            <Input placeholder="Please enter item" ref={inputRef} value={name} onChange={onNameChange} />
            <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
              Add item
            </Button>
          </Space>
        </>
      )}
      options={props.options?.concat(items.map((item) => ({ label: item, value: item })))}
    />
  );
};
