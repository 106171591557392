import { MenuOutlined } from '@ant-design/icons';
import { Children, cloneElement, useMemo } from 'react';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { CSSObject } from '@emotion/react';

export const ModelEditorSchemaEditorRow = ({ children, ...props }: RowProps) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: props['data-row-key'],
  });

  const css = (): CSSObject => ({
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    cursor: 'move',
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  });

  const cells = useMemo(
    () =>
      Children.map(children, (child) => {
        if ((child as React.ReactElement).key === 'sort') {
          return cloneElement(child as React.ReactElement, {
            children: <MenuOutlined {...attributes} {...listeners} style={{ touchAction: 'none', cursor: 'move' }} />,
          });
        }
        return child;
      }),
    [children, attributes, listeners],
  );

  return (
    <tr {...props} ref={setNodeRef} css={css}>
      {cells}
    </tr>
  );
};

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}
