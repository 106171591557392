import { Typography } from '@ui';

export const convertOptionsToFilterItems = (data: Array<ITableFilterItemWithDescription>) =>
  data
    .sort((a, b) => a.text.localeCompare(b.text))
    .map((item, index) => ({
      ...item,
      text: <TableFilterItem key={index} title={item.text} description={item.description} />,
    }));

export const TableFilterItem = ({ title, description }: ITableFilterItemProps) => (
  <Typography.Text>
    {title}
    {description && (
      <Typography.Text style={{ fontSize: '0.8rem' }} type="secondary">
        {' '}
        {description}
      </Typography.Text>
    )}
  </Typography.Text>
);

interface ITableFilterItemProps {
  title: string;
  description?: string;
}

export interface ITableFilterItemWithDescription {
  text: string;
  value: string | number;
  description?: string;
}
