import { IBaseLibraryEntity, IBaseLibraryParams } from '@modules/library/root/LibraryTypes';
import { ICascadeObjectsTableData } from '@shared/components/CascadeObjects';

export enum ECdrType {
  Dashboard = 'Dashboard',
  Report = 'Report',
}

export interface ICDRReport {
  id: number;
  name: string;
  params: string;
  query_context: string;
  settings: string;
  version: number;
  version_label?: number | null;
  viz_type: string;
  type?: ECdrType;
}

export type TLibraryCdrDeleteProps = Pick<ILibraryCDR, 'id' | 'name'>;

export interface ILibraryCDR extends IBaseLibraryEntity {
  object_type: ECdrType;
  configuration: Omit<ICDRReport, 'id'>;
  version_id: number;
}

export interface ILibraryCDRResponse {
  currentPage: number;
  totalItems: number;
  totalPages: number;
  items: ILibraryCDR[];
}

export interface ILibraryCdrListQueryParams extends IBaseLibraryParams {
  page?: number;
  page_size?: number;
  detailed?: '0' | '1';
  sort_by?: string;
  order?: string;
}

export interface ILibraryCDRQuery {
  data: Array<
    Omit<ILibraryCDR, 'created_at' | 'updated_at' | 'created_by' | 'updated_by' | 'version' | 'id' | 'version_id'>
  >;
  overwrite: boolean;
  cascade_update?: ICascadeObjectsTableData[];
}

export interface ILibraryCDRValidateQuery {
  cdr_names: string[];
}
