import { ISnapshot, SNAPSHOT_STATUS } from '../SnapshotTypes';

export enum SNAPSHOT_TAG_DESCRIPTION {
  'LIST' = 'SNAPSHOT_LIST',
  'ID' = 'SNAPSHOT_NAME',
  'SOURCE_TABLE' = 'SNAPSHOT_SOURCE_TABLE',
  'SUB_STUDY' = 'SUB_STUDY',
  'TABLE_ERROR' = 'TABLE_ERROR',
}

export const SNAPSHOT_STATUS_MAPPING = {
  [SNAPSHOT_STATUS.COMPLETED]: 'success',
  [SNAPSHOT_STATUS.FAILED]: 'error',
  [SNAPSHOT_STATUS.IN_PROGRESS]: 'processing',
  [SNAPSHOT_STATUS.NEW]: 'processing',
  [SNAPSHOT_STATUS.PARTIAL]: 'warning',
};

export const SNAPSHOTS_CH_POSTFIXES = {
  defaultScope: '_SNAPSHOTS',
  studyScope: '_SNAPSHOTS_DIST',
};

export const LATEST_DATA_SNAPSHOT: ISnapshot = {
  id: 'current',
  studyId: 0,
  created_by: '',
  protocolId: '',
  description: 'Current',
  createdAt: '',
  tablesDetails: [],
};

export const CH_SNAPSHOT_COLUMN_NAME = 'AUD_SNAPSHOT';
export const EMPTY_SUBSTUDY_VALUE = '<Empty>';
