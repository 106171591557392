import { TLibraryCdrModalsPushModalPayload, TLibraryCdrModalsState } from '@modules/library/cdr/modals';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: LibrarySdrStore = {
  modals: { selectedModal: null },
};

export const libraryCdrSlice = createSlice({
  name: 'libraryCdr',
  initialState,
  reducers: {
    pushModal: (state, action: PayloadAction<TLibraryCdrModalsPushModalPayload>) => {
      const { type, data } = action.payload;
      state.modals = { selectedModal: type, data };
    },
    dropModal: (state, action: PayloadAction<void>) => {
      state.modals = initialState.modals;
    },
    reset: (state) => {
      return initialState;
    },
  },
});

export const libraryCdrActions = libraryCdrSlice.actions;

export const libraryCdrReducer = libraryCdrSlice.reducer;

interface LibrarySdrStore {
  modals: TLibraryCdrModalsState;
}
