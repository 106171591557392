import { I18nLocales } from '@i18n';
import en from './datasource_en.json';

export const dataSourceLocales: Record<I18nLocales, I18nDataSourceTranslations> = {
  en,
};

export interface I18nDataSourceTranslations {
  pageRootName: string;
  loadingError: string;
  confirmation: {
    title: string;
    description: string;
  };
  buttons: {
    testConnection: string;
  };
  rootTable: {
    name: string;
    connectionName: string;
    connectionType: string;
    version: string;
    created: string;
    active: string;
  };
  saveForm: {
    title: string;
    titleEdit: string;
    name: string;
    connectionName: string;
    connectionType: string;
    connection: string;
    active: string;
  };
}
