import { useLocationResolver } from '@routes/routesHooks';
import { LibraryResolver } from '@routes/library/RoutesLibrary';
import { useLibraryQuery } from '@modules/library/root/duck/libraryApi';
import { appActions } from '@app/duck/appSlice';
import { IAppLibraryContext } from '@app/AppTypes';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export const useLibraryAppListener = () => {
  const dispatch = useDispatch();
  const {
    params: { libraryId },
    state: providedContext,
  } = useLocationResolver<LibraryResolver['view']['params'], Omit<IAppLibraryContext, 'id'>>();

  const libraryQuery = useLibraryQuery(libraryId, { skip: !libraryId || !!providedContext?.name });

  useEffect(() => {
    const libraryData = libraryQuery.data;
    const currentLibraryId = Number(libraryData?.id);

    if (currentLibraryId === libraryData?.id) {
      dispatch(
        appActions.setContext({
          type: 'library',
          context: {
            id: libraryData.id,
            name: libraryData.name,
            status: libraryData.status,
          },
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [libraryQuery.data?.id]); // Control Ready Stage

  return { queryError: libraryQuery.error };
};
