import { Navigate, useParams } from 'react-router-dom';

export const Redirect = ({ URLResolver }: RedirectProps) => {
  const params = useParams();

  return <Navigate to={URLResolver(params)} />;
};

interface RedirectProps {
  URLResolver: (data: any) => string;
}
