import { TLibrarySqlLabModalsPushModalPayload, TLibrarySqlLabModalsState } from '@modules/library/sqlLab/modals';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: LibrarySqlLabStore = {
  modals: { selectedModal: null },
};

export const librarySqlLabSlice = createSlice({
  name: 'librarySqlLab',
  initialState,
  reducers: {
    pushModal: (state, action: PayloadAction<TLibrarySqlLabModalsPushModalPayload>) => {
      const { type, data } = action.payload;
      state.modals = { selectedModal: type, data };
    },
    dropModal: (state, action: PayloadAction<void>) => {
      state.modals = initialState.modals;
    },
    reset: (state) => {
      return initialState;
    },
  },
});

export const librarySqlLabActions = librarySqlLabSlice.actions;

export const librarySqlLabReducer = librarySqlLabSlice.reducer;

interface LibrarySqlLabStore {
  modals: TLibrarySqlLabModalsState;
}
