import { configuration } from '@config/constants';
import { prepareCurrentEnvironment } from '@modules/viewer/duck/viewerUtils';
import { authActions } from '@modules/auth/duck/AuthSlice';
import { IAppLibraryContext, IAppStudyContext, IEnvironment } from '@app/AppTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AppStore {
  ready: boolean;
  isUnauthorized: boolean;
  isForbidden: boolean;
  innerSiderWidth: number;
  context: {
    library: null | IAppLibraryContext;
    study: null | IAppStudyContext;
  };
  environment: IEnvironment | null;
}

type SetContextPayload = { type: 'library' | 'study'; context: IAppLibraryContext | IAppStudyContext | null };

const initialState: AppStore = {
  ready: false,
  isUnauthorized: false,
  isForbidden: false,
  innerSiderWidth: configuration.defaultInnerSiderWidth,
  context: {
    library: null,
    study: null,
  },
  environment: null,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setReady: (state, action: PayloadAction<boolean>) => {
      state.ready = action.payload;
    },
    setContext: (state, { payload }: PayloadAction<SetContextPayload>) => {
      state.context = {
        ...{ library: null, study: null },
        [payload.type]: payload.context,
      };
    },
    setUnauthorized: (state, action: PayloadAction<boolean>) => {
      state.isUnauthorized = action.payload;
    },
    setForbidden: (state, action: PayloadAction<boolean>) => {
      state.isForbidden = action.payload;
    },
    setInnerSiderWidth: (state, action: PayloadAction<number>) => {
      state.innerSiderWidth = action.payload;
    },
    setENVSwitch: (state, action: PayloadAction<IEnvironment | null>) => {
      state.environment = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authActions.setUser, (state, action) => {
      const userEnvs = action.payload?.environments || {};
      const userCurrentEnv = action.payload?.env_name || '';
      state.environment = prepareCurrentEnvironment(userCurrentEnv, userEnvs);
    });
  },
});

export const appActions = appSlice.actions;

export const appReducer = appSlice.reducer;
