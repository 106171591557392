import { ILibraryModalsSaveLibraryProps, LibraryModalSaveLibrary } from '@modules/library/root/modals/components';
import { ELibraryModalsType } from '@modules/library/root/modals';
import { selectLibraryModals } from '@modules/library/root/duck/librarySelectors';
import { libraryActions } from '@modules/library/root/duck/librarySlice';
import { useDispatch, useSelector } from 'react-redux';

export const LibraryModalsController = () => {
  const dispatch = useDispatch();
  const { data, selectedModal } = useSelector(selectLibraryModals);

  const closeModal = () => {
    dispatch(libraryActions.dropModal());
  };

  return (
    <LibraryModalSaveLibrary
      open={selectedModal === ELibraryModalsType.saveLibrary}
      data={data as ILibraryModalsSaveLibraryProps['data']}
      onClose={closeModal}
    />
  );
};
