import { I18nLocales } from '@i18n';
import en from './snapshot_en.json';

export const snapshotLocales: Record<I18nLocales, I18nSnapshotTranslations> = {
  en,
};

interface I18nSnapshotTranslations {
  pageRootName: string;
  loadingError: string;
  rootTable: {
    name: string;
    tables: string;
    created: string;
    description: string;
    status: string;
    tableFailed_one: string;
    tableFailed_other: string;
  };
  createPage: {
    creationDate: string;
    title: string;
    name: string;
    description: string;
    subStudy: string;
    namePlaceholder: string;
    subStudyPlaceholder: string;
    successMessageCreate: string;
    errorMessageCreate: string;
  };
  viewPage: {
    title: string;
    status: string;
    lastUpdate: string;
    createdBy: string;
    contentEmpty: string;
  };
  snapshotSelectLabel: string;
}
