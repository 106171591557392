import { Button, notification } from '@ui';
import { downloadByUrl } from '@shared/utils/File';
import { useTranslation } from 'react-i18next';

export const ExportButton = ({ name, onExport }: ExportButtonProps) => {
  const { t } = useTranslation();

  const handleExport = async () => {
    try {
      notification.info({
        message: t('exportMessages.inProgress', { name }),
        description: t('exportMessages.inProgressDescription'),
      });

      const url = await onExport();

      if (!url) {
        notification.error({
          message: t('errors.exportFailed'),
          description: t('errors.urlFailed'),
        });
        return;
      }

      downloadByUrl(url);

      notification.success({
        message: t('exportMessages.success', { name }),
      });
    } catch (err) {
      console.error(err);
      notification.error({
        message: t('errors.exportFailed'),
      });
    }
  };

  return <Button children={t('export')} onClick={handleExport} />;
};

export interface ExportButtonProps {
  name: string;
  onExport: () => Promise<string>;
}
