import { I18nLocales } from '@i18n';
import en from './librarySqlLab_en.json';

export const librarySqlLabLocales: Record<I18nLocales, I18nLibrarySqlLabTranslations> = {
  en,
};

interface I18nLibrarySqlLabTranslations {
  pageRootName: string;
  loadingError: string;
  rootTable: {
    name: string;
    description: string;
    created: string;
    updated: string;
    version: string;
    source: string;
  };
  confirmation: {
    title: string;
    description: string;
    successMessage: string;
  };
  copyForm: {
    title: string;
  };
}
