import { DraggableModal } from '@ui';
import { prepareLibraryObject } from '@modules/library/root/duck/libraryUtils';
import { IDataset } from '@modules/dataset/DatasetTypes';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { DataObjectSelectForm, IDataObjectSelectFormProps } from './DataObjectSelectForm';
import { useImportAnalysisObjectSQLDatasetMutation } from '../../../duck/analysisObjectSQLDatasetApi';
import { IAOSQLDatasetObject } from '../../../AnalysisObjectSQLDatasetTypes';

const AnalysisObjectSQLDatasetModalsImportContent = ({
  onClose,
}: IAnalysisObjectSQLDatasetModalsImportContentProps) => {
  const [importAOSQLDataset] = useImportAnalysisObjectSQLDatasetMutation();

  const onImport: IDataObjectSelectFormProps['onSubmit'] = async (values: IDataset[], { systemInfo }) => {
    return await importAOSQLDataset({
      data: prepareLibraryObject<IAOSQLDatasetObject>(
        values.map((item) => ({
          name: item.name,
          configuration: { name: item.name, scope: item.scope, query: item.query },
        })),
        systemInfo,
      ).map((item) => ({ ...item, description: '', source_id: null })) as any,
      source: 'study',
    }).unwrap();
  };

  return <DataObjectSelectForm onClose={onClose} onSubmit={onImport} hideOverwriteHandles onlyCurrentEnv />;
};

export const AnalysisObjectSQLDatasetModalsCopy = ({
  open,
  data,
  onClose,
}: IAnalysisObjectSQLDatasetModalsCopyProps) => {
  const { t } = useTranslation(['analysisObjects']);

  return (
    <DraggableModal
      width="50%"
      open={open}
      onCancel={onClose}
      title={t('sqlDatasetAO.importForm.title')}
      footer={null}
      destroyOnClose
    >
      {open && <AnalysisObjectSQLDatasetModalsImportContent data={data} onClose={onClose} t={t} />}
    </DraggableModal>
  );
};

export interface IAnalysisObjectSQLDatasetModalsCopyProps {
  open: boolean;
  data: any;
  onClose: () => void;
}

interface IAnalysisObjectSQLDatasetModalsImportContentProps
  extends Pick<IAnalysisObjectSQLDatasetModalsCopyProps, 'data' | 'onClose'> {
  t: TFunction;
}
