import { ViewerCHTableInfoData } from '@modules/viewer/ViewerTypes';

export const tableInfoMetaToOptions = (data?: ViewerCHTableInfoData[]) =>
  data?.map((item) => ({
    label: item.name,
    value: item.name,
  })) || [];

export const isContainForbiddenWords = (sqlStatement: string) => {
  const forbiddenKeywords = [
    'INSERT',
    'UPDATE',
    'DELETE',
    'CREATE',
    'ALTER',
    'DROP',
    'GRANT',
    'REVOKE',
    'SHOW',
    'EXPLAIN',
  ];
  const regexPattern = new RegExp(`\\b(${forbiddenKeywords.join('|')})\\b`, 'i');
  return regexPattern.test(sqlStatement);
};

export const isContainSelect = (sqlStatement: string) => {
  const keyWord = 'SELECT';
  const regexPattern = new RegExp(`\\b${keyWord}\\s.*\\S`, 'i');
  return regexPattern.test(sqlStatement);
};

export const isContainSpecialCharOrSpace = (value: string) => {
  const regex = /[^.\w]/;
  const regexPattern = new RegExp(regex, 'i');
  return regexPattern.test(value);
};

export const isContainTable = (sqlStatement: string, table: string) => {
  // Might be false positive f.e. inside comments or string constants,
  // but it is better than have broken model.
  const regexPattern = new RegExp(`\\.${table}\\b`, 'i');
  return regexPattern.test(sqlStatement);
};
