import { appApi } from '@config/appApi';
import { Store } from '@modules/stores/StoreTypes';
import { STORE_TAG_DESCRIPTION } from '@modules/stores/duck/storeConstants';
import { AuditInvalidations } from '@modules/audit/duck/auditApi';
import { TNotificationConfig } from '@components/form';
import { TagDescription } from '@reduxjs/toolkit/query/react';

export const StoreApiRoutes = {
  list: `api/stores`,
  store: (storeId: number) => `api/stores/${storeId}`,
  studyUserList: 'api/study/users',
};

export const StoreInvalidations: {
  ID: (storeId: number) => TagDescription<STORE_TAG_DESCRIPTION.ID>;
  LIST: TagDescription<STORE_TAG_DESCRIPTION.LIST>;
  STUDY_USER_LIST: TagDescription<STORE_TAG_DESCRIPTION.STUDY_USER_LIST>;
} = {
  ID: (storeId: number) => ({ type: STORE_TAG_DESCRIPTION.ID, storeId }),
  LIST: { type: STORE_TAG_DESCRIPTION.LIST, id: 'LIST' },
  STUDY_USER_LIST: { type: STORE_TAG_DESCRIPTION.STUDY_USER_LIST, id: 'LIST' },
};

export const StoreApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    storeListPaginated: builder.query<StoreListResponse, StoreListQueryParams | void>({
      providesTags: [StoreInvalidations.LIST],
      query: (params) => ({
        params,
        url: StoreApiRoutes.list,
      }),
    }),
    storeList: builder.query<StoreListResponse['items'], StoreListQueryParams | void>({
      providesTags: [StoreInvalidations.LIST],
      query: (params) => ({
        params,
        url: StoreApiRoutes.list,
      }),
    }),
    studyUserList: builder.query<StudyUserListResponse, void>({
      providesTags: [StoreInvalidations.STUDY_USER_LIST],
      query: () => ({
        url: StoreApiRoutes.studyUserList,
      }),
    }),
    deleteStore: builder.mutation<void, number>({
      invalidatesTags: (request, error, id) => [
        StoreInvalidations.LIST,
        StoreInvalidations.ID(id),
        AuditInvalidations.LIST,
      ],
      query: (id) => ({
        method: 'DELETE',
        url: StoreApiRoutes.store(id),
      }),
    }),
    saveStore: builder.mutation<void, StoreSaveQuery>({
      invalidatesTags: (request, error, data) =>
        data.id
          ? [StoreInvalidations.LIST, StoreInvalidations.ID(data.id), AuditInvalidations.LIST]
          : [StoreInvalidations.LIST, AuditInvalidations.LIST],
      query: (data) => ({
        data,
        method: data.id ? 'PUT' : 'POST',
        url: data.id ? StoreApiRoutes.store(data.id) : StoreApiRoutes.list,
      }),
    }),
  }),
});

export const {
  useStoreListQuery,
  useStoreListPaginatedQuery,
  useStudyUserListQuery,
  useDeleteStoreMutation,
  useSaveStoreMutation,
} = StoreApi;

export interface StoreListResponse {
  currentPage: number;
  totalItems: number;
  totalPages: number;
  items: Array<Store>;
}

export type StudyUserListResponse = Array<{ id: number; first_name: string; last_name: string; username: string }>;

interface StoreSaveQuery {
  id?: number;
  name: string;
  description: string;
  data_source_id: number;
  data_source_name: string;
  sensitive: boolean;
  active: boolean;
  job: {
    active: boolean;
    run_once: boolean;
    crontab: string;
    crontab_ui_repr: string;
  };
  notification: TNotificationConfig;
}

interface StoreListQueryParams {
  page?: number;
  detailed?: number;
  source_env?: string;
}
