import { ViewerGroupType } from '@modules/viewer/ViewerTypes';
import { NodeData } from '@shared/components/TableListSider/tableListSiderHooks';

const CLICKHOUSE_DB = 'data_stage';

export const getTableNameWithSchema = (tableId: string | null) => {
  // tableId can be like as Clinical:CH_001, default.rules, 123:database.table
  if (tableId) {
    const id = removeItemId(tableId);
    const [schema, tableName] = id.split('.');
    return {
      name: tableName || schema,
      schema: tableName && schema ? schema : CLICKHOUSE_DB,
    };
  }
  return { name: '', schema: '' };
};

export const isContainSchema = (tableId: string) => tableId.split('.').length > 1;

export const removeItemId = (tableId: string) => tableId.split(':').at(-1) ?? tableId;

export const applySchemaToTable = (tableName: string, ch_db: string) => `${ch_db}.${tableName}`;

export const getFolderIconColor = (type: ViewerGroupType): string => {
  if (type === ViewerGroupType.internal) {
    return '#2171B5';
  }

  if (type === ViewerGroupType.studyRT || type === ViewerGroupType.crossStudyRT) {
    return '#60c1b5';
  }

  if (type === ViewerGroupType.external) {
    return '#0DACEB';
  }

  if (type === ViewerGroupType.rccGroups) {
    return '#b368d7';
  }

  if (type === ViewerGroupType.globalDS) {
    return '#d496c4';
  }

  return '#8c8c8c';
};

export const filteredTreeData = (treeData: NodeData[], searchText: string) =>
  treeData
    .map((group) => {
      const filteredChildren =
        group.children?.filter((child) => (child.name as string)?.toLowerCase().includes(searchText.toLowerCase())) ||
        [];
      return { ...group, children: filteredChildren };
    })
    .filter((group) => group.children?.length > 0);
