import { useFeatures } from '@modules/user/duck/userHooks';
import { PageNotFound } from '@components';
import { ReactElement } from 'react';

export const FeatureGate = ({ children, deniedFallback, ...targetFeatures }: FeatureGateProps) => {
  const { ...features } = useFeatures();

  if (Object.keys(targetFeatures).every((p) => (features || {})[p as keyof TFeaturesFlags] === true)) {
    return children;
  }
  return deniedFallback || <PageNotFound />;
};

type TFeaturesFlags = {
  hasGL: boolean;
  hasDeployment: boolean;
  hasGPDIP: boolean;
};

interface FeatureGateProps extends Partial<Record<keyof TFeaturesFlags, true>> {
  children: ReactElement;
  deniedFallback?: ReactElement;
}
