import { Space, Typography } from '@components/ui';
import Progress, { ProgressProps } from 'antd/es/progress';
import { CSSObject } from '@emotion/react';
import dayjs from 'dayjs';
import { ProgressSize } from 'antd/es/progress/progress';

const round = (value?: number) => (value ? Math.round((value + Number.EPSILON) * 100) / 100 : 0);

export const ProgressWithTitle = ({ title, percent, ...rest }: ProgressWithTitleProps) => (
  <div css={cssProgressLayout}>
    <div css={cssProgressTitleLayout}>{title}:</div>
    <Progress percent={round(percent)} {...rest} />
  </div>
);

const getTimeDuration = (estimatedMs: number) => {
  const timeDuration = dayjs.duration(estimatedMs, 'milliseconds');
  const hours = timeDuration.hours();
  const minutes = timeDuration.minutes();
  const seconds = timeDuration.seconds();

  let formattedTimeDuration = '';

  if (hours > 0) {
    formattedTimeDuration += `${hours} hours`;
  }
  if (minutes > 0) {
    if (formattedTimeDuration) formattedTimeDuration += ', ';
    formattedTimeDuration += `${minutes} minutes`;
  }
  if (seconds > 0 || !formattedTimeDuration) {
    if (formattedTimeDuration) formattedTimeDuration += ', ';
    formattedTimeDuration += `${seconds} seconds`;
  }

  return formattedTimeDuration;
};

const renderEstimatedTime = (estimated: number) =>
  estimated > 0 ? dayjs.duration(estimated, 'milliseconds').humanize() : <>&mdash;</>;

export const ProgressCircleWithTitle = ({ title, size, estimated, percent, ...rest }: ProgressCircleWithTitleProps) => (
  <Space full direction="vertical" justify="center" align="center">
    <Typography.Title level={4}>{title}</Typography.Title>
    {estimated ? <Typography.Text type="secondary">Estimated: {renderEstimatedTime(estimated)}</Typography.Text> : null}
    <Progress type="circle" size={size ?? 200} percent={round(percent)} {...rest} />
  </Space>
);

export const ProgressLineWithTitle = ({
  title,
  size,
  status,
  estimated,
  isError,
  percent,
  ...rest
}: ProgressLineWithTitleProps) => {
  const estimatedTime = estimated ? <span>({renderEstimatedTime(estimated)})</span> : null;

  return (
    <>
      <Typography.Text type="secondary">
        {title} {estimatedTime}
      </Typography.Text>
      <Progress
        percent={round(percent)}
        size={size ?? 'small'}
        status={isError ? 'exception' : status ?? 'active'}
        {...rest}
      />
    </>
  );
};

const cssProgressLayout = (): CSSObject => ({
  display: 'flex',
  alignItems: 'center',
});

const cssProgressTitleLayout = (): CSSObject => ({
  marginRight: '1em',
  textWrap: 'nowrap',
});

interface ProgressWithTitleProps extends ProgressProps {
  title: string;
}

interface ProgressCircleWithTitleProps extends ProgressProps {
  title: string;
  size?: number;
  estimated?: number;
}

interface ProgressLineWithTitleProps extends ProgressProps {
  title: string;
  size?: ProgressSize;
  estimated?: number;
  isError?: boolean;
}

export { Progress };
