import { selectLibraryAuditModals } from '@modules/library/audit/duck/libraryAuditSelectors';
import { libraryAuditActions } from '@modules/library/audit/duck/libraryAuditSlice';
import { useDispatch, useSelector } from 'react-redux';
import { LibraryAuditModalsDetails, LibraryAuditModalsDetailsProps } from './components';
import { LibraryAuditModalsType } from './LibraryAuditModalsConstants';

export const LibraryAuditModalsController = () => {
  const dispatch = useDispatch();
  const { data, selectedModal } = useSelector(selectLibraryAuditModals);

  const closeModal = () => {
    dispatch(libraryAuditActions.dropModal());
  };

  return (
    <>
      <LibraryAuditModalsDetails
        open={selectedModal === LibraryAuditModalsType.showDetails}
        data={data as LibraryAuditModalsDetailsProps['data']}
        onClose={closeModal}
      />
    </>
  );
};
