import { AuditModalsPushModalPayload, AuditModalsState } from '@modules/audit/modals/AuditModalsTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: AuditStore = {
  modals: { selectedModal: null },
};

export const auditSlice = createSlice({
  name: 'source',
  initialState,
  reducers: {
    pushModal: (state, action: PayloadAction<AuditModalsPushModalPayload>) => {
      const { type, data } = action.payload;
      state.modals = { selectedModal: type, data };
    },
    dropModal: (state, action: PayloadAction<void>) => {
      state.modals = initialState.modals;
    },
    reset: (state) => {
      return initialState;
    },
  },
});

export const auditActions = auditSlice.actions;

export const auditReducer = auditSlice.reducer;

interface AuditStore {
  modals: AuditModalsState;
}
