import { GPDIP_TAG_DESCRIPTION } from '@modules/gpdip/duck/gpdipConstants';
import { TagDescription } from '@reduxjs/toolkit/query/react';

export const GpdipInvalidations: {
  LIST: TagDescription<GPDIP_TAG_DESCRIPTION.LIST>;
  SETTINGS: TagDescription<GPDIP_TAG_DESCRIPTION.SETTINGS>;
  STATUSES: TagDescription<GPDIP_TAG_DESCRIPTION.STATUSES>;
  ID: (id: number) => TagDescription<GPDIP_TAG_DESCRIPTION.ID>;
} = {
  LIST: { type: GPDIP_TAG_DESCRIPTION.LIST, id: 'LIST' },
  SETTINGS: { type: GPDIP_TAG_DESCRIPTION.SETTINGS, id: 'SETTINGS' },
  STATUSES: { type: GPDIP_TAG_DESCRIPTION.STATUSES, id: 'STATUSES' },
  ID: (id: number) => ({ type: GPDIP_TAG_DESCRIPTION.ID, id }),
};
