import { ViewerGroupType } from '@modules/viewer/ViewerTypes';
import { ViewerGroup } from '@modules/viewer/duck/viewerSlice';
import { RootState } from '@store';
import { createSelector } from '@reduxjs/toolkit';
import { Key } from 'react';

export const selectViewerDataFormats = (state: RootState) => state.viewer.dataFormats;

export const selectViewerModals = (state: RootState) => state.viewer.modals;

export const selectViewerGroupsData = (state: RootState) => state.viewer.groups;

export const selectViewerLeftSideData = (state: RootState) => state.viewer.leftSide;

export const selectViewerSnapshot = (state: RootState) => state.viewer.snapshot;

export const selectViewerEnvironment = (state: RootState) => state.viewer.environment;

export const selectTasks = (state: RootState) => state.viewer.tasks;

export const selectViewerExpanded = (state: RootState) => state.viewer.expanded;

const selectExpandedGroups = createSelector(
  selectViewerExpanded,
  selectViewerEnvironment,
  (_: any, studyId: string | number) => studyId,
  (expanded: Record<string, Record<number | string, Key[]>>, environment: string, studyId: string | number) => {
    return (expanded || {})[studyId]?.[environment] || [];
  },
);

export const selectExpandedGroupsMemo = (studyId: string | number) => (state: RootState) =>
  selectExpandedGroups(state, studyId);

const selectViewerGroups = createSelector(
  selectViewerGroupsData,
  selectViewerEnvironment,
  (_: any, studyId: string | number) => studyId,
  (
    groups: Record<string, Record<string, Record<ViewerGroupType, ViewerGroup[]>>>,
    environment: string,
    studyId: string | number,
  ) => {
    return (groups || {})[studyId]?.[environment] || {};
  },
);

export const selectViewerGroupsMemo = (studyId: string | number) => (state: RootState) =>
  selectViewerGroups(state, studyId);
