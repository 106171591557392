import { createContext, ReactNode, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

const defaultContext = {
  modalTitle: '',
  stepNumber: 1,
  isFileProcessingFinished: true,
  modalStudyName: '',
  setModalTitle: () => {},
  setStepNumber: () => {},
  setIsFileProcessingFinished: () => {},
  setModalStudyName: () => {},
};

const JobModalUploadContext = createContext<JobModalUploadContextState>(defaultContext);

export const JobModalProvider = ({ children }: JobModalUploadProviderProps) => {
  const { t } = useTranslation(['job']);
  const [modalTitle, setModalTitle] = useState(t('uploadModal.title'));
  const [stepNumber, setStepNumber] = useState(1);
  const [isFileProcessingFinished, setIsFileProcessingFinished] = useState(true);
  const [modalStudyName, setModalStudyName] = useState('');

  const values: JobModalUploadContextState = {
    modalTitle,
    setModalTitle,
    stepNumber,
    setStepNumber,
    isFileProcessingFinished,
    setIsFileProcessingFinished,
    modalStudyName,
    setModalStudyName,
  };

  return <JobModalUploadContext.Provider value={values}>{children}</JobModalUploadContext.Provider>;
};

export const useJobModalUploadContext = () => useContext(JobModalUploadContext);

interface JobModalUploadProviderProps {
  children: ReactNode;
}

interface JobModalUploadContextState {
  modalTitle: string;
  stepNumber: number;
  isFileProcessingFinished: boolean;
  modalStudyName: string;
  setStepNumber: (val: number) => void;
  setModalTitle: (val: string) => void;
  setIsFileProcessingFinished: (val: boolean) => void;
  setModalStudyName: (val: string) => void;
}
