import { useLocationResolver } from '@routes/routesHooks';
import { StudyResolver } from '@routes/study/RoutesStudy';
import { appActions } from '@app/duck/appSlice';
import { IAppStudyContext } from '@app/AppTypes';
import { isValidStudyId } from '@routes/utils';
import { CrossStudyId } from '@config/constants';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStudyContextQuery } from './studyApi';
import { selectStudyAppPreloaded } from './studySelectors';

export const useStudyAppListener = () => {
  const dispatch = useDispatch();
  const studyAppPreloaded = useSelector(selectStudyAppPreloaded);

  const {
    params: { studyId },
    state: providedContext,
  } = useLocationResolver<StudyResolver['view']['params'], Omit<IAppStudyContext, 'id'>>();

  const studyContextQuery = useStudyContextQuery(
    { study_id: studyId },
    {
      skip:
        !isValidStudyId(studyId) ||
        !!(providedContext?.name && providedContext?.studySchema && providedContext.configured),
    },
  );

  useEffect(() => {
    const studyData = studyContextQuery.data;
    const currentStudyId = Number(studyId);

    if (currentStudyId === studyData?.id) {
      dispatch(
        appActions.setContext({
          type: 'study',
          context: {
            id: studyData.id,
            name: studyData.name,
            studySchema: studyData.study_schema,
            protocolId: studyData.protocol_id,
            configured: studyData.configured,
            sameStudy: true,
          },
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studyContextQuery.data?.id]); // Control Ready Stage

  return {
    queryError: studyContextQuery.error,
    studyAppPreloaded,
    isCrossStudy: CrossStudyId === Number(studyId),
  };
};
