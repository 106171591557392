import { DraggableModal, Table } from '@ui';
import { Domain, DomainMetadata } from '@modules/job/JobTypes';
import { useColumnSearch } from '@components/ui/table/tableHooks';
import { useTableInfoQuery } from '@modules/viewer/duck/viewerApi';
import { selectStudyFallbackCHDB } from '@modules/study/duck/studySelectors';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { TableColumnsType } from 'antd';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const JobModalsViewVariablesContent = ({ tableId, t }: JobModalsViewVariablesContentProps) => {
  const fallbackCHDB = useSelector(selectStudyFallbackCHDB);
  const tableInfo = useTableInfoQuery({ tableName: tableId, fallbackCHDB: fallbackCHDB });
  const { getColumnSearchProps, locale } = useColumnSearch<DomainMetadata>();

  const data: DomainMetadata[] = tableInfo.data?.columns.map((item) => ({ name: item.name, type: item.type })) || [];

  const columns: TableColumnsType<DomainMetadata> = useMemo(
    () => [
      {
        width: 60,
        title: t('viewVariablesModal.rowNumber'),
        dataIndex: 'rowNumber',
        render: (_, record, index) => index + 1,
      },
      {
        title: t('viewVariablesModal.name'),
        dataIndex: 'name',
        key: 'name',
        ...getColumnSearchProps('name'),
      },
      {
        width: 160,
        title: t('viewVariablesModal.type'),
        dataIndex: 'type',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Table
      loading={tableInfo.isLoading}
      locale={locale}
      columns={columns}
      dataSource={data}
      size="small"
      rowKey={(item) => item.name}
      tableLayout="fixed"
      pagination={false}
    />
  );
};
export const JobModalsViewVariables = ({ open, data, onClose }: JobModalsViewVariablesProps) => {
  const { t } = useTranslation(['job']);
  return (
    <DraggableModal
      open={open}
      onCancel={onClose}
      title={t('viewVariablesModal.title', { name: data?.table_name })}
      footer={null}
      width="50%"
      destroyOnClose
    >
      {open && <JobModalsViewVariablesContent tableId={data.table_name!} t={t} />}
    </DraggableModal>
  );
};

export interface JobModalsViewVariablesProps {
  open: boolean;
  data: Domain;
  onClose: () => void;
}

interface JobModalsViewVariablesContentProps {
  t: TFunction;
  tableId: string;
}
