import { IGpdip, IGpdipTree, ObjectTypeLabels, RunStatus } from '@modules/gpdip/GpdipTypes';

export const prepareTableTreeData = (data: IGpdip[]) =>
  data?.reduce((acc, item) => {
    const foundedObjIndex = acc.findIndex((el) => el.id === item.type);

    if (foundedObjIndex !== -1) {
      const foundedElement = acc[foundedObjIndex];
      const { automaticCount, manualCount, periodicallyCount, successCount, errorCount, finished_at, error, children } =
        foundedElement;

      const itemFinishedAt = new Date(item.finished_at ?? '');
      const elementFinishedAt = new Date(finished_at ?? '');
      const latestFinishedAt = (itemFinishedAt > elementFinishedAt ? item.finished_at : finished_at) ?? '';
      const errorValue = itemFinishedAt > elementFinishedAt ? item.error ?? '' : error;

      acc[foundedObjIndex] = {
        ...foundedElement,
        finished_at: latestFinishedAt,
        automaticCount: automaticCount + +item.export.automatic,
        manualCount: manualCount + (!item.export.crontab ? +!item.export.automatic : 0),
        periodicallyCount: periodicallyCount + (item.export.crontab ? +!item.export.automatic : 0),
        successCount: successCount + (item.finished_at ? +!Boolean(item.error) : 0),
        errorCount: errorCount + (item.finished_at ? +Boolean(item.error) : 0),
        error: errorValue,
        children: [...children, item],
      };
      return acc;
    }
    return [
      ...acc,
      {
        id: item.type,
        type: item.type,
        name: ObjectTypeLabels[item.type],
        finished_at: item.finished_at ?? '',
        error: item.error ?? '',
        export: item.export,
        automaticCount: +item.export.automatic,
        manualCount: !item.export.crontab ? +!item.export.automatic : 0,
        periodicallyCount: item.export.crontab ? +!item.export.automatic : 0,
        successCount: item.finished_at ? +!Boolean(item.error) : 0,
        errorCount: item.finished_at ? +Boolean(item.error) : 0,
        children: [item],
      },
    ];
  }, [] as IGpdipTree[]);

export const getRunStatusById = (statuses: RunStatus[], id: IGpdipTree['id']) =>
  statuses?.find((el) => el.id === id)?.status;
