import {
  useSaveSnapshotMutation,
  SnapshotInvalidations,
  useSnapshotSubStudyQuery,
} from '@modules/snapshot/duck/snapshotApi';
import { PageTemplateSimple } from '@components';
import { Button, Form, FormLayout, FormItem, Input, Space, notification, Alert, DatePicker, Select } from '@ui';
import { useLocationResolver } from '@routes/routesHooks';
import { StudyResolver } from '@routes/study/RoutesStudy';
import routes from '@routes';
import { appApi } from '@config/appApi';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { SnapshotTableSelect } from '../components';
import { CDMQueryErrorType, ISnapshot, ISnapshotSaveQuery } from '../SnapshotTypes';
import { EMPTY_SUBSTUDY_VALUE } from '../duck/snapshotConstants';

export const SnapshotCreatePage = () => {
  const {
    params: { studyId },
  } = useLocationResolver<StudyResolver['snapshots']['create']['params'], ISnapshot>();
  const [form] = Form.useForm<ISnapshotSaveQuery>();
  const [notHandledErrors, setNotHandleErrors] = useState<string[]>([]);
  const navigate = useNavigate();
  const [saveSnapshot, saveSnapshotQuery] = useSaveSnapshotMutation();
  const subStudyQuery = useSnapshotSubStudyQuery();
  const dispatch = useDispatch();
  const { t } = useTranslation(['snapshot']);

  const subStudiesOptions = useMemo(
    () =>
      subStudyQuery?.data
        ? subStudyQuery.data.map((subStudy) => ({
            value: subStudy,
            label: subStudy || EMPTY_SUBSTUDY_VALUE,
          }))
        : [],
    [subStudyQuery?.data],
  );

  const onCancel = () => navigate(routes.study.snapshots.root.resolver({ studyId }));

  const onSubmit = async (values: ISnapshotSaveQuery) => {
    const tables: string[] = (form.getFieldValue('tables') || [])
      .map((table: string) => table.replace(/.+:.+\./, ''))
      .filter((v: string) => v);

    if (!tables.length) {
      notification.warning({ message: 'At least one table should be selected' });
      return;
    }

    setNotHandleErrors([]);

    // Workaround for CD Timeout. Client is waiting for 30 sec and then emulate success response
    new Promise((resolve, reject) => {
      let timer = setTimeout(() => {
        timer = null as any;

        notification.info({
          message: t('createPage.infoAboutProcess'),
        });
        notification.success({
          message: t('createPage.successMessageCreate', { name: values.description }),
        });
        onCancel();
        resolve('success');
        dispatch(appApi.util.invalidateTags([SnapshotInvalidations.LIST]));
      }, 30000);

      saveSnapshot({ ...values, tables })
        .unwrap()
        .then(() => {
          clearTimeout(timer);
          if (timer !== null) {
            notification.info({
              message: t('createPage.infoAboutProcess'),
            });
            notification.success({
              message: t('createPage.successMessageCreate', { name: values.description }),
            });
            onCancel();
            resolve('success');
          }
        })
        .catch((e) => {
          const cdmErrorData = e as CDMQueryErrorType;
          clearTimeout(timer);

          if (timer !== null) {
            if (cdmErrorData.status && cdmErrorData.status >= 400) {
              if (cdmErrorData.data.userMsg?.includes('already exists')) {
                form.setFields([
                  {
                    name: 'description',
                    errors: [t('createPage.alreadyExists')],
                  },
                ]);
              } else if (cdmErrorData.data.userMsg?.toLowerCase()?.includes('forbidden')) {
                form.setFields([
                  {
                    name: 'description',
                    errors: [t('createPage.forbiddenName')],
                  },
                ]);
              } else {
                setNotHandleErrors([cdmErrorData.data?.userMsg || t('shared.errors.smthWrong')]);
              }
            }
            resolve('error');
          }
        });
    });
  };

  const onSelectTable = useCallback((values: React.Key[]) => form.setFieldValue('tables', values), [form]);

  return (
    <PageTemplateSimple
      hideTitleSkeleton
      title={{
        children: t('createPage.title'),
        extra: (
          <Space>
            <Button loading={saveSnapshotQuery.isLoading} type="primary" children={t('create')} onClick={form.submit} />
            <Button children={t('back')} onClick={onCancel} />
          </Space>
        ),
      }}
    >
      <FormLayout
        form={form}
        onCancel={onCancel}
        onSubmit={onSubmit}
        okText={t('save')}
        hideCancelBtn
        hideOkBtn
        disabled={saveSnapshotQuery.isLoading}
      >
        {!!notHandledErrors.length && (
          <FormItem wrapperCol={{ span: 24 }}>
            <Alert message={<Space direction="vertical" children={notHandledErrors} />} type="error" />
          </FormItem>
        )}
        <FormItem
          name="description"
          label={t('createPage.name')}
          rules={[
            { required: true, max: 255 },
            {
              pattern: /^[A-Z0-9_]+$/,
              message: t('createPage.validationNameMessage'),
            },
          ]}
        >
          <Input placeholder={t('createPage.namePlaceholder')} />
        </FormItem>
        <FormItem name="subStudies" label={t('createPage.subStudy')}>
          <Select
            mode="multiple"
            options={subStudiesOptions}
            placeholder={t('createPage.subStudyPlaceholder')}
            allowClear
          />
        </FormItem>
        <FormItem
          name="cutoffDate"
          label={t('createPage.cutoffDate')}
          getValueProps={(value) => ({
            value: value ? dayjs(value).utc() : undefined,
          })}
          getValueFromEvent={(value) => (value ? dayjs(value).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : undefined)}
        >
          <DatePicker
            showTime={{
              format: 'h:mm:ss A',
            }}
            showNow={false}
            format="MMM D, YYYY h:mm:ss A"
          />
        </FormItem>
        <FormItem shouldUpdate wrapperCol={{ span: 24 }}>
          {() => (
            <SnapshotTableSelect
              disabled={saveSnapshotQuery.isLoading}
              selectedTables={form.getFieldValue('tables')}
              onSelectTable={onSelectTable}
            />
          )}
        </FormItem>
      </FormLayout>
    </PageTemplateSimple>
  );
};
