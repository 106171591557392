import { StrOrNum } from '@shared/GlobalTypes';
import { include, reverse } from 'named-urls';

const libraryRouteId = {
  root: 'library-root',
  view: 'library-view',
  models: {
    root: 'library-models-root',
  },
  cdr: {
    root: 'library-cdr-root',
  },
  sqlLab: {
    root: 'library-sqlLab-root',
  },
  notebook: {
    root: 'library-notebook-root',
  },
  audit: {
    root: 'library-audit-root',
  },
};

const studyLibraryRouteId = {
  analysisObjects: 'study-analysis-objects',
  analysisPackage: 'study-analysis-package',
  deploymentPlans: 'study-deployment-plans',
  analysisObjectsModels: {
    root: 'study-analysisObjects-models-root',
  },
  analysisObjectsCdr: {
    root: 'study-analysisObjects-cdr-root',
  },
  analysisObjectsSQLDatasets: {
    root: 'study-analysisObjects-sql-datasets-root',
  },
};

const libraryRoute = include('/library', {
  root: '',
  view: ':libraryId',
  models: include(':libraryId/models', {
    root: '',
  }),
  cdr: include(':libraryId/cdr', {
    root: '',
  }),
  sqlLab: include(':libraryId/sqlLab', {
    root: '',
  }),
  notebook: include(':libraryId/notebook', {
    root: '',
  }),
  audit: include(':libraryId/audit', {
    root: '',
  }),
});

const studyLibraryRoute = include('/study', {
  view: include(':studyId', {
    analysisObjects: 'analysisObjects',
    analysisPackage: 'analysisPackage',
    deploymentPlans: 'deployment',
    analysisObjectsModels: include('analysisObjects/models', {
      root: '',
    }),
    analysisObjectsCdr: include('analysisObjects/cdr', {
      root: '',
    }),
    analysisObjectsSQLDatasets: include('analysisObjects/sql-datasets', {
      root: '',
    }),
  }),
});

const libraryResolver = {
  root: () => libraryRoute.root,
  view: (params: LibraryResolver['view']['params']) => reverse(libraryRoute.view, params),
  models: {
    root: (params: LibraryResolver['models']['root']['params']) => reverse(libraryRoute.models.root, params),
  },
  cdr: {
    root: (params: LibraryResolver['cdr']['root']['params']) => reverse(libraryRoute.cdr.root, params),
  },
  sqlLab: {
    root: (params: LibraryResolver['sqlLab']['root']['params']) => reverse(libraryRoute.sqlLab.root, params),
  },
  notebook: {
    root: (params: LibraryResolver['notebook']['root']['params']) => reverse(libraryRoute.notebook.root, params),
  },
  audit: {
    root: (params: LibraryResolver['audit']['root']['params']) => reverse(libraryRoute.audit.root, params),
  },
  analysisObjects: (params: LibraryResolver['analysisObjects']['params']) =>
    reverse(studyLibraryRoute.view.analysisObjects, params),
  analysisPackage: (params: LibraryResolver['analysisPackage']['params']) =>
    reverse(studyLibraryRoute.view.analysisPackage, params),
  deploymentPlans: (params: LibraryResolver['deploymentPlans']['params']) =>
    reverse(studyLibraryRoute.view.deploymentPlans, params),
  analysisObjectsModels: {
    root: (params: LibraryResolver['analysisObjectsModels']['root']['params']) =>
      reverse(studyLibraryRoute.view.analysisObjectsModels.root, params),
  },
  analysisObjectsCdr: {
    root: (params: LibraryResolver['analysisObjectsCdr']['root']['params']) =>
      reverse(studyLibraryRoute.view.analysisObjectsCdr.root, params),
  },
  analysisObjectsSQLDatasets: {
    root: (params: LibraryResolver['analysisObjectsSQLDatasets']['root']['params']) =>
      reverse(studyLibraryRoute.view.analysisObjectsSQLDatasets.root, params),
  },
};

export interface LibraryResolver {
  view: {
    params: { libraryId: StrOrNum };
  };
  models: {
    root: {
      params: { libraryId: StrOrNum };
    };
  };
  cdr: {
    root: {
      params: { libraryId: StrOrNum };
    };
  };
  sqlLab: {
    root: {
      params: { libraryId: StrOrNum };
    };
  };
  notebook: {
    root: {
      params: { libraryId: StrOrNum };
    };
  };
  audit: {
    root: {
      params: { libraryId: StrOrNum };
    };
  };
  analysisPackage: {
    params: { studyId: StrOrNum };
  };
  deploymentPlans: {
    params: { studyId: StrOrNum };
  };
  analysisObjects: {
    params: { studyId: StrOrNum };
  };
  analysisObjectsModels: {
    root: {
      params: { studyId: StrOrNum };
    };
  };
  analysisObjectsCdr: {
    root: {
      params: { studyId: StrOrNum };
    };
  };
  analysisObjectsSQLDatasets: {
    root: {
      params: { studyId: StrOrNum };
    };
  };
}

export default {
  root: {
    path: libraryRoute.root,
    resolver: libraryResolver.root,
    id: libraryRouteId.root,
  },
  view: {
    path: libraryRoute.view,
    resolver: libraryResolver.view,
    id: libraryRouteId.view,
  },
  models: {
    root: {
      path: libraryRoute.models.root,
      resolver: libraryResolver.models.root,
      id: libraryRouteId.models.root,
    },
  },
  cdr: {
    root: {
      path: libraryRoute.cdr.root,
      resolver: libraryResolver.cdr.root,
      id: libraryRouteId.cdr.root,
    },
  },
  sqlLab: {
    root: {
      path: libraryRoute.sqlLab.root,
      resolver: libraryResolver.sqlLab.root,
      id: libraryRouteId.sqlLab.root,
    },
  },
  notebook: {
    root: {
      path: libraryRoute.notebook.root,
      resolver: libraryResolver.notebook.root,
      id: libraryRouteId.notebook.root,
    },
  },
  audit: {
    root: {
      path: libraryRoute.audit.root,
      resolver: libraryResolver.audit.root,
      id: libraryRouteId.audit.root,
    },
  },
  analysisObjects: {
    path: studyLibraryRoute.view.analysisObjects,
    resolver: libraryResolver.analysisObjects,
    id: studyLibraryRouteId.analysisObjects,
  },
  analysisPackage: {
    path: studyLibraryRoute.view.analysisPackage,
    resolver: libraryResolver.analysisPackage,
    id: studyLibraryRouteId.analysisPackage,
  },
  deploymentPlans: {
    path: studyLibraryRoute.view.deploymentPlans,
    resolver: libraryResolver.deploymentPlans,
    id: studyLibraryRouteId.deploymentPlans,
  },
  analysisObjectsModels: {
    root: {
      path: studyLibraryRoute.view.analysisObjectsModels.root,
      resolver: libraryResolver.analysisObjectsModels.root,
      id: studyLibraryRouteId.analysisObjectsModels.root,
    },
  },
  analysisObjectsCdr: {
    root: {
      path: studyLibraryRoute.view.analysisObjectsCdr.root,
      resolver: libraryResolver.analysisObjectsCdr.root,
      id: studyLibraryRouteId.analysisObjectsCdr.root,
    },
  },
  analysisObjectsSQLDatasets: {
    root: {
      path: studyLibraryRoute.view.analysisObjectsSQLDatasets.root,
      resolver: libraryResolver.analysisObjectsSQLDatasets.root,
      id: studyLibraryRouteId.analysisObjectsSQLDatasets.root,
    },
  },
};
