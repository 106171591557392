import { ISettings } from '@modules/gpdip/GpdipTypes';
import { useGpdipSaveSettingsMutation, useGpdipSettingsQuery } from '@modules/gpdip/duck/gpdipApi';
import { getErrorMessage } from '@shared/utils/Error';
import { DraggableModal, Form, FormItem, FormLayout, Input, notification, Skeleton, Typography } from '@ui';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { isEmpty } from 'lodash';

const GpdipModalsSettingsContent = ({ data, onClose, t }: GpdipSettingsContentProps) => {
  const [form] = Form.useForm();
  const settingsQuery = useGpdipSettingsQuery();
  const [saveSettingsQuery, { error, isLoading }] = useGpdipSaveSettingsMutation();

  const errorSaveSettingsMessage = getErrorMessage(error);

  if (settingsQuery.isLoading) {
    return <Skeleton active />;
  }

  if (settingsQuery.isError) {
    return (
      <Typography.Paragraph type="danger" ellipsis={{ rows: 2, expandable: true, symbol: 'more' }}>
        {getErrorMessage(settingsQuery.error)}
      </Typography.Paragraph>
    );
  }

  const initValues = (settingsQuery?.data &&
    !isEmpty(settingsQuery?.data) && {
      separator: settingsQuery?.data?.separator,
    }) || {
    separator: ';',
  };

  const onSubmit = async (values: ISettings) => {
    try {
      await saveSettingsQuery(values).unwrap();
      notification.success({ message: t('settings.successMessage') });
      onClose();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <FormLayout
      disabled={isLoading}
      form={form}
      onCancel={onClose}
      onSubmit={onSubmit}
      okText={t('save')}
      initialValues={initValues}
    >
      <FormItem name="separator" label={t('settings.separator')} rules={[{ required: true }]}>
        <Input />
      </FormItem>
      {errorSaveSettingsMessage && (
        <Typography.Paragraph type="danger" ellipsis={{ rows: 2, expandable: true, symbol: 'more' }}>
          {errorSaveSettingsMessage}
        </Typography.Paragraph>
      )}
    </FormLayout>
  );
};

export const GpdipModalsSettings = ({ open, data, onClose }: GpdipSettingsProps) => {
  const { t } = useTranslation(['gpdip']);
  return (
    <DraggableModal open={open} onCancel={onClose} title={t('settings.title')} footer={null} destroyOnClose>
      {open && <GpdipModalsSettingsContent data={data} onClose={onClose} t={t} />}
    </DraggableModal>
  );
};

export interface GpdipSettingsProps {
  open: boolean;
  data: ISettings;
  onClose: () => void;
}

interface GpdipSettingsContentProps extends Pick<GpdipSettingsProps, 'data' | 'onClose'> {
  t: TFunction;
}
