import { modelEditorActions } from '@modules/modelEditor/duck/modelEditorSlice';
import { useDispatch } from 'react-redux';
import { ModelEditorModalsSaveModalPayloadData } from '../ModelEditorModalsTypes';

export const useSaveStage = (nodeId: string, onClose: () => void) => {
  const dispatch = useDispatch();

  const onSubmit = (values: ModelEditorModalsSaveModalPayloadData, sideEffect?: () => void) => {
    dispatch(
      modelEditorActions.saveStage({
        nodeId: nodeId,
        data: values,
      }),
    );
    onClose();
    if (sideEffect) {
      sideEffect();
    }
  };

  return { onSubmit, dispatch };
};
