import { Button, Card, Space, Tabs } from '@components/ui';
import { Ace } from 'ace-builds/ace';
import AceEditor from 'react-ace';
import { CSSObject, Theme } from '@emotion/react';
import Scrollbars from 'react-custom-scrollbars-2';
import { createRef, useEffect, useMemo } from 'react';
import { TFunction } from 'i18next';
import { ExpressionVarsList } from './ExpressionVarsList';
import { FUNCTIONS, OPERATORS } from './ExpressionSystemData';
import CustomHighlightMode from './Highlights';

export const ExpressionBuilder = ({
  id,
  className,
  variables,
  t,
  value,
  readOnly,
  onChange,
}: ExpressionBuilderProps) => {
  const refEditor = createRef<AceEditor>();

  useEffect(() => {
    refEditor.current?.editor.focus();
  }, [refEditor]);

  useEffect(() => {
    const customMode = new CustomHighlightMode(variables) as Ace.SyntaxMode;
    refEditor.current?.editor.getSession().setMode(customMode);
  }, [variables, refEditor]);

  const sourceVars = useMemo(
    () =>
      variables.map((variable) => ({
        name: variable,
        rawData: {
          name: variable,
        },
      })),
    [variables],
  );

  const insertTextAtCursor = (text: string) => refEditor.current?.editor.insert(text);

  const addVariable = (data: Field) => insertTextAtCursor(data.name);

  const addFunction = ({ value }: { value: string }) => {
    insertTextAtCursor(value);
    // move cursor one symbol back to be inside parenthesis
    const point = refEditor.current?.editor.getCursorPosition();
    if (point) {
      refEditor.current?.editor.moveCursorTo(point.row, point.column - 1);
    }
  };

  const onChangeValue = () => onChange(refEditor.current?.editor.getValue() ?? '');

  return (
    <div css={cssBody} className={className}>
      <AceEditor
        readOnly={readOnly}
        ref={refEditor}
        css={cssEditorBody}
        height="100px"
        width="100%"
        mode="text"
        fontSize={18}
        wrapEnabled={true}
        focus={!readOnly}
        name={id ?? 'code_editor'}
        defaultValue={value ?? ''}
        onChange={onChangeValue}
      />
      {!readOnly && (
        <Scrollbars css={cssOperationsBody} autoHide={false} autoHeight autoHeightMin={50}>
          <Space css={cssTools}>
            {OPERATORS.map((data) => (
              <Button
                size="middle"
                type="default"
                icon={data.value}
                onClick={() => {
                  insertTextAtCursor(data.value);
                }}
              />
            ))}
          </Space>
        </Scrollbars>
      )}
      <div css={cssPanelBody}>
        <Card css={cssCard} size="small" full bordered={false}>
          <Tabs
            defaultActiveKey="1"
            size="small"
            items={[
              {
                label: t('transform.variables'),
                key: 'variables',
                children: (
                  <ExpressionVarsList
                    disabled={readOnly}
                    dataSource={sourceVars}
                    onClick={addVariable}
                    searchPlaceholder={t('transform.search.variables')}
                    t={t}
                  />
                ),
              },
            ]}
          />
        </Card>
        <Card css={cssCard} size="small" full bordered={false}>
          <Tabs
            defaultActiveKey="1"
            size="small"
            items={[
              {
                label: t('transform.functions'),
                key: 'functions',
                children: (
                  <ExpressionVarsList
                    disabled={readOnly}
                    dataSource={FUNCTIONS}
                    onClick={addFunction}
                    searchPlaceholder={t('transform.search.functions')}
                    t={t}
                  />
                ),
              },
            ]}
          />
        </Card>
      </div>
    </div>
  );
};

const cssBody = (theme: Theme): CSSObject => ({
  width: '100%',
  border: `1px solid ${theme.colorBorder}`,
  borderRadius: theme.borderRadiusSM,
});

const cssTools = (theme: Theme): CSSObject => ({
  padding: theme.paddingXS,
});

const cssEditorBody = (theme: Theme): CSSObject => ({
  borderBottom: `1px solid ${theme.colorBorder}`,
  backgroundColor: 'transparent',
});

const cssOperationsBody = (theme: Theme): CSSObject => ({
  borderBottom: `1px solid ${theme.colorBorder}`,
});

const cssPanelBody = (): CSSObject => ({
  display: 'flex',
});

const cssCard = (theme: Theme): CSSObject => ({
  '&:last-child': {
    borderLeft: `1px solid ${theme.colorBorder}`,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  '&&': { boxShadow: 'none' },
  '& >.ant-card-body': {
    padding: `0 ${theme.paddingXS}px ${theme.paddingXS}px`,
  },
});

interface ExpressionBuilderProps {
  id?: string;
  className?: string;
  onChange: (value: string) => void;
  t: TFunction;
  variables: string[];
  value?: string;
  readOnly?: boolean;
}

interface Field {
  name: string;
}
