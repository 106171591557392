import { DraggableModal, Form, FormItem, FormLayout, Select } from '@ui';
import { listToOptions } from '@shared/utils/Form';
import { selectModelEditorReadOnly } from '@modules/modelEditor/duck/modelEditorSelectors';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { Row, Col, Alert, Space } from 'antd';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { useSaveStage } from './modelEditorModalsHooks';
import { getNumericValues, getValuesWithSpecialCharacters } from '../../components/builder/Utils';
import { useSourceTableInfoAnalyzer } from '../../duck/modelEditorSourceTableInfoAnalyzer';
import { ColumnType, ModelEditorNodeResult } from '../../ModelEditorTypes';

const ModelEditorModalsResultSettingsContent = ({ data, onClose, t }: ModelEditorModalsResultSettingsContentProps) => {
  const [form] = Form.useForm();

  const readOnly = useSelector(selectModelEditorReadOnly);
  const { onSubmit } = useSaveStage(data.nodeId, onClose);
  const { loading, sourceColumns } = useSourceTableInfoAnalyzer(data.nodeId);

  const columns = sourceColumns[0]?.map((item) => item.name) || [];
  if (!columns.includes('DS_ID')) {
    columns.unshift('DS_ID');
  }
  if (!columns.includes('DS_UPDATED_AT')) {
    columns.push('DS_UPDATED_AT');
  }
  const options = listToOptions(columns);

  const nodeErrors: string[] = [];

  if (!loading) {
    const columnsWithSpecialCharacters = getValuesWithSpecialCharacters(columns);
    if (!isEmpty(columnsWithSpecialCharacters)) {
      nodeErrors.push(t('result.errors.specialCharactersDetailed', { list: columnsWithSpecialCharacters.join(', ') }));
    }

    const columnsNumeric = getNumericValues(columns);
    if (!isEmpty(columnsNumeric)) {
      nodeErrors.push(t('result.errors.numericColumnNamesDetailed', { list: columnsNumeric.join(', ') }));
    }
  }

  const initValues = data.initData || {};

  const onSave = (values: Parameters<typeof onSubmit>[0]) => {
    const updatedValues = {
      ...values,
      columns: sourceColumns[0] as ColumnType[],
    };
    onSubmit(updatedValues);
  };

  return (
    <FormLayout
      form={form}
      readOnly={readOnly}
      layout="vertical"
      onCancel={onClose}
      onSubmit={onSave}
      okText={t('save')}
      initialValues={initValues}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      submitIsDisabled={!!nodeErrors.length || loading}
    >
      <Row gutter={8}>
        <Col span={12}>
          <FormItem
            name="primaryKeys"
            label={t('result.prKeys')}
            rules={[
              {
                validator: (rule, value: string[] = [], callback) => {
                  if (!loading && value.filter((v) => !columns.some((column) => column === v)).length) {
                    return Promise.reject(new Error(t('result.errors.wrongColumns')));
                  }
                  if (!value.length) {
                    return Promise.reject(new Error(t('result.errors.missingPrKeys')));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Select mode="multiple" placeholder={t('result.prKeys')} options={options} loading={loading} />
          </FormItem>
        </Col>
      </Row>
      {!!nodeErrors.length && (
        <FormItem wrapperCol={{ span: 24 }}>
          <Alert message={<Space direction="vertical" children={nodeErrors} />} type="error" />
        </FormItem>
      )}
    </FormLayout>
  );
};

export const ModelEditorModalsResultSettings = ({ open, data, onClose }: ModelEditorModalsResultSettingsProps) => {
  const { t } = useTranslation(['model']);

  return (
    <DraggableModal open={open} onCancel={onClose} title={t('result.title')} footer={null} destroyOnClose>
      {open && <ModelEditorModalsResultSettingsContent data={data} onClose={onClose} t={t} />}
    </DraggableModal>
  );
};

interface ModelEditorModalsResultSettingsContentProps
  extends Pick<ModelEditorModalsResultSettingsProps, 'data' | 'onClose'> {
  t: TFunction;
}

export interface ModelEditorModalsResultSettingsProps {
  open: boolean;
  data: { nodeId: string; initData?: ModelEditorNodeResult };
  onClose: () => void;
}
