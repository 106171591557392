import { DraggableModal, Form, FormLayout, notification, FormItem } from '@ui';
import { getDefaultInitialValueGpdip, GpdipForm, prepareFieldsGpdip, prepareUpdateDataExport } from '@components/form';
import { useGpdipSyncMutation } from '@modules/gpdip/duck/gpdipApi';
import { QueryErrorType } from '@shared/utils/Error';
import { IGpdip } from '@modules/gpdip/GpdipTypes';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

const GpdipModalsSyncSettingsContent = ({ data, onClose, t }: GpdipModalsSyncSettingsContentProps) => {
  const [form] = Form.useForm();
  const [gpdipSync, { error }] = useGpdipSyncMutation();

  const onSubmit = async (values: any) => {
    try {
      const preparedValues = {
        id: data.id,
        type: data.type,
        export: values.export ? prepareUpdateDataExport({ values: values.export }) : data.export!,
      };

      await gpdipSync(preparedValues).unwrap();

      onClose();
    } catch (err) {
      console.error(err);
      notification.error({ message: t('errors.sync'), description: t('errors.syncDetails', { name: data.name }) });
    }
  };

  const initValues = (data && {
    export: data.export ? prepareFieldsGpdip(data.export!) : getDefaultInitialValueGpdip(),
  }) || {
    export: getDefaultInitialValueGpdip(),
  };

  return (
    <FormLayout
      form={form}
      onCancel={onClose}
      onSubmit={onSubmit}
      okText={t('save')}
      initialValues={initValues}
      errors={error ? [(error as QueryErrorType).data.userMsg!] : undefined}
    >
      <FormItem wrapperCol={{ span: 24 }}>
        <GpdipForm />
      </FormItem>
    </FormLayout>
  );
};

export const GpdipModalsSyncSettings = ({ open, data, onClose }: GpdipModalsSyncSettingsProps) => {
  const { t } = useTranslation(['gpdip']);
  return (
    <DraggableModal
      open={open}
      onCancel={onClose}
      title={t('syncSettings.title', { name: data?.name })}
      footer={null}
      destroyOnClose
    >
      {open && <GpdipModalsSyncSettingsContent data={data} onClose={onClose} t={t} />}
    </DraggableModal>
  );
};

export interface GpdipModalsSyncSettingsProps {
  open: boolean;
  data: IGpdip;
  onClose: () => void;
}

interface GpdipModalsSyncSettingsContentProps extends Pick<GpdipModalsSyncSettingsProps, 'data' | 'onClose'> {
  t: TFunction;
}
