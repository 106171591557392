import { GPDIP_STATUS } from '@modules/gpdip/GpdipTypes';

export enum GPDIP_TAG_DESCRIPTION {
  'LIST' = 'GPDIP_LIST',
  'SETTINGS' = 'GPDIP_SETTINGS',
  'STATUSES' = 'GPDIP_STATUSES',
  'ID' = 'GPDIP_ID',
}

export const STATUSES_TIMEOUT = 5000;

export const FINISHED_STATUSES = [GPDIP_STATUS.FINISHED, GPDIP_STATUS.FAILED];
