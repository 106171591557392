import { analysisObjectCDRActions } from '@modules/library/analysisObjects/cdr/duck/analysisObjectCDRSlice';
import { selectAnalysisObjectCDRModals } from '@modules/library/analysisObjects/cdr/duck/analysisObjectCDRSelectors';
import { EAnalysisObjectCDRModalsType } from '@modules/library/analysisObjects/cdr/modals/AnalysisObjectCDRModalsConstants';
import { useDispatch, useSelector } from 'react-redux';
import { AnalysisObjectCdrModalsCopyCdr } from './components';
import { IAnalysisObjectCdrModalsCopyCdrProps } from './components/copy/AnalysisObjectCdrModalsCopyCdr';

export const AnalysisObjectCDRModalsController = () => {
  const dispatch = useDispatch();
  const { data, selectedModal } = useSelector(selectAnalysisObjectCDRModals);

  const closeModal = () => {
    dispatch(analysisObjectCDRActions.dropModal());
  };

  return (
    <AnalysisObjectCdrModalsCopyCdr
      open={selectedModal === EAnalysisObjectCDRModalsType.copyCDR}
      data={data as IAnalysisObjectCdrModalsCopyCdrProps['data']}
      onClose={closeModal}
    />
  );
};
