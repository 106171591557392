import { FallbackRender } from '@app/components';
import { useEffect, useRef, useState } from 'react';
import { Modal, ModalProps } from 'antd';
import type { DraggableData, DraggableEvent } from 'react-draggable';
import Draggable from 'react-draggable';

import { ErrorBoundary } from 'react-error-boundary';

export const DraggableModal = ({
  title,
  isMinimized,
  maskClosable = true,
  children,
  ...props
}: DraggableModalProps) => {
  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const draggableRef = useRef<HTMLDivElement>(null);

  const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggableRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  const onDrag = (_event: DraggableEvent, uiData: DraggableData) => {
    setPosition({ x: uiData.x, y: uiData.y });
  };

  useEffect(() => {
    if (!isMinimized) {
      setPosition({ x: 0, y: 0 });
    }
  }, [isMinimized]);

  return (
    <Modal
      title={
        <div
          style={{
            width: '100%',
            cursor: 'move',
          }}
          onMouseOver={() => {
            if (disabled) {
              setDisabled(false);
            }
          }}
          onMouseOut={() => {
            setDisabled(true);
          }}
        >
          {title}
        </div>
      }
      modalRender={(modal) => (
        <Draggable
          disabled={disabled}
          bounds={bounds}
          onStart={(event, uiData) => onStart(event, uiData)}
          nodeRef={draggableRef}
          position={isMinimized ? position : { x: 0, y: 0 }}
          onDrag={isMinimized ? onDrag : undefined}
        >
          <div ref={draggableRef}>{modal}</div>
        </Draggable>
      )}
      mask={!isMinimized}
      maskClosable={maskClosable}
      {...props}
    >
      <ErrorBoundary FallbackComponent={FallbackRender}>{children}</ErrorBoundary>
    </Modal>
  );
};

interface DraggableModalProps extends Exclude<ModalProps, 'modalRender'> {
  isMinimized?: boolean;
  maskClosable?: boolean;
}
