import { NotificationInstance } from 'antd/es/notification/interface';
import { createContext, useContext } from 'react';

export const AppContext = createContext({
  notificationApi: {
    success: () => {},
    error: () => {},
    info: () => {},
    warning: () => {},
    open: () => {},
    destroy: () => {},
  } as NotificationInstance,
});

export const useAppContext = () => {
  const appContext = useContext(AppContext);

  return appContext;
};
