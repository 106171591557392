import { appApi } from '@config/appApi';
import { TagDescription } from '@reduxjs/toolkit/query/react';
import { AUTH_TAG_DESCRIPTION } from './authConstants';
import { IAuthStudyRolesResponse, IAuthStudyRolesSetActiveQuery } from '../AuthTypes';

export const AuthApiRoutes = {
  studyRoles: `/api/user_roles/active`,
};

export const AuthInvalidations: {
  STUDY_ROLES: (studyId: number) => TagDescription<AUTH_TAG_DESCRIPTION.STUDY_ROLES>;
} = {
  STUDY_ROLES: (studyId: number) => ({ type: AUTH_TAG_DESCRIPTION.STUDY_ROLES, id: `STUDY_ROLES_${studyId}` }),
};

export const AuthApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    studyRoles: builder.query<IAuthStudyRolesResponse, number>({
      providesTags: (request, error, studyId) => [AuthInvalidations.STUDY_ROLES(studyId)],
      query: (studyId) => ({
        url: AuthApiRoutes.studyRoles,
      }),
    }),
    setActiveStudyRole: builder.mutation<void, IAuthStudyRolesSetActiveQuery>({
      invalidatesTags: (request, error, { study_id }) => [AuthInvalidations.STUDY_ROLES(study_id)],
      query: ({ study_id, ...data }) => ({
        data,
        method: 'POST',
        url: AuthApiRoutes.studyRoles,
      }),
    }),
  }),
});

export const { useStudyRolesQuery, useSetActiveStudyRoleMutation } = AuthApi;
