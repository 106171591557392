import { Button, ProgressCircleWithTitle, Space, Typography } from '@ui';
import { CSSObject } from '@emotion/react';
import { TFunction } from 'i18next';

export const UploadingStep = ({
  t,
  progress,
  uploadFileResult,
  discoveryJobResult,
  processJobResult,
  onCancel,
  progressUpload,
  btnText,
  restartDiscoveryJob,
  restartProcessJob,
}: StepTwoProps) => {
  const isError = uploadFileResult?.isError || discoveryJobResult?.isError || processJobResult?.isError;
  const uploadResultMessage = [uploadFileResult, discoveryJobResult, processJobResult]
    .map(
      (result) =>
        result?.isError && result.error && ('data' in result.error ? result.error.data.userMsg : result.error?.message),
    )
    .filter(Boolean)
    .join(';');

  return (
    <div css={cssContainer}>
      <div css={cssContent}>
        {uploadFileResult && discoveryJobResult && (
          <Space style={{ justifyContent: 'space-evenly' }} full align="center">
            <ProgressCircleWithTitle
              title={t('Upload file')}
              status={uploadFileResult?.isError && 'exception'}
              percent={progressUpload?.progress}
              estimated={progressUpload?.estimated}
            />
            <ProgressCircleWithTitle
              title={t('uploadModal.discovering')}
              percent={progress?.progress}
              status={discoveryJobResult?.isError && 'exception'}
              estimated={progress?.estimated}
            />
          </Space>
        )}
        {processJobResult && (
          <ProgressCircleWithTitle
            title={t('uploadModal.processing')}
            status={processJobResult?.isError && 'exception'}
            percent={progress?.progress}
            estimated={progress?.estimated}
          />
        )}
        {isError && (
          <Typography.Text type="danger" css={cssTestMsg}>
            {uploadResultMessage}
          </Typography.Text>
        )}
        {discoveryJobResult?.isError && (
          <Space align="center" justify="center">
            <Button onClick={restartDiscoveryJob}>{t('retry')}</Button>
          </Space>
        )}
        {processJobResult?.isError && (
          <Space align="center" justify="center">
            <Button onClick={restartProcessJob}>{t('retry')}</Button>
          </Space>
        )}
      </div>

      <Button css={cssActionBtn} onClick={onCancel}>
        {btnText ?? t('cancel')}
      </Button>
    </div>
  );
};

interface StepTwoProps {
  t: TFunction;
  progress: Record<string, any> | null;
  uploadFileResult?: Record<string, any>;
  discoveryJobResult?: Record<string, any>;
  processJobResult?: Record<string, any>;
  onCancel: () => void;
  progressUpload?: Record<string, any> | null;
  btnText?: string;
  restartDiscoveryJob?: () => void;
  restartProcessJob?: () => void;
}

const cssTestMsg = (): CSSObject => ({
  textAlign: 'center',
  margin: 16,
  maxHeight: 300,
  overflow: 'auto',
});

const cssContainer = (): CSSObject => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
});

const cssContent = (): CSSObject => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '0 24px',
});

const cssActionBtn = (): CSSObject => ({
  alignSelf: 'flex-end',
  marginTop: 'auto',
});
