import { dateToString } from '@shared/utils/Date';
import { Typography } from '@ui';

export const AuditLogsDiffTitleContent = ({ text, date, username }: IAuditLogDiffTitleContentProps) => (
  <Typography.Text>
    {username ? `${username}: ` : ''}
    {text}
    {date && <Typography.Text type="secondary"> ({dateToString(date)})</Typography.Text>}
  </Typography.Text>
);

interface IAuditLogDiffTitleContentProps {
  text: string;
  username?: string;
  date?: number;
}
