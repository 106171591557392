import { Space, Typography } from '@ui';
import { DataTable } from '@components';
import { TFunction } from 'i18next';
import { useMemo } from 'react';
import { CSSObject } from '@emotion/react';

export const PreviewTabData = ({ data, t }: PreviewTabDataProps) => {
  const dataColumns = useMemo(() => (!!data.length ? Object.keys(data[0]) : []), [data]);
  const columns = useMemo(() => dataColumns.map((col) => ({ key: col, name: col })), [dataColumns]);

  return (
    <Space direction="vertical" full css={cssPreviewContainer}>
      <Typography.Text strong children={t('uploadRT.preview.header')} />
      {!!data?.length ? (
        <DataTable columns={columns} rows={data} rowHeight={25} />
      ) : (
        <Typography.Title type="secondary" level={4} children={t('uploadRT.preview.noData')} />
      )}
    </Space>
  );
};

const cssPreviewContainer = (): CSSObject => ({
  marginBottom: 8,
  height: 200,
});

interface PreviewTabDataProps {
  data: Record<string, string | number>[];
  t: TFunction;
}
