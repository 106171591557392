import { notification } from 'antd';
import { ReactNode } from 'react';
import { AppContext } from './AppContext';

export const AppProvider = ({ children }: AppProviderProps) => {
  const [api, contextHolder] = notification.useNotification();

  return (
    <AppContext.Provider value={{ notificationApi: api }}>
      {contextHolder}
      {children}
    </AppContext.Provider>
  );
};

interface AppProviderProps {
  children: ReactNode;
}
