import { Alert, DraggableModal, Form, FormItem, FormLayout, Select } from '@ui';
import { selectViewerDataFormats } from '@modules/viewer/duck/viewerSelectors';
import { viewerActions } from '@modules/viewer/duck/viewerSlice';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';

const dateTimeFormatOptions = [
  { label: 'Native', value: '' },
  { label: 'YYYY-MM-DD HH:mm:ss', value: 'YYYY-MM-DD HH:mm:ss' },
];

const ViewerModalsDataFormatsContent = ({ onClose, t }: ViewerModalsDataFormatsContentProps) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const initValues = useSelector(selectViewerDataFormats);

  const onSubmit = async (values: ViewerDataFormatsFormValues) => {
    dispatch(viewerActions.setDataFormats(values));
    onClose();
  };

  return (
    <FormLayout
      form={form}
      onCancel={onClose}
      onSubmit={onSubmit}
      initialValues={{
        dateTimeFormat: initValues?.dateTimeFormat || dateTimeFormatOptions[0].value,
      }}
    >
      <FormItem wrapperCol={{ span: 24 }}>
        <Alert type="info" message={t('dataFormats.alert')} />
      </FormItem>
      <FormItem name="dateTimeFormat" label={t('dataFormats.dateTimeFormat')}>
        <Select options={dateTimeFormatOptions} placeholder={t('select')} />
      </FormItem>
    </FormLayout>
  );
};

export const ViewerModalsDataFormats = ({ open, onClose }: ViewerModalsDataFormatsProps) => {
  const { t } = useTranslation(['viewer']);

  return (
    <DraggableModal open={open} onCancel={onClose} title={t('dataFormats.title')} footer={null} destroyOnClose>
      {open && <ViewerModalsDataFormatsContent onClose={onClose} t={t} />}
    </DraggableModal>
  );
};

export interface ViewerModalsDataFormatsProps {
  open: boolean;
  onClose: () => void;
}

interface ViewerModalsDataFormatsContentProps extends Pick<ViewerModalsDataFormatsProps, 'onClose'> {
  t: TFunction;
}

export interface ViewerDataFormatsFormValues {
  dateTimeFormat: string;
}
