import { TLibraryModelModalsPushModalPayload, TLibraryModelModalsState } from '@modules/library/model/modals';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: LibraryModelStore = {
  modals: { selectedModal: null },
};

export const libraryModelSlice = createSlice({
  name: 'libraryModel',
  initialState,
  reducers: {
    pushModal: (state, action: PayloadAction<TLibraryModelModalsPushModalPayload>) => {
      const { type, data } = action.payload;
      state.modals = { selectedModal: type, data };
    },
    dropModal: (state, action: PayloadAction<void>) => {
      state.modals = initialState.modals;
    },
    reset: (state) => {
      return initialState;
    },
  },
});

export const libraryModelActions = libraryModelSlice.actions;

export const libraryModelReducer = libraryModelSlice.reducer;

interface LibraryModelStore {
  modals: TLibraryModelModalsState;
}
