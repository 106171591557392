import { useTablePaginationState } from '@components/ui/table/tableHooks';
import { Button, notification } from '@ui';
import { initialPage } from '@shared/utils/common';
import { PageTemplateSimple } from '@components';
import { selectGlobalStudy } from '@app/duck/appSelectors';
import { QueryErrorType } from '@shared/utils/Error';
import { useSupportedEnvsQuery } from '@app/duck/appApi';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IAnalysisObjectSQLDatasetListResponse } from '../AnalysisObjectSQLDatasetTypes';
import { AnalysisObjectSQLDatasetList } from '../components';
import {
  useAnalysisObjectSQLDatasetListQuery,
  useDeleteAnalysisObjectSQLDatasetMutation,
} from '../duck/analysisObjectSQLDatasetApi';
import { analysisObjectSQLDatasetActions } from '../duck/analysisObjectSQLDatasetSlice';
import { EAnalysisObjectSQLDatasetModalsType } from '../modals/AnalysisObjectSQLDatasetModalsConstants';
import { AnalysisObjectSQLDatasetModalsController } from '../modals/AnalysisObjectSQLDatasetModalsController';

export const AnalysisObjectSQLDatasetRoot = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['analysisObjects']);
  const supportedEnvsQuery = useSupportedEnvsQuery();
  const globalStudy = useSelector(selectGlobalStudy);
  const { paginationState, getPagination, onTableChange, preparedFilters, preparedSorter } =
    useTablePaginationState<IAnalysisObjectSQLDatasetListResponse['items'][0]>(initialPage);

  const sqlDatasetQuery = useAnalysisObjectSQLDatasetListQuery({
    detailed: '0',
    page: paginationState.current - 1,
    page_size: paginationState.pageSize,
    ...preparedFilters,
    ...preparedSorter,
  });

  const [deleteSQLDataset, deleteSQLDatasetQuery] = useDeleteAnalysisObjectSQLDatasetMutation();

  const pagination = getPagination(sqlDatasetQuery.data?.totalItems);

  const copySQLDatasetAOModal = () =>
    dispatch(analysisObjectSQLDatasetActions.pushModal({ type: EAnalysisObjectSQLDatasetModalsType.importSQLDataset }));

  const onDeleteSQLDataset = async (value: { id: number; name: string }) => {
    const { id, name } = value;
    try {
      await deleteSQLDataset(id).unwrap();
      notification.success({ message: t('sqlDatasetAO.modals.deleteSuccess', { name }) });
    } catch (e) {
      notification.error({ message: (e as QueryErrorType).data.userMsg });
    }
  };

  return (
    <PageTemplateSimple
      content={{
        isLoading: sqlDatasetQuery.isLoading && !sqlDatasetQuery.data,
        errorText: t('sqlDatasetAO.loadingListError'),
        error: sqlDatasetQuery.error,
      }}
      title={{
        children: t('sqlDatasetAO.rootPageName'),
        pageTitle: t('sqlDatasetAO.pageTitle'),
        extra: <Button children={t('import')} onClick={copySQLDatasetAOModal} />,
      }}
    >
      {sqlDatasetQuery.data && (
        <AnalysisObjectSQLDatasetList
          data={sqlDatasetQuery.data}
          studyId={globalStudy?.id!}
          onChange={onTableChange}
          pagination={pagination}
          loading={sqlDatasetQuery.isFetching}
          onDeleteSQLDataset={onDeleteSQLDataset}
          loadingDelete={deleteSQLDatasetQuery.isLoading}
          supportedEnvs={supportedEnvsQuery.data || {}}
          t={t}
        />
      )}
      <AnalysisObjectSQLDatasetModalsController />
    </PageTemplateSimple>
  );
};
