import { appApi } from '@config/appApi';
import { GpdipSynchronization, IGpdip, ISettings, RunExport, RunStatus } from '@modules/gpdip/GpdipTypes';
import { DatasetInvalidations } from '@modules/dataset/duck/datasetApi';
import { ModelInvalidations } from '@modules/model/duck/modelApi';
import { GpdipInvalidations } from '@modules/gpdip/duck/gpdipTags';

export const GpdipApiRoutes = {
  list: 'api/external-sync/data',
  settings: 'api/external-sync/settings',
  run: 'api/external-sync/data/run',
  status: 'api/external-sync/data/run/status',
};

export const GpdipApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    gpdipList: builder.query<IGpdip[], void>({
      providesTags: [GpdipInvalidations.LIST],
      query: () => ({
        url: GpdipApiRoutes.list,
      }),
    }),
    gpdipSync: builder.mutation<void, GpdipSynchronization>({
      invalidatesTags: (request, error, data) =>
        data.id
          ? [
              GpdipInvalidations.LIST,
              GpdipInvalidations.ID(data.id as number),
              DatasetInvalidations.LIST,
              ModelInvalidations.LIST,
            ]
          : [GpdipInvalidations.LIST, DatasetInvalidations.LIST, ModelInvalidations.LIST],
      query: (data) => ({
        data,
        method: 'PUT',
        url: GpdipApiRoutes.list,
      }),
    }),
    gpdipSettings: builder.query<ISettings, void>({
      providesTags: [GpdipInvalidations.SETTINGS],
      query: () => ({
        url: GpdipApiRoutes.settings,
      }),
    }),
    gpdipSaveSettings: builder.mutation<void, ISettings>({
      invalidatesTags: [GpdipInvalidations.SETTINGS],
      query: (data) => ({
        data,
        method: 'POST',
        url: GpdipApiRoutes.settings,
      }),
    }),
    gpdipRun: builder.mutation<void, RunExport>({
      query: (data) => ({
        data,
        method: 'POST',
        url: GpdipApiRoutes.run,
      }),
    }),
    gpdipRunStatus: builder.query<RunStatus[], void>({
      providesTags: [GpdipInvalidations.STATUSES],
      query: () => ({
        url: GpdipApiRoutes.status,
      }),
    }),
  }),
});

export const {
  useGpdipListQuery,
  useGpdipSyncMutation,
  useGpdipSettingsQuery,
  useGpdipSaveSettingsMutation,
  useLazyGpdipRunStatusQuery,
  useGpdipRunMutation,
} = GpdipApi;
