import { QueryError } from '@components';
import { Button } from '@ui';
import { CSSObject } from '@emotion/react';

export const FallbackRender = ({ resetErrorBoundary }: any) => {
  return (
    <div css={cssContainer}>
      <QueryError
        error={{ status: 'warning', data: { userMsg: 'There are some problems with your operation.' } }}
        title="Sorry, an unexpected error has occurred."
        extra={
          <Button type="primary" key="resetErrorBoundary" onClick={resetErrorBoundary}>
            Try again
          </Button>
        }
      />
    </div>
  );
};

const cssContainer = (): CSSObject => ({
  alignContent: 'center',
  height: '100%',
  width: '100%',
});
