import { selectJobModals } from '@modules/job/duck/jobSelectors';
import { JobModalProvider } from '@modules/job/modals/components/upload/JobModalProvider';
import { JobModalsUploadJobProps, JobModalsUploadJobRT } from '@modules/job/modals/components';
import { jobActions } from '@modules/job/duck/jobSlice';
import { JobModalsType } from '@modules/job/modals/JobModalsConstants';
import { useDispatch, useSelector } from 'react-redux';

export const JobModalsControllerApp = () => {
  const dispatch = useDispatch();
  const { data, selectedModal, isMinimized } = useSelector(selectJobModals);

  const closeModal = () => {
    dispatch(jobActions.dropModal());
  };

  const handleToggleMinimize = () => dispatch(jobActions.minimizeModal(!isMinimized));

  return (
    <JobModalProvider>
      <JobModalsUploadJobRT
        open={selectedModal === JobModalsType.uploadJobRT}
        data={data as JobModalsUploadJobProps['data']}
        onClose={closeModal}
        isMinimized={isMinimized}
        handleToggleMinimize={handleToggleMinimize}
      />
    </JobModalProvider>
  );
};
