import { StageButton } from '@modules/modelEditor/components/builder/toolbar/StageButton';
import { Space } from '@ui';
import { ModelEditorNodeType } from '@modules/modelEditor/ModelEditorTypes';

export const Toolbar = () => {
  return (
    <Space wrap full>
      <StageButton nodeType={ModelEditorNodeType.sql} />
      <StageButton nodeType={ModelEditorNodeType.join} />
      <StageButton nodeType={ModelEditorNodeType.union} />
      <StageButton nodeType={ModelEditorNodeType.transform} />
      <StageButton nodeType={ModelEditorNodeType.pivot} />
      <StageButton nodeType={ModelEditorNodeType.unpivot} />
      <StageButton nodeType={ModelEditorNodeType.groupBy} />
      <StageButton nodeType={ModelEditorNodeType.result} />
    </Space>
  );
};
