import { selectLibrarySqlLabModals } from '@modules/library/sqlLab/duck/librarySqlLabSelectors';
import { librarySqlLabActions } from '@modules/library/sqlLab/duck/librarySqlLabSlice';
import { ELibrarySqlLabModalsType } from '@modules/library/sqlLab/modals/LibrarySqlLabModalsConstants';
import { useDispatch, useSelector } from 'react-redux';
import { LibrarySqlLabModalsCopySqlLab, ILibrarySqlLabModalsCopySqlLabProps } from './components';

export const LibrarySqlLabModalsController = () => {
  const dispatch = useDispatch();
  const { data, selectedModal } = useSelector(selectLibrarySqlLabModals);

  const closeModal = () => {
    dispatch(librarySqlLabActions.dropModal());
  };

  return (
    <LibrarySqlLabModalsCopySqlLab
      open={selectedModal === ELibrarySqlLabModalsType.copySqlLab}
      data={data as ILibrarySqlLabModalsCopySqlLabProps['data']}
      onClose={closeModal}
    />
  );
};
