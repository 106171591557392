import { CDRExtraTypes, TDatastageEvent, TRawEvent } from './hooks/messagesTypes';
import { isError } from './hooks/messages';

export const isDatastageEvent = (extra: TRawEvent) => {
  try {
    return extra.event && extra.event.toString().startsWith('datastage:');
  } catch (e) {
    return false;
  }
};

export const isAnotherAppEvent = (extra: TRawEvent) => {
  try {
    return (
      ('type' in extra && extra.type === CDRExtraTypes.ERROR_ALERT) ||
      'report_export_id' in extra ||
      'reportName' in extra ||
      'link' in extra ||
      'query_id' in extra ||
      ('source' in extra && extra.source === 'schedulers') ||
      'comment' in extra
    );
  } catch (e) {
    return false;
  }
};

export const shouldAutoclick = (extra: TRawEvent) => {
  try {
    return (
      isDatastageEvent(extra) &&
      (isError(extra as TDatastageEvent) || (extra as TDatastageEvent).event === 'datastage:table-export-event')
    );
  } catch (e) {
    return false;
  }
};
