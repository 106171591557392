import { appApi } from '@config/appApi';
import { TRawEvent } from './hooks/messagesTypes';

export const NotificationsApiRoutes = {
  notifications: '/api/notifications',
};

export const NotificationsApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    notifications: builder.query<TRawNotification[], void>({
      keepUnusedDataFor: 0,
      query: () => ({ url: NotificationsApiRoutes.notifications }),
    }),
    archive: builder.mutation<void, number[]>({
      query: (notificationIds) => ({
        data: {
          notification_ids: notificationIds,
        },
        method: 'DELETE',
        url: NotificationsApiRoutes.notifications,
      }),
    }),
    read: builder.mutation<void, number[]>({
      query: (notificationIds) => ({
        data: {
          notification_ids: notificationIds,
        },
        method: 'POST',
        url: NotificationsApiRoutes.notifications,
      }),
    }),
    unread: builder.mutation<void, number[]>({
      query: (notificationIds) => ({
        data: {
          notification_ids: notificationIds,
          is_read: false,
        },
        method: 'POST',
        url: NotificationsApiRoutes.notifications,
      }),
    }),
  }),
});

export const { useLazyNotificationsQuery, useArchiveMutation, useReadMutation, useUnreadMutation } = NotificationsApi;

export type TNotificationId = string;

export type TRawNotification = {
  notificationId: TNotificationId;
  actorId?: string;
  actorName?: string;
  creationDateTs: string;
  extra: string;
  isArchived: boolean;
  isRead: boolean;
  objectId: string;
  tenantId: string;
};

export type TNotification = Omit<TRawNotification, 'extra'> & {
  extra: TRawEvent;
};
