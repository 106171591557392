import { Button, Dropdown, notification, Popconfirm } from '@ui';
import { useSaveLibraryMutation } from '@modules/library/root/duck/libraryApi';
import { Library } from '@modules/library/root/LibraryTypes';
import { LibraryStatus } from '@modules/library/root/duck/libraryConstants';
import { useState } from 'react';
import { CheckCircleOutlined, CloudUploadOutlined, ContainerOutlined, MoreOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { CSSObject, Theme } from '@emotion/react';
import { TFunction } from 'i18next';

const PopconfirmWrapperMenuItem = ({
  title,
  description,
  setOpenDropdown,
  libraryId,
  status,
}: IPopconfirmWrapperMenuIemProps) => {
  const { t } = useTranslation(['shared', 'libraryRoot']);
  const [openPopconfirm, setOpenPopconfirm] = useState(false);

  const [saveLibrary, { isError, error }] = useSaveLibraryMutation();

  const handlePopconfirmConfirm = async () => {
    try {
      setOpenPopconfirm(false);
      setOpenDropdown(false);
      await saveLibrary({ id: libraryId, status });
      notification.success({
        message: t('libraryRoot.root.statusChangedSuccess'),
      });
    } catch (e) {
      console.error(e);
      notification.error({
        message: t('libraryRoot.root.statusChangedError'),
      });
    }
  };

  const handlePopconfirmCancel = () => {
    setOpenPopconfirm(false);
  };

  return (
    <Popconfirm
      title={title}
      description={description}
      okText={t('yes')}
      cancelText={t('no')}
      onConfirm={handlePopconfirmConfirm}
      onCancel={handlePopconfirmCancel}
      onOpenChange={(open) => setOpenPopconfirm(open)}
      open={openPopconfirm}
      zIndex={1100}
    >
      {title}
    </Popconfirm>
  );
};

export const LibraryActionMenu = ({ record, t }: ILibraryActionMenuProps) => {
  const [openDropdown, setOpenDropdown] = useState(false);

  const items = [
    {
      key: 'moveToDev',
      label: (
        <PopconfirmWrapperMenuItem
          title={t('root.table.actionMenu.moveToDev')}
          description={t('root.table.actionMenu.confirmation.moveToDevDescription', { name: record.name })}
          status={LibraryStatus.Development}
          libraryId={record.id}
          setOpenDropdown={setOpenDropdown}
        />
      ),
      icon: <CloudUploadOutlined />,
    },
    {
      key: 'moveToArchive',
      label: (
        <PopconfirmWrapperMenuItem
          title={t('root.table.actionMenu.moveToArchive')}
          description={t('root.table.actionMenu.confirmation.moveToArchiveDescription', { name: record.name })}
          status={LibraryStatus.Retired}
          libraryId={record.id}
          setOpenDropdown={setOpenDropdown}
        />
      ),
      icon: <ContainerOutlined />,
    },
    {
      key: 'approve',
      label: (
        <PopconfirmWrapperMenuItem
          title={t('root.table.actionMenu.approve')}
          description={t('root.table.actionMenu.confirmation.approveDescription', { name: record.name })}
          status={LibraryStatus.Active}
          libraryId={record.id}
          setOpenDropdown={setOpenDropdown}
        />
      ),
      icon: <CheckCircleOutlined />,
    },
  ];

  const getMenuItems = (status: LibraryStatus) => {
    switch (status) {
      case LibraryStatus.Active:
        return [items[0], items[1]];
      case LibraryStatus.Development:
        return [items[2], items[1]];
      case LibraryStatus.Retired:
        return [items[0]];
      default:
        return [];
    }
  };

  return (
    <Dropdown
      menu={{
        items: getMenuItems(record.status),
      }}
      open={openDropdown}
      trigger={['click']}
      autoAdjustOverflow={false}
      onOpenChange={(open) => setOpenDropdown(open)}
    >
      <Button
        css={cssActionBtn}
        icon={<MoreOutlined />}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      />
    </Dropdown>
  );
};

const cssActionBtn = (theme: Theme): CSSObject => ({
  border: 'none',
  background: 'none',
  color: theme.colorLink,
  cursor: 'pointer',
});

interface IPopconfirmWrapperMenuIemProps {
  title: string;
  description: string;
  setOpenDropdown: (val: boolean) => void;
  libraryId: number;
  status: LibraryStatus;
}

interface ILibraryActionMenuProps {
  record: Library;
  t: TFunction;
}
