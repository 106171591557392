import '@config/RuntimeConfig';
import 'antd/dist/reset.css';
import { EmotionAntdThemeProvider } from '@config/EmotionConfig';
import { router } from '@routes/router';
import { store } from '@store';
import i18n from '@i18n';
import { AuthProvider } from '@modules/auth/AuthProvider';
import { AppPermissionControlHandler } from '@app/components/PermissionControl';
import { AppProvider } from '@app/AppProvider';
import * as process from 'process';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { StrictMode } from 'react';
// import reportWebVitals from "./reportWebVitals";

if (process.env.NODE_ENV === 'development' && process.env.RUN_WITH_MOCK) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { worker } = require('./mocks/browser');
  worker.start({ onUnhandledRequest: 'bypass' });
}

const persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <EmotionAntdThemeProvider>
        <I18nextProvider i18n={i18n}>
          <AppProvider>
            <AppPermissionControlHandler />
            <AuthProvider>
              <StrictMode>
                <RouterProvider router={router} />
              </StrictMode>
            </AuthProvider>
          </AppProvider>
        </I18nextProvider>
      </EmotionAntdThemeProvider>
    </PersistGate>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
