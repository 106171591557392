import { User } from '@modules/user/UserTypes';
import { TAuthAppPermissions } from '@modules/auth/AuthTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: AuthStore = {
  ready: false,
  user: null,
  appPermissions: {},
};

const authSlice = createSlice({
  initialState,
  name: 'auth',
  reducers: {
    setUser: (state, action: PayloadAction<User | null>) => {
      state.ready = !!action.payload;
      state.user = action.payload;
    },
    setAppPermissions: (state, action: PayloadAction<TAuthAppPermissions>) => {
      state.appPermissions = action.payload;
    },
  },
});

export const authActions = authSlice.actions;

export const authReducer = authSlice.reducer;

interface AuthStore {
  ready?: boolean;
  user: User | null;
  appPermissions: Partial<TAuthAppPermissions>;
}
