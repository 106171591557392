import { APP_HEADER_CUSTOM_TOOLBAR } from '@app/components/Header/AppHeaderConstants';
import { ReactNode, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

export const AppToolbarPortal = ({ children }: AppToolbarPortal) => {
  const [toolbarContainer, setToolbarContainer] = useState<Element>();

  useEffect(() => {
    const appCustomMenu = document.getElementById(APP_HEADER_CUSTOM_TOOLBAR);
    if (appCustomMenu) {
      setToolbarContainer(appCustomMenu);
    }
  }, []);

  if (!toolbarContainer) {
    return null;
  }

  return createPortal(children, toolbarContainer);
};

interface AppToolbarPortal {
  children: ReactNode;
}
