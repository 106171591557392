export const capitalizeFirstLetter = (s: string = '') => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();

export const listToOptions = (arr: string[], capitalizeLabel: boolean = false) =>
  arr.map((item) => ({
    label: capitalizeLabel ? capitalizeFirstLetter(item) : item,
    value: item,
  }));

const concatFullName = (firstName: string, lastName: string) => `${firstName} ${lastName}`;

export const studyUserToOptions = (users?: Array<{ first_name: string; last_name: string; username: string }>) =>
  users?.map(({ first_name, last_name, username }) => ({
    label: concatFullName(first_name, last_name),
    value: username,
  })) || [];

export const itemsToOptions = (arr: { id: number; name: string }[] = []) =>
  Array.isArray(arr) ? arr.map((item) => ({ value: item.id, label: item.name })) : [];
