import { DraggableModal } from '@ui';
import { useAuditLogDiffQuery } from '@modules/audit/duck/auditApi';
import { PageSkeleton, QueryError } from '@components';
import { QueryErrorType } from '@shared/utils/Error';
import { AuditLogDiffResponse, AuditLogItem, IAuditLogDiffItem } from '@modules/audit/AuditTypes';
import { getKindTitle } from '@modules/audit/duck/auditHelpers';
import { AuditLogsDiffTitleContent } from '@modules/audit/components';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { SerializedError } from '@reduxjs/toolkit';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';
import { CSSObject } from '@emotion/react';
import stringify from 'json-stable-stringify';

const AuditLogsDiffTitle = ({ item, isNext, t }: IAuditLogDiffTitleProps) => {
  if (item?.action === 'UPDATED') {
    if (isNext) {
      return (
        <AuditLogsDiffTitleContent
          text={t('audit.details.nextChanges')}
          date={item?.action_date}
          username={item?.user_name}
        />
      );
    }
    return (
      <AuditLogsDiffTitleContent
        text={t('audit.details.prevChanges')}
        date={item?.action_date}
        username={item?.user_name}
      />
    );
  }
  if (item?.action === 'CREATED') {
    return (
      <AuditLogsDiffTitleContent
        text={t('audit.details.created')}
        date={item?.action_date}
        username={item?.user_name}
      />
    );
  }
  if (item?.action === 'DELETED') {
    return (
      <AuditLogsDiffTitleContent
        text={t('audit.details.deleted')}
        date={item?.action_date}
        username={item?.user_name}
      />
    );
  }
  if (item?.action === 'IMPORTED') {
    return (
      <AuditLogsDiffTitleContent
        text={t('audit.details.imported')}
        date={item?.action_date}
        username={item?.user_name}
      />
    );
  }
  if (isNext) {
    return <AuditLogsDiffTitleContent text={t('audit.details.noNextChanges')} username={item?.user_name} />;
  }
  return <AuditLogsDiffTitleContent text={t('audit.details.noPreviousChanges')} username={item?.user_name} />;
};

const AuditModalsDetailsContent = ({ data, diff, isFetching, error, t }: AuditModalsDetailsContentProps) => {
  const { previous: left, latest: right } = diff || {};
  let leftData = left?.data;
  let rightData = right?.data;

  const leftValue = leftData ? stringify(leftData, { space: 4 }) : '';
  const rightValue = rightData ? stringify(rightData, { space: 4 }) : '';

  return (
    <div>
      {isFetching && <PageSkeleton hideTitle={isFetching} />}
      {!isFetching && error && <QueryError error={error} title={t('errors.loadFailed')} />}
      {!isFetching && !error && (
        <div css={cssDiff}>
          <ReactDiffViewer
            leftTitle={<AuditLogsDiffTitle item={left} t={t} />}
            rightTitle={<AuditLogsDiffTitle item={right} t={t} isNext />}
            oldValue={leftValue}
            newValue={rightValue}
            splitView={true}
            styles={{ line: { fontSize: '0.75rem' } }}
            compareMethod={DiffMethod.WORDS}
          />
        </div>
      )}
    </div>
  );
};

export const AuditModalsDetails = ({ open, data, onClose }: AuditModalsDetailsProps) => {
  const { t } = useTranslation(['audit']);
  const diffQuery = useAuditLogDiffQuery(data?.id!, { skip: !data?.id });
  const isFetching = diffQuery.isFetching || diffQuery.isLoading;

  return (
    <DraggableModal
      width="80%"
      open={open}
      onCancel={onClose}
      onOk={onClose}
      title={t('details.title', { kind: getKindTitle(t, data?.kind), name: data?.entity_name })}
      destroyOnClose
    >
      {open && (
        <AuditModalsDetailsContent
          data={data}
          diff={diffQuery.data}
          isFetching={isFetching}
          error={diffQuery.error}
          t={t}
        />
      )}
    </DraggableModal>
  );
};

export interface AuditModalsDetailsProps {
  open: boolean;
  data: Partial<AuditLogItem>;
  onClose: () => void;
}

interface AuditModalsDetailsContentProps extends Pick<AuditModalsDetailsProps, 'data'> {
  t: TFunction;
  isFetching: boolean;
  error?: QueryErrorType | SerializedError;
  diff?: AuditLogDiffResponse;
}

interface IAuditLogDiffTitleProps {
  item?: IAuditLogDiffItem | null;
  isNext?: boolean;
  t: TFunction;
}

const cssDiff = (): CSSObject => ({
  height: '50vh',
  minHeight: '300px',
  overflowY: 'auto',
  overflowX: 'hidden',
  overflowWrap: 'anywhere',
});
