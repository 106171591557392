import { appApi } from '@config/appApi';
import {
  ILibrarySqlLabListQueryParams,
  ILibrarySqlLabQuery,
  ILibrarySqlLabResponse,
  ILibrarySqlLabValidateQuery,
} from '@modules/library/sqlLab/LibrarySqlLabTypes';
import { LIBRARY_SQL_LAB_TAG_DESCRIPTION } from '@modules/library/sqlLab/duck/librarySqlLabConstants';
import { TagDescription } from '@reduxjs/toolkit/query/react';

export const LibrarySqlLabApiRoutes = {
  list: `api/library/libraries/sql-lab`,
  item: (sqlId: number) => `api/library/libraries/sql-lab/${sqlId}`,
  validate: `/api/library/libraries/sql-lab/validate`,
};

const LibrarySqlLabInvalidations: {
  LIST: TagDescription<LIBRARY_SQL_LAB_TAG_DESCRIPTION.LIST>;
  ID: (sqlId: number) => TagDescription<LIBRARY_SQL_LAB_TAG_DESCRIPTION.ID>;
} = {
  LIST: { type: LIBRARY_SQL_LAB_TAG_DESCRIPTION.LIST, id: 'LIST' },
  ID: (sqlId: number) => ({ type: LIBRARY_SQL_LAB_TAG_DESCRIPTION.ID, sqlId }),
};

export const LibrarySqlLabApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    librarySqlLabList: builder.query<ILibrarySqlLabResponse, ILibrarySqlLabListQueryParams | void>({
      providesTags: [LibrarySqlLabInvalidations.LIST],
      query: (params) => ({
        params,
        url: LibrarySqlLabApiRoutes.list,
      }),
    }),
    libraryDeleteSqlLab: builder.mutation<void, number>({
      invalidatesTags: (request, error, sqlId) => [
        LibrarySqlLabInvalidations.LIST,
        LibrarySqlLabInvalidations.ID(sqlId),
      ],
      query: (sqlId) => ({
        method: 'DELETE',
        url: LibrarySqlLabApiRoutes.item(sqlId),
      }),
    }),
    libraryCopySqlLab: builder.mutation<void, ILibrarySqlLabQuery>({
      invalidatesTags: [LibrarySqlLabInvalidations.LIST],
      query: ({ data, overwrite }) => ({
        data,
        params: { overwrite },
        method: 'POST',
        url: LibrarySqlLabApiRoutes.list,
      }),
    }),
    validateSqlLab: builder.mutation<void, ILibrarySqlLabValidateQuery>({
      query: (data) => ({
        data,
        method: 'POST',
        url: LibrarySqlLabApiRoutes.validate,
      }),
    }),
  }),
});

export const {
  useLibrarySqlLabListQuery,
  useLazyLibrarySqlLabListQuery,
  useLibraryDeleteSqlLabMutation,
  useLibraryCopySqlLabMutation,
  useValidateSqlLabMutation,
} = LibrarySqlLabApi;
