import { Button, Space, Spin, Tooltip, Typography } from '@ui';
import { selectAppENV } from '@app/duck/appSelectors';
import { Divider, Tag } from '@ui';
import { LoadingOutlined, RedoOutlined, SearchOutlined } from '@ant-design/icons';
import { css, CSSObject } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Input } from 'antd';
import { ChangeEvent } from 'react';

export const TableListSiderToolbar = ({
  isLoadingData,
  reloadTables,
  isErrorLoadingTables,
  setSearch,
}: TableListSiderToolbarProps) => {
  const appEnv = useSelector(selectAppENV);
  const { t } = useTranslation(['viewer']);

  const onSearchHandled = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearch(value);
  };

  return (
    <>
      <Space justify="space-between" full css={cssContainer}>
        <Space>
          <Typography.Title level={5} children={t('sider.title')} css={cssTitle} />
          {appEnv?.current === false && <Tag type="source" text={appEnv?.label} upperCaseText />}
        </Space>
        <Space>
          {isLoadingData && <Spin css={cssSpinner} indicator={<LoadingOutlined />} size="small" />}
          {!isLoadingData && (
            <Tooltip
              placement="top"
              title={t('sider.loadingError')}
              open={isErrorLoadingTables === true ? undefined : false}
            >
              <Button
                title={t('sider.reloadBtn')}
                icon={<RedoOutlined />}
                danger={isErrorLoadingTables}
                size="small"
                onClick={reloadTables}
              />
            </Tooltip>
          )}
        </Space>
      </Space>
      <Space full css={cssSearchContainer}>
        <Input prefix={<SearchOutlined />} placeholder={t('sider.searchBy')} onChange={onSearchHandled} allowClear />
      </Space>
      <Divider css={cssDivider} />
    </>
  );
};

interface TableListSiderToolbarProps {
  isLoadingData: boolean;
  reloadTables: () => void;
  isErrorLoadingTables: boolean;
  setSearch: (searchText: string) => void;
}

const cssDivider = (): CSSObject => ({
  margin: '0 8px 8px',
});

const cssSpinner = css({
  paddingLeft: '0.25rem',
});

const cssContainer = (): CSSObject => ({
  minWidth: '200px',
  padding: '4px 8px',
});

const cssSearchContainer = (): CSSObject => ({
  ...cssContainer(),
  '& .ant-space-item': {
    width: '100%',
  },
});

const cssTitle = (): CSSObject => ({
  '&.ant-typography': {
    marginBottom: 0,
  },
});
