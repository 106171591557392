import { DraggableModal, notification } from '@ui';
import { ELibraryEntityNames, ELibrarySourceType } from '@modules/library/root/LibraryTypes';
import {
  useLazyLibrarySqlLabListQuery,
  useLibraryCopySqlLabMutation,
  useValidateSqlLabMutation,
} from '@modules/library/sqlLab/duck/librarySqlLabApi';
import { prepareLibrarySqlLab } from '@modules/library/sqlLab/duck/librarySqlLabUtils';
import { useSqlLabColumns } from '@modules/library/sqlLab/duck/librarySqlLabHooks';
import { LibraryStatus } from '@modules/library/root/duck/libraryConstants';
import { ConfirmModal, useConfirmModal } from '@components';
import { QueryErrorType } from '@shared/utils/Error';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import {
  getLibraryImportSqlLabFromLibrarySource,
  ILibraryImportSqlLabProps,
  LibraryImportSqlLab,
  WrapperFn,
} from '../../components/LibraryImportSqlLab';

const LibrarySqlLabModalsCopySqlLabContent = ({ t, onClose }: LibrarySqlLabModalsCopySqlLabContentProps) => {
  const { sqlLabColumns, locale } = useSqlLabColumns();
  const [librarySqlLabListQuery, librarySqlLabListQueryData] = useLazyLibrarySqlLabListQuery();
  const [copySqlLab] = useLibraryCopySqlLabMutation();
  const [validateSqlLab] = useValidateSqlLabMutation();
  const confirmModal = useConfirmModal();

  const wrapperLibrary: WrapperFn = async (id: number) => {
    const data = await librarySqlLabListQuery({ library_id: id, detailed: '1' }).unwrap();
    return getLibraryImportSqlLabFromLibrarySource(data.items, ELibrarySourceType.Library);
  };

  const onImport: ILibraryImportSqlLabProps['onImport'] = async (values, { kind, overwrite, systemInfo }) => {
    if (overwrite) {
      try {
        await validateSqlLab({ sql_lab_names: values.map((item) => item.name) }).unwrap();

        await copySqlLab({ data: prepareLibrarySqlLab(values, kind, systemInfo), overwrite: overwrite }).unwrap();
      } catch (error) {
        const { data } = error as QueryErrorType;
        confirmModal.openConfirmation({
          content: (
            <span>
              {t('confirmOverwrite.content')}
              <strong>{(data.rawData?.error || []).join(', ')}</strong>
            </span>
          ),
          data: prepareLibrarySqlLab(values, kind, systemInfo),
        });
        // TODO Refactor this stuff later
        // eslint-disable-next-line no-throw-literal
        throw '';
      }
    } else {
      await copySqlLab({ data: prepareLibrarySqlLab(values, kind, systemInfo), overwrite: overwrite }).unwrap();
    }
  };

  const onOverwriteConfirm = async (data: any) => {
    try {
      await copySqlLab({ data, overwrite: true }).unwrap();
      onClose();
    } catch (error) {
      notification.error({ message: (error as QueryErrorType).data.userMsg });
    }
  };

  return (
    <>
      <LibraryImportSqlLab
        onClose={onClose}
        columns={sqlLabColumns}
        locale={locale}
        kind={ELibraryEntityNames.SQL_Lab}
        libraryTableListQuery={wrapperLibrary}
        tableDataFetching={librarySqlLabListQueryData.isFetching}
        onImport={onImport}
        libraryStatuses={[LibraryStatus.Active, LibraryStatus.Development]}
        sourceOnlyLibrary
      />
      <ConfirmModal title={t('confirmOverwrite.content')} submitFunc={onOverwriteConfirm} {...confirmModal} />
    </>
  );
};

export const LibrarySqlLabModalsCopySqlLab = ({ open, data, onClose }: ILibrarySqlLabModalsCopySqlLabProps) => {
  const { t } = useTranslation(['librarySqlLab']);

  return (
    <DraggableModal width="50%" open={open} onCancel={onClose} title={t('copyForm.title')} footer={null} destroyOnClose>
      {open && <LibrarySqlLabModalsCopySqlLabContent data={data} onClose={onClose} t={t} />}
    </DraggableModal>
  );
};

export interface ILibrarySqlLabModalsCopySqlLabProps {
  open: boolean;
  data: any;
  onClose: () => void;
}

interface LibrarySqlLabModalsCopySqlLabContentProps
  extends Pick<ILibrarySqlLabModalsCopySqlLabProps, 'data' | 'onClose'> {
  t: TFunction;
}
