import { isEqual, pick, cloneDeep } from 'lodash';

/**
 * Equal Proxy object with pain object.
 *
 * @param sliceData can be Proxy type object
 * @param data pain object
 */
export const isSliceDataEqual = (sliceData: Record<string, any>, data: Record<string, any>) => {
  const clearSliceData = Object.keys(data!).reduce((acc, key) => ({ ...acc, [key]: cloneDeep(sliceData[key]) }), {});

  return isEqual(pick(clearSliceData, Object.keys(data!)), data);
};
