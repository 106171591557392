import {
  AnalysisPackageModalsPushAnalysisPackagePayload,
  AnalysisPackageModalsState,
} from '@modules/library/analysisPackage/modals';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: AnalysisPackageStore = {
  modals: { selectedModal: null },
};

export const analysisPackageSlice = createSlice({
  name: 'analysisPackage',
  initialState,
  reducers: {
    pushModal: (state, action: PayloadAction<AnalysisPackageModalsPushAnalysisPackagePayload>) => {
      const { type, data } = action.payload;
      state.modals = { selectedModal: type, data };
    },
    dropModal: (state, action: PayloadAction<void>) => {
      state.modals = initialState.modals;
    },
    reset: (state) => {
      return initialState;
    },
  },
});

export const analysisPackageActions = analysisPackageSlice.actions;

export const analysisPackageReducer = analysisPackageSlice.reducer;

interface AnalysisPackageStore {
  modals: AnalysisPackageModalsState;
}
