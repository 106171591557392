import { CSSObject, Theme } from '@emotion/react';
import { SpaceProps } from 'antd';

export const cssSpace =
  ({ direction = 'horizontal', size = 'small' }: Pick<SpaceProps, 'size' | 'direction'> = {}) =>
  (theme: Theme): CSSObject => ({
    display: 'flex',
    flexDirection: direction === 'horizontal' ? 'row' : 'column',
    gap:
      size === 'small'
        ? theme.paddingXS
        : size === 'middle'
        ? theme.padding
        : size === 'large'
        ? theme.paddingMD
        : `${size}px`,
  });
