import { PageTemplateSimple } from '@components';
import { CrossStudyDetails } from '@modules/study/components';
import { useTranslation } from 'react-i18next';

const crossStudyDetails = {
  id: 0,
  name: 'SETUPS',
  summary: 'SETUPS allow user to view and fill Reference Tables which can be used in any Study',
};

export const CrossStudyPage = () => {
  const { t } = useTranslation(['study']);
  const study = crossStudyDetails.name;

  return (
    <PageTemplateSimple
      title={{ children: study, pageTitle: t('detailsPage.pageTitle') }}
      content={{ isLoading: false }}
    >
      <CrossStudyDetails study={crossStudyDetails} />
    </PageTemplateSimple>
  );
};
