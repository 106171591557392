import { Button, Select } from '@ui';
import { LATEST_DATA_SNAPSHOT } from '@modules/snapshot/duck/snapshotConstants';
import { appActions } from '@app/duck/appSlice';
import { useEnvironments } from '@modules/viewer/duck/viewerHooks';
import { selectViewerSnapshot } from '@modules/viewer/duck/viewerSelectors';
import { prepareCurrentEnvironment } from '@modules/viewer/duck/viewerUtils';
import { viewerActions } from '@modules/viewer/duck/viewerSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Space } from 'antd';

export const DataViewerEnvSelector = () => {
  const { t } = useTranslation(['shared', 'app', 'appHeader']);
  const dispatch = useDispatch();

  const { currentAppEnv, userCurrentEnv, envOptions, userEnvs } = useEnvironments();
  const snapshot = useSelector(selectViewerSnapshot);

  const onChangeEnvironment = (value: string) => {
    dispatch(appActions.setENVSwitch(prepareCurrentEnvironment(userCurrentEnv, userEnvs || {}, value)));
    if (snapshot) {
      dispatch(viewerActions.setSnapshot({ ...LATEST_DATA_SNAPSHOT, value: '' }));
    }
  };

  return (
    <Space.Compact style={{ order: 30 }}>
      <Button type="default" disabled style={{ cursor: 'auto' }}>
        {t('appHeader.env.switchEnvironment')}
      </Button>
      <Select
        dropdownMatchSelectWidth={false}
        defaultValue={currentAppEnv?.env}
        value={currentAppEnv?.env}
        options={envOptions}
        defaultActiveFirstOption
        onChange={onChangeEnvironment}
      />
    </Space.Compact>
  );
};
