import { DATASTAGE_PREFIX } from '@routes/utils';

const DOMAIN_PREFIX = `//${DATASTAGE_PREFIX}`;

export const getDomain = (data: string) => {
  if (!data.includes(DOMAIN_PREFIX) && !data.includes('//localhost')) {
    return data.replace('//', DOMAIN_PREFIX);
  }
  return data;
};

export const removeParametersFromSearch = (search: string, parameterNames: string[]) => {
  const searchParams = new URLSearchParams(search);
  parameterNames.forEach((param) => searchParams.delete(param));
  return searchParams ? `?${decodeURIComponent(searchParams.toString())}` : '';
};
