import { Badge, Dropdown } from '@ui';
import { NotificationsPanel } from '@app/components/Notifications/components/NotificationsPanel';
import { BellOutlined } from '@ant-design/icons';
import { useMemo } from 'react';
import { CSSObject } from '@emotion/react';
import { useSelector } from 'react-redux';
import { selectNotifications } from './duck/notificationsSelector';

export const NotificationsButton = () => {
  const { archived, latest } = useSelector(selectNotifications);
  const unreadNotifications = useMemo(() => latest.filter((notification) => !notification.isRead), [latest]);

  return (
    <Dropdown
      placement="bottomRight"
      trigger={['click']}
      menu={{
        items: [{ key: 'panel', label: <NotificationsPanel notifications={latest} archived={archived} /> }],
      }}
    >
      <Badge count={unreadNotifications.length} size="small">
        <BellOutlined css={cssNotificationsButton} />
      </Badge>
    </Dropdown>
  );
};

const cssNotificationsButton = (): CSSObject => ({
  fontSize: '24px',
});
