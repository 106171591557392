import { useMemo, useRef } from 'react';

export const useClickAndDoubleClickHandler = <T,>({
  onSingleClick,
  onDoubleClick,
  latency = 250,
}: ClickHandlerProps<T>) => {
  const clickCount = useRef(0);

  const clickHandler = useMemo(() => {
    return (item: T, event: React.MouseEvent<HTMLElement>) => {
      clickCount.current += 1;
      setTimeout(() => {
        if (clickCount.current === 1) {
          onSingleClick(item, event);
        } else if (clickCount.current === 2) {
          onDoubleClick(item, event);
        }
        clickCount.current = 0;
      }, latency);
    };
  }, [onSingleClick, onDoubleClick, latency]);

  return clickHandler;
};

export type ClickHandler<T> = (item: T, e: React.MouseEvent<HTMLElement>) => void;

interface ClickHandlerProps<T> {
  onSingleClick: ClickHandler<T>;
  onDoubleClick: ClickHandler<T>;
  latency?: number;
}
