import { getPeriodicallyFieldsFromCron, RUN_TYPE, setCronValue } from '@components/form';
import { PrepareUpdateSchedulerRunDataGpdip, SchedulerRunFormValuesGpdip } from '@components/form/gpdipRun';

export const getDefaultInitialValueGpdip = (): SchedulerRunFormValuesGpdip => ({
  run: RUN_TYPE.manual,
});

export const prepareFieldsGpdip = (values: { [key: string]: any }): SchedulerRunFormValuesGpdip => {
  const { automatic, crontab, crontab_ui_repr, ...restValues } = values;

  return {
    run: automatic ? RUN_TYPE.automatic : crontab ? RUN_TYPE.periodically : RUN_TYPE.manual,
    runPeriodically: !automatic && crontab ? getPeriodicallyFieldsFromCron(crontab_ui_repr) : undefined,
    ...restValues,
  };
};

export const prepareUpdateDataExport = ({ values }: PrepareUpdateSchedulerRunDataGpdip) => {
  const { runPeriodically, run, ...restValues } = values;

  const crontab = run === RUN_TYPE.periodically ? setCronValue(runPeriodically!) : '';

  return {
    ...restValues,
    crontab,
    automatic: run === RUN_TYPE.automatic,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    crontab_ui_repr: run === RUN_TYPE.periodically ? JSON.stringify(runPeriodically) : '',
  };
};
