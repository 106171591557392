import { Tooltip } from '@ui';
import { JSXElementConstructor, ReactNode, Ref } from 'react';
import { TooltipProps } from 'antd';
import { Theme } from '@emotion/react';

export const BaseIcon = ({
  children,
  clickable,
  tip,
  tooltip,
  forwardRef,
  width,
  height,
  className,
  viewBox,
  color,
  IconElement = 'svg',
  rotate,
  onClick,
}: BaseSvgProps) => {
  const hasDimensions = width && height;
  const content = (
    <IconElement
      css={cssBody({ clickable, color, rotate })}
      className={className}
      ref={forwardRef}
      width={hasDimensions ? `${Number(width / height).toFixed(3) || 1}em` : undefined}
      height={hasDimensions ? '1em' : undefined}
      viewBox={viewBox}
      onClick={onClick}
    >
      {children}
    </IconElement>
  );

  if (tip) {
    return (
      <Tooltip {...tooltip} title={tip}>
        {content}
      </Tooltip>
    );
  }

  return content;
};

export interface BaseSvgProps {
  children?: ReactNode;
  width?: number;
  height?: number;
  viewBox?: string;
  forwardRef: Ref<any>;
  tooltip?: TooltipProps;
  tip?: string;
  clickable?: boolean;
  color?: 'primary' | 'warning' | 'success' | 'danger' | 'white';
  className?: string;
  IconElement?: JSXElementConstructor<any> | 'svg';
  rotate?: number;
  onClick?: () => void;
}

export type SvgIconProps = Pick<
  BaseSvgProps,
  'tooltip' | 'tip' | 'className' | 'clickable' | 'color' | 'rotate' | 'onClick'
>;

enum colorMap {
  'primary' = 'colorPrimary',
  'danger' = 'colorError',
  'warning' = 'colorWarning',
  'success' = 'colorSuccess',
  'white' = 'colorWhite',
}

const cssBody =
  ({ clickable, color, rotate }: Pick<BaseSvgProps, 'clickable' | 'color' | 'rotate'>) =>
  (theme: Theme) => ({
    display: 'inline-block',
    color: color ? theme[colorMap[color]] : 'initial',
    '&&': {
      rotate: rotate ? `${rotate}deg` : undefined,
    },
    '&': clickable
      ? {
          transition: 'opacity 0.3s',
          opacity: 0.8,
          curssor: 'pointer',
          padding: '5px',
          margin: '-5px',
          '&:hover': {
            opacity: 1,
          },
        }
      : {},
  });
