import { appApi } from '@config/appApi';
import { SchemaTables, StudyContextResponse, StudyDetails, StudyListResponse } from '@modules/study/StudyTypes';
import { TagDescription } from '@reduxjs/toolkit/query/react';
import { STUDY_TAG_DESCRIPTION } from './studyConstants';

export const StudyApiRoutes = {
  studyDetails: '/api/study',
  studyList: '/api/study/list',
  schemaTables: '/api/tables/describe',
  studyContext: '/api/study/context',
};

const StudyInvalidates: {
  DETAILS: TagDescription<STUDY_TAG_DESCRIPTION.DETAILS>;
  CONTEXT: TagDescription<STUDY_TAG_DESCRIPTION.CONTEXT>;
  LIST: TagDescription<STUDY_TAG_DESCRIPTION.LIST>;
  SCHEMA_TABLES: TagDescription<STUDY_TAG_DESCRIPTION.SCHEMA_TABLES>;
} = {
  DETAILS: { type: STUDY_TAG_DESCRIPTION.DETAILS, id: 'DETAILS' },
  LIST: { type: STUDY_TAG_DESCRIPTION.LIST, id: 'LIST' },
  SCHEMA_TABLES: { type: STUDY_TAG_DESCRIPTION.SCHEMA_TABLES, id: 'SCHEMA_TABLES' },
  CONTEXT: { type: STUDY_TAG_DESCRIPTION.CONTEXT, id: 'CONTEXT' },
};

export const StudyApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    StudyList: builder.query<StudyListResponse, { roles: number } | void>({
      providesTags: [StudyInvalidates.LIST],
      query: (params) => ({
        params,
        url: StudyApiRoutes.studyList,
      }),
    }),
    studyContext: builder.query<StudyContextResponse, { study_id: number | string }>({
      providesTags: [StudyInvalidates.CONTEXT],
      query: (params) => ({
        url: StudyApiRoutes.studyContext,
        params,
      }),
    }),
    studyDetails: builder.query<StudyDetails, void>({
      providesTags: [StudyInvalidates.DETAILS],
      query: () => ({
        url: StudyApiRoutes.studyDetails,
      }),
    }),
    schemaTables: builder.query<SchemaTables, void>({
      providesTags: [StudyInvalidates.SCHEMA_TABLES],
      query: () => ({
        url: StudyApiRoutes.schemaTables,
      }),
    }),
  }),
});

export const {
  useStudyListQuery,
  useStudyDetailsQuery,
  useLazyStudyListQuery,
  useSchemaTablesQuery,
  useStudyContextQuery,
} = StudyApi;
