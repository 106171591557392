import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { selectAuthUser } from './AuthSelector';

export const useAuthUserEnvs = () => {
  const user = useSelector(selectAuthUser);

  const userCurrentEnv = user?.env_name ?? '';
  const allUserEnvs = user?.environments;
  const userCurrentEnvLabel = allUserEnvs && allUserEnvs[userCurrentEnv]?.label;

  return {
    user,
    allUserEnvs,
    userCurrentEnv,
    userCurrentEnvLabel,
  };
};

export const useAuthSessionListener = () => {
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const sessionReferrer = searchParams.get('referrer');

    if (sessionReferrer) {
      localStorage.setItem('referrer', sessionReferrer);
    }

    if (sessionReferrer) {
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, [dispatch, searchParams]);
};

export const useSetAuthReferrer = () => {
  const setAuthReferrerToSearchParams = useCallback(() => {
    const sessionReferrer = localStorage.getItem('referrer');
    const userName = localStorage.getItem('user_name');
    const currentURL = new URL(window.location.href);

    if (sessionReferrer) {
      currentURL.searchParams.set('referrer', sessionReferrer);
    }
    if (userName) {
      currentURL.searchParams.set('username', userName);
    }

    currentURL.searchParams.set('redirect_url', window.location.pathname);
    window.history.replaceState({}, document.title, decodeURIComponent(currentURL.href));
  }, []);

  return {
    setAuthReferrerToSearchParams,
  };
};
