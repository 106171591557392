import { Space, Typography } from '@components/ui';
import React, { ReactNode } from 'react';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { CSSObject, Theme } from '@emotion/react';
import { Col, Row } from 'antd';

export const PropertiesView: React.FC<PropertiesViewProps> = ({ data, renderHeader, renderFooter, className }) => {
  const [showSecret, setShowSecret] = React.useState<Record<string, boolean>>({});

  return (
    <Space direction="vertical" className={className} full>
      {renderHeader}
      {data
        .sort((itemPrev, itemNext) => ((itemPrev || {}).order || 0) - ((itemNext || {}).order || 0))
        .map(
          (item, key) =>
            item && (
              <Row key={key} css={cssRow}>
                <Col
                  span={6}
                  children={
                    <Typography.Text css={cssTitle}>
                      <Typography.Text strong children={item.key} />
                      {item.secret &&
                        (showSecret[key] ? (
                          <EyeInvisibleOutlined
                            css={cssEye}
                            onClick={() => setShowSecret((prev) => ({ ...prev, [key]: false }))}
                          />
                        ) : (
                          <EyeOutlined
                            css={cssEye}
                            onClick={() => setShowSecret((prev) => ({ ...prev, [key]: true }))}
                          />
                        ))}
                    </Typography.Text>
                  }
                />
                <Col
                  span={18}
                  children={
                    <Typography.Paragraph
                      ellipsis={{ rows: 2, expandable: true, symbol: 'more' }}
                      children={item.secret ? (showSecret[key] ? item.value : '∗∗∗') : item.value}
                    />
                  }
                />
              </Row>
            ),
        )}
      {renderFooter}
    </Space>
  );
};

interface PropertiesViewProps {
  data: Array<
    | {
        key: string | ReactNode;
        value: string | ReactNode;
        secret?: boolean;
        order?: number;
      }
    | null
    | false
  >;
  renderHeader?: ReactNode;
  renderFooter?: ReactNode;
  className?: string;
}

const cssRow = (): CSSObject => ({
  width: '100%',
  '& > .ant-col': {
    wordBreak: 'break-all',
  },
});

const cssTitle = (theme: Theme): CSSObject => ({
  display: 'inline-block',
  wordBreak: 'break-word',
  paddingRight: theme.paddingMD,
});

const cssEye = (theme: Theme): CSSObject => ({
  color: theme.colorPrimary,
  marginLeft: theme.paddingXS,
  verticalAlign: 'middle',
});
