import {
  AnalysisPackageModalsSaveAnalysisPackage,
  AnalysisPackageModalsSaveAnalysisPackageProps,
} from 'src/modules/library/analysisPackage/modals/components/save';
import {
  AnalysisPackageModalsDeployAnalysisPackage,
  AnalysisPackageModalsDeployAnalysisPackageProps,
} from '@modules/library/analysisPackage/modals/components';
import { selectAnalysisPackageModals } from '@modules/library/analysisPackage/duck/analysisPackageSelectors';
import { analysisPackageActions } from '@modules/library/analysisPackage/duck/analysisPackageSlice';
import { EAnalysisPackageType } from '@modules/library/analysisPackage/modals/index';
import { useDispatch, useSelector } from 'react-redux';
import {
  AnalysisPackageModalsDeploymentLog,
  AnalysisPackageModalsDeploymentLogProps,
} from './components/deploymentLog/AnalysisPackageModalsDeploymentLog';

export const AnalysisPackageModalsController = () => {
  const dispatch = useDispatch();
  const { data, selectedModal } = useSelector(selectAnalysisPackageModals);

  const closeModal = () => {
    dispatch(analysisPackageActions.dropModal());
  };

  return (
    <>
      <AnalysisPackageModalsSaveAnalysisPackage
        open={selectedModal === EAnalysisPackageType.saveAnalysisPackage}
        data={data as AnalysisPackageModalsSaveAnalysisPackageProps['data']}
        onClose={closeModal}
      />
      <AnalysisPackageModalsDeployAnalysisPackage
        open={selectedModal === EAnalysisPackageType.deployAnalysisPackage}
        data={data as AnalysisPackageModalsDeployAnalysisPackageProps['data']}
        onClose={closeModal}
      />
      <AnalysisPackageModalsDeploymentLog
        open={selectedModal === EAnalysisPackageType.deploymentLog}
        data={data as AnalysisPackageModalsDeploymentLogProps['data']}
        onClose={closeModal}
      />
    </>
  );
};
