import { Typography, Table } from '@ui';
import { dateToString } from '@shared/utils/Date';
import { tableListToOptions } from '@shared/utils/Table';
import { getCreatedByInfo } from '@shared/utils/common';
import { ConnectionListResponse, SourceListResponse } from '@modules/source/duck/sourceApi';
import { useColumnSearch } from '@components/ui/table/tableHooks';
import { sourceActions } from '@modules/source/duck/sourceSlice';
import { SourceModalsType } from '@modules/source/modals';
import { ActorAndInfo } from '@components';
import { TableColumnsType, TableProps } from 'antd';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { TFunction } from 'i18next';

export const SourceList = ({ data, loading, pagination, studyId, onChange, connectionList, t }: SourceListProps) => {
  const dispatch = useDispatch();
  const { getColumnSearchProps, locale } = useColumnSearch<SourceListResponse['items'][0]>();
  const connectionTypes = tableListToOptions(connectionList);

  const openSourceModal = (data: SourceListResponse['items'][0]) => {
    dispatch(sourceActions.pushModal({ type: SourceModalsType.saveSource, data }));
  };

  const columns: TableColumnsType<SourceListResponse['items'][0]> = useMemo(
    () => [
      {
        title: t('rootTable.name'),
        dataIndex: 'name',
        ...getColumnSearchProps('name'),
        onFilter: undefined,
        render: (name, record) =>
          record.system ? record.name : <Typography.Link children={name} onClick={() => openSourceModal(record)} />,
      },
      {
        title: t('rootTable.connectionName'),
        render: (_, record) =>
          record.connection_name || connectionList?.filter((item) => item.id === record.connection_id)[0].name,
      },
      {
        title: t('rootTable.connectionType'),
        dataIndex: 'connection_id',
        filters: connectionTypes,
        onFilter: undefined,
        render: (_, record) => connectionList?.filter((item) => item.id === record.connection_id)[0].name,
      },
      {
        width: 90,
        title: t('rootTable.version'),
        dataIndex: 'version',
      },
      {
        title: t('rootTable.created'),
        dataIndex: 'created_at',
        sorter: (a, b) => 0,
        sortDirections: ['ascend'],
        render: (createdAt: number, record) => (
          <ActorAndInfo info={dateToString(createdAt)} actor={getCreatedByInfo(record)} />
        ),
      },
      {
        width: 90,
        title: t('rootTable.active'),
        dataIndex: 'active',
        render: (active: boolean) => (active ? 'Active' : 'Disabled'),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [connectionTypes],
  );

  return (
    <Table
      bordered
      locale={locale}
      columns={columns}
      dataSource={data?.items}
      loading={loading}
      rowKey={(item) => item.id}
      onChange={onChange}
      tableLayout="fixed"
      scroll={{ x: 900 }}
      pagination={pagination}
    />
  );
};

interface SourceListProps {
  studyId: number;
  data?: SourceListResponse;
  pagination?: TableProps<SourceListResponse['items'][0]>['pagination'];
  onChange?: TableProps<SourceListResponse['items'][0]>['onChange'];
  loading?: boolean;
  pageSize?: number;
  disableKindSort?: boolean;
  connectionList?: ConnectionListResponse;
  t: TFunction;
}
