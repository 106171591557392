import { useSnapshotListQuery } from '@modules/snapshot/duck/snapshotApi';
import { ITableFilterItemWithDescription, PageTemplateSimple, PropertiesView } from '@components';
import { Button, Space, Tag, Typography } from '@ui';
import { ViewerGroupType } from '@modules/viewer/ViewerTypes';
import { useLocationResolver } from '@routes/routesHooks';
import { StudyResolver } from '@routes/study/RoutesStudy';
import { useStoreAggregation } from '@shared/components/TableListSider/tableListSiderHooks';
import { dateToTimezoneString } from '@shared/utils/Date';
import { butifyUsername } from '@shared/utils/common';
import routes from '@routes';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import { UndoOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import { ISnapshot } from '../SnapshotTypes';
import { SnapshotTableList } from '../components/SnapshotTableList';
import { SnapshotCategoryOptionHelper } from '../duck/snapshotUtils';
import { EMPTY_SUBSTUDY_VALUE } from '../duck/snapshotConstants';

const defaultSnapshotTables: [] = [];

export const SnapshotViewPage = () => {
  const { t } = useTranslation(['snapshot']);
  const navigate = useNavigate();
  const {
    params: { snapshotId, studyId },
    state: preloadedSnapshot,
  } = useLocationResolver<StudyResolver['snapshots']['view']['params'], ISnapshot>();
  const snapshotListQuery = useSnapshotListQuery();
  const snapshot = snapshotListQuery.data?.find((item) => item.id === snapshotId) || preloadedSnapshot;
  const snapshotTables = snapshot?.tablesDetails || defaultSnapshotTables;
  const { treeData, tablesQuery } = useStoreAggregation();

  const onBack = () => navigate(routes.study.snapshots.root.resolver({ studyId }));

  const data = useMemo(() => {
    if (snapshotTables.length) {
      const invertedSnapshotTables: Record<string, ISnapshot['tablesDetails'][0]> = snapshotTables.reduce(
        (acc, item) => ({ ...acc, [item.tableName]: item }),
        {},
      );
      const storeListResult: Array<ITableFilterItemWithDescription> = [];
      const result = treeData.reduce((acc, item) => {
        item.children?.forEach((storeItem) => {
          const storeTableName = (storeItem as any).metadata.originalId.split('.')[1];
          if (invertedSnapshotTables[storeTableName]) {
            if (
              !storeListResult.some(
                (_item) =>
                  SnapshotCategoryOptionHelper.getOptionValue(item.name as string, item.metadata?.groupType!) ===
                  _item.value,
              )
            ) {
              storeListResult.push(
                SnapshotCategoryOptionHelper.getOption(item.name as string, item.metadata?.groupType!),
              );
            }
            acc.push({
              ...invertedSnapshotTables[storeTableName],
              store: item.name as string,
              type: item.metadata?.groupType!,
            });
          }
        });
        return acc;
      }, [] as Array<ISnapshot['tablesDetails'][0] & { store: string; type: ViewerGroupType }>);

      return {
        tables: result,
        storeList: storeListResult,
      };
    }

    return {
      tables: [],
      storeList: [],
    };
  }, [treeData, snapshotTables]);

  const detailsMap = [
    {
      key: t('createPage.name'),
      value: snapshot?.description,
    },
    {
      key: t('createPage.creationDate'),
      value: dateToTimezoneString(snapshot?.createdAt),
    },
    {
      key: t('viewPage.createdBy'),
      value: butifyUsername(snapshot?.created_by),
    },
    {
      key: t('viewPage.subStudy'),
      value:
        Array.isArray(snapshot.subStudies) && snapshot.subStudies.length
          ? snapshot.subStudies?.map((subStudy) => <Tag children={subStudy || EMPTY_SUBSTUDY_VALUE} />)
          : t('na'),
    },
    {
      key: t('viewPage.cutoffDate'),
      value: dateToTimezoneString(snapshot?.cutoffDate),
    },
  ];

  const isLoading = tablesQuery.isFetching || snapshotListQuery.isFetching;

  return (
    <PageTemplateSimple
      hideTitleSkeleton
      title={{
        children: t('viewPage.title'),
        extra: (
          <Space>
            <Button
              children={<UndoOutlined style={{ transform: 'rotate(0.25turn)' }} />}
              onClick={snapshotListQuery.refetch}
            />
            <Button children={t('back')} onClick={onBack} />
          </Space>
        ),
      }}
      content={{ isLoading: isLoading && !data.tables.length }}
    >
      {!!data.tables.length && (
        <>
          <PropertiesView data={detailsMap} />
          <SnapshotTableList data={data.tables} loading={snapshotListQuery.isFetching} storeList={data.storeList} />
        </>
      )}
      {!data.tables.length && !isLoading && (
        <div css={cssEmptyBox}>
          <Typography.Title type="secondary">{t('viewPage.contentEmpty')}</Typography.Title>
        </div>
      )}
    </PageTemplateSimple>
  );
};

const cssEmptyBox = css({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
