import { appApi } from '@config/appApi';
import { getTableNameWithSchema } from '@shared/utils/Viewer';
import { ViewerExportFormValues } from '@modules/viewer/modals/components';
import {
  GroupsDataResponse,
  Task,
  ViewerCHTableInfoResponse,
  ViewerGroupType,
  ViewerTracedInfoParams,
} from '@modules/viewer/ViewerTypes';
import { getCHTableNameAndDB } from '@modules/modelEditor/duck/modelEditorUtils';
import { appAxios } from '@config/AppConfig';
import { handleQueryError } from '@shared/utils/Error';
import { sleep } from '@shared/utils/common';
import { EXTERNAL_STORES } from '@modules/stores/duck/storeConstants';
import { TagDescription } from '@reduxjs/toolkit/query/react';
import { VIEWER_TAG_DESCRIPTION } from './viewerConstants';

export const ViewerApiRoutes = {
  data: (tableId: string) => `api/data/${tableId}`,
  tableInfo: (tableId: string) => `api/data/${tableId}/info`,
  tracedInfo: (tableId: string) => `api/data/${tableId}/trace`,
  tableExport: (tableId: string) => `api/data/${tableId}/export`,
  tableExportResult: (id: string) => `api/data/export/${id}`,
  externalTables: 'api/external/stores',
  groups: 'api/study/groups',
  tablesExist: 'api/data/exists',
  tablesInSqlExist: 'api/data/sql/validate',
  crossStudyRT: `api/reference-tables`,
  studyRT: `api/external/reference-tables`,
  referenceTableInfo: (id: string) => `api/data/${id}/structure`,
  tables: 'api/tables',
  tasksActive: 'api/tasks/active',
  globalTables: 'api/global/tables',
};

export const ViewerInvalidations: {
  DATA: (tableId: string) => TagDescription<VIEWER_TAG_DESCRIPTION.DATA>;
  TABLE_INFO: (tableId: string) => TagDescription<VIEWER_TAG_DESCRIPTION.TABLE_INFO>;
  TRACED_INFO: (
    tableId: string,
    rowKey: string,
    targetColumn?: string,
  ) => TagDescription<VIEWER_TAG_DESCRIPTION.TRACED_INFO>;
  ALL_TABLE_INFO: TagDescription<VIEWER_TAG_DESCRIPTION.TABLE_INFO>;
  EXPORT: (data: ViewerExportQuery) => TagDescription<VIEWER_TAG_DESCRIPTION.EXPORT>;
  EXPORT_RESULT: (id: string) => TagDescription<VIEWER_TAG_DESCRIPTION.EXPORT_RESULT>;
  EXTERNAL_TABLES: TagDescription<VIEWER_TAG_DESCRIPTION.EXTERNAL_TABLES>;
  GROUPS: TagDescription<VIEWER_TAG_DESCRIPTION.GROUPS>;
  TABLES_EXIST: TagDescription<VIEWER_TAG_DESCRIPTION.TABLES_EXIST>;
  TABLES_IN_SQL_EXIST: TagDescription<VIEWER_TAG_DESCRIPTION.TABLES_IN_SQL_EXIST>;
  CROSS_STUDY_RT_LIST: TagDescription<VIEWER_TAG_DESCRIPTION.CROSS_STUDY_RT_LIST>;
  STUDY_RT_LIST: TagDescription<VIEWER_TAG_DESCRIPTION.STUDY_RT_LIST>;
  TABLE_INFO_RT: (id: string) => TagDescription<VIEWER_TAG_DESCRIPTION.TABLE_INFO_RT>;
  TABLES_LIST: TagDescription<VIEWER_TAG_DESCRIPTION.TABLES_LIST>;
  TASKS_ACTIVE: TagDescription<VIEWER_TAG_DESCRIPTION.TASKS_ACTIVE>;
  GLOBAL_TABLES_LIST: TagDescription<VIEWER_TAG_DESCRIPTION.GLOBAL_TABLES_LIST>;
} = {
  DATA: (tableId: string) => ({ type: VIEWER_TAG_DESCRIPTION.DATA, tableId }),
  TABLE_INFO: (tableId: string) => ({ type: VIEWER_TAG_DESCRIPTION.TABLE_INFO, tableId }),
  TRACED_INFO: (tableId: string, rowKey: string, targetColumn?: string) => ({
    type: VIEWER_TAG_DESCRIPTION.TRACED_INFO,
    tableId,
    rowKey,
    targetColumn,
  }),
  ALL_TABLE_INFO: VIEWER_TAG_DESCRIPTION.TABLE_INFO,
  EXPORT: (data: ViewerExportQuery) => ({ type: VIEWER_TAG_DESCRIPTION.EXPORT, data }),
  EXPORT_RESULT: (id: string) => ({ type: VIEWER_TAG_DESCRIPTION.EXPORT_RESULT, id }),
  EXTERNAL_TABLES: VIEWER_TAG_DESCRIPTION.EXTERNAL_TABLES,
  GROUPS: VIEWER_TAG_DESCRIPTION.GROUPS,
  TABLES_EXIST: VIEWER_TAG_DESCRIPTION.TABLES_EXIST,
  TABLES_IN_SQL_EXIST: VIEWER_TAG_DESCRIPTION.TABLES_IN_SQL_EXIST,
  CROSS_STUDY_RT_LIST: { type: VIEWER_TAG_DESCRIPTION.CROSS_STUDY_RT_LIST, id: 'LIST' },
  STUDY_RT_LIST: { type: VIEWER_TAG_DESCRIPTION.STUDY_RT_LIST, id: 'LIST' },
  TABLE_INFO_RT: (id: string) => ({ type: VIEWER_TAG_DESCRIPTION.TABLE_INFO_RT, id }),
  TABLES_LIST: { type: VIEWER_TAG_DESCRIPTION.TABLES_LIST, id: 'TABLES_LIST' },
  TASKS_ACTIVE: { type: VIEWER_TAG_DESCRIPTION.TASKS_ACTIVE, id: 'TASKS_ACTIVE' },
  GLOBAL_TABLES_LIST: { type: VIEWER_TAG_DESCRIPTION.GLOBAL_TABLES_LIST, id: 'GLOBAL_TABLES_LIST' },
};

export const ViewerApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    data: builder.query<ViewerDataResponse, string>({
      providesTags: (result, error, tableId) => [ViewerInvalidations.DATA(tableId)],
      query: (tableId) => {
        const { name, schema } = getTableNameWithSchema(tableId);
        return {
          params: { ch_db: schema },
          url: ViewerApiRoutes.data(name),
          timeout: 0,
        };
      },
    }),
    dataPaginated: builder.query<ViewerDataPaginationResponse, ViewerDataPaginationParams>({
      providesTags: (result, error, { tableId }) => [ViewerInvalidations.DATA(tableId)],
      query: ({ tableId, page, size, where, order, source_env }) => {
        const { name, schema } = getTableNameWithSchema(tableId);
        return {
          params: { page, size, where, order, ch_db: schema, source_env },
          url: ViewerApiRoutes.data(name),
          timeout: 0,
        };
      },
    }),
    tableInfo: builder.query<ViewerCHTableInfoResponse, { tableName: string; fallbackCHDB: string }>({
      providesTags: (result, error, { tableName }) => [ViewerInvalidations.TABLE_INFO(tableName)],
      query: ({ tableName, fallbackCHDB }) => {
        const { tableId, ch_db } = getCHTableNameAndDB(tableName, fallbackCHDB);
        return {
          params: { ch_db },
          url: ViewerApiRoutes.tableInfo(tableId),
        };
      },
    }),
    tracedInfo: builder.query<string, ViewerTracedInfoParams>({
      providesTags: (result, error, { tableId, rowKey, targetColumn }) => [
        ViewerInvalidations.TRACED_INFO(tableId, rowKey, targetColumn),
      ],
      query: ({ tableId, rowKey, targetColumn }) => {
        const { name, schema } = getTableNameWithSchema(tableId);
        return {
          params: { ch_db: schema, row_key: rowKey, target_column: targetColumn },
          url: ViewerApiRoutes.tracedInfo(name),
          timeout: 0,
        };
      },
    }),
    exportData: builder.mutation<string, ViewerExportQuery>({
      invalidatesTags: (result, error, data) => [ViewerInvalidations.EXPORT(data)],
      queryFn: async ({ callback, ...params }) => {
        return appAxios
          .post(ViewerApiRoutes.tableExport(getTableNameWithSchema(params.tableId).name), params)
          .then(async ({ data }: ViewerExportResponse) => {
            while (true) {
              const response: ViewerExportResultResponse = await appAxios.get(
                ViewerApiRoutes.tableExportResult(data.id),
              );
              if (response.data.result) {
                const result = JSON.parse(response.data.result);
                if (result.url) {
                  callback(100);
                  await sleep(500);
                  return { data: result.url };
                }
                if (result.error) {
                  console.error(result.error);
                  return Promise.reject(new Error(result.error));
                }
              }
              callback();
              await sleep(5000);
            }
          })
          .catch((error) => {
            return { error: handleQueryError(error) };
          });
      },
    }),
    exportDataInBackground: builder.mutation<string, ViewerExportQuery>({
      invalidatesTags: (result, error, data) => [ViewerInvalidations.EXPORT(data)],
      queryFn: async ({ callback, ...params }) => {
        return appAxios
          .post(ViewerApiRoutes.tableExport(getTableNameWithSchema(params.tableId).name), params)
          .then(({ data }: ViewerExportResponse) => ({ data: data.id }))
          .catch((error) => {
            return { error: handleQueryError(error) };
          });
      },
    }),
    externalTables: builder.query<Record<EXTERNAL_STORES, string[]>, void>({
      providesTags: [ViewerInvalidations.EXTERNAL_TABLES],
      query: () => ({
        url: ViewerApiRoutes.externalTables,
      }),
    }),
    groups: builder.query<GroupsDataResponse, void>({
      providesTags: [ViewerInvalidations.GROUPS],
      query: () => ({
        url: ViewerApiRoutes.groups,
      }),
    }),
    tablesExist: builder.query<Record<string, string>, string[]>({
      providesTags: [ViewerInvalidations.TABLES_EXIST],
      query: (tables) => ({
        data: { tables },
        method: 'POST',
        url: ViewerApiRoutes.tablesExist,
      }),
    }),
    tablesInSqlExist: builder.query<Record<string, string>, string[]>({
      providesTags: [ViewerInvalidations.TABLES_IN_SQL_EXIST],
      query: (sqlStatements) => ({
        data: { sql_statements: sqlStatements },
        method: 'POST',
        url: ViewerApiRoutes.tablesInSqlExist,
      }),
    }),
    crossStudyRTList: builder.query<string[], { studyId?: number }>({
      providesTags: [ViewerInvalidations.CROSS_STUDY_RT_LIST],
      query: (params = {}) => ({
        params: { ...(params?.studyId && { study_id: params.studyId }) },
        url: ViewerApiRoutes.crossStudyRT,
      }),
    }),
    studyRTList: builder.query<string[], { studyId?: number }>({
      providesTags: [ViewerInvalidations.CROSS_STUDY_RT_LIST],
      query: (params = {}) => ({
        params: { ...(params?.studyId && { study_id: params.studyId }) },
        url: ViewerApiRoutes.studyRT,
      }),
    }),
    referenceTableInfo: builder.query<ReferenceTableInfo[], { table: string; studyId: number }>({
      providesTags: (result, error, { table }) => [ViewerInvalidations.TABLE_INFO_RT(table)],
      query: (params) => {
        const [schema, table] = params.table.split('.');
        return {
          params: { ch_db: schema, study_id: params.studyId },
          url: ViewerApiRoutes.referenceTableInfo(table),
        };
      },
    }),
    tables: builder.query<TableResponse, { study_id: number | string; source_env?: string; role_id?: number }>({
      providesTags: [ViewerInvalidations.TABLES_LIST],
      query: (params) => ({
        params,
        url: ViewerApiRoutes.tables,
      }),
    }),
    tasksActive: builder.query<Task[], void>({
      providesTags: [ViewerInvalidations.TASKS_ACTIVE],
      query: () => ({
        url: ViewerApiRoutes.tasksActive,
      }),
    }),
    globalTables: builder.query<TableResponse, { study_id: number | string; source_env?: string }>({
      providesTags: [ViewerInvalidations.GLOBAL_TABLES_LIST],
      query: (params) => ({
        params,
        url: ViewerApiRoutes.globalTables,
      }),
    }),
  }),
});

export const {
  useDataQuery,
  useDataPaginatedQuery,
  useLazyDataPaginatedQuery,
  useExternalTablesQuery,
  useLazyTableInfoQuery,
  useTracedInfoQuery,
  useLazyTracedInfoQuery,
  useTableInfoQuery,
  useExportDataMutation,
  useExportDataInBackgroundMutation,
  useGroupsQuery,
  useLazyTablesExistQuery,
  useLazyTablesInSqlExistQuery,
  useCrossStudyRTListQuery,
  useStudyRTListQuery,
  useLazyStudyRTListQuery,
  useLazyCrossStudyRTListQuery,
  useLazyReferenceTableInfoQuery,
  useTablesQuery,
  useTasksActiveQuery,
  useGlobalTablesQuery,
} = ViewerApi;

export type ViewerDataResponse = {
  meta: { name: string; type: string }[];
  data: Record<string, any>[];
};

export type TableGroup = {
  id?: number;
  name: string;
  type: ViewerGroupType;
  tables: string[];
};

export type TableResponse = TableGroup[];

export interface ViewerDataPaginationResponse extends Pick<ViewerDataResponse, 'meta'> {
  items: ViewerDataResponse['data'];
  currentPage: number;
  totalItems: number;
  totalPages: number;
  options: {
    row_key_pattern: string;
    filtered: boolean;
    reordered: boolean;
    blinded: boolean;
  };
}

interface ViewerExportQuery extends ViewerExportFormValues {
  tableId: string;
  callback: (value?: number) => void;
  where?: string;
  order?: string;
}

interface ViewerExportResponse {
  data: {
    id: string;
  };
}

interface ViewerExportResultResponse {
  data: {
    result: string;
    finished: boolean;
  };
}

export interface ViewerDataPaginationParams {
  page: number;
  size: number;
  tableId: string;
  where?: string;
  order?: string;
  source_env?: string;
}

export interface ReferenceTableInfo {
  name: string;
  description: string;
  type: string;
  length: number | null;
  is_nullable: boolean;
  is_primary_key: boolean;
}
