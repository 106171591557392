import { useColumnSearch } from '@components/ui/table/tableHooks';
import { Popover, Table, Checkbox } from '@ui';
import { SELECTED_ACTIONS } from '@shared/GlobalConstants';
import { selectGlobalStudy } from '@app/duck/appSelectors';
import { ViewerGroupType } from '@modules/viewer/ViewerTypes';
import { convertOptionsToFilterItems, TableFilterItem } from '@components';
import { TableColumnsType } from 'antd';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TableRowSelection } from 'antd/es/table/interface';
import { useSelector } from 'react-redux';
import { snapshotTableListOnFilter } from './SnapshotTableList';
import { useSnapshotSourceTableListQuery } from '../duck/snapshotApi';
import {
  snapshotCategoryAggregation,
  SnapshotCategoryOptionHelper,
  SnapshotStoreAggregateSeparator,
} from '../duck/snapshotUtils';

const isValidSchema = (record: ISnapshotTableItem, studySchema: string) => record.originalId.includes(studySchema);

export const SnapshotTableSelect = memo(({ onSelectTable, selectedTables, disabled }: ISnapshotTableSelectProps) => {
  const { t } = useTranslation(['snapshot']);
  const { getColumnSearchProps, locale } = useColumnSearch<ISnapshotTableItem>();
  const globalStudy = useSelector(selectGlobalStudy);
  const sourceTableListQuery = useSnapshotSourceTableListQuery();

  const data = useMemo(() => {
    const tempData = (sourceTableListQuery?.data ?? []).reduce<ISnapshotTableItem[]>((acc, store, storeIndex) => {
      if (store.tables?.length) {
        store.tables.forEach((table, index) => {
          acc.push({
            key: `${storeIndex}${index}:${table}`,
            originalId: table,
            tableName: table.split('.')[1],
            store: store.name,
            type: store.type,
          });
        });
      }
      return acc;
    }, []);

    return {
      tables: snapshotCategoryAggregation(tempData),
      storeOptions: (sourceTableListQuery?.data ?? []).map((store) =>
        SnapshotCategoryOptionHelper.getOption(store.name, store.type),
      ),
    };
  }, [sourceTableListQuery?.data]);

  const columns: TableColumnsType<(typeof data.tables)[0]> = useMemo(
    () => [
      {
        title: t('createPage.category'),
        dataIndex: 'store',
        filters: convertOptionsToFilterItems(data.storeOptions),
        onFilter: snapshotTableListOnFilter,
        render: (_, record) => (
          <TableFilterItem
            title={Array.isArray(record.store) ? record.store.join(SnapshotStoreAggregateSeparator) : record.store}
            description={record.description}
          />
        ),
      },
      {
        title: t('createPage.tableName'),
        dataIndex: 'tableName',
        key: 'tableName',
        ...getColumnSearchProps('tableName'),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data],
  );

  const rowSelection: TableRowSelection<ISnapshotTableItem> = {
    onChange: (selectedRowKeys) => {
      onSelectTable(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: disabled || !isValidSchema(record, globalStudy?.studySchema!),
      name: `selected-${record.key}`,
    }),
    renderCell: (value, record, index, originNode) =>
      isValidSchema(record, globalStudy?.studySchema!) ? (
        originNode
      ) : (
        <Popover title={t('errors.tableHasWrongSchema', { name: record.tableName })} color="yellow">
          <Checkbox id={`selected-${record.key}`} disabled />
        </Popover>
      ),
  };

  return (
    <Table
      size="small"
      bordered
      columns={columns}
      locale={locale}
      loading={sourceTableListQuery.isLoading}
      rowKey="key"
      dataSource={data.tables}
      pagination={false}
      rowSelection={{
        preserveSelectedRowKeys: true,
        type: 'checkbox',
        ...rowSelection,
        selectedRowKeys: selectedTables,
        selections: [
          Table.SELECTION_INVERT,
          {
            key: SELECTED_ACTIONS.SHOW_ALL,
            text: 'Select all',
            onSelect: () => onSelectTable(data.tables.map((table) => table.key)),
          },
          {
            key: SELECTED_ACTIONS.HIDE_SELECTED,
            text: 'Unselect all',
            onSelect: () => onSelectTable([]),
          },
        ],
      }}
    />
  );
});

interface ISnapshotTableSelectProps {
  onSelectTable: (keys: React.Key[]) => void;
  selectedTables: React.Key[];
  disabled?: boolean;
}

interface ISnapshotTableItem {
  key: string;
  originalId: string;
  tableName: string;
  store: string;
  description?: string;
  type: ViewerGroupType;
}
