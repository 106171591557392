import { crossStudyInternalMenu } from '@routes/appMenu';
import { selectGlobalStudy } from '@app/duck/appSelectors';
import { useSelector } from 'react-redux';
import { StudySiderTemplate } from './StudySiderTemplate';

export const CrossStudySider = () => {
  const globalStudy = useSelector(selectGlobalStudy);

  return <StudySiderTemplate menu={crossStudyInternalMenu(globalStudy?.id!)} />;
};
