import { QueryErrorType } from '@shared/utils/Error';
import { Space, Typography } from '@ui';
import { InformationModal } from '@components';
import { AntdIconBox } from '@components/icons';
import { ArrowRightOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import i18n from 'i18next';
import { useState } from 'react';
import { CSSObject } from '@emotion/react';

export const NotificationErrorDescription = ({ error, visibleSymbols = 300 }: NotificationErrorDescriptionProps) => {
  const [errorDetails, setErrorDetails] = useState('');

  const originMessage = error.data?.rawData?.message;
  const url = error.data?.rawData?.url;
  const link = `${window.location.origin}${error.data?.rawData?.url}`;

  const renderMessage = () => {
    if (originMessage.length > visibleSymbols) {
      return (
        <>
          {originMessage.slice(0, visibleSymbols)}...
          <AntdIconBox
            css={cssAntdIconBox}
            icon={QuestionCircleOutlined}
            tip={i18n.t('shared.notification.fullErrorMessage')}
            onClick={() => setErrorDetails(originMessage)}
          />
        </>
      );
    }
    return originMessage;
  };

  const clearErrorMessage = () => setErrorDetails('');

  return (
    <Space direction="vertical">
      <div>{renderMessage()}</div>
      {url && (
        <Space>
          <ArrowRightOutlined />
          <Typography.Link href={link} target="_blank">
            {i18n.t('notification.descriptionLinkName')}
          </Typography.Link>
        </Space>
      )}
      {errorDetails && (
        <InformationModal title={i18n.t('errorDetails')} message={errorDetails} onClose={clearErrorMessage} />
      )}
    </Space>
  );
};

const cssAntdIconBox = (): CSSObject => ({
  paddingLeft: 8,
});

interface NotificationErrorDescriptionProps {
  error: QueryErrorType;
  visibleSymbols?: number;
}
