import { appApi } from '@config/appApi';
import { IDataset } from '@modules/dataset/DatasetTypes';
import { EnvDataListQueryBase } from '@shared/GlobalTypes';
import { TagDescription } from '@reduxjs/toolkit/query/react';
import { ANALYSIS_OBJECT_SQL_DATASET_TAG_DESCRIPTION } from './analysisObjectSQLDatasetConstants';
import {
  IAnalysisObjectSQLDatasetListQueryParams,
  IAnalysisObjectSQLDatasetListResponse,
  IAnalysisObjectSQLDatasetQuery,
  TAnalysisObjectSQLDatasetFiltersResponse,
} from '../AnalysisObjectSQLDatasetTypes';

export const AnalysisObjectSQLDatasetApiRoutes = {
  list: '/api/analysis/sql-lab',
  item: (analysisObjectId: number) => `/api/analysis/sql-lab/${analysisObjectId}`,
  filters: '/api/analysis/sql-lab/filters',
  envSQLDatasetList: `/api/external/env/sql-lab`,
};

const AnalysisObjectSQLDatasetInvalidations: {
  LIST: TagDescription<ANALYSIS_OBJECT_SQL_DATASET_TAG_DESCRIPTION.LIST>;
  ITEM: (analysisObjectId: number) => TagDescription<ANALYSIS_OBJECT_SQL_DATASET_TAG_DESCRIPTION.ITEM>;
  FILTERS: TagDescription<ANALYSIS_OBJECT_SQL_DATASET_TAG_DESCRIPTION.FILTERS>;
} = {
  LIST: { type: ANALYSIS_OBJECT_SQL_DATASET_TAG_DESCRIPTION.LIST, id: 'LIST' },
  ITEM: (analysisObjectId: number) => ({ type: ANALYSIS_OBJECT_SQL_DATASET_TAG_DESCRIPTION.ITEM, analysisObjectId }),
  FILTERS: { type: ANALYSIS_OBJECT_SQL_DATASET_TAG_DESCRIPTION.FILTERS, id: 'FILTERS' },
};

export const AnalysisObjectSQLDatasetApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    analysisObjectSQLDatasetList: builder.query<
      IAnalysisObjectSQLDatasetListResponse,
      IAnalysisObjectSQLDatasetListQueryParams | void
    >({
      providesTags: [AnalysisObjectSQLDatasetInvalidations.LIST],
      query: (params) => ({
        params,
        url: AnalysisObjectSQLDatasetApiRoutes.list,
      }),
    }),
    analysisObjectSQLDatasetFilters: builder.query<TAnalysisObjectSQLDatasetFiltersResponse, void>({
      providesTags: [AnalysisObjectSQLDatasetInvalidations.FILTERS],
      query: (params) => ({
        params,
        url: AnalysisObjectSQLDatasetApiRoutes.filters,
      }),
    }),
    envSQLDatasetList: builder.query<IDataset[], EnvDataListQueryBase>({
      query: (params) => ({
        params,
        url: AnalysisObjectSQLDatasetApiRoutes.envSQLDatasetList,
      }),
    }),
    deleteAnalysisObjectSQLDataset: builder.mutation<void, number>({
      invalidatesTags: (request, error, analysisObjectId) => [
        AnalysisObjectSQLDatasetInvalidations.LIST,
        AnalysisObjectSQLDatasetInvalidations.ITEM(analysisObjectId),
      ],
      query: (analysisObjectId) => ({
        method: 'DELETE',
        url: AnalysisObjectSQLDatasetApiRoutes.item(analysisObjectId),
      }),
    }),
    importAnalysisObjectSQLDataset: builder.mutation<void, IAnalysisObjectSQLDatasetQuery>({
      invalidatesTags: [AnalysisObjectSQLDatasetInvalidations.LIST],
      query: ({ data, source }) => ({
        params: { source },
        data,
        method: 'POST',
        url: AnalysisObjectSQLDatasetApiRoutes.list,
      }),
    }),
  }),
});

export const {
  useAnalysisObjectSQLDatasetListQuery,
  useAnalysisObjectSQLDatasetFiltersQuery,
  useLazyEnvSQLDatasetListQuery,
  useDeleteAnalysisObjectSQLDatasetMutation,
  useImportAnalysisObjectSQLDatasetMutation,
} = AnalysisObjectSQLDatasetApi;
