import { I18nLocales } from '@i18n';
import en from './datamapper_en.json';

export const dataMapperLocales: Record<I18nLocales, I18nDataMapperTranslations> = {
  en,
};

export interface I18nDataMapperTranslations {
  source: {
    title: string;
  };
  target: {
    title: string;
  };
  editFieldForm: {
    addTitle: string;
    editTitle: string;
    nameLabel: string;
    typeLabel: string;
  };
}
