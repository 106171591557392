import { Loader, PageTemplateSimple } from '@components';
import { TAuthStudyRolesPermissions } from '@modules/auth/AuthTypes';
import { ReactElement } from 'react';
import { useStudyPermissions } from './duck/userHooks';

export const DefaultDeniedFallback = () => {
  return (
    <PageTemplateSimple
      title={{ children: 'Permission Denied' }}
      content={{ error: { code: '403', data: { userMsg: 'Permission Denied' } } }}
    />
  );
};

export const UserRoleGate = ({ children, deniedFallback, loaderFallback, ...targetPermissions }: UserRoleGateProps) => {
  const { userPermissions, userPermissionsReady } = useStudyPermissions();

  if (!userPermissionsReady) {
    return loaderFallback || <Loader mode="fixed" size="large" />;
  }

  if (
    Object.keys(targetPermissions).every((p) => (userPermissions || {})[p as keyof TAuthStudyRolesPermissions] === true)
  ) {
    return children;
  }
  return deniedFallback || <DefaultDeniedFallback />;
};

interface UserRoleGateProps extends Partial<Record<keyof TAuthStudyRolesPermissions, true>> {
  children: ReactElement;
  deniedFallback?: ReactElement;
  loaderFallback?: ReactElement;
}
