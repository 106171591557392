import dayjs from 'dayjs';

export const DEFAULT_SCHEDULER_TIME_FROM = dayjs().hour(8).minute(0).valueOf();
export const DEFAULT_SCHEDULER_TIME_TO = dayjs().hour(17).minute(0).valueOf();

export const PERIODICALLY_EVERY_OPTIONS = {
  hour: { label: 'Hour', value: 0 },
  day: { label: 'Day', value: 1 },
  week: { label: 'Week', value: 7 },
  month: { label: 'Month', value: 30 },
  year: { label: 'Year', value: 360 },
};
export const PERIODICALLY_WEEKDAYS: Array<{ label: string; value: number }> = Array.from({ length: 7 }).map(
  (_empty, i) => ({
    label: dayjs.weekdays()[(i + 1) % 7], // Need to do this because CRON weekdays start from Monday
    value: i,
  }),
);

export const PERIODICALLY_MONTHS: Array<{ label: string; value: number }> = dayjs.months().map((month, i) => ({
  label: month,
  value: i + 1,
}));
export const PERIODICALLY_HOUR_OPTIONS = Array.from({ length: 12 }).map((v, i) => ({
  label: i + 1,
  value: i + 1,
}));
export const PERIODICALLY_DAYS_ALL_VALUES = PERIODICALLY_WEEKDAYS.map((v) => v.value.toString());

export const DEFAULT_INITIAL_VALUE = {
  run_once: 'once',
  runOnceAt: dayjs().add(1, 'hours'),
};
