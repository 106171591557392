import ace from 'ace-builds/src-noconflict/ace';
import { FUNCTIONS } from './ExpressionSystemData';

export class CustomHighlightRules extends ace.acequire('ace/mode/text_highlight_rules').TextHighlightRules {
  constructor() {
    super();
    this.$rules = {
      start: [
        {
          token: 'string',
          regex: '".*?"',
        },
        {
          token: 'entity.name.function',
          regex: `\\b(${FUNCTIONS.map((item) => item.name).join('|')})\\b`,
        },
        {
          token: 'keyword.operator',
          regex: `(\\[|]|\\(|\\)|-|\\+|\\*|\\/|\\^|%|=|<>|>=|<=|<|>|and|or)`,
        },
        {
          token: 'variable.language',
          regex: `\\b(${(CustomHighlightRules.prototype.variables || []).join('|')})\\b`,
          caseInsensitive: true,
        },
      ],
    };
  }
}

export default class CustomHighlightMode extends ace.acequire('ace/mode/text').Mode {
  constructor(variables: string[]) {
    super();
    CustomHighlightRules.prototype.variables = variables;
    this.HighlightRules = CustomHighlightRules;
  }
}
