import { useColumnSearch } from '@components/ui/table/tableHooks';
import { capitalizeFirstLetter } from '@shared/utils/Form';
import { getTableListOption } from '@shared/utils/Table';
import { NOTEBOOKS_TYPES } from '@modules/library/notebook/duck/libraryNotebookConstants';
import { TableColumnsType } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ILibraryImportNotebook } from '../components/LibraryImportNotebook';

export const useNotebookColumns = () => {
  const { getColumnSearchProps, locale } = useColumnSearch<ILibraryImportNotebook>();
  const { t } = useTranslation(['libraryNotebook']);

  const notebookColumns: TableColumnsType<ILibraryImportNotebook> = useMemo(
    () => [
      {
        title: t('rootTable.name'),
        dataIndex: 'name',
        key: 'name',
        ...getColumnSearchProps('name'),
      },
      {
        title: t('rootTable.type'),
        dataIndex: 'object_type',
        filters: getTableListOption(NOTEBOOKS_TYPES, true),
        onFilter: (value, record) => value === record.object_type,
        render: (val) => capitalizeFirstLetter(val),
      },
      {
        title: t('rootTable.version'),
        dataIndex: 'version',
        render: (_, record) => record.version || '-',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return { notebookColumns, locale };
};
