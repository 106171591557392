import { getMenuItem, Menu } from '@ui';
import { MenuType } from '@routes/appMenu';
import { InternalSider } from '@app/components';
import { Link, useMatches } from 'react-router-dom';
import { CSSObject, Theme } from '@emotion/react';

export const StudySiderTemplate = ({ menu }: IStudySiderTemplateProps) => {
  const matches = useMatches();

  return (
    <InternalSider adjustable={false}>
      <Menu
        css={cssStudyDetailsMenu}
        selectedKeys={[matches.at(-1)?.id!]}
        items={menu.map((menu) =>
          getMenuItem({ label: <Link to={menu.path} children={menu.title} />, key: menu.key, icon: menu.icon }),
        )}
      />
    </InternalSider>
  );
};

interface IStudySiderTemplateProps {
  menu: MenuType;
}

const cssStudyDetailsMenu = (theme: Theme): CSSObject => ({
  '&& .ant-menu-item-selected': {
    backgroundColor: theme.colorBorder,
    borderRadius: 0,
  },
  '& .ant-menu-item': {
    borderRadius: 0,
    minHeight: '64px',
    margin: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
  },
  '&& .ant-menu-item-icon': {
    fontSize: '1.5rem',
    marginBottom: '0.25rem',
  },
  '&&& .ant-menu-title-content': {
    marginInlineStart: 0,
    display: 'inline-block',
    width: '100%',
    whiteSpace: 'break-spaces',
    lineHeight: '1rem',
    fontSize: '0.8rem',
  },
  '&&&': {
    borderInlineEnd: 'none',
    backgroundColor: theme.colorBorderSecondary,
  },
});
