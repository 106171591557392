import { PageTemplateSimple } from '@components';
import { useStudyListQuery } from '@modules/study/duck/studyApi';
import { StudyList } from '@modules/study/components';
import { useTranslation } from 'react-i18next';
import sortBy from 'lodash/sortBy';

export const StudyRootPage = () => {
  const studiesQuery = useStudyListQuery();
  const { t } = useTranslation(['study']);

  const studies = sortBy(studiesQuery.data, 'name');

  return (
    <PageTemplateSimple
      hideTitleSkeleton
      title={{ children: t('rootPage.title') }}
      content={{
        isLoading: studiesQuery.isLoading,
        errorText: t('rootPage.loadingError'),
        error: studiesQuery.error,
      }}
    >
      {studiesQuery.data && <StudyList studies={studies} />}
    </PageTemplateSimple>
  );
};
