import { DirectoryTree, DraggableModal, Skeleton, Table } from '@ui';
import { useColumnSearch } from '@components/ui/table/tableHooks';
import { useSchemaTablesQuery } from '@modules/study/duck/studyApi';
import { SchemaTable, SchemaTableColumn } from '@modules/study/StudyTypes';
import { QueryErrorType } from '@shared/utils/Error';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { CSSObject } from '@emotion/react';
import { Button, Col, notification, Row, TableColumnsType } from 'antd';
import { get } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { DirectoryTreeProps } from 'antd/es/tree';
import Scrollbars from 'react-custom-scrollbars-2';
import { TableOutlined } from '@ant-design/icons';

const StudyModalsDbSchemaContent = ({ t }: StudyModalsDbSchemaContentProps) => {
  const { getColumnSearchProps, locale } = useColumnSearch<SchemaTableColumn>();
  const [selectedTable, setSelectedTable] = useState<string>();
  const schemaTablesQuery = useSchemaTablesQuery();

  useEffect(() => {
    if (schemaTablesQuery.error) {
      notification.error({ message: (schemaTablesQuery.error as QueryErrorType).data.userMsg });
    }
  }, [schemaTablesQuery.error]);

  const tablesList = useMemo(() => {
    const tables = get(schemaTablesQuery.data, 'tables', [] as SchemaTable[]);
    const views = get(schemaTablesQuery.data, 'views', [] as SchemaTable[]);

    return [
      {
        title: t('tables'),
        key: 'tables',
        children: tables.map((table) => ({
          title: table.name,
          key: `tables#${table.name}`,
          isLeaf: true,
          icon: <TableOutlined />,
        })),
      },
      {
        title: t('views'),
        key: 'views',
        children: views.map((view) => ({
          title: view.name,
          key: `views#${view.name}`,
          isLeaf: true,
          icon: <TableOutlined />,
        })),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schemaTablesQuery.data]);

  const columnsList = useMemo(() => {
    if (selectedTable) {
      const [type, name] = selectedTable.split('#');

      const table = get(schemaTablesQuery.data, type, [] as SchemaTable[]).find((item) => item.name === name);
      if (table) {
        return table.schema.map((column, index) => ({ ...column, rowNumber: index + 1 }));
      }
    }

    return [] as SchemaTableColumn[];
  }, [schemaTablesQuery.data, selectedTable]);

  const columns: TableColumnsType<SchemaTableColumn> = useMemo(
    () => [
      {
        width: 60,
        title: t('dbSchema.rowNumber'),
        dataIndex: 'rowNumber',
      },
      {
        title: t('dbSchema.name'),
        dataIndex: 'name',
        key: 'name',
        ...getColumnSearchProps('name'),
      },
      {
        width: 200,
        title: t('dbSchema.type'),
        dataIndex: 'type',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onSelect: DirectoryTreeProps['onSelect'] = (keys, info) => {
    if (info.node.isLeaf) {
      setSelectedTable(info.node.key as string);
    } else {
      setSelectedTable(undefined);
    }
  };

  if (schemaTablesQuery.isLoading || schemaTablesQuery.isFetching) {
    return <Skeleton active />;
  }

  return (
    <div css={cssModal}>
      <Row gutter={24}>
        <Col span={8}>
          <Scrollbars autoHide={false} autoHeight autoHeightMin={500}>
            <DirectoryTree css={cssTree} defaultExpandAll treeData={tablesList} onSelect={onSelect} />
          </Scrollbars>
        </Col>
        <Col span={16}>
          <Scrollbars autoHide={false} autoHeight autoHeightMin={500}>
            <Table
              locale={locale}
              columns={columns}
              dataSource={columnsList}
              size="small"
              rowKey={(item) => item.name}
              tableLayout="fixed"
              pagination={false}
            />
          </Scrollbars>
        </Col>
      </Row>
    </div>
  );
};

export const StudyModalsDbSchema = ({ open, onClose }: StudyModalsDbSchemaProps) => {
  const { t } = useTranslation(['study']);

  return (
    <DraggableModal
      width={1000}
      open={open}
      title={t('dbSchema.title')}
      onCancel={onClose}
      footer={
        <Button type="primary" onClick={onClose}>
          Ok
        </Button>
      }
      destroyOnClose
    >
      {open && <StudyModalsDbSchemaContent onClose={onClose} t={t} />}
    </DraggableModal>
  );
};

const cssModal = (): CSSObject => ({
  height: '500px',
});

const cssTree = (): CSSObject => ({
  '&&.ant-tree': {
    '&& .ant-tree-node-content-wrapper': {
      display: 'flex',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    '&& .ant-tree-title': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '100%',
    },
    '&& .ant-tree-switcher': {
      display: 'none',
    },
  },
});

export interface StudyModalsDbSchemaProps {
  open: boolean;
  onClose: () => void;
}

interface StudyModalsDbSchemaContentProps extends Pick<StudyModalsDbSchemaProps, 'onClose'> {
  t: TFunction;
}
