import { ESiderType } from '@modules/study/StudyTypes';
import { StudySider } from '@modules/study/components/StudySider';
import { AnalysisObjectModelModalsController } from '@modules/library/analysisObjects/model/modals/AnalysisObjectModelModalsController';
import { FallbackRender } from '@app/components';
import { CSSObject } from '@emotion/react';
import { Outlet } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

export const StudyAnalysisObjectsApp = () => {
  return (
    <div css={cssBody}>
      <StudySider kind={ESiderType.AnalysisObject} />
      <div css={cssContent}>
        <ErrorBoundary FallbackComponent={FallbackRender}>
          <Outlet />
        </ErrorBoundary>
      </div>
      <AnalysisObjectModelModalsController />
    </div>
  );
};

const cssBody = (): CSSObject => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  position: 'relative',
});

const cssContent = (): CSSObject => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  overflowX: 'hidden',
  overflowY: 'auto',
});
