import { AUDIT_TAG_DESCRIPTION } from '@modules/audit/duck/auditConstants';
import { appAxiosBaseQuery, AppBaseQueryFn } from '@config/AppConfig';
import { SOURCE_TAG_DESCRIPTION } from '@modules/source/duck/sourceConstants';
import { STORE_TAG_DESCRIPTION } from '@modules/stores/duck/storeConstants';
import { VIEWER_TAG_DESCRIPTION } from '@modules/viewer/duck/viewerConstants';
import { JOB_TAG_DESCRIPTION } from '@modules/job/duck/jobConstants';
import { MODEL_TAG_DESCRIPTION } from '@modules/model/duck/modelConstants';
import { USER_TAG_DESCRIPTION } from '@modules/user/duck/userConstants';
import { STUDY_TAG_DESCRIPTION } from '@modules/study/duck/studyConstants';
import { LIBRARY_TAG_DESCRIPTION } from '@modules/library/root/duck/libraryConstants';
import { LIBRARY_MODEL_TAG_DESCRIPTION } from '@modules/library/model/duck/libraryModelConstants';
import { LIBRARY_CDR_TAG_DESCRIPTION } from '@modules/library/cdr/duck/libraryCdrConstants';
import { LIBRARY_AUDIT_TAG_DESCRIPTION } from '@modules/library/audit/duck/libraryAuditConstants';
import { ANALYSIS_PACKAGE_TAG_DESCRIPTION } from '@modules/library/analysisPackage/duck/analysisPackageConstants';
import { ANALYSIS_OBJECT_MODEL_TAG_DESCRIPTION } from '@modules/library/analysisObjects/model/duck/analysisObjectModelConstants';
import { ANALYSIS_OBJECT_CDR_TAG_DESCRIPTION } from '@modules/library/analysisObjects/cdr/duck/analysisObjectCDRConstants';
import { LIBRARY_SQL_LAB_TAG_DESCRIPTION } from '@modules/library/sqlLab/duck/librarySqlLabConstants';
import { LIBRARY_NOTEBOOK_TAG_DESCRIPTION } from '@modules/library/notebook/duck/libraryNotebookConstants';
import { SNAPSHOT_TAG_DESCRIPTION } from '@modules/snapshot/duck/snapshotConstants';
import { AUTH_TAG_DESCRIPTION } from '@modules/auth/duck/authConstants';
import { GPDIP_TAG_DESCRIPTION } from '@modules/gpdip/duck/gpdipConstants';
import { DATASET_TAG_DESCRIPTION } from '@modules/dataset/duck/datasetConstants';
import { ANALYSIS_OBJECT_SQL_DATASET_TAG_DESCRIPTION } from '@modules/library/analysisObjects/sqlDataset/duck/analysisObjectSQLDatasetConstants';
import { createApi } from '@reduxjs/toolkit/query/react';

export const appApiTagTpes = [
  ...Object.values(AUTH_TAG_DESCRIPTION),
  ...Object.values(STUDY_TAG_DESCRIPTION),
  ...Object.values(AUDIT_TAG_DESCRIPTION),
  ...Object.values(SOURCE_TAG_DESCRIPTION),
  ...Object.values(STORE_TAG_DESCRIPTION),
  ...Object.values(VIEWER_TAG_DESCRIPTION),
  ...Object.values(JOB_TAG_DESCRIPTION),
  ...Object.values(MODEL_TAG_DESCRIPTION),
  ...Object.values(USER_TAG_DESCRIPTION),
  ...Object.values(LIBRARY_TAG_DESCRIPTION),
  ...Object.values(LIBRARY_MODEL_TAG_DESCRIPTION),
  ...Object.values(LIBRARY_CDR_TAG_DESCRIPTION),
  ...Object.values(LIBRARY_SQL_LAB_TAG_DESCRIPTION),
  ...Object.values(LIBRARY_NOTEBOOK_TAG_DESCRIPTION),
  ...Object.values(LIBRARY_AUDIT_TAG_DESCRIPTION),
  ...Object.values(ANALYSIS_PACKAGE_TAG_DESCRIPTION),
  ...Object.values(ANALYSIS_OBJECT_MODEL_TAG_DESCRIPTION),
  ...Object.values(ANALYSIS_OBJECT_CDR_TAG_DESCRIPTION),
  ...Object.values(SNAPSHOT_TAG_DESCRIPTION),
  ...Object.values(GPDIP_TAG_DESCRIPTION),
  ...Object.values(DATASET_TAG_DESCRIPTION),
  ...Object.values(ANALYSIS_OBJECT_SQL_DATASET_TAG_DESCRIPTION),
];

const appApi = createApi({
  reducerPath: 'AppApi',
  tagTypes: appApiTagTpes,
  invalidationBehavior: 'immediately',
  baseQuery: appAxiosBaseQuery() as AppBaseQueryFn,
  endpoints: (builder) => ({
    simple: builder.query<any, { url: string } & Record<string, any>>({
      query: (args) => args,
    }),
  }),
});

const { useLazySimpleQuery, useSimpleQuery } = appApi;
const useLazySimpleQuerySubscription = appApi.endpoints.simple.useLazyQuerySubscription;

const invalidateStudyQueryTags = appApiTagTpes.filter(
  (tag) =>
    ![
      USER_TAG_DESCRIPTION.CURRENT_USER,
      STUDY_TAG_DESCRIPTION.LIST,
      LIBRARY_TAG_DESCRIPTION.LIST,
      STUDY_TAG_DESCRIPTION.CONTEXT,
      AUTH_TAG_DESCRIPTION.STUDY_ROLES,
      VIEWER_TAG_DESCRIPTION.TABLE_INFO_RT,
    ].includes(tag as any),
);

export { appApi, useLazySimpleQuery, useSimpleQuery, useLazySimpleQuerySubscription, invalidateStudyQueryTags };
