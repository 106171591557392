export enum USER_TAG_DESCRIPTION {
  'CURRENT_USER' = 'USER_CURRENT',
}

export const permissionsMock = {
  DATA_SOURCE_INSERT: true,
  DATA_SOURCE_UPDATE: false,
  DATA_SOURCE_DELETE: true,
  DATA_STORE_INSERT: true,
  DATA_STORE_UPDATE: false,
  DATA_STORE_DELETE: true,
  AUDIT_LOG_READ: false,
  AUDIT_LOG_EXPORT: false,
  DATA_MODEL_READ: true,
  DATA_MODEL_INSERT: true,
  DATA_MODEL_UPDATE: false,
  DATA_MODEL_DELETE: false,
  DATA_MODEL_CONFIGURE: false,
  DATA_MODEL_RUN: false,
  DATA_MODEL_RT_SWITCH: false,
  DATA_VIEWER_EXPORT: true,
  DATA_VIEWER_RT_SWITCH: true,
  IMPORT_JOB_READ: true,
  IMPORT_JOB_UPLOAD: true,
  IMPORT_JOB_DELETE: true,
  IMPORT_JOB_EDIT_STRUCTURE: true,
  REF_TABLE_UPLOAD: false,
  SNAPSHOTS_READ: true,
  SNAPSHOTS_INSERT: true,
  GL_DM_AO_READ: true,
  GL_DM_AO_IMPORT: false,
  GL_DM_AO_DELETE: false,
  GL_CDR_AO_READ: true,
  GL_CDR_AO_IMPORT: false,
  GL_CDR_AO_DELETE: false,
  GL_AP_READ: true,
  GL_AP_UPDATE: false,
  GL_DEPLOYMENT_TO_PROD: false,
  GL_DEPLOYMENT_NOT_TO_PROD: true,
};
