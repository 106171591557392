import { selectModelEditorSchemaEditor } from '@modules/modelEditor/duck/modelEditorSelectors';
import { modelEditorActions } from '@modules/modelEditor/duck/modelEditorSlice';
import { ModelEditorSchemaEditorItem } from '@modules/modelEditor/ModelEditorTypes';
import { ViewerCHTableInfoData } from '@modules/viewer/ViewerTypes';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { FocusEvent, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { extractType, isNullable, parseInputName } from './ModelEditorSchemaEditorUtils';

export const useModelEditorSchemaEditor = (
  tableInfo?: ViewerCHTableInfoData[],
  initData?: ModelEditorSchemaEditorItem[],
) => {
  const dispatch = useDispatch();
  const schemaEditor = useSelector(selectModelEditorSchemaEditor);

  const schemaEditorData = schemaEditor?.map((col) => ({
    ...col,
    type: extractType(col.type),
    nullable: col.nullable ?? isNullable(col.type),
  }));

  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      dispatch(modelEditorActions.moveSchemaEditorRow({ dragIndex, hoverIndex }));
    },
    [dispatch],
  );

  useEffect(() => {
    const clearedInitData = initData?.map((el) => ({ name: el?.name, type: el?.type, source: el?.source })) ?? [];
    const isSourceNodeChanged = JSON.stringify(tableInfo) !== JSON.stringify(clearedInitData);

    if (!initData) {
      dispatch(modelEditorActions.initSchemaEditor((tableInfo || []).map((item, index) => ({ ...item, index }))));
    } else {
      const indexes: Record<string, number> = {};
      const customItems = initData.filter((item, index) => {
        indexes[item.name] = index;
        return item.custom;
      });
      const appliedInitData = (
        !isSourceNodeChanged
          ? tableInfo?.map((item, index) => {
              const initItemIndex = initData.findIndex((initItem) => initItem.name === item.name);

              if (initItemIndex >= 0) {
                indexes[item.name] = initItemIndex;
                return initData[initItemIndex];
              }
              indexes[item.name] = index;
              return item;
            }) || []
          : tableInfo || []
      )
        .concat(customItems)
        .sort((a, b) => indexes[a.name] - indexes[b.name]);

      dispatch(modelEditorActions.initSchemaEditor(appliedInitData.map((item, index) => ({ ...item, index }))));
    }

    return () => {
      dispatch(modelEditorActions.destroySchemaEditor());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableInfo]);

  const actions = useMemo(() => {
    return {
      onChangeItemWithCheckbox: (e: CheckboxChangeEvent) => {
        const { index, name } = parseInputName(e.target.name);
        if (index !== undefined && name !== undefined) {
          dispatch(modelEditorActions.changeSchemaEditorItem({ index, field: name, value: e.target.checked }));
        }
      },
      onChangeItemWithInputText: (e?: FocusEvent<HTMLInputElement, Element>) => {
        const { index, name } = parseInputName(e?.target?.name);
        if (index !== undefined && name !== undefined) {
          dispatch(modelEditorActions.changeSchemaEditorItem({ index, field: name, value: e?.target.value.trim() }));
        }
      },
      onChangeItemWithExpression: (fieldName: string, value?: string) => {
        const { index, name } = parseInputName(fieldName);
        if (index !== undefined && name !== undefined) {
          dispatch(modelEditorActions.changeSchemaEditorItem({ index, field: name, value }));
        }
      },
      onChangeItemWithSelect: (fieldName: string, value: any) => {
        const { index, name } = parseInputName(fieldName);
        if (index !== undefined && name !== undefined) {
          dispatch(modelEditorActions.changeSchemaEditorItem({ index, field: name, value }));
        }
      },
      onChangeDeletedAllCheckbox: (e: CheckboxChangeEvent) => {
        dispatch(modelEditorActions.switchSchemaEditorDeletedAll(e.target.checked));
      },
      onAddNewItem: () => {
        dispatch(modelEditorActions.insertSchemaEditorRow());
      },
    };
  }, [dispatch]);

  const numberOfDeletedRows =
    schemaEditorData?.reduce((acc, item) => {
      if (item.deleted) {
        acc++;
      }
      return acc;
    }, 0) || 0;
  const deletedAllValue =
    numberOfDeletedRows === 0 ? false : numberOfDeletedRows === schemaEditorData?.length ? true : null;

  const indexes = schemaEditorData?.reduce((acc, item) => ({ ...acc, [item.name]: item.index }), {}) || {};

  return {
    schemaEditorData,
    actions,
    deletedAllValue,
    indexes,
    moveRow,
  };
};
