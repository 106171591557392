import { ESiderType } from '@modules/study/StudyTypes';
import { MenuType, studyInternalMenu } from '@routes/appMenu';
import { selectGlobalStudy } from '@app/duck/appSelectors';
import { useFeatures, useStudyPermissions } from '@modules/user/duck/userHooks';
import { useSelector } from 'react-redux';
import { StudySiderTemplate } from './StudySiderTemplate';

export const StudySider = ({ kind }: IStudySiderProps) => {
  const globalStudy = useSelector(selectGlobalStudy);
  const { userPermissions } = useStudyPermissions();
  const { hasDeployment } = useFeatures();
  let currentMenu: MenuType = [];

  if (kind === ESiderType.AnalysisObject) {
    const { studyAnalysisObjectsInternalMenu } = require('@routes/library/libraryMenu');
    currentMenu = studyAnalysisObjectsInternalMenu(globalStudy?.id!, userPermissions);
  } else if (kind === ESiderType.Study) {
    currentMenu = studyInternalMenu(globalStudy?.id!, userPermissions);

    if (hasDeployment) {
      if (globalStudy) {
        const { appStudyAnalysisPackageMenu } = require('@routes/library/libraryMenu');
        currentMenu.push(...appStudyAnalysisPackageMenu(globalStudy?.id!, userPermissions));
      }
    }
  }

  return <StudySiderTemplate menu={currentMenu} />;
};
interface IStudySiderProps {
  kind: ESiderType;
}
