export const SKIP_COLUMN = 'skip';

export const INITIAL_PROGRESS = { progress: 0, estimated: 0 };

export enum COLUMN_TYPES {
  Int32 = 'Int32',
  Int64 = 'Int64',
  Float32 = 'Float32',
  Float64 = 'Float64',
  String = 'String',
  Boolean = 'Boolean',
  Date = 'Date',
  DateTime = 'DateTime',
}

export const SYSTEM_COLUMNS = [
  'DS_ID',
  'DS_UPDATED_AT',
  'AUD_SNAPSHOT',
  'PARTITION_0',
  'LAST_MODIFICATION',
  'CDR_SKEY',
  'MODIFIEDDATETIME',
];
