export enum LIBRARY_TAG_DESCRIPTION {
  'LIST' = 'LIBRARY_LIST',
  'ID' = 'LIBRARY_ID',
}

export enum LibraryStatus {
  Active = 'active',
  Development = 'development',
  Retired = 'retired',
}
