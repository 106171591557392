import { Input, Popover } from '@ui';
import { CSSObject, Theme } from '@emotion/react';
import React, { useRef, useState } from 'react';

const getValue = (range: number[], values: number[] = []) =>
  range
    .reduce((acc, value, index) => {
      if (values.includes(value)) {
        const banchOfValues = acc.at(-1) || [];
        const lastValue = banchOfValues.at(-1);
        if (lastValue !== undefined && lastValue === range[index - 1]) {
          acc.at(-1)?.push(value);
        } else {
          acc.push([value]);
        }
      }

      return acc;
    }, [] as Array<number[]>)
    .reduce((acc, value) => {
      if (value.length === 1) {
        acc.push(value.toString());
      } else {
        acc.push(`${value.at(0)}-${value.at(-1)}`);
      }

      return acc;
    }, [] as string[])
    .join(', ');

export const RangeNumberPicker: React.FC<IRangeNumberPickerProps> = ({
  placeholder,
  value,
  range,
  disabled,
  renderValue,
  onChange,
}) => {
  const [active, setActive] = useState(false);
  const refInput = useRef<HTMLInputElement>(null);

  const triggerChange = (v: number) => {
    const values = value?.includes(v) ? value.filter((current) => v !== current) : (value || []).concat(v);
    onChange?.(values.sort((prev, next) => prev - next));
  };

  return (
    <Popover
      content={
        <div css={cssBody}>
          {range.map((v) => (
            <div
              css={cssItem({ isActive: value?.includes(v) })}
              key={v}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                triggerChange(v);
              }}
            >
              {renderValue?.(v) || v}
            </div>
          ))}
        </div>
      }
      title=""
      trigger={['click']}
      open={active}
      onOpenChange={() => {
        if (active) {
          refInput.current?.blur();
        }
        setActive(!active);
      }}
      arrowContent={null}
      placement="bottom"
    >
      <Input
        css={cssInput}
        ref={refInput as any}
        value={getValue(range, value)}
        placeholder={placeholder}
        disabled={disabled}
      />
    </Popover>
  );
};

interface IRangeNumberPickerProps {
  value?: number[];
  range: number[];
  placeholder?: string;
  disabled?: boolean;
  renderValue?: (item: number) => string;
  onChange?: (value: number[]) => void;
}

const cssInput = (): CSSObject => ({
  caretColor: 'transparent',
});

const cssBody = (): CSSObject => ({
  minWidth: '260px',
  display: 'grid',
  columnGap: '1px',
  rowGap: '1px',
  gridTemplateColumns: 'repeat(6, 1fr)',
});

const cssItem =
  ({ isActive }: { isActive?: boolean }) =>
  (theme: Theme): CSSObject => ({
    textAlign: 'center',
    transition: 'background-color 300ms, color 300ms',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.colorPrimaryHover,
      color: theme.colorTextInverse,
    },
    backgroundColor: isActive ? theme.colorPrimaryActive : theme.colorPrimaryBg,
    color: isActive ? theme.colorTextInverse : theme.colorPrimaryText,
  });
