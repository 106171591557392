import { TFormattedPermissions, TPermissions } from '@modules/auth/AuthTypes';
import { capitalize } from 'lodash';

export const camelCaseObjectName = (name: string) =>
  name
    .split('_')
    .map((el) => capitalize(el))
    .join('');

export const formatUserPermissions = (userPermissions: TPermissions) =>
  Object.entries(userPermissions).reduce(
    (acc, [k, v]) => ({
      ...acc,
      [`can${camelCaseObjectName(k)}`]: v,
    }),
    {} as TFormattedPermissions,
  );
