import { selectAuthUser } from '@modules/auth/duck/AuthSelector';
import { selectAppENV } from '@app/duck/appSelectors';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';

export const useEnvironments = (hideCurrentLabel?: boolean) => {
  const user = useSelector(selectAuthUser);
  const appENV = useSelector(selectAppENV);
  const userEnvs = user?.environments;
  const userCurrentEnv = user?.env_name || '';

  const envOptions = useMemo(
    () =>
      Object.entries(userEnvs || {}).map(([k, v]) => ({
        value: k,
        label: !hideCurrentLabel && k === userCurrentEnv ? `Current (${v?.label})` : v?.label || '',
      })),
    [userEnvs, hideCurrentLabel, userCurrentEnv],
  );

  const checkExternalSource = ({
    targetSource,
    targetLabel,
  }: {
    targetSource?: string | null;
    targetLabel?: string | null;
  }) => {
    if (targetLabel) {
      [targetSource] = Object.entries(userEnvs || {}).find(([key, value]) => value?.label === targetLabel) || [];
    }

    const targetSourceEnvData = (userEnvs || {})[targetSource || ''] || undefined;
    const isExternalSource = targetSourceEnvData ? targetSource !== user?.env_name : undefined;

    return {
      targetSourceEnv: targetSourceEnvData ? targetSource! : undefined,
      targetSourceEnvData,
      isExternalSource,
    };
  };

  const getLableSource = (targetSource?: string) => {
    return (userEnvs || {})[targetSource || '']?.label;
  };

  return {
    currentAppEnv: appENV,
    userCurrentEnv,
    userEnvs,
    envOptions,
    checkExternalSource,
    getLableSource,
  };
};
