import { useSnapshotListQuery } from '@modules/snapshot/duck/snapshotApi';
import { SnapshotList } from '@modules/snapshot/components/SnapshotList';
import { PageTemplateSimple, RefreshButton } from '@components';
import { Button, Space } from '@ui';
import routes from '@routes';
import { selectGlobalStudy } from '@app/duck/appSelectors';
import { useStudyPermissions } from '@modules/user/duck/userHooks';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const SnapshotRootPage = () => {
  const snapshotListQuery = useSnapshotListQuery();
  const { t } = useTranslation(['snapshot']);
  const globalStudy = useSelector(selectGlobalStudy);
  const {
    userPermissions: { canSnapshotsInsert },
  } = useStudyPermissions();

  return (
    <PageTemplateSimple
      hideTitleSkeleton
      title={{
        children: t('pageRootName'),
        extra: (
          <Space>
            <RefreshButton onClick={snapshotListQuery.refetch} iconOnly />
            {canSnapshotsInsert && (
              <Link to={routes.study.snapshots.create.resolver({ studyId: globalStudy!.id })}>
                <Button children={t('add')} />
              </Link>
            )}
          </Space>
        ),
      }}
      content={{ isLoading: snapshotListQuery.isLoading }}
    >
      {!snapshotListQuery.isLoading && (
        <SnapshotList data={snapshotListQuery.data || []} loading={snapshotListQuery.isFetching} t={t} />
      )}
    </PageTemplateSimple>
  );
};
