export const emptyError = {
  isError: false,
  errorMessage: '',
};

export const initialMapping = {
  referenceTable: undefined,
  total_columns: 0,
  total_rows: 0,
  mapping: [],
  confirmed: false,
  skip: false,
  ...emptyError,
};

export const DEFAULT_PK = 'DS_ID';
