import { appActions } from '@app/duck/appSlice';
import { TLibraryModalsLibraryState, TLibraryModalsPushModalPayload } from '@modules/library/root/modals';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface LibraryStore {
  ready: boolean;
  preloaded: boolean;
  modals: TLibraryModalsLibraryState;
}

const initialState: LibraryStore = {
  ready: false,
  preloaded: false,
  modals: { selectedModal: null },
};

export const librarySlice = createSlice({
  name: 'library',
  initialState,
  reducers: {
    setReady: (state, action: PayloadAction<boolean>) => {
      state.ready = action.payload;
    },
    pushModal: (state, action: PayloadAction<TLibraryModalsPushModalPayload>) => {
      const { type, data } = action.payload;
      state.modals = { selectedModal: type, data };
    },
    dropModal: (state, action: PayloadAction<void>) => {
      state.modals = initialState.modals;
    },
    reset: (state) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(appActions.setContext, (state, { payload }) => {
        state.ready = payload.type === 'library' && !!(payload.context?.id && payload.context?.name);
        state.preloaded = payload.type === 'library' && !!payload.context?.id;
      })
      .addDefaultCase((state, action) => {});
  },
});

export const libraryActions = librarySlice.actions;

export const libraryReducer = librarySlice.reducer;
