import { ELibrarySourceType, IBaseLibraryEntitySystemInfo } from '@modules/library/root/LibraryTypes';
import { ILibraryImportCDR } from '../components/LibraryImportCDR';
import { ECdrType, ICDRReport, ILibraryCDR, ILibraryCDRQuery } from '../LibraryCdrTypes';

export const prepareLibraryCDR = (
  data: ILibraryImportCDR[],
  kind: ELibrarySourceType,
  systemInfo?: IBaseLibraryEntitySystemInfo,
): ILibraryCDRQuery['data'] => {
  if (!Array.isArray(data)) return [];
  return data.map((item) => {
    if (kind === ELibrarySourceType.Library) {
      const rawData = item.rawData as ILibraryCDR;
      return {
        id: rawData.id,
        name: rawData.name,
        object_type: rawData.object_type || ECdrType.Report,
        configuration: rawData.configuration,
        version_id: rawData.version_id,
        source: rawData.source || '',
        env: rawData.env || '',
        tenant_info: rawData.tenant_info || '',
      };
    }

    const { id, ...rawData } = item.rawData as ICDRReport;
    return {
      name: rawData.name,
      object_type: rawData.type || ECdrType.Report,
      configuration: rawData,
      source: systemInfo?.source || '',
      env: systemInfo?.env || '',
      tenant_info: systemInfo?.tenant_info || '',
    };
  });
};
