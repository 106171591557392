import { appActions } from '@app/duck/appSlice';
import { ModelModalsPushModalPayload, ModelModalsState } from '@modules/model/modals';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: ModelStore = {
  dataViewer: {
    tableKey: null,
    tableName: null,
    isReview: false,
    opened: false,
  },
  modals: { selectedModal: null },
};

export const modelSlice = createSlice({
  name: 'model',
  initialState,
  reducers: {
    pushModal: (state, action: PayloadAction<ModelModalsPushModalPayload>) => {
      const { type, data } = action.payload;
      state.modals = { selectedModal: type, data };
    },
    dropModal: (state) => {
      state.modals = initialState.modals;
    },
    setDataViewer: (state, action: PayloadAction<DataViewerTablePayload>) => {
      const { tableKey, tableName, isReview = false, sourceEnv } = action.payload;
      state.dataViewer = {
        sourceEnv,
        tableKey,
        tableName,
        isReview,
        opened: !!tableKey && !!tableName,
      };
    },
    setSelectedModelEnv: (state, action: PayloadAction<string>) => {
      state.selectedModelEnv = action.payload;
    },
    closeDataViewer: (state) => {
      state.dataViewer = {
        ...state.dataViewer,
        opened: false,
      };
    },
    reset: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(appActions.setContext, (state, action) => {
        state.dataViewer = initialState.dataViewer;
      })
      .addDefaultCase((state, action) => {});
  },
});

export const modelActions = modelSlice.actions;

export const modelReducer = modelSlice.reducer;

interface ModelStore {
  dataViewer: {
    tableKey: string | null;
    tableName: string | null;
    opened: boolean;
    isReview: boolean;
    sourceEnv?: string;
  };
  selectedModelEnv?: string;
  modals: ModelModalsState;
}

interface DataViewerTablePayload {
  tableKey: string | null;
  tableName: string | null;
  sourceEnv?: string;
  isReview?: boolean;
}
