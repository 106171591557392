import { I18nLocales } from '@i18n';
import en from './model_en.json';

export const modelLocales: Record<I18nLocales, I18nModelTranslations> = {
  en,
};

interface I18nModelTranslations {
  pageRootName: string;
  loadingError: string;
  rootTable: {
    name: string;
    dataStore: string;
    intermediate: string;
    created: string;
    updated: string;
    sync: string;
    build: string;
    syncStates: {
      auto: string;
      once: string;
      disabled: string;
      period: string;
      manual: string;
    };
    schedulerStates: {
      auto: string;
      once: string;
      disabled: string;
      period: string;
    };
    actionMenu: {
      label: string;
      viewLog: string;
      edit: string;
    };
  };
  logTitle: string;
  logErrorTitle: string;
  confirmation: {
    title: string;
    description: string;
  };
  saveForm: {
    title: string;
    titleEdit: string;
    name: string;
    description: string;
    dataStore: string;
    intermediate: string;
    status: string;
  };
  builder: {
    runAndSave: string;
    unknownName: string;
    confirmation: {
      warn: string;
      delete: {
        title: string;
        description: string;
      };
      deleteEdge: {
        title: string;
        description: string;
      };
      deleteAll: {
        title: string;
        description: string;
      };
    };
    controls: {
      zoomIn: string;
      zoomOut: string;
      interactivity: string;
      autolayout: string;
      deleteAll: string;
    };
  };
}
