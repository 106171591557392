import { TFunction } from 'i18next';
import { LibraryAuditLogItemAction, LibraryAuditLogItemKind } from '../LibraryAuditTypes';

export const getActionTitle = (t: TFunction, action: LibraryAuditLogItemAction) => {
  switch (action) {
    case 'CREATED':
      return t('audit.table.created');
    case 'UPDATED':
      return t('audit.table.updated');
    case 'VERSION_CREATED':
      return t('audit.table.versionCreated');
    case 'VERSION_REMOVED':
      return t('audit.table.versionRemoved');
    case 'DELETED':
      return t('audit.table.deleted');
    default:
      return action;
  }
};

export const getKindTitle = (t: TFunction, kind?: LibraryAuditLogItemKind) => {
  switch (kind) {
    case 'MODEL':
      return t('audit.table.model');
    case 'GL':
      return t('audit.table.library');
    case 'REPORT':
      return t('audit.table.report');
    case 'SQLLAB':
      return t('audit.table.sqllab');
    case 'NOTEBOOK':
      return t('audit.table.notebook');
    default:
      return kind;
  }
};
