import { BaseIcon, SvgIconProps } from '@components/icons/BaseIcon';
import { forwardRef, ForwardRefExoticComponent, JSXElementConstructor } from 'react';

export const AntdIconBox: ForwardRefExoticComponent<AntdIconBoxProps> = forwardRef(({ icon, ...props }, ref) => (
  <BaseIcon {...props} forwardRef={ref} IconElement={icon} />
));

interface AntdIconBoxProps extends SvgIconProps {
  icon: JSXElementConstructor<any>;
}
