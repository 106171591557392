import { selectLibraryModelModals } from '@modules/library/model/duck/libraryModelSelectors';
import { libraryModelActions } from '@modules/library/model/duck/libraryModelSlice';
import { ELibraryModelModalsType } from '@modules/library/model/modals';
import { useDispatch, useSelector } from 'react-redux';
import { LibraryModelModalsCopyModel, ILibraryModelModalsCopyModelProps } from './components';

export const LibraryModelModalsController = () => {
  const dispatch = useDispatch();
  const { data, selectedModal } = useSelector(selectLibraryModelModals);

  const closeModal = () => {
    dispatch(libraryModelActions.dropModal());
  };

  return (
    <LibraryModelModalsCopyModel
      open={selectedModal === ELibraryModelModalsType.copyModel}
      data={data as ILibraryModelModalsCopyModelProps['data']}
      onClose={closeModal}
    />
  );
};
