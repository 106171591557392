import { appApi } from '@config/appApi';
import {
  ILibraryCdrListQueryParams,
  ILibraryCDRQuery,
  ILibraryCDRResponse,
  ILibraryCDRValidateQuery,
} from '@modules/library/cdr/LibraryCdrTypes';
import { LIBRARY_CDR_TAG_DESCRIPTION } from '@modules/library/cdr/duck/libraryCdrConstants';
import { TagDescription } from '@reduxjs/toolkit/query/react';

export const LibraryCdrApiRoutes = {
  list: `api/library/libraries/cdr`,
  model: (reportId: number) => `api/library/libraries/cdr/${reportId}`,
  validate: `/api/library/libraries/cdr/validate`,
};

const LibraryCdrInvalidations: {
  LIST: TagDescription<LIBRARY_CDR_TAG_DESCRIPTION.LIST>;
  LIST_BY_STUDY: TagDescription<LIBRARY_CDR_TAG_DESCRIPTION.LIST_BY_STUDY>;
  ID: (modelId: number) => TagDescription<LIBRARY_CDR_TAG_DESCRIPTION.ID>;
} = {
  LIST: { type: LIBRARY_CDR_TAG_DESCRIPTION.LIST, id: 'LIST' },
  LIST_BY_STUDY: { type: LIBRARY_CDR_TAG_DESCRIPTION.LIST_BY_STUDY, id: 'LIST_BY_STUDY' },
  ID: (reportId: number) => ({ type: LIBRARY_CDR_TAG_DESCRIPTION.ID, reportId }),
};

export const LibraryCdrApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    libraryCdrList: builder.query<ILibraryCDRResponse, ILibraryCdrListQueryParams | void>({
      providesTags: [LibraryCdrInvalidations.LIST],
      query: (params) => ({
        params,
        url: LibraryCdrApiRoutes.list,
      }),
    }),
    libraryDeleteCdr: builder.mutation<void, number>({
      invalidatesTags: (request, error, reportId) => [
        LibraryCdrInvalidations.LIST,
        LibraryCdrInvalidations.ID(reportId),
      ],
      query: (modelId) => ({
        method: 'DELETE',
        url: LibraryCdrApiRoutes.model(modelId),
      }),
    }),
    libraryCopyCdr: builder.mutation<void, ILibraryCDRQuery>({
      invalidatesTags: [LibraryCdrInvalidations.LIST],
      query: ({ data, overwrite, cascade_update }) => ({
        data: {
          data,
          cascade_update,
        },
        params: { overwrite },
        method: 'POST',
        url: LibraryCdrApiRoutes.list,
      }),
    }),
    usedCDR: builder.mutation<void, ILibraryCDRValidateQuery>({
      query: (data) => ({
        data,
        method: 'POST',
        url: LibraryCdrApiRoutes.validate,
      }),
    }),
  }),
});

export const {
  useLibraryCdrListQuery,
  useLazyLibraryCdrListQuery,
  useLibraryDeleteCdrMutation,
  useLibraryCopyCdrMutation,
  useUsedCDRMutation,
} = LibraryCdrApi;
