import { useColumnSearch } from '@components/ui/table/tableHooks';
import { DatasetScopeList, DatasetScopeListScope } from '@modules/dataset/components';
import { Scope } from '@modules/dataset/DatasetTypes';
import { TableColumnsType } from 'antd';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IAOSQLDatasetItem } from '../AnalysisObjectSQLDatasetTypes';

export const useSQLDatasetColumns = () => {
  const { getColumnSearchProps, locale } = useColumnSearch<IAOSQLDatasetItem>();
  const [studiesDetailsModal, setStudiesDetailsModal] = useState<DatasetScopeListScope>();
  const { t } = useTranslation(['analysisObjects']);

  const sqlDatasetsColumns: TableColumnsType<IAOSQLDatasetItem> = useMemo(
    () => [
      {
        title: t('rootTable.name'),
        dataIndex: 'name',
        key: 'name',
        ellipsis: true,
        ...getColumnSearchProps('name'),
      },
      {
        title: t('sqlDatasetAO.rootTable.scope'),
        dataIndex: 'object_scope',
        key: 'object_scope',
        render: (object_scope: Scope) => (
          <DatasetScopeList currentStudyName={'-'} onMoreBtnClick={setStudiesDetailsModal} scope={object_scope} />
        ),
      },
      {
        width: 100,
        title: t('rootTable.source'),
        key: 'source',
        dataIndex: 'source',
        render: (_, record) => record?.source || '-',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return { sqlDatasetsColumns, studiesDetailsModal, setStudiesDetailsModal, locale };
};
