import AntdTag, { TagProps as AntdTagProps } from 'antd/es/tag';
import { ForwardedRef, forwardRef } from 'react';

const colorsMap: Record<TagType, string> = {
  blinded: 'green',
  filter: 'purple',
  snapshot: 'geekblue',
  source: 'cyan',
  review: 'magenta',
};

export const Tag = forwardRef(({ type, text, upperCaseText, ...props }: TagProps, ref: ForwardedRef<any>) => {
  if (type) {
    return (
      <AntdTag
        {...props}
        children={text ? (upperCaseText ? text.toUpperCase() : text) : props.children}
        color={colorsMap[type]}
        ref={ref}
      />
    );
  }
  return <AntdTag {...props} ref={ref} />;
});

type TagType = 'filter' | 'source' | 'snapshot' | 'blinded' | 'review';

interface TagProps extends AntdTagProps {
  type?: TagType;
  text?: string;
  upperCaseText?: boolean;
}
