import { MODEL_STATUSES_TIMEOUT } from '@modules/model/duck/modelConstants';
import { useLazyRunModelStatusQuery } from '@modules/model/duck/modelApi';
import { RunModelStatus } from '@modules/model/ModelTypes';
import { selectModelModals } from '@modules/model/duck/modelSelectors';
import { ModelModalsType } from '@modules/model/modals';
import { useEffect, useState } from 'react';
import { isArray } from 'lodash';
import { useSelector } from 'react-redux';

export const useModelRunStatusListener = () => {
  const [runModelStatusQuery, { isLoading }] = useLazyRunModelStatusQuery();
  const { selectedModal } = useSelector(selectModelModals);
  const [statuses, setStatuses] = useState<RunModelStatus[]>([]);
  const refRunModelStatusesState: { intervalId: null | NodeJS.Timer; lastFetch: number | null } = {
    intervalId: null,
    lastFetch: null,
  };

  let isFetchStatuses = false;

  const getStatuses = async () => {
    try {
      const data = await runModelStatusQuery().unwrap();
      setStatuses(isArray(data) ? data : []);
    } catch (err) {
      console.error(err);
    }
  };

  const currentFetchRunModelStatuses = () => {
    if (isFetchStatuses) {
      return;
    }
    refRunModelStatusesState.lastFetch = new Date().valueOf();
    isFetchStatuses = true;

    getStatuses().finally(() => {
      isFetchStatuses = false;
    });
  };

  const runModelStatusesLoop = () => {
    if (!refRunModelStatusesState.intervalId) {
      refRunModelStatusesState.intervalId = setInterval(currentFetchRunModelStatuses, MODEL_STATUSES_TIMEOUT);
      if (
        !refRunModelStatusesState.lastFetch ||
        new Date().valueOf() - refRunModelStatusesState.lastFetch > MODEL_STATUSES_TIMEOUT
      ) {
        currentFetchRunModelStatuses();
      }
    }
  };

  const stopRunModelStatusesLoop = () => {
    if (refRunModelStatusesState.intervalId) {
      clearInterval(refRunModelStatusesState.intervalId);
      refRunModelStatusesState.intervalId = null;
    }
  };

  const onVisibilityChangeHandler = (selectedModal: ModelModalsType | null) => () => {
    const isModalOpen = selectedModal === ModelModalsType.saveModel;

    if (document.visibilityState === 'hidden' || isModalOpen) {
      stopRunModelStatusesLoop();
    } else {
      runModelStatusesLoop();
    }
  };

  useEffect(() => {
    const onVisibilityChange = onVisibilityChangeHandler(selectedModal);
    document.addEventListener('visibilitychange', onVisibilityChange);

    onVisibilityChange();

    return () => {
      stopRunModelStatusesLoop();
      document.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, [selectedModal]);

  return { statuses, isLoading };
};
