import { ViewerCHTableInfoData } from '@modules/viewer/ViewerTypes';

export enum ModelEditorStageActionType {
  delete = 'Delete',
  show = 'Show',
  settings = 'Settings',
}

export enum ModelEditorNodeType {
  result = 'result',
  domain = 'domain',
  join = 'join',
  union = 'union',
  pivot = 'pivot',
  sql = 'sql',
  unpivot = 'unpivot',
  groupBy = 'groupBy',
  transform = 'transform',
}

export enum ModelEditorEdgeType {
  button = 'buttonEdge',
}

export enum ModelEditorNodeJoinType {
  inner = 'inner',
  left = 'left',
  right = 'right',
  full = 'full',
  cross = 'cross',
}

export enum ModelEditorNodeUnionMode {
  byPosition = 'ByPosition',
  byName = 'ByName',
}

export enum ModelEditorNodeUnionType {
  all = 'all',
  distinct = 'distinct',
}

export enum ModelEditorNodeJoinOperations {
  eq = '=',
  ne = '<>',
  lt = '<',
  le = '<=',
  gt = '>',
  ge = '>=',
}

export enum ModelEditorNodeGroupByOperations {
  avg = 'AVG',
  count = 'COUNT',
  max = 'MAX',
  min = 'MIN',
  sum = 'SUM',
}

export enum ModelEditorNodePivotAggregateFnOperations {
  FIRST = 'FIRST',
  SUM = 'SUM',
  AVG = 'AVG',
  MAX = 'MAX',
  MIN = 'MIN',
}

interface ModelEditorNodeBase {
  name: string;
  tableName?: string;
}

export interface ModelEditorNodeResult extends ModelEditorNodeBase {
  primaryKeys?: string[];
  columns: ColumnType[];
}

export interface ModelEditorNodeUnion extends ModelEditorNodeBase {
  type: ModelEditorNodeUnionType;
  mode: ModelEditorNodeUnionMode;
}

export interface ModelEditorNodePivot extends ModelEditorNodeBase {
  groupingColumns: string[];
  pivotColumn: string;
  aggregateFn: ModelEditorNodePivotAggregateFnOperations;
  columns: string[];
  translatedValues: string[];
}

export interface ModelEditorNodeUnPivot extends ModelEditorNodeBase {
  unchangedColumns: string[];
  unpivotColumns: string[];
  unpivotNames: string[];
}

export interface ModelEditorNodeDomain extends ModelEditorNodeBase {
  exists?: boolean;
  modelName?: string;
  final?: boolean;
}

export interface ModelEditorNodeJoin extends ModelEditorNodeBase {
  type: ModelEditorNodeJoinType;
  relations: Array<{ leftColumn: string; rightColumn: string }>;
}

export interface ModelEditorNodeJoin_deprecated extends ModelEditorNodeBase {
  type: ModelEditorNodeJoinType;
  relations: string;
}

export interface ModelEditorNodeGroupBy extends ModelEditorNodeBase {
  dropGroupingColumns?: boolean;
  groupBy: string[];
  aggregateFn: Array<{ operation: string; variable: string }>;
}

export type ModelEditorNodeGroupByRelation = {
  variable: string;
  alias?: string;
  operation?: ModelEditorNodeGroupByOperations;
  groupBy: boolean;
};

export interface ModelEditorNodeTransformation extends ModelEditorNodeBase {
  transformation: ModelEditorSchemaEditorItem[];
  lineage: Record<string, string[]>;
}

export interface ModelEditorNodeSql extends ModelEditorNodeBase {
  sql_schema: ColumnType[];
  sql_statement: string;
  preprocessed_sql?: string;
  isValid?: boolean;
  modelName?: string;
}

type ModelEditorNodeAggregatedDataType =
  | ModelEditorNodeResult
  | ModelEditorNodeDomain
  | ModelEditorNodeJoin
  | ModelEditorNodeUnion
  | ModelEditorNodePivot
  | ModelEditorNodeSql
  | ModelEditorNodeTransformation;

export interface ModelEditorNode {
  id: string;
  type: ModelEditorNodeType;
  children: string[];
  data: ModelEditorNodeAggregatedDataType & { isCheckingNeeded?: boolean | undefined };
  position: { x: number; y: number };
}

export type ModelEditor = ModelEditorNode[];

export interface ModelEditorSchemaEditorItem extends ViewerCHTableInfoData {
  key?: string;
  index: number;
  deleted?: boolean;
  expression?: string;
  length?: string;
  precision?: string;
  scale?: string;
  custom?: boolean;
  newName?: string;
  nullable?: boolean;
  source?: string[];
}

export interface ModelEditorValidationError {
  message: string;
  node?: ModelEditorNode;
}

export interface ColumnType {
  name: string;
  type: string;
  source?: string[];
}
