import { Button, notification, Popconfirm, Space } from '@ui';
import { viewerActions } from '@modules/viewer/duck/viewerSlice';
import routes from '@routes';
import { PREFIX_SQL_LAB_DATASET } from '@shared/GlobalConstants';
import { getFormattedDnaPlatformUrl, getStudyPathName } from '@routes/utils';
import { DeleteDatasetProps } from '@modules/dataset/DatasetTypes';
import { isCrossStudy } from '@shared/utils/common';
import { QueryErrorType } from '@shared/utils/Error';
import { useAppPermissions } from '@modules/user/duck/userHooks';
import { useDeleteDatasetMutation } from '@modules/dataset/duck/datasetApi';
import { selectGlobalStudy } from '@app/duck/appSelectors';
import { DeleteOutlined, EditOutlined, FundViewOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TFunction } from 'i18next';

export const DatasetActionButtons = ({ record, studyId, t }: DatasetActionButtonsProps) => {
  const dispatch = useDispatch();
  const [deleteDataset, deleteDatasetQuery] = useDeleteDatasetMutation();
  const {
    appPermissions: { canDatasetsDelete, canDatasetsInsert },
  } = useAppPermissions();

  const globalStudy = useSelector(selectGlobalStudy);

  const navigate = useNavigate();
  const { id, name } = record;

  const onDeleteDataset = async (value: DeleteDatasetProps) => {
    const { id, name } = value;
    try {
      const isGlobal = isCrossStudy(globalStudy?.id!);
      await deleteDataset(id).unwrap();
      // TODO this new api doesn't work if we use them from datastage. Need to change after backend changes
      // await deleteDataset({ name, isGlobal }).unwrap();
      notification.success({ message: t('rootTable.actions.delete.successDeleteMessage', { name }) });
    } catch (e) {
      console.error(e);
      notification.error({
        message: t('rootTable.actions.delete.errorDeleteMessage', { name }),
        description: (e as QueryErrorType).data.userMsg,
      });
    }
  };

  const showEditBtn = canDatasetsInsert && record?.name?.startsWith(PREFIX_SQL_LAB_DATASET);

  return (
    <Space>
      {showEditBtn && (
        <Button
          title={t('rootTable.actions.edit')}
          icon={<EditOutlined />}
          loading={deleteDatasetQuery.isLoading}
          onClick={() => {
            const pathToCdrEditDataset = getFormattedDnaPlatformUrl({
              customPath: '/superset/sqllab/',
              customParams: `dataset_name=${record.name}&dataset_study_id=${studyId}`,
            });
            window.open(pathToCdrEditDataset, '_blank');
          }}
        />
      )}
      <Button
        title={t('rootTable.actions.viewData')}
        icon={<FundViewOutlined />}
        loading={deleteDatasetQuery.isLoading}
        onClick={() => {
          dispatch(viewerActions.setLeftSideData({}));
          navigate(
            routes[getStudyPathName(studyId)].dataViewer.resolver({
              studyId,
              tableId: name,
            }),
            { state: { external: true } },
          );
        }}
      />
      {canDatasetsDelete && (
        <Popconfirm
          title={t('rootTable.actions.delete.confirmationDeleteTitle')}
          description={
            <div style={{ whiteSpace: 'pre-line' }}>
              {!isCrossStudy(studyId)
                ? t('rootTable.actions.delete.confirmationScopeDelete')
                : t('rootTable.actions.delete.confirmationDelete')}
            </div>
          }
          okText={t('yes')}
          cancelText={t('no')}
          onConfirm={() => onDeleteDataset({ id, name })}
        >
          <Button
            title={t('rootTable.actions.delete.title')}
            icon={<DeleteOutlined />}
            loading={deleteDatasetQuery.isLoading}
          />
        </Popconfirm>
      )}
    </Space>
  );
};

interface DatasetActionButtonsProps {
  record: any;
  t: TFunction;
  studyId: number;
}
