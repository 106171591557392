import { selectLibraryNotebookModals } from '@modules/library/notebook/duck/libraryNotebookSelectors';
import { libraryNotebookActions } from '@modules/library/notebook/duck/libraryNotebookSlice';
import { ELibraryNotebookModalsType } from '@modules/library/notebook/modals/LibraryNotebookModalsConstants';
import { useDispatch, useSelector } from 'react-redux';
import { LibraryNotebookModalsCopyNotebook, ILibraryNotebookModalsCopyNotebookProps } from './components';

export const LibraryNotebookModalsController = () => {
  const dispatch = useDispatch();
  const { data, selectedModal } = useSelector(selectLibraryNotebookModals);

  const closeModal = () => {
    dispatch(libraryNotebookActions.dropModal());
  };

  return (
    <LibraryNotebookModalsCopyNotebook
      open={selectedModal === ELibraryNotebookModalsType.copyNotebook}
      data={data as ILibraryNotebookModalsCopyNotebookProps['data']}
      onClose={closeModal}
    />
  );
};
