import { appApi } from '@config/appApi';
import { TagDescription } from '@reduxjs/toolkit/query/react';
import { LIBRARY_AUDIT_TAG_DESCRIPTION } from './libraryAuditConstants';
import {
  ILibraryExport,
  LibraryAuditLogDiffResponse,
  LibraryAuditLogListQueryParams,
  LibraryAuditLogListResponse,
} from '../LibraryAuditTypes';

export const LibraryAuditApiRoutes = {
  logList: '/api/library/audit/logs',
  logDiff: (id: number) => `/api/library/audit/${id}/diff`,
  libraryExport: '/api/library/audit/export',
};

export const LibraryAuditInvalidations: {
  LIST: TagDescription<LIBRARY_AUDIT_TAG_DESCRIPTION.LIST>;
  DIFF: (id: number) => TagDescription<LIBRARY_AUDIT_TAG_DESCRIPTION.DIFF>;
} = {
  LIST: { type: LIBRARY_AUDIT_TAG_DESCRIPTION.LIST, id: 'LIST' },
  DIFF: (id: number) => ({ type: LIBRARY_AUDIT_TAG_DESCRIPTION.DIFF, id }),
};

export const LibraryAuditApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    libraryAuditLogList: builder.query<LibraryAuditLogListResponse, LibraryAuditLogListQueryParams | void>({
      keepUnusedDataFor: 0,
      providesTags: [LibraryAuditInvalidations.LIST],
      query: (params) => ({
        params,
        url: LibraryAuditApiRoutes.logList,
      }),
    }),
    libraryAuditLogDiff: builder.query<LibraryAuditLogDiffResponse, number>({
      providesTags: (request, error, logId) => [LibraryAuditInvalidations.DIFF(logId)],
      query: (logId) => ({
        url: LibraryAuditApiRoutes.logDiff(logId),
      }),
    }),
    libraryExportToExcel: builder.mutation<ILibraryExport, number>({
      query: (library_id) => ({
        params: { library_id },
        method: 'POST',
        url: LibraryAuditApiRoutes.libraryExport,
      }),
    }),
  }),
});

export const { useLibraryAuditLogListQuery, useLibraryAuditLogDiffQuery, useLibraryExportToExcelMutation } =
  LibraryAuditApi;
