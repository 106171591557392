import { I18nLocales } from '@i18n';
import en from './audit_en.json';

export const auditLocales: Record<I18nLocales, I18nAuditTranslations> = {
  en,
};

export interface I18nAuditTranslations {
  list: {
    title: string;
    description: string;
  };
  table: {
    filter: {
      noData: string;
    };
    column: {
      action: string;
      username: string;
      date: string;
      user: string;
      kind: string;
      name: string;
      ip: string;
    };
    created: string;
    updated: string;
    deleted: string;
    model: string;
    source: string;
    store: string;
    diff: string;
  };
  details: {
    title: string;
  };
  errors: {
    loadFailed: string;
  };
}
